import { type CurrencyIRI, type Money, moneyFromServer, type TaxRate, priceToServer, type Currency } from '@/modules/money';
import { getStringEnumValues, stringToServer } from '@/utils/common';
import { DateTime } from 'luxon';
import { Log, type LogFromServer } from '../Log';
import { type Entity, Id, type IRI } from '../Id';
import { InvoicingIdentity, type InvoicingIdentityFromServer, type FileToServer, type InvoicingIdentityUpdateToServer, invoicingIdentityUpdateToServer, type InvoicingIdentityUpdate } from '@/types/Invoicing';
import { ClientInfo, type ClientInfoFromServer } from '../Client';
import { logoToServer } from '@/components/settings/PersonalizationForm';
import { type EventItemToServer, EventOrderItem, type EditableEventItem } from './EventOrderItem';
import { basicItemUpdateToServer, CustomOrderItem, type BasicItemUpdateToServer, type CustomItemToServer, type EditableBasicItem } from './CustomOrderItem';
import { orderItemFromServer, type OrderItem, type OrderItemFromServer } from './OrderItem';
import { CreditOrderItem } from './CreditOrderItem';
import { ProductOrderItem } from './ProductOrderItem';
import { type i18n } from 'i18next';
import { clientOrContactToServer, type Participant, getParticipantName, type PayingParticipant, type ClientOrContactToServer } from '../EventParticipant';
import { type Product } from '../Product';
import { type Event } from '../Event';
import { type MasterContext } from '@/context/UserProvider';
import { type TeamMember } from '../Team';

export const MAX_DESCRIPTION_LENGTH = 2000;
export const DEFAULT_PREFIX = 'FLOW-';

export enum OrderState {
    Fulfilled = 'fulfilled',
    New = 'new',
    Overdue = 'overdue',
    Canceled = 'canceled',
}

export const ORDER_STATE_VALUES = getStringEnumValues(OrderState);

export type InvoiceInfo = {
    readonly prefix: string;
    readonly index: number;
};

function invoiceInfoFromServer(input: OrderInfoFromServer): InvoiceInfo | undefined {
    if (input.prefix === undefined || input.index === undefined)
        return undefined;

    return { prefix: input.prefix, index: input.index };
}

export type OrderInfoFromServer = {
    '@id': IRI;
    client: ClientInfoFromServer;
    prefix?: string;
    index?: number;
    title: string;
    total: number;
    currency: CurrencyIRI;
    state: OrderState;
    createdAt: string;
    issueDate: string;
    stripeOrder: boolean;
    /** AppUser. */
    scheduler?: IRI;
}

export class OrderInfo implements Entity {
    private constructor(
        readonly id: Id,
        readonly client: ClientInfo,
        readonly title: string,
        readonly price: Money,
        readonly state: OrderState,
        readonly createdAt: DateTime,
        readonly issueDate: DateTime,
        readonly isStripeOrder: boolean,
        readonly invoice: InvoiceInfo | undefined,
        readonly schedulerId: Id | undefined,
    ) {}

    static fromServer(input: OrderInfoFromServer): OrderInfo {
        return new OrderInfo(
            Id.fromIRI(input['@id']),
            ClientInfo.fromServer(input.client),
            input.title,
            moneyFromServer(input.total, input.currency),
            input.state,
            DateTime.fromISO(input.createdAt),
            DateTime.fromISO(input.issueDate),
            input.stripeOrder,
            invoiceInfoFromServer(input),
            input.scheduler && Id.fromIRI(input.scheduler),
        );
    }

    // If the order is free, it can't be marked as fulfilled. Its payment method must be noInvoice.
    // However, a non-free order with the noInvoice method can still be marked as fulfilled.
    get isFree(): boolean {
        return this.price.amount === 0;
    }

    static createExample(variant: number, currency: Currency, issuedAt: DateTime, state: OrderState): OrderInfo {
        const d = exampleVariants[variant];

        return new OrderInfo(
            Id.createExample('orders'),
            ClientInfo.createExample(),
            d.title,
            { amount: d.price, currency },
            state,
            issuedAt,
            issuedAt,
            false,
            undefined,
            undefined,
        );
    }
}

const exampleVariants = [
    { title: '🆓 Free consultation', price: 0 },
    { title: '👤 1:1 Session', price: 70 },
    { title: '📦 3 month program (10 sessions)', price: 500 },
] as const;

export type SchedulerOrderInfoFromServer = {
    '@id': IRI;
    client: ClientInfoFromServer;
    title: string;
    state: OrderState;
    createdAt: string;
};

export class SchedulerOrderInfo {
    private constructor(
        readonly id: Id,
        readonly client: ClientInfo,
        readonly title: string,
        readonly state: OrderState,
        readonly createdAt: DateTime,
    ) {}

    static fromServer(input: SchedulerOrderInfoFromServer): SchedulerOrderInfo {
        return new SchedulerOrderInfo(
            Id.fromIRI(input['@id']),
            ClientInfo.fromServer(input.client),
            input.title,
            input.state,
            DateTime.fromISO(input.createdAt),
        );
    }
}

export type OrderFields = {
    header?: string;
    footer?: string;
    customKey1?: string;
    customValue1?: string;
    customKey2?: string;
    customValue2?: string;
}

function orderFieldsFromServer(input: OrderFromServer): OrderFields {
    return {
        header: input.header,
        footer: input.footer,
        customKey1: input.customKey1,
        customValue1: input.customValue1,
        customKey2: input.customKey2,
        customValue2: input.customValue2,
    };
}

export type EditableOrderFields = Required<OrderFields>;

export function orderFieldsUpdateToServer(input: EditableOrderFields): OrderFields {
    return {
        header: stringToServer(input.header),
        footer: stringToServer(input.footer),
        customKey1: stringToServer(input.customKey1),
        customValue1: stringToServer(input.customValue1),
        customKey2: stringToServer(input.customKey2),
        customValue2: stringToServer(input.customValue2),
    };
}

type InvoiceDetail = InvoiceInfo &{
    readonly variableSymbol: string;
};

function invoiceDetailFromServer(input: OrderFromServer): InvoiceDetail | undefined {
    const info = invoiceInfoFromServer(input);
    if (!info || input.variableSymbol === undefined)
        return undefined;

    return { ...info, variableSymbol: input.variableSymbol };
}

export type OrderFromServer = OrderInfoFromServer & OrderFields & {
    supplier: InvoicingIdentityFromServer;
    subscriber: InvoicingIdentityFromServer;
    paymentMethod: PaymentMethod;
    variableSymbol?: string;
    items: OrderItemFromServer[];
    logs: LogFromServer[];
    createdNotificationSent: boolean;
    dueDate: string;
    taxDate: string;
    condensedInvoice: boolean;
};

export class Order implements Entity {
    protected constructor(
        readonly id: Id,
        readonly client: ClientInfo,
        readonly title: string,
        readonly isCreatedNotificationSent: boolean,
        readonly supplier: InvoicingIdentity,
        readonly subscriber: InvoicingIdentity,
        readonly price: Money,
        readonly state: OrderState,
        readonly createdAt: DateTime,
        readonly issueDate: DateTime,
        readonly dueDate: DateTime,
        readonly taxDate: DateTime,
        readonly isCondensedInvoice: boolean,
        readonly isStripeOrder: boolean,
        readonly paymentMethod: PaymentMethod,
        readonly items: OrderItem[],
        readonly logs: Log[],
        readonly fields: OrderFields,
        readonly invoice: InvoiceDetail | undefined,
    ) {}

    static fromServer(input: OrderFromServer): Order {
        return new Order(
            Id.fromIRI(input['@id']),
            ClientInfo.fromServer(input.client),
            input.title,
            input.createdNotificationSent,
            InvoicingIdentity.fromServer(input.supplier),
            InvoicingIdentity.fromServer(input.subscriber),
            moneyFromServer(input.total, input.currency),
            input.state,
            DateTime.fromISO(input.createdAt),
            DateTime.fromISO(input.issueDate),
            DateTime.fromISO(input.dueDate),
            DateTime.fromISO(input.taxDate),
            input.condensedInvoice,
            input.stripeOrder,
            input.paymentMethod,
            input.items.map(item => orderItemFromServer(item, input )).toSorted((a, b) => a.index - b.index),
            input.logs.map(Log.fromServer).sort(Log.compareDesc),
            orderFieldsFromServer(input),
            invoiceDetailFromServer(input),
        );
    }

    /** returns just EventOrderItem-s */
    getEventItems = (): EventOrderItem[] => (this.items.filter((value): value is EventOrderItem => (value instanceof EventOrderItem)));

    /** returns {Custom,Credit,Product}OrderItem-s, without EventOrderItem-s */
    getBasicItems = (): OrderItem[] =>
        (this.items.filter((value): value is CustomOrderItem | CreditOrderItem | ProductOrderItem =>
            (value instanceof CustomOrderItem) || (value instanceof CreditOrderItem) || (value instanceof ProductOrderItem)));

    get isFree(): boolean {
        return this.price.amount === 0;
    }
}

export type OrderToServer = {
    custom: CustomOrderToServer;
} | {
    event: EventOrderToServer;
} | {
    product: ProductOrderToServer;
};

export type CustomOrderToServer = {
    title: string;
    paymentMethod: PaymentMethod;
    dueDays?: number;
    notification?: NotificationToServer;

    client: ClientOrContactToServer;
    currency: CurrencyIRI;
    items: CustomItemToServer[];
};

export type ProductOrderToServer = {
    title: string;
    scheduler?: IRI;
    paymentMethod: PaymentMethod;
    dueDays?: number;
    notification?: NotificationToServer;

    client: ClientOrContactToServer;
    guest: ClientOrContactToServer;

    /** Product */
    productItems: IRI[];
    discountItems: DiscountItemToServer[];
};

export type EventOrderToServer = {
    paymentMethod: PaymentMethod;
    dueDays?: number;
    notification?: NotificationToServer;

    /** There should be one item for each clients. */
    eventItems: EventItemToServer[];
};

type Notification = {
    email: string;
    cc: string[];
    subject: string;
    body: string;
};

export type NotificationToServer = {
    /** If undefined, the client's email will be used instead. */
    email: string | undefined;
    cc: string[] | undefined;
    subject: string;
    body: string;
};

export type DiscountItem = {
    price: Money;
    vat: TaxRate;
    label: string;
};

export type DiscountItemToServer = CustomItemToServer & {
    currency: CurrencyIRI;
};

export enum PaymentMethod {
    BankTransfer = 'bankTransfer',
    Stripe = 'stripe',
    Credit = 'credit',
    NoInvoice = 'noInvoice',
}

export function determineIsSendNotification(method: PaymentMethod): boolean | undefined {
    if (method === PaymentMethod.Stripe)
        return true;
    if (method === PaymentMethod.NoInvoice)
        return false;
}

enum ErrorType {
    MinimalCharge = 'order.minimalCharge',
    MaximalCharge = 'order.maximalCharge',
    NumberAlreadyUsed = 'order.numberAlreadyUsed',
}

export type ChargeError = {
    type: ErrorType.MinimalCharge | ErrorType.MaximalCharge;
    provided: number;
    required: number;
    currency: CurrencyIRI;
};

export function isChargeError(value: unknown): value is ChargeError {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value))
        return false;

    return value.type === ErrorType.MinimalCharge || value.type === ErrorType.MaximalCharge;
}

export type NumberAlreadyUsedError = {
    type: ErrorType.NumberAlreadyUsed;
    prefix: string;
    index: number;
};

export function isNumberAlreadyUsedError(value: unknown): value is NumberAlreadyUsedError {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value))
        return false;

    return value.type === ErrorType.NumberAlreadyUsed;
}

export enum TransitionName {
    Fulfill = 'fulfill',
    Unfulfill = 'unfulfill',
}

const stateTransitionPrerequisites: {
    [key in TransitionName]: OrderState[];
} = {
    [TransitionName.Fulfill]: [ OrderState.New, OrderState.Overdue ],
    [TransitionName.Unfulfill]: [ OrderState.Fulfilled ],
};

export function canTransition(order: Order | OrderInfo, transition: TransitionName): boolean {
    // Free orders can't be fulfilled or unfulfilled. However, if a new transition is added, this condition needs to be reviewed.
    if (order.isFree)
        return false;

    return stateTransitionPrerequisites[transition].includes(order.state);
}

export type OrderEdit = {
    prefix?: string;
    index?: number;
    variableSymbol?: string;
    issueDate?: DateTime;
    dueDate?: DateTime;
    taxDate?: DateTime;
    isCondensedInvoice?: boolean;
    logo?: FileList | null;
    subscriber?: InvoicingIdentityUpdate;
    supplier?: InvoicingIdentityUpdate;
    fields?: EditableOrderFields;
    eventItems: EditableEventItem[];
    basicItems: EditableBasicItem[];
};

export enum SyncType {
    Update = 'update',
    Transition = 'transition',
    Delete = 'delete',
    SendNotification = 'sendNotification',
}

type TOrderSync<TType extends SyncType, TData> = {
    type: TType;
    data: TData;
}

export type OrderUpdate = TOrderSync<SyncType.Update, OrderEdit>;
export type OrderTransition = TOrderSync<SyncType.Transition, { transition: TransitionName }>;
export type OrderDelete = TOrderSync<SyncType.Delete, undefined>;
export type OrderSendNotification = TOrderSync<SyncType.SendNotification, Notification>;

export type OrderSync = OrderUpdate | OrderTransition | OrderDelete | OrderSendNotification;

export type OrderEditToServer = {
    prefix?: string;
    index?: number;
    title?: string;
    variableSymbol?: string;
    issueDate?: string;
    dueDate?: string;
    taxDate?: string;
    condensedInvoice?: boolean;
    logo?: FileToServer | null;
    /** Like PUT - if anything changes, the object is replaced. */
    subscriber?: InvoicingIdentityUpdateToServer;
    /** Like PUT - if anything changes, the object is replaced. */
    supplier?: InvoicingIdentityUpdateToServer;
    /** Like PUT - if anything changes, the object is replaced. */
    fields?: OrderFields;
    /** Like PUT - if anything changes on a specific item, the item is replaced. */
    orderItems?: BasicItemUpdateToServer[];  // now unified, TODO create {o,O}rderItemUpdateToServer
};

export async function orderEditToServer({ data }: OrderUpdate): Promise<OrderEditToServer> {
    const logo = await logoToServer(data.logo);

    const orderItems = [ ...data.eventItems, ...data.basicItems ].map(basicItemUpdateToServer);

    return {
        prefix: data.prefix,
        index: data.index,
        variableSymbol: data.variableSymbol,
        issueDate: data.issueDate?.toISO(),
        dueDate: data.dueDate?.toISO(),
        taxDate: data.taxDate?.toISO(),
        condensedInvoice: data.isCondensedInvoice,
        logo,
        supplier: data.supplier && invoicingIdentityUpdateToServer(data.supplier),
        subscriber: data.subscriber && invoicingIdentityUpdateToServer(data.subscriber),
        fields: data.fields && orderFieldsUpdateToServer(data.fields),
        orderItems,
    };
}

export type CustomOrderInit = {
    client: Participant;
    dueDays: number | undefined;
    items: CustomItemInit[];
    discountItems: DiscountItem[];
    paymentMethod: PaymentMethod;
    notification: Notification | undefined;
};

export type CustomItemInit = {
    label: string;
    quantity: number;
    price: Money;
    vat: TaxRate;
};

export function customOrderToServer(init: CustomOrderInit, { settings }: MasterContext, i18n: i18n): CustomOrderToServer {
    const { paymentMethod, dueDays, notification, client, discountItems } = init;
    const title = i18n.t('components:checkout.custom-order-title', { client: getParticipantName(client) });

    const currency = init.items[0].price.currency;
    const items: CustomItemToServer[] = init.items.map(item => ({
        title: item.label,
        quantity: item.quantity,
        unitPrice: priceToServer(item.price.amount),
        vat: item.vat.toIRI(),
    }));

    discountItems
        .map(discountItemToServer)
        .forEach(item => items.push(item));

    return {
        title,
        paymentMethod,
        dueDays,
        notification: notification && notificationToServer(notification),
        client: clientOrContactToServer(client, settings),
        currency: currency.toIRI(),
        items,
    };
}

export type ProductOrderInit = {
    client: Participant;
    guest: Participant;
    scheduler?: TeamMember;
    items: ProductItemInit[];
    discountItems: DiscountItem[];
    paymentMethod: PaymentMethod;
    notification: Notification | undefined;
};

export type ProductItemInit = {
    product: Product;
};

export function productOrderToServer(init: ProductOrderInit, { settings }: MasterContext, i18n: i18n): ProductOrderToServer {
    const { paymentMethod, notification, client, guest, items, discountItems } = init;
    // TODO check the translations
    const title = items.length === 1
        ? i18n.t('components:checkout.product-order-single-item-title', { ...items[0].product, guest: getParticipantName(guest) })
        : i18n.t('components:checkout.product-order-title', { guest: getParticipantName(guest) });

    return {
        title,
        paymentMethod,
        scheduler: init.scheduler?.id.toIRI(),
        notification: notification && notificationToServer(notification),
        client: clientOrContactToServer(client, settings),
        guest: clientOrContactToServer(guest, settings),
        productItems: items.map(item => item.product.id.toIRI()),
        discountItems: discountItems.map(discountItemToServer),
    };
}

function discountItemToServer(item: DiscountItem): DiscountItemToServer {
    return {
        title: item.label,
        quantity: 1,
        unitPrice: priceToServer(item.price.amount),
        vat: item.vat.toIRI(),
        currency: item.price.currency.toIRI(),
    };
}

export type EventOrderInit = {
    paymentMethod: PaymentMethod;
    notification: Notification | undefined;
    forClients: EventItemsForClient[];
};

export type EventItemsForClient = {
    info: ClientInfo;
    participants: EventParticipantItem[];
};

export type EventParticipantItem = {
    event: Event;
    participant: PayingParticipant;
};

export function eventOrderToServer(init: EventOrderInit, i18n: i18n): EventOrderToServer {
    const { paymentMethod, notification, forClients } = init;

    const eventItems: EventItemToServer[] = forClients
        .map(({ info, participants }) => ({
            participants: participants.map(p => p.participant.id.toIRI()),
            title: i18n.t('components:checkout.event-order-title', { client: info.name }),
        }))
        .filter(item => item.participants.length > 0);

    return {
        paymentMethod,
        notification: notification && notificationToServer(notification),
        eventItems,
    };
}

export function notificationToServer(notification: Notification): NotificationToServer {
    return {
        email: stringToServer(notification.email),
        cc: notification.cc,
        subject: notification.subject,
        body: notification.body,
    };
}
