import React from 'react';
import { type Order, type OrderInfo } from '@/types/orders/Order';
import { type IconBaseProps, type IconType } from 'react-icons/lib';
import { MdPendingActions } from 'react-icons/md';
import { FaStripeS } from 'react-icons/fa';

function getOrderIcon(order: Order | OrderInfo): IconType {
    return order.isStripeOrder ? FaStripeS : MdPendingActions;
}

type PaymentMethodIconProps = Omit<IconBaseProps, 'order'> & {
    order: Order | OrderInfo;
};

export default function PaymentMethodIcon({ order, ...rest }: PaymentMethodIconProps) {
    return (
        <span className={rest.className}>
            {getOrderIcon(order)({ size: rest.size ?? 20 })}
        </span>
    );
}
