import type { CountryCode } from '@/types/i18n';
import { roundMoney } from '@/utils/math';
import { type Money, type TaxRate, type TaxRateIRI } from './types';
import { type InvoicingProfile } from '@/types/Invoicing';
import { type ClientInfo } from '@/types/Client';

const ALL_TAX_RATES_MAP: Map<TaxRateIRI, TaxRate> = new Map;

export function getAllTaxRatesForCountries(countries: CountryCode[]): TaxRate[] {
    const output = [ getDefaultTaxRate() ];
    countries.forEach(country => getAllTaxRatesForOneCountryOnly(country).forEach(taxRate => output.push(taxRate)));
    
    return output;
}

function getAllTaxRatesForOneCountryOnly(country: CountryCode): TaxRate[] {
    return [ ...ALL_TAX_RATES_MAP.values() ].filter(taxRate => taxRate.country === country).sort((a, b) => a.value - b.value);
}

export function addTaxRate(taxRate: TaxRate) {
    ALL_TAX_RATES_MAP.set(taxRate.toIRI(), taxRate);
}

export function getTaxRate(iri: TaxRateIRI): TaxRate {
    const taxRate = ALL_TAX_RATES_MAP.get(iri);
    if (!taxRate)
        throw new Error(`Tax rate with iri ${iri} not found.`);

    return taxRate;
}

/**
 * The zero tax rate is supposed to be common for all countries so it is a natural fit for a default tax rate.
 */
let zeroTaxRate: TaxRate | undefined = undefined;

export function getDefaultTaxRate(): TaxRate {
    if (!zeroTaxRate) {
        const foundTaxRate = [ ...ALL_TAX_RATES_MAP.values() ].find(taxRate => !taxRate.country);
        if (!foundTaxRate)
            throw new Error('Default tax rate not found');

        zeroTaxRate = foundTaxRate;
    }

    return zeroTaxRate;
}

export function findTaxRate(profiles: InvoicingProfile[], client?: ClientInfo): TaxRate {
    if (!profiles || profiles.length === 0)
        return getDefaultTaxRate();

    if (client) {
        const profile = profiles.find(p => p.id.equals(client.invoicingProfileId));
        if (profile)
            return profile.vat;
    }

    return profiles[0].vat;
}

export function addVatAsNumber(amount: number, vat: TaxRate): { withoutVat: number, withVat: number, difference: number } {
    if (vat.isInclusive) {
        const withoutVat = roundMoney(amount / (1 + vat.value));
        const withVat = roundMoney(amount);
        const difference = roundMoney(withVat - withoutVat);

        return { withoutVat, withVat, difference };
    }
    else {
        const withoutVat = roundMoney(amount);
        const withVat = roundMoney(amount * (1 + vat.value));
        const difference = roundMoney(withVat - withoutVat);
        
        return { withoutVat, withVat, difference };
    }
}

export function addVat(base: Money, vat: TaxRate): { withoutVat: Money, withVat: Money, difference: Money } {
    const numberResult = addVatAsNumber(base.amount, vat);

    return {
        withoutVat: {
            amount: numberResult.withoutVat,
            currency: base.currency,
        },
        withVat: {
            amount: numberResult.withVat,
            currency: base.currency,
        },
        difference: {
            amount: numberResult.difference,
            currency: base.currency,
        },
    };
}
