import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { api } from '@/utils/api/backend';
import { type BaseLocation, getLocationFromServer, PlatformType } from '@/types/location';
import LocationForm, { type LocationFormData } from './LocationForm';
import { useTranslation } from 'react-i18next';

type EditLocationModalProps = Readonly<{
    location?: BaseLocation;
    onClose(): void;
    onLocationEdit(updatedLocation: BaseLocation): void;
}>;

export default function EditLocationModal({ location, onClose, onLocationEdit }: EditLocationModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.locations' });
    const [ isFetching, setIsFetching ] = useState(false);

    async function onSubmit(data: LocationFormData) {
        if (!location)
            return;

        setIsFetching(true);
        const response = await api.location.update(location, dataToUpdateObject(data));
        setIsFetching(false);

        if (response.status) {
            onLocationEdit(getLocationFromServer(response.data));
            onClose();
        }
    }

    return (
        <Modal show={!!location} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('edit-location-modal-title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LocationForm
                    defaultValues={location ?? {}}
                    isFetching={isFetching}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                    typeIsConstant
                />
            </Modal.Body>
        </Modal>
    );
}

function dataToUpdateObject(data: LocationFormData) {
    if (data.platformType === PlatformType.physicalLocation) {
        return {
            title: data.title,
            address: {
                city: data.city,
                line1: data.line1,
                postalCode: data.postalCode,
                country: data.country,
            },
        };
    }
    else {
        return {
            title: data.title,
            platform: data.platformType === PlatformType.custom ? data.platform : data.platformType,
            url: data.url,
        };
    }
}
