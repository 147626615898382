import { type Entity, type Id, type IRI } from '../Id';

export enum LocationType {
    video = 'VideoLocation',
    physical = 'PhysicalLocation',
}

/**
 * The keys and values of the enum must be the same because values are sometimes used as keys.
 * In a selectbox, the value part is used as the option's value because it's converted to a string. Then we need to convert it back, so we use Enum[value] ...
 */
export enum PlatformType {
    googleMeet = 'googleMeet',
    microsoftTeams = 'microsoftTeams',
    zoom = 'zoom',
    physicalLocation = 'physicalLocation',
    custom = 'custom',
}

export type BaseLocationFromServer = {
    '@id': IRI;
    '@type': LocationType;
    title: string;
    displayName: string;
};

export class BaseLocation implements Entity {
    id: Id;
    type: LocationType;
    platform: string;
    platformType: PlatformType;
    title: string;
    displayName: string;

    protected constructor(id: Id, type: LocationType, platform: string, platformType: PlatformType, title: string, displayName: string) {
        this.id = id;
        this.type = type;
        this.platform = platform;
        this.platformType = platformType;
        this.title = title;
        this.displayName = displayName;
    }
}
