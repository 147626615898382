import { type BaseLocation } from './BaseLocation';
import { PhysicalLocation } from './PhysicalLocation';
import { VideoLocation } from './VideoLocation';

export { LocationType, PlatformType, BaseLocation } from './BaseLocation';
export { type LocationFromServer, getLocationFromServer } from './Location';
export { VideoLocation, type VideoLocationFromServer } from './VideoLocation';
export { PhysicalLocation, type PhysicalLocationFromServer } from './PhysicalLocation';

export function getLocationLink(location: BaseLocation): string {
    if (location instanceof PhysicalLocation)
        return `https://www.google.com/maps/search/?api=1&query=${location.address.city}, ${location.address.line1}`;

    if (location instanceof VideoLocation)
        return location.url;

    return '';
}
