import React, { useState } from 'react';
import localStorage from '@/utils/localStorage';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LS_CLOSED_KEY = 'use_desktop_modal_closed';

export function GoToDesktopModal() {
    const { t } = useTranslation('common', { keyPrefix: 'goToDesktopModal' });

    const [ checkboxChecked, setCheckboxChecked ] = useState(false);
    // non-existant value (null, after first open) gets automatically converted to true
    const [ showModal, setShowModal ] = useState(() => !localStorage.get<boolean>(LS_CLOSED_KEY));

    const handleCheckboxChange: React.ComponentProps<typeof Form.Check>['onChange'] = e => {
        setCheckboxChecked(e.target.checked);
        localStorage.set(LS_CLOSED_KEY, e.target.checked);
    };

    function close() {
        setShowModal(false);
    }

    return (
        <Modal
            className='sh-use-desktop-modal'
            backdropClassName='sh-use-desktop-modal-backdrop'
            show={showModal}
            onHide={close}
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t('body')}</p>
                <Form.Check
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    label={t('checkbox-label')}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>{t('button')}</Button>
            </Modal.Footer>
        </Modal>
    );
}
