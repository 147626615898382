import React, { useEffect, useMemo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ClientAccessType, type Client, type ClientPreferencesUpdate } from '@/types/Client';
import { useTranslation } from 'react-i18next';
import { toMaster, useUser } from '@/context/UserProvider';
import type { CountryCode, LocaleCode, TimezoneCode } from '@/types/i18n';
import { ControlledInvoicingProfileSelect, ControlledLocaleSelect, ControlledCountrySelect, ControlledTimezoneSelect } from '@/components/forms';
import type { IRI } from '@/types/Id';

type ClientPreferencesFormProps = Readonly<{
    onSubmit: (output: ClientPreferencesUpdate) => void;
    defaultValue: Client;
    onChange?: (isDirty: boolean) => void;
    saveButton?: React.FC<{ onClick: () => void }>;
}>;

export default function ClientPreferencesForm({ onSubmit, defaultValue, onChange, saveButton }: ClientPreferencesFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientForm' });

    const {
        form,
        handleSubmit,
        showProfileSelect,
        isEditable,
    } = useClientPreferencesForm(onSubmit, defaultValue, onChange);
    const { control } = form;

    return (
        <Form noValidate onSubmit={handleSubmit} className='sh-design'>
            <Row>
                <Col xs={8}>
                    <div className='sh-description-no-border mb-0'>{t('preferences-description')}</div>
                </Col>
            </Row>
            <Row className='gap-row-3 mt-4'>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('timezone-label')}</Form.Label>
                    <ControlledTimezoneSelect
                        control={control}
                        name='timezone'
                        disabled={!isEditable}
                    />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('locale-label')}</Form.Label>
                    <ControlledLocaleSelect
                        control={control}
                        name='locale'
                        type='client'
                        disabled={!isEditable}
                    />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('country-label')}</Form.Label>
                    <ControlledCountrySelect
                        control={control}
                        name='countryForPreferences'
                        disabled={!isEditable}
                    />
                </Form.Group>
                {showProfileSelect && (
                    <Form.Group as={Col} xs={6}>
                        <Form.Label>{t('profile-label')}</Form.Label>
                        <ControlledInvoicingProfileSelect
                            control={control}
                            name='invoicingProfileIRI'
                            disabled={!isEditable}
                        />
                    </Form.Group>
                )}
            </Row>
            <>{saveButton?.({ onClick: handleSubmit })}</>
        </Form>
    );
}

export function useClientPreferencesForm(
    onSubmit: (output: ClientPreferencesUpdate) => void,
    defaultValue: Client,
    onChange?: (isDirty: boolean) => void,
) {
    const form = useForm<ClientPreferencesFormData>();
    const isDirty = form.formState.isDirty;
    const userContext = useUser();
    const masterContext = toMaster(userContext);

    const isEditable = useMemo(() => {
        if (masterContext)
            return true;
        return defaultValue.getAccess(userContext.appUser)?.accessType === ClientAccessType.Owner;
    }, [ defaultValue, userContext, masterContext ]);

    useEffect(() => onChange?.(isDirty), [ isDirty, onChange ]);

    function onValidSubmit(data: ClientPreferencesFormData) {
        onSubmit(formToOutput(data));
    }

    useEffect(() => {
        form.reset(inputToForm(defaultValue, !!masterContext));
    }, [ defaultValue ]);

    return {
        form,
        handleSubmit: form.handleSubmit(onValidSubmit),
        showProfileSelect: masterContext && masterContext.profiles.length > 1,
        isEditable,
    };
}

type ClientPreferencesFormData = {
    timezone: TimezoneCode;
    locale: LocaleCode;
    countryForPreferences: CountryCode;
    invoicingProfileIRI?: string;
};

function inputToForm(client: Client, isMaster: boolean): ClientPreferencesFormData {
    return {
        timezone: client.timezone,
        locale: client.locale,
        countryForPreferences: client.country,
        invoicingProfileIRI: isMaster ? (client.invoicingProfileId).toIRI() as unknown as string : undefined,
    };
}

function formToOutput(data: ClientPreferencesFormData): ClientPreferencesUpdate {
    return {
        timezone: data.timezone,
        locale: data.locale,
        country: data.countryForPreferences,
        invoicingProfile: data.invoicingProfileIRI as unknown as IRI,
    };
}
