import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { routes } from './index';
import { createActionState, NavigateWithState } from '@/hooks';

import MasterDashboard from '@/pages/master/MasterDashboard';
import SchedulerDashboard from '@/pages/scheduler/SchedulerDashboard';
import Clients from '@/pages/client/Clients';
import NewBackpayOrder from '@/pages/client/NewBackpayOrder';
import ClientDetail from '@/pages/client/ClientDetail';
import NewClient from '@/pages/client/NewClient';
import ImportClients from '@/pages/client/ImportClients';
import EventDetail from '@/pages/event/EventDetail';
import Settings from '@/pages/settings/Settings';
import OrderDetail from '@/pages/orders/OrderDetail';
import NewCustomOrder from '@/pages/orders/NewCustomOrder';
import Payments from '@/pages/Payments';
import Subscriptions from '@/pages/Subscriptions';
import Integrations from '@/pages/Integrations';
import CalendarDetail, { type PreselectEvent } from '@/pages/CalendarDetail';
import Orders from '@/pages/orders/Orders';
import InvoicingProfileDetail from '@/pages/InvoicingProfileDetail';
import DevPage from '@/components/dev/DevPage';
import { toMaster, useUser } from '@/context/UserProvider';
import TeamDetail from '@/pages/TeamDetail';
import { OrdersExport } from '@/pages/orders/OrdersExport';

const enableDevPage = import.meta.env.DEV;

export default function AuthorizedRoutes() {
    const location = useLocation();
    const from = (location.state as { from: string | undefined })?.from ?? routes.root;
    const isMasterOrFreelancer = !!toMaster(useUser());

    return (
        <Routes>
            <Route path={routes.calendar}                       element={<CalendarDetail />} />

            <Route path={routes.clients.list}                   element={<Clients />} />
            <Route path={routes.clients.detail.path}            element={<ClientDetail />} />
            <Route path={routes.clients.new}                    element={<NewClient />} />
            <Route path={routes.clients.import}                 element={<ImportClients />} />

            <Route path={routes.orders.list}                    element={<Orders />} />

            <Route path={routes.events.detail.path}             element={<EventDetail />} />
            <Route path={routes.settings.path}                  element={<Settings />} />

            {isMasterOrFreelancer ? (<>
                <Route path={routes.dashboard}                      element={<MasterDashboard />} />

                <Route path={routes.orders.detail.path}             element={<OrderDetail />} />
                <Route path={routes.orders.export}                  element={<OrdersExport />} />
                <Route path={routes.orders.newBackpay}              element={<NewBackpayOrder />} />
                <Route path={routes.orders.newCustom}               element={<NewCustomOrder />} />
                <Route path={routes.orders.newEventGroup}           element={<NavigateWithState state={newEventGroupAction} replace to={routes.calendar} />} />
                <Route path={routes.team}                           element={<TeamDetail />} />
                <Route path={routes.invoicingProfiles.detail.path}  element={<InvoicingProfileDetail />} />
                <Route path={routes.payments}                       element={<Payments />} />
                <Route path={routes.subscription}                   element={<Subscriptions />} />
            </>) : (<>
                <Route path={routes.dashboard}                      element={<SchedulerDashboard />} />
            </>)}

            <Route path={routes.integrations}                   element={<Integrations />} />

            {enableDevPage && <Route path={routes.dev} element={<DevPage />} />}

            <Route path='*' element={<Navigate replace to={from} />} />
        </Routes>
    );
}

const newEventGroupAction = createActionState<PreselectEvent>('preselectEvent', 'new');
