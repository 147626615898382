import type { Money, TaxRate } from '@/modules/money';
import { type Id } from '../Id';
import type { OrderItem } from './OrderItem';
import { type DateTime } from 'luxon';
import { secondsToMinutes } from '@/utils/common';
import { type Event, EventState } from '../Event';
import { type ExceptScheduler, type UserRole } from '../Team';
import { ClientInfo, type ClientInfoFromServer } from '../Client';

export type ProductOrderItemFromServer = {
    icon?: string;
    sessionsCount: number;
    sessionsDuration: number;
    scheduledCount: number;
    completedCount: number;
    guest: ClientInfoFromServer;
};

export class ProductOrderItem<TRole extends UserRole = UserRole.Master | UserRole.Freelancer> implements OrderItem<TRole> {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly quantity: number,
        readonly unitPrice: ExceptScheduler<TRole, Money>,
        readonly vat: ExceptScheduler<TRole, TaxRate>,
        readonly index: number,
        readonly createdAt: DateTime,

        readonly icon: string | undefined,
        readonly sessionsCount: number,
        readonly sessionsDuration: number,
        readonly scheduledCount: number,
        readonly completedCount: number,
        readonly guest: ClientInfo,
    ) {}

    static fromServer<TRole extends UserRole>(base: OrderItem<TRole>, input: ProductOrderItemFromServer): ProductOrderItem<TRole> {
        return new ProductOrderItem(
            base.id,
            base.title,
            base.quantity,
            base.unitPrice,
            base.vat,
            base.index,
            base.createdAt,
            input.icon,
            input.sessionsCount,
            secondsToMinutes(input.sessionsDuration),
            input.scheduledCount,
            input.completedCount,
            ClientInfo.fromServer(input.guest),
        );
    }

    get price(): ExceptScheduler<TRole, Money> {
        return this.unitPrice;
    }

    get isCompleted(): boolean {
        return this.completedCount === this.sessionsCount;
    }

    get isScheduled(): boolean {
        return this.scheduledCount === this.sessionsCount;
    }

    updateFromEvents(events: Event[]): ProductOrderItem<TRole> {
        return new ProductOrderItem(
            this.id,
            this.title,
            this.quantity,
            this.unitPrice,
            this.vat,
            this.index,
            this.createdAt,
            this.icon,
            this.sessionsCount,
            this.sessionsDuration,
            this.scheduledCount + events.length,
            this.completedCount + events.filter(e => e.state === EventState.Finished).length,
            this.guest,
        );
    }
}

export type GenericProductItem = ProductOrderItem<UserRole>;
export type SchedulerProductItem = ProductOrderItem<UserRole.Scheduler>;
