import { createDELETE, createGET, createPOST, createPUT } from '../rawApiObject';
import type { HydraCollection } from '@/types/api/result';
import type { TaskInitToServer, TaskEditToServer, TaskFromServer } from '@/types/Task';
import { type Id } from '@/types/Id';

export const task = {
    getAll: createGET<HydraCollection<TaskFromServer>>(
        '/tasks',
    ),
    create: createPOST<TaskFromServer, TaskInitToServer>(
        '/tasks',
    ),
    update: createPUT<{ id: Id }, TaskFromServer, TaskEditToServer>(
        u => `${u.id.toIRI()}`,
    ),
    delete: createDELETE<{ id: Id }, TaskFromServer>(
        u => `${u.id.toIRI()}`,
    ),
};
