import React, { useCallback } from 'react';
import { Table } from '@/components/common';
import { api } from '@/utils/api/backend';
import { Container } from 'react-bootstrap';
import { Team, type TeamMember, type TeamUpdateToServer } from '@/types/Team';
import { getPersonName } from '@/types/Person';
import { useMaster, useUser } from '@/context/UserProvider';
import EditableTextInput from '@/components/forms/EditableTextInput';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';

export default function TeamDetail() {
    const { t } = useTranslation('pages', { keyPrefix: 'team' });
    const { team, setTeam, teamMembers } = useMaster();

    const syncTeam = useCallback(async (update: TeamUpdateToServer) => {
        if (Object.values(update).some(i => !i))
            return false;

        const response = await api.team.update(update);
        if (!response.status)
            return false;

        const newTeam = Team.fromServer(response.data);
        setTeam(newTeam);

        return true;
    }, [ team.title ]);

    return (
        <Container className='container-large pb-5 mt-4'>
            <EditableTextInput
                className='d-flex align-items-center gap-3 sh-reset-form-control fs-4 fw-medium mb-4'
                viewClassName='text-truncate'
                value={team.title}
                syncFunction={title => syncTeam({ title })}
            />
            {/* <div className='d-flex gap-2 mb-3'>
                <span>Legal name:</span>
                <EditableTextInput
                    className='d-flex align-items-center gap-2 sh-reset-form-control fw-medium'
                    viewClassName='text-truncate'
                    value={team.legalName}
                    syncFunction={legalName => syncTeam({ legalName })}
                />
            </div>
            <div className='d-flex gap-2 mb-3'>
                <span>Email:</span>
                <EditableTextInput
                    className='d-flex align-items-center gap-2 sh-reset-form-control fw-medium'
                    viewClassName='text-truncate'
                    value={team.email}
                    syncFunction={email => syncTeam({ email })}
                />
            </div> */}
            <Table>
                <Table.Header>
                    <Table.HeaderCol xs={2}>
                        {t('name')}
                    </Table.HeaderCol>
                    <Table.HeaderCol xs={1}>
                        {t('role')}
                    </Table.HeaderCol>
                    <Table.HeaderCol xs={2}>
                        {t('email')}
                    </Table.HeaderCol>
                    <Table.HeaderCol xs={1.5}>
                        {t('phone')}
                    </Table.HeaderCol>
                </Table.Header>
                <Table.Body>
                    <MemberList members={teamMembers.all} />
                </Table.Body>
            </Table>
            <span className='d-block text-center w-100 fs-4 pt-4 pre-line lha-4'>
                <Trans
                    t={t}
                    i18nKey='add-members'
                    components={{
                        email: <a href='mailto:info@flowlance.com' target='_blank' rel='noreferrer' className='text-decoration-none' />,
                    }}
                />
            </span>
        </Container>
    );
}

type MemberListProps = Readonly<{
    members: TeamMember[];
}>;

function MemberList({ members }: MemberListProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'team' });
    const { appUser } = useUser();

    if (members.length === 0) {
        return (
            <Table.Row>
                <Table.Col colSpan={7} className='text-center fs-4 py-5'>
                    {t('no-members-text')}
                </Table.Col>
            </Table.Row>
        );
    }

    return (<>
        {members?.map(member => (
            <Table.Row className={clsx('hoverable', { 'bg-success bg-opacity-25': member.appUserId.equals(appUser.id) })} key={member.id.toString()}>
                <Table.Col className='fw-medium'>{getPersonName(member)}</Table.Col>
                <Table.Col className='fw-medium'>{t(`roles.${member.role}`)}</Table.Col>
                <Table.Col className='fw-medium'>{member.email}</Table.Col>
                <Table.Col>{member.phoneNumber}</Table.Col>
            </Table.Row>
        ))}
    </>);
}
