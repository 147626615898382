import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { api } from '@/utils/api/backend';
import { createTranslatedSuccessAlert } from '@/components/notifications';
import useNotifications from '@/context/NotificationProvider';
import { createErrorAlert } from '../notifications/TranslatedAlertMessage';
import { Product, type ProductEditToServer, type ProductInitToServer } from '@/types/Product';
import ProductForm, { FID_SAVE } from './ProductForm';
import { useTranslation } from 'react-i18next';
import { SpinnerButton } from '../common';
import { TrashIcon } from '../icons';
import { useUser } from '@/context/UserProvider';
import { Onboarding } from '@/types/Onboarding';
import { sleep } from '@/utils/common';

type ProductModalProps = Readonly<{
    state: ProductModalState;
    onClose: () => void;
    onProductUpdate: (product: Product, isDeleted?: boolean) => void;
}>;

export default function ProductModal({ state, onClose, onProductUpdate }: ProductModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productModal' });
    const { addAlert } = useNotifications();
    const [ fetching, setFetching ] = useState<string>();
    const { onboarding, setOnboarding } = useUser();

    const trySyncOnboarding = useCallback(async () => {
        if (onboarding.flags.productCreatedOrUpdated)
            return;

        // This is just so wrong on so many levels. But we have to fix this on backend first.
        await sleep(2000);
        const response = await api.settings.getOnboarding();
        if (!response.status)
            return;

        setOnboarding(Onboarding.fromServer(response.data));
    }, [ onboarding.flags.productCreatedOrUpdated, setOnboarding ]);

    const createProduct = useCallback(async (init: ProductInitToServer) => {
        setFetching(FID_SAVE);
        const response = await api.product.create(init);
        setFetching(undefined);

        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            onClose();
            return;
        }

        const newProduct = Product.fromServer(response.data);

        addAlert(createTranslatedSuccessAlert('components:productModal.product-created-alert'));

        onProductUpdate(newProduct);
        onClose();

        trySyncOnboarding();
    }, [ onClose, onProductUpdate, addAlert, trySyncOnboarding ]);

    const editProduct = useCallback(async (edit: ProductEditToServer, product: Product) => {
        setFetching(FID_SAVE);
        const response = await api.product.update({ id: product.id }, edit);
        setFetching(undefined);

        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            onClose();
            return;
        }

        const newProduct = Product.fromServer(response.data);

        addAlert(createTranslatedSuccessAlert('components:productModal.product-updated-alert'));

        onProductUpdate(newProduct);
        onClose();

        trySyncOnboarding();
    }, [ onClose, onProductUpdate, addAlert, trySyncOnboarding ]);

    async function deleteProduct() {
        const product = state.product;
        if (!product)
            return;

        setFetching(FID_DELETE);
        const response = await api.product.delete({ id: product.id });
        setFetching(undefined);

        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            onClose();
            return;
        }

        addAlert(createTranslatedSuccessAlert('components:productModal.product-deleted-alert'));
        onProductUpdate(product, true);
        onClose();
    }

    return (
        <Modal show={state.isShow} onHide={onClose} contentClassName='rounded-4'>
            <Modal.Body style={{ padding: '24px' }}>
                <div className='d-flex align-items-top mb-4'>
                    <h2 className='my-0'>{t(state.product ? 'edit-product-title' : 'new-product-title')}</h2>
                    {state.product && (<>
                        <div className='flex-grow-1' />
                        <SpinnerButton
                            fetching={fetching}
                            fid={FID_DELETE}
                            variant='outline-danger'
                            className='compact'
                            onClick={deleteProduct}
                        >
                            <TrashIcon size={18} className='me-2'/>{t('delete-button')}
                        </SpinnerButton>
                    </>)}
                </div>
                <ProductForm
                    onInitSubmit={createProduct}
                    onEditSubmit={editProduct}
                    onClose={onClose}
                    fetching={fetching}
                    defaultValue={state.product}
                />
            </Modal.Body>
        </Modal>
    );
}

const FID_DELETE = 'delete';

type ProductModalState = {
    isShow: boolean;
    product?: Product;
};

export type ProductModalControl = {
    startCreating: () => void;
    startEditing: (product: Product) => void;
    close: () => void;
};

export function useProductModal(): { state: ProductModalState, control: ProductModalControl } {
    const [ state, setState ] = useState<ProductModalState>({ isShow: false });
    const control = useMemo(() => ({
        startCreating: () => setState({ isShow: true }),
        startEditing: (product: Product) => setState({ isShow: true, product }),
        close: () => setState(state => ({ ...state, isShow: false })),
    }), []);

    return {
        state,
        control,
    };
}
