import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMaster } from '@/context/UserProvider';
import { type InvoicingProfile } from '@/types/Invoicing';
import { type ClientInfo } from '@/types/Client';
import { api } from '@/utils/api/backend';
import useNotifications from '@/context/NotificationProvider';
import { createTranslatedErrorAlert } from '../notifications';
import { SpinnerButton } from '../common';
import { PlainInvoicingProfileSelect } from '../forms';
import type { IRI } from '@/types/Id';
import ClientIconLink from '../client/ClientIconLink';

type DeleteProfileModalProps = Readonly<{
    profile?: InvoicingProfile;
    allClients: ClientInfo[];
    onClose(): void;
    onDelete(deletedLocation: InvoicingProfile): void;
}>;

export default function DeleteProfileModal({ profile, allClients, onClose, onDelete }: DeleteProfileModalProps) {
    // TODO cache the profile
    
    const [ isFetching, setIsFetching ] = useState(false);
    const { t } = useTranslation('components', { keyPrefix: 'deleteProfileModal' });
    const { addAlert } = useNotifications();
    const { profiles } = useMaster();

    const clients = useMemo(
        () => profile && allClients.filter(client => client.invoicingProfileId.equals(profile.id)),
        [ profile, allClients ],
    );

    const availableProfiles = useMemo(
        () => profile && profiles.filter(p => !p.id.equals(profile.id)),
        [ profile, profiles ],
    );

    const [ replaceProfileIRI, setReplaceProfileIRI ] = useState<IRI>();

    useEffect(() => {
        setReplaceProfileIRI(availableProfiles?.[0].id.toIRI());
    }, [ availableProfiles ]);

    async function deleteProfile() {
        if (!profile)
            return;

        setIsFetching(true);
        const response = await api.settings.replaceInvoicingProfile(profile, { replaceInvoicingProfile: replaceProfileIRI });
        setIsFetching(false);

        if (!response.status) {
            addAlert(createTranslatedErrorAlert());
            onClose();
            return;
        }

        onDelete(profile);
    }

    return (
        <Modal show={!!profile} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='text-center'>
                    <Col>{t('text')}<br /></Col>
                </Row>
                {availableProfiles && clients && clients.length > 0 && (<>
                    <Row className='mt-4'><Col>{t('client-dependency-text', { count: clients.length })}</Col></Row>
                    <Row className='mt-2'><Col>{clients.map(client => <ClientIconLink key={client.id.toString()} client={client} />)}</Col></Row>
                    <Form.Group className='mt-2'>
                        <Form.Label>{t('new-profile-label', { count: clients.length })}</Form.Label>
                        <PlainInvoicingProfileSelect
                            iri={replaceProfileIRI}
                            onChange={setReplaceProfileIRI}
                            options={availableProfiles}
                        />
                    </Form.Group>
                </>)}
            </Modal.Body>
            <Modal.Footer>
                <SpinnerButton
                    variant='danger'
                    isFetching={isFetching}
                    onClick={deleteProfile}
                    className='mx-auto'
                >
                    {t('confirm-button')}
                </SpinnerButton>
            </Modal.Footer>
        </Modal>
    );
}
