import React, { useState } from 'react';
import type { InternalFieldName, UseFormRegisterReturn } from 'react-hook-form';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

type PasswordInputProps<TFieldName extends InternalFieldName = InternalFieldName> = Readonly<{
    register: UseFormRegisterReturn<TFieldName>;
}>;

export default function PasswordInput({ register }: PasswordInputProps) {
    const [ show, setShow ] = useState(false);
    
    return (
        <InputGroup>
            <Form.Control
                {...register}
                type={show ? 'text' : 'password'}
            />
            <Button onClick={() => setShow(!show)} className='py-0'>
                {show ? <AiFillEyeInvisible size={24} /> : <AiFillEye size={24} />}
            </Button>
        </InputGroup>
    );
}
