import React from 'react';
import { type Order, type OrderInfo, OrderState, type SchedulerOrderInfo } from '@/types/orders/Order';
import { useTranslation } from 'react-i18next';
import { CircleCrossIcon, CircleFullIcon, CircleMostlyFullIcon, CircleSemiFullIcon } from '../icons';
import clsx from 'clsx';

export const ORDER_STATE_DESCRIPTIONS = {
    [OrderState.Fulfilled]: { color: '#6BB358', label: 'state-fulfilled', icon: CircleFullIcon },
    [OrderState.New]: { color: '#F0BA51', label: 'state-new', icon: CircleSemiFullIcon },
    [OrderState.Overdue]: { color: '#C75B61', label: 'state-overdue', icon: CircleMostlyFullIcon },
    // The danger color. TODO: provide all these colors from a central file.
    [OrderState.Canceled]: { color: '#FF0C55', label: 'state-canceled', icon: CircleCrossIcon },
} as const;

const iconSizes = {
    'small': 16,
    'large': 20,
} as const;

type OrderStateBadgeProps = {
    size?: keyof typeof iconSizes;
    className?: string;
} & ({
    order: Order | OrderInfo | SchedulerOrderInfo;
} | {
    state: OrderState;
});

export default function OrderStateBadge(props: OrderStateBadgeProps) {
    const { size = 'small' } = props;
    const { t } = useTranslation('common');

    const state = 'order' in props ? props.order.state : props.state;
    const iconSize = iconSizes[size];
    const description = ORDER_STATE_DESCRIPTIONS[state];

    return (
        <div className={clsx('d-flex align-items-center fw-medium gap-col-2', props.className)}>
            <span style={{ color: description.color }}>
                {description.icon({ size: iconSize })}
            </span>
            {t(`order.${description.label}`)}
        </div>
    );
}

export function AllOrderStates() {
    return (
        <div className='d-flex flex-column gap-2'>
            {Object.values(OrderState).map(state => (
                <OrderStateBadge key={state} state={state} />
            ))}
        </div>
    );
}
