import { secondsToMinutes } from '@/utils/common';
import { type Entity, Id, type IRI, type OmitId } from './Id';
import { moneyFromServer, type CurrencyIRI, type Money, type TaxRate, type TaxRateIRI, getTaxRate } from '@/modules/money';

export type ProductFromServer = {
    '@id': IRI;
    title: string;
    icon?: string;
    description?: string;
    sessionsCount: number;
    /** In seconds. */
    sessionsDuration: number;
    price: number;
    currency: CurrencyIRI;
    vat: TaxRateIRI;
    location?: IRI;
};

export class Product implements Entity {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly icon: string | undefined,
        readonly description: string | undefined,
        readonly sessionsCount: number,
        /** In minutes. */
        readonly sessionsDuration: number,
        /** Total price for all sessions. */
        readonly price: Money,
        readonly vat: TaxRate,
        readonly locationId: Id | undefined,
    ) {}

    static fromServer(input: ProductFromServer): Product {
        return new Product(
            Id.fromIRI(input['@id']),
            input.title,
            input.icon,
            input.description,
            input.sessionsCount,
            secondsToMinutes(input.sessionsDuration),
            moneyFromServer(input.price, input.currency),
            getTaxRate(input.vat),
            input.location && Id.fromIRI(input.location),
        );
    }
}

export type ProductInitToServer = OmitId<ProductFromServer>;

export type ProductEditToServer = Partial<Omit<ProductFromServer, 'icon' | 'description' | 'location'>> & {
    icon?: string | false;
    description?: string | false;
    location?: IRI | false;
};
