import React from 'react';
import CloseChevronButton from './CloseChevronButton';
import OpenChevronButton from './OpenChevronButton';

type OpenCloseChevronButtonProps = {
    disabled?: boolean;
    className?: string;
    opened: boolean;
    onClick: () => void;
};

export default function OpenCloseChevronButton({ disabled, className, opened, onClick }: OpenCloseChevronButtonProps) {
    return opened ? (
        <CloseChevronButton disabled={disabled} className={className} onClick={onClick} />
    ) : (
        <OpenChevronButton disabled={disabled} className={className} onClick={onClick} />
    );
}