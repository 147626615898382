import { type ClientInfo } from '@/types/Client';
import { useEffect, useMemo, useReducer, type Dispatch, useCallback } from 'react';
import { api } from '@/utils/api/backend';
import { dehydrate } from '@/types/api/result';
import { Event, eventHasClientWithCurrency } from '@/types/Event';
import { Id, type IRI } from '@/types/Id';
import { computeIfAbsent } from '@/utils/common';
import { type Currency } from '@/modules/money';
import { useNavigationAction, type NavigationProperty } from './useHistoryState';
import { participantIsBillable, type PayingParticipant } from '@/types/EventParticipant';
import { CheckoutType, type EventCheckoutInput } from '@/components/orders/checkout/useCheckout';
import { type EventItemsForClient } from '@/types/orders/Order';

export type PreselectBackpay = NavigationProperty<'preselectBackpay', {
    eventIRIs?: IRI[];
    clientIRI?: IRI;
}>;

type PreselectData = {
    clientId?: Id;
    eventIds?: Id[];
};

export function useInvoiceBackpay() {
    const action = useNavigationAction<PreselectBackpay>('preselectBackpay');
    const preselected = useMemo(() => {
        if (!action)
            return undefined;

        return {
            clientId: action.data?.clientIRI && Id.fromIRI(action.data.clientIRI),
            eventIds: action.data?.eventIRIs && action.data.eventIRIs.map(Id.fromIRI),
        };
    }, [ action ]);

    const [ state, dispatch ] = useReducer(backpayReducer, {});

    const fetchInitialState = useCallback(async (signal: AbortSignal) => {
        dispatch({ type: 'set', state: {} });

        // TODO - For each Event we load all EventParticipants-s and therefore their Participant-s and Person-s ... it should be entirely sufficient to load only their IRIs.
        // It needs to be fixed on multiple places, but the difference in performance should be noticeable.
        const response = await api.event.getWithoutOrder(signal);
        if (!response.status)
            return;

        const events = dehydrate(response).items.map(Event.fromServer);
        const invoicingClients = computeFetchedClientState(events, preselected);
        const clients = invoicingClients.map(c => c.client);

        dispatch({ type: 'set', state: { invoicingClients, clients } });
    }, [ preselected ]);
    
    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchInitialState(signal);
    
        return abort;
        // The callback dependency can't be included because of the preselected data.
    }, []);

    const selectedItems = useMemo(() => computeSelectedItems(state.invoicingClients), [ state.invoicingClients ]);

    return {
        state,
        selectedItems,
        preselected,
        dispatch,
    };
}

function computeFetchedClientState(events: Event[], preselected?: PreselectData): InvoicingClient[] {
    // Find all clients that have at least one event that has at least one participant that is billable.
    const clientsData = new Map<IRI, { client: ClientInfo, events: Event[] }>();
    events
        .forEach(event => {
            event.clients
                .filter(participantIsBillable)
                .map(participant => participant.client)
                .forEach(client => {
                    const data = computeIfAbsent(clientsData, client.id.toIRI(), () => ({ client, events: [] }));
                    data.events.push(event);
                });
        });

    const clients = [ ...clientsData.values() ].map(({ client, events }) => clientToClientState(client, events, preselected));
    const clientsIsPreselected = clients
        .map((client, index) => ({ index, isPreselected: client.items.some(item => item.isSelected) }))
        .sort((a, b) => +b.isPreselected - +a.isPreselected);

    return clientsIsPreselected.map(({ index }) => clients[index]);
}

function clientToClientState(client: ClientInfo, events: Event[], preselected?: PreselectData): InvoicingClient {
    return {
        client,
        items: [ ...events ].sort(Event.compareAsc).map(event => eventToEventItem(event, client.id, preselected)),
    };
}

function eventToEventItem(event: Event, clientId: Id, preselected?: PreselectData): EventItem {
    // If both the client and the event are preselected, we need an exact match.
    // If one of them is specified, we preselect all events for the client or all clients for the event.
    const isSelected = (preselected?.clientId && preselected?.eventIds)
        ? clientId.equals(preselected.clientId) && preselected.eventIds.some(id => id.equals(event.id))
        : clientId.equals(preselected?.clientId) || !!preselected?.eventIds?.some(id => id.equals(event.id));

    return {
        event,
        participant: event.clients.find(participants => participants.client.id.equals(clientId))!,
        isSelected,
    };
}

function computeSelectedItems(clients?: InvoicingClient[]): number {
    return (clients ?? [])
        .map(c => c.items.filter(i => i.isSelected).length)
        .reduce((ans, value) => ans + value, 0);
}

export type InvoicingClient = {
    client: ClientInfo;
    items: EventItem[];
};

export type EventItem = {
    event: Event;
    participant: PayingParticipant;
    isSelected: boolean;
};

type BackpayState = {
    invoicingClients?: InvoicingClient[];
    /** All clients loaded from backend in this session. */
    clients?: ClientInfo[];
    checkoutInput?: EventCheckoutInput;
}

type BackpayStateAction = SetAction | SelectAction | ParticipantAction | ControlAction;
export type UseBackpayDispatch = Dispatch<BackpayStateAction>;

function backpayReducer(state: BackpayState, action: BackpayStateAction): BackpayState {
    switch (action.type) {
    case 'set': return action.state;
    case 'select': return updateSelect(state, action);
    case 'participant': return updateParticipant(state, action);
    }

    return control(state, action);
}

type SetAction = {
    type: 'set';
    state: BackpayState;
};

type SelectAction = {
    type: 'select';
    isSelected: boolean;
} & ({
    /** One item at a time. */
    item: EventItem;
} | {
    /** Multiple items at once. */
    items: EventItem[];
} | {
    /** All items for given client (and optionally his currency). */
    client: ClientInfo;
    currency?: Currency;
});

function updateSelect(state: BackpayState, action: SelectAction): BackpayState {
    if (!state.invoicingClients)
        return state;

    if ('items' in action && action.items.length === 0)
        return state;

    const client = 'client' in action
        ? action.client
        : 'item' in action
            ? action.item.participant.client
            : action.items[0].participant.client;
    const invoicingClient = state.invoicingClients.find(c => c.client.id.equals(client.id));
    if (!invoicingClient)
        return state;

    const newItems = updateItems(invoicingClient.items, action);

    return updateClientState(state, state.invoicingClients, invoicingClient, newItems);
}

function updateItems(items: EventItem[], action: SelectAction): EventItem[] {
    const isSelected = action.isSelected;
    if ('item' in action)
        return items.map(i => i !== action.item ? i : { ...i, isSelected });

    if ('items' in action)
        return items.map(i => !action.items.includes(i) ? i : { ...i, isSelected });

    const currency = action.currency;
    if (currency)
        return items.map(i => eventHasClientWithCurrency(i.event, action.client, currency) ? i : { ...i, isSelected });

    return items.map(i => ({ ...i, isSelected }));
}

function updateClientState({ clients }: BackpayState, invoicingClients: InvoicingClient[], client: InvoicingClient, items: EventItem[]): BackpayState {
    const newClient: InvoicingClient = { client: client.client, items };

    return {
        clients,
        invoicingClients: invoicingClients.map(c => c !== client ? c : newClient),
    };
}

type ParticipantAction = {
    type: 'participant';
    newParticipant: PayingParticipant;
    eventId: Id;
};

function updateParticipant(state: BackpayState, { newParticipant, eventId }: ParticipantAction): BackpayState {
    if (!state.invoicingClients)
        return state;

    const invoicingClient = state.invoicingClients.find(c => c.client.id.equals(newParticipant.client.id));
    if (!invoicingClient)
        return state;

    const newItems = invoicingClient.items.map(i => !i.event.id.equals(eventId) ? i : { ...i, participant: newParticipant });

    return updateClientState(state, state.invoicingClients, invoicingClient, newItems);
}

type ControlAction = {
    type: 'reset' | 'checkoutStart' | 'checkoutEnd';
};

function control(state: BackpayState, action: ControlAction): BackpayState {
    if (action.type === 'reset')
        return unselectAll(state);
    if (action.type === 'checkoutEnd')
        return { ...state, checkoutInput: undefined };

    if (!state.invoicingClients)
        return state;

    return { ...state, checkoutInput: createCheckoutInput(state.invoicingClients) };
}

function unselectAll(state: BackpayState): BackpayState {
    if (!state.invoicingClients)
        return state;

    const newClients = state.invoicingClients.map(client => ({
        ...client,
        state: {
            items: client.items.map(item => ({ ...item, isSelected: false })),
        },
    }));

    return { clients: state.clients, invoicingClients: newClients };
}

function createCheckoutInput(invoicingClients: InvoicingClient[]): EventCheckoutInput {
    // Nothing special - we just filter out all unselected events. Then we filter all clients with zero selected events.
    const forClients: EventItemsForClient[] = invoicingClients
        .map(client => {
            const participants = client.items
                .filter(i => i.isSelected)
                .map(i => ({
                    event: i.event,
                    participant: i.participant,
                }));

            return { info: client.client, participants };
        })
        .filter(client => client.participants.length > 0);

    return { type: CheckoutType.Event, forClients };
}
