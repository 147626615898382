import { getStringEnumValues } from '@/utils/common';
import { Id, type Entity, type IRI } from './Id';

enum Track {
    Subscription = 'subscription',
}

enum SubscriptionPhase {
	TrialClaimed = 'trialClaimed',
    Finished = 'finished',
}

export enum OnboardingFlag {
    ClientsCreated = 'clientsCreated',
    StripeOrBankAccount = 'stripeOrBankAccount',
    SettingsOpened = 'settingsOpened',
    ProductCreatedOrUpdated = 'productCreatedOrUpdated',
}

type OnboardingFlags = { [key in OnboardingFlag]: boolean };

export type OnboardingFromServer = {
    '@id': IRI;
} & {
	[key in Track]: SubscriptionPhase;
} & {
    [key in OnboardingFlag]: boolean;
};

export class Onboarding implements Entity {
    constructor(
        readonly id: Id,
        readonly subscription: SubscriptionPhase,
        readonly flags: OnboardingFlags,
        readonly isFlagsFinished: boolean,
    ) {}

    static fromServer(input: OnboardingFromServer): Onboarding {
        const flags: OnboardingFlags = {} as OnboardingFlags;
        let allFlagsFinished = true;
        getStringEnumValues(OnboardingFlag).forEach(key => {
            flags[key] = input[key];
            if (!flags[key])
                allFlagsFinished = false;
        });
        
        return new Onboarding(
            Id.fromIRI(input['@id']),
            input.subscription,
            flags,
            allFlagsFinished,
        );
    }
}

export type OnboardingInfo = {
    profession: string;
    clientCount: string;
    weeklySessionCount: string;
}
