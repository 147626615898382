import { createPOST } from '../rawApiObject';

export const auth = {
    googleRegister: createPOST<GoogleAuthFromServer>(
        '/oauth/google/register',
    ),
    googleLogin: createPOST<GoogleAuthFromServer>(
        '/oauth/google/login',
    ),
    googleConnect: createPOST<GoogleAuthFromServer>(
        '/oauth/google/connect',
    ),
};

type GoogleAuthFromServer = {
    connectUrl: string;
};
