import React, { useState, useRef, useMemo, useLayoutEffect, type ReactNode } from 'react';
import { Button, type ButtonProps, Spinner } from 'react-bootstrap';

type BaseButtonProps = Omit<ButtonProps, 'isFetching' | 'fetching' | 'fid' | 'isOverlay'> & {
    /** The icon is like content, but it will be displayed even if the button is fetching. */
    icon?: ReactNode;
};

// Type OR is not ideal here, because we would need to delete the other properties from the rest object.
type SpinnerButtonProps = BaseButtonProps & {
    isFetching?: boolean;
    /**
     * If fetching === fid, then the button is fetching.
     * Else if !!fetching, then the button is disabled.
     */
    fetching?: string;
    fid?: string;
    /** If the button is under overlay, it shouldn't be disabled even during fetching. */
    isOverlay?: boolean;
};

export default function SpinnerButton(props: BaseButtonProps & { isFetching: boolean | undefined }): JSX.Element;

export default function SpinnerButton(props: BaseButtonProps & { fetching: string | undefined, fid: string, isOverlay?: boolean }): JSX.Element;

/**
 * This component acts like a button that turns into a spinner whenewer isFetching === true.
 * The button is disabled, however its dimensions remain constant.
 */
export default function SpinnerButton({ disabled, isFetching, fetching, fid, isOverlay, style, icon, ...rest }: SpinnerButtonProps) {
    const [ maxWidth, setMaxWidth ] = useState<number>();
    const [ maxHeight, setMaxHeight ] = useState<number>();
    const contentRef = useRef<HTMLButtonElement>(null);

    const isFetchingInner = isFetching ?? (fid !== undefined && fetching === fid);
    const isDisabled = !!disabled || isFetchingInner || !(!fetching || isOverlay);

    useLayoutEffect(() => {
        if (!contentRef.current)
            return;

        const newWidth = contentRef.current.getBoundingClientRect().width;
        setMaxWidth((current) => (!current || newWidth > current) ? newWidth : current);

        const newHeight = contentRef.current.getBoundingClientRect().height;
        setMaxHeight((current) => (!current || newHeight > current) ? newHeight : current);
    }, [ isFetchingInner ]);

    const variant = useMemo(() => {
        if (rest.variant?.includes('outline'))
            return 'dark';
        else
            return 'light';
    }, [ rest.variant ]);

    return (
        <Button
            variant='primary'
            {...rest}
            disabled={isDisabled}
            ref={contentRef}
            style={(isFetchingInner ? { width: maxWidth, height: maxHeight, ...style } : style)}
        >
            {isFetchingInner ? (
                <Spinner
                    size='sm'
                    variant={variant}
                    animation='border'
                />
            ) : (
                rest.children
            )}
            {icon}
        </Button>
    );
}
