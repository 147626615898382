import { type BaseLocation, LocationType } from './BaseLocation';
import { PhysicalLocation, type PhysicalLocationFromServer } from './PhysicalLocation';
import { VideoLocation, type VideoLocationFromServer } from './VideoLocation';

export type LocationFromServer = VideoLocationFromServer | PhysicalLocationFromServer;

export function getLocationFromServer(locationFromServer: LocationFromServer): BaseLocation {
    return locationFromServer['@type'] === LocationType.physical ?
        PhysicalLocation.fromServer(locationFromServer) :
        VideoLocation.fromServer(locationFromServer as VideoLocationFromServer);
}
