import axios from 'axios';
import { ApiAuthorizer } from '../ApiAuthorizer';
import { type Id } from '@/types/Id';
import { FRONTEND_ERROR_NAME, RawApi } from '../rawApi';

const authorizer = new ApiAuthorizer();

function actionToPath(action: Id | string): string {
    if (typeof action !== 'string')
        throw new Error('Google api does not support ids.');

    return action;
}

const axiosInstance = axios.create({
    baseURL: '',
    headers: {
        Accept: 'application/ld+json',
        //Accept: 'application/json',
    },
    params: {}, // do not remove this, its added to add params later in the config
});

class GoogleNotAuthorizedError extends Error {
    readonly name = FRONTEND_ERROR_NAME;
    readonly data = 'googleApi.notAuthorized';

    constructor() {
        super('Google api not authorized.');
    }
}

axiosInstance.interceptors.request.use(
    config => {
        const token = authorizer.getToken();
        if (!token)
            throw new GoogleNotAuthorizedError();

        if (config.headers)
            config.headers['Authorization'] = 'Bearer ' + token;
        
        return config;
    },
);

export default new RawApi(
    axiosInstance,
    authorizer,
    actionToPath,
);