import React from 'react';
import { Alert } from 'react-bootstrap';
import useNotifications from '@/context/NotificationProvider';
import { Visibility } from '@/types/notifications';

type AlertDisplayProps = Readonly<{
    visibility: Visibility;
}>;

export default function AlertDisplay({ visibility }: AlertDisplayProps) {
    const { alerts, removeAlert } = useNotifications();

    return (
        <div className='sh-alert-display'>
            {alerts
                .filter(alert => alert.visibility === visibility || alert.visibility === Visibility.Global)
                .map(alert => (
                    <Alert
                        key={alert.id}
                        variant={alert.content.variant}
                        dismissible
                        show={alert.show}
                        onClose={() => removeAlert(alert.id)}
                    >
                        {typeof alert.content.message === 'string' ? (
                            alert.content.message
                        ) : (
                            <alert.content.message alertId={alert.id} />
                        )}
                    </Alert>
                ))}
        </div>
    );
}