import { dehydrate } from '@/types/api/result';
import { getLocationFromServer, type BaseLocation } from '@/types/location';
import { useCallback, useEffect, useState, type Dispatch, type SetStateAction, useMemo } from 'react';
import type { DefaultValue } from './utils';
import { type Id } from '@/types/Id';
import { api } from '@/utils/api/backend';

// @ts-expect-error React api overload
export function useLocations(defaultValue: DefaultValue<BaseLocation[]>): {
    locations: BaseLocation[];
    setLocations: Dispatch<SetStateAction<BaseLocation[]>>;
    addLocation: (location: BaseLocation) => void;
}

export function useLocations(): {
    locations: BaseLocation[] | undefined;
    setLocations: Dispatch<SetStateAction<BaseLocation[] | undefined>>;
    addLocation: (location: BaseLocation) => void;
};

export function useLocations(defaultValue?: DefaultValue<BaseLocation[]>) {
    const [ locations, setLocations ] = useState(defaultValue);

    const fetchLocations = useCallback(async (signal: AbortSignal) => {
        const response = await api.location.getAll(signal);
        if (response.status) {
            const fetchedLocations = dehydrate(response).items.map(getLocationFromServer);
            setLocations(fetchedLocations);
        }
    }, []);

    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchLocations(signal);

        return abort;
    }, [ fetchLocations ]);

    const addLocation = useCallback((location: BaseLocation) => {
        if (locations)
            setLocations([ ...locations, location ]);
        
    }, [ locations ]);

    return {
        locations,
        setLocations,
        addLocation,
    };
}

/**
 * It's like useLocation but it will also try to fetch a location with the specified locationId. The reason is that the locations can be soft-deleted. In that case, there could be events without location.
 */
export function useAllLocations(locationId: Id | undefined) {
    const [ location, setLocation ] = useState<BaseLocation>();
    const { locations, addLocation } = useLocations([]);

    const fetchLocation = useCallback(async (id: Id, signal: AbortSignal) => {
        const response = await api.location.get({ id }, signal);
        if (!response.status)
            return;

        setLocation(getLocationFromServer(response.data));
    }, [ setLocation ]);

    useEffect(() => {
        if (!locationId)
            return;

        if (!location || !locationId.equals(location.id)) {
            const [ signal, abort ] = api.prepareAbort();
            fetchLocation(locationId, signal);

            return abort;
        }

    }, [ location, locationId, fetchLocation ]);

    const allLocations = useMemo(() => {
        const currentLocation = (location && !locations.find(l => l.id.equals(location.id)))
            ? [ location ]
            : [];

        return [ ...currentLocation, ...locations ];
    }, [ location, locations ]);

    return {
        location,
        allLocations,
        addLocation,
    };
}