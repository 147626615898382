import React, { useCallback, useMemo } from 'react';
import { type SingleValue } from 'react-select';
import FormSelect from '@/components/forms/FormSelect';
import { useMaster } from '@/context/UserProvider';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { type InvoicingProfile } from '@/types/Invoicing';
import { Id, type IRI } from '@/types/Id';
import { useTranslation } from 'react-i18next';

type Option = {
    value: IRI;
    label: string;
};

function profileToOption(profile: InvoicingProfile): Option {
    return {
        value: profile.id.toIRI(),
        label: profile.title,
    };
}

type PlainInvoicingProfileSelectProps = Readonly<{
    iri?: IRI;
    onChange: (iri?: IRI) => void;
    options?: InvoicingProfile[];
    disabled?: boolean;
}>;

export function PlainInvoicingProfileSelect({ iri, onChange, options, disabled }: PlainInvoicingProfileSelectProps) {
    const { t } = useTranslation('common', { keyPrefix: 'select' });
    const handleOnChange = useCallback((option: SingleValue<Option>) => onChange(option !== null ? option.value : undefined), [ onChange ]);

    const { profiles } = useMaster();
    const innerOptions = useMemo(() => (options ?? profiles).map(profileToOption), [ options, profiles ]);
    const innerValue = useMemo(() => {
        if (!iri)
            return undefined;

        const id = Id.fromIRI(iri);
        const profile = (options ?? profiles).find(p => p.id.equals(id));
        if (!profile)
            return undefined;

        return profileToOption(profile);
    }, [ iri, options, profiles ]);

    return (
        <FormSelect
            placeholder={t('profile-placeholder')}
            value={innerValue}
            onChange={handleOnChange}
            options={innerOptions}
            isDisabled={disabled}
        />
    );
}

type ControlledInvoicingProfileSelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    options?: InvoicingProfile[];
    disabled?: boolean;
};

export function ControlledInvoicingProfileSelect<TFieldValues extends FieldValues>({ control, name, options, disabled }: ControlledInvoicingProfileSelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: IRI, onChange: (value?: IRI ) => void } }) => {
        return (
            <PlainInvoicingProfileSelect
                iri={field.value}
                onChange={field.onChange}
                options={options}
                disabled={disabled}
            />
        );
    }, [ options ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}
