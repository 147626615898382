import { getTaxRate, moneyFromServer, priceToServer, type TaxRate, type CurrencyIRI, type Money, type TaxRateIRI, type Currency } from '@/modules/money';
import type { RequiredBy } from '@/utils/common';
import { ClientInfo, type ClientInfoFromServer, type ClientContact, type ClientInit } from './Client';
import { Id, plainIdToIRI, type Entity, type IRI } from './Id';
import { type InvoicingProfile } from './Invoicing';
import type { ClientLocaleCode } from './i18n';
import { type OrderFromServer } from './orders/Order';
import { type Settings } from './Settings';

enum PaymentState {
    Paid = 'paid',
    Unpaid = 'unpaid',
}

function toPaymentState(paid: boolean): PaymentState {
    return paid ? PaymentState.Paid : PaymentState.Unpaid;
}

export enum ParticipantType {
    Guest = 'guest',
    Client = 'client',
    Both = 'both',
}

export type EventParticipantFromServer = {
    '@id': IRI;
    client: ClientInfoFromServer;
    paymentState: PaymentState;
    inOrder: boolean;
    inStripeOrder: boolean;
    type: ParticipantType;
    price?: number;
    currency?: CurrencyIRI;
    vat?: TaxRateIRI;
    invoiceIRI?: string;
    payByCredit: boolean;
};

export type EventParticipantUpdate = {
    client: IRI;
    paymentState?: PaymentState;
};

export class EventParticipant implements Entity {
    private constructor(
        readonly id: Id,
        readonly client: ClientInfo,
        readonly isGuest: boolean,
        readonly isInOrder: boolean,
        readonly isInStripeOrder: boolean,
        readonly payment?: EventParticipantPayment,
        readonly invoiceIRI?: string,
    ) {}

    static fromServer(input: EventParticipantFromServer) {
        return new EventParticipant(
            Id.fromIRI(input['@id']),
            ClientInfo.fromServer(input.client),
            input.type === ParticipantType.Guest || input.type === ParticipantType.Both,
            input.inOrder,
            input.inStripeOrder,
            EventParticipantPayment.fromServer(input),
            input.invoiceIRI,
        );
    }

    toUpdate(update: { isPaid?: boolean }): EventParticipantUpdate {
        return {
            client: this.client.id.toIRI(),
            paymentState: toPaymentState(update.isPaid ?? !!this.payment?.isPaid),
        };
    }

    get isPaying(): boolean {
        return !!this.payment;
    }
}

export class EventParticipantPayment {
    private constructor(
        readonly price: Money,
        readonly vat: TaxRate,
        readonly isPayByCredit: boolean,
        readonly isPaid: boolean,
    ) {}

    static fromServer(input: EventParticipantFromServer) {
        if (input.type === ParticipantType.Guest)
            return;

        if (input.price === undefined || !input.currency || !input.vat) {
            console.warn('EventParticipantPayment.fromServer: missing price, currency or vat', input);

            return undefined;
        }

        return new EventParticipantPayment(
            moneyFromServer(input.price, input.currency),
            getTaxRate(input.vat),
            input.payByCredit,
            input.paymentState == PaymentState.Paid,
        );
    }
}

export type PayingParticipant = RequiredBy<EventParticipant, 'payment'>;

function participantIsBilled(participant: PayingParticipant): boolean {
    return participant.isInOrder || participant.payment.isPaid || participant.payment.isPayByCredit;
}

export function participantIsBillable(participant: PayingParticipant): boolean {
    return participant.isPaying && !participantIsBilled(participant);
}

export type Participant = {
    identifier: string;
} & ({
    info: ClientInfo;
} | {
    contact: ClientContact;
});

export function getClientOrContact(participant: Participant): ClientInfo | ClientContact {
    return 'info' in participant ? participant.info : participant.contact;
}

export function getClientIdentifier(client: ClientInfo): string {
    return client.id.toString();
}

export function getContactIdentifier(contact: ClientContact): string {
    return contact.canonicalEmail;
}

export function getParticipantName(participant: Participant): string {
    return getClientOrContact(participant).name;
}

export function getParticipantEmail(participant: Participant): string {
    return ('info' in participant ? participant.info : participant.contact).email;
}

export function getParticipantLocale(participant: Participant, settings: Settings): ClientLocaleCode {
    return ('info' in participant ? participant.info : settings).locale as ClientLocaleCode;
}

export type EditablePayingParticipant = Participant & {
    price: number | '';
    currency: Currency;
    vat: TaxRate;
    /** Initially, this property is false. When the particicipant is loaded as a part of credit order, this is set to true. */
    readonly isPayByCredit: boolean;
    /** This is similar but for the payment state. */
    isPaid: boolean;
    original: PayingParticipant | undefined;
};

export type ClientOrContactToServer = {
    client: IRI;
} | {
    contact: ClientInit;
};

export function clientOrContactToServer(participant: Participant, settings: Settings, profile?: InvoicingProfile): ClientOrContactToServer {
    return 'info' in participant
        ? { client: plainIdToIRI(participant.info.id) }
        : { contact: clientContactToServer(participant.contact, settings, profile) };
}

export function clientContactToServer(contact: ClientContact, settings: Settings, profile?: InvoicingProfile): ClientInit {
    return {
        name: contact.name,
        email: contact.canonicalEmail,
        timezone: settings.timezone,
        locale: settings.locale as ClientLocaleCode,
        country: settings.country,
        invoicingProfile: profile?.id.toIRI(),
    };
}

export type ParticipantInitToServer = {
    client: IRI;
} & ParticipantPaymentData;

export type NewParticipantInitToServer = {
    contact: ClientInit;
} & ParticipantPaymentData;

type ParticipantPaymentData = {
    type: ParticipantType.Guest;
} | {
    type: ParticipantType.Client | ParticipantType.Both;
    payment: PaymentDataToServer;
}

type PaymentDataToServer = {
    price: number;
    currency: CurrencyIRI;
    vat: TaxRateIRI;
    paymentState: PaymentState;
}

export function participantsToServer(clients: EditablePayingParticipant[], guests: Participant[], settings: Settings): {
    participants: ParticipantInitToServer[];
    newParticipants: NewParticipantInitToServer[];
} {
    const participantsToServer: (ParticipantInitToServer | NewParticipantInitToServer)[] = [];

    const clientIdentifiers = new Set(clients.map(c => c.identifier));
    const guestIdentifiers = new Set(guests.map(g => g.identifier));

    clients.forEach(c => {
        if (c.price === '')
            return;

        participantsToServer.push({
            ...clientOrContactToServer(c, settings),
            // If the client is also a guest, we include him here and we set his type to both.
            type: guestIdentifiers.has(c.identifier) ? ParticipantType.Both : ParticipantType.Client,
            payment: {
                price: priceToServer(c.price),
                currency: c.currency.toIRI(),
                vat: c.vat.toIRI(),
                paymentState: toPaymentState(c.isPaid),
            },
        });
    });

    guests.forEach(g => {
        // If the guest is also a client, we already included him above.
        if (clientIdentifiers.has(g.identifier))
            return;

        participantsToServer.push({
            ...clientOrContactToServer(g, settings),
            type: ParticipantType.Guest,
        });
    });

    return {
        participants: participantsToServer.filter((p): p is ParticipantInitToServer => 'client' in p),
        newParticipants: participantsToServer.filter((p): p is NewParticipantInitToServer => 'contact' in p),
    };
}

export type UpdatedEventParticipantFromServer = {
    participant: EventParticipantFromServer;
    order?: OrderFromServer;
};
