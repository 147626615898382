import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Card, Accordion, Modal, Row, Col, FormControl, Button } from 'react-bootstrap';
import { api } from '@/utils/api/backend';
import { SpinnerButton } from '@/components/common';
import { useMaster } from '@/context/UserProvider';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from '@/router';
import { BankAccount, type BankAccountUpdate } from '@/types/BankAccount';
import { type Currency } from '@/modules/money';
import { createErrorAlert, createTranslatedErrorAlert, createTranslatedSuccessAlert } from '@/components/notifications';
import useNotifications from '@/context/NotificationProvider';
import BankingForm from '@/components/settings/BankingForm';
import { InfoCard, infos } from '@/components/settings/InfoCard';
import { uuidToBase32 } from '@/utils/math';
import { Team } from '@/types/Team';

const STRIPE_DASHBOARD_LINK = 'https://dashboard.stripe.com/';
const stripeIntegrationIcon = infos.stripeIntegration.icon;
const bankIntegrationIcon = infos.bankIntegration.icon;

export default function Payments() {
    const { t } = useTranslation('pages', { keyPrefix: 'payments' });
    const { appUser } = useMaster();
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.get('c') !== '1')
            return;

        if ('rewardful' in window && typeof window.rewardful === 'function') {
            window.rewardful('convert', { email: appUser.email });
            console.log('Rewardful converted.');
        }

        navigate(routes.payments);
    }, []);

    return (
        <div className='container-small pb-5'>
            <h1 className='mb-4'>{t('page-title')}</h1>
            <StripeIntegration />
            <h2 className='mt-5'>{t('bank-accounts-title')}</h2>
            <InfoCard infoKey='bankAccounts' className='my-4' />
            <div className='sh-description-no-border'>{t('bank-accounts-description')}</div>
            <BankAccountsSettings />
            <div className='pt-4' />
            <BankIntegration />
        </div>
    );
}

function StripeIntegration() {
    const { t } = useTranslation('pages', { keyPrefix: 'payments.stripeIntegration' });
    const { team } = useMaster();
    const [ isFetching, setIsFetching ] = useState(false);
    const { addAlert } = useNotifications();

    async function connectToStripe() {
        setIsFetching(true);
        const response = await api.team.connectStripe({});
        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            setIsFetching(false);
            return;
        }

        window.location.href = response.data.onboardingUrl;
    }

    const [ showInfoModal, setShowInfoModal ] = useState(false);

    if (!team.isStripeConnected) {
        return (
            <InfoCard
                infoKey='stripeIntegration'
                primaryButton={
                    <SpinnerButton
                        isFetching={isFetching}
                        onClick={connectToStripe}
                        className='compact sh-shadow-sm-dark'
                    >
                        {t('connect-stripe-button')}
                    </SpinnerButton>
                }
            />
        );
    }

    return (<>
        <Card className='border'>
            <Card.Body className='px-4 pb-4 d-flex flex-column'>
                <div className='mb-3'>{stripeIntegrationIcon}</div>
                <h2 className='mt-0 mb-2 fw-semibold'>{t('title')}</h2>
                <div className='flex-grow-1 sh-description-no-border mb-0'>{t('description')}</div>
                <div className='d-flex gap-2 mt-4'>
                    <a href={STRIPE_DASHBOARD_LINK} className='btn btn-primary compact' target='_blank' rel='noreferrer'>
                        {t('open-stripe-button')}
                    </a>
                    <Button variant='light' className='compact sh-shadow-sm-dark' onClick={() => setShowInfoModal(true)}>
                        {t('show-info-button')}
                    </Button>
                </div>
            </Card.Body>
        </Card>
        <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)} className='sh-modal-800'>
            <InfoCard
                infoKey='stripeIntegration'
                primaryButton={
                    <Button className='compact' onClick={() => setShowInfoModal(false)}>
                        {t('hide-info-button')}
                    </Button>
                }
            />
        </Modal>
    </>);
}

function BankAccountsSettings() {
    const { addAlert } = useNotifications();
    const { bankAccounts, setBankAccounts } = useMaster();

    function setAccount(account: BankAccount) {
        const index = bankAccounts.findIndex(a => a.id.equals(account.id));
        if (index === -1) {
            setBankAccounts([ ...bankAccounts, account ]);
        }
        else {
            bankAccounts[index] = account;
            setBankAccounts([ ...bankAccounts ]);
        }
    }

    const [ deletingAccount, setDeletingAccount ] = useState<BankAccount>();

    function deleteAccount(account: BankAccount) {
        setBankAccounts(bankAccounts.filter(a => !a.id.equals(account.id)));
        setDeletingAccount(undefined);

        addAlert(createTranslatedSuccessAlert('common:bankAccount.deleted-alert'));
    }

    return (<>
        <Accordion alwaysOpen className='w-75 mx-auto'>
            {bankAccounts.map(account => (
                <BankAccountItem
                    key={account.id.toString()}
                    account={account}
                    onUpdate={setAccount}
                    onDelete={setDeletingAccount}
                />
            ))}
            <CreateNewBankAccount
                onCreate={setAccount}
            />
        </Accordion>
        <DeleteBankAccountModal
            account={deletingAccount}
            onClose={() => setDeletingAccount(undefined)}
            onDelete={deleteAccount}
        />
    </>);
}

type BankAccountItemProps = Readonly<{
    account: BankAccount;
    onUpdate: (update: BankAccount) => void;
    onDelete: (account: BankAccount) => void;
}>;

function BankAccountItem({ account, onUpdate, onDelete }: BankAccountItemProps) {
    const [ isFetching, setIsFetching ] = useState(false);
    const { addAlert } = useNotifications();

    async function onSubmit(update: BankAccountUpdate) {
        setIsFetching(true);
        const response = await api.settings.updateBankAccount(account, update);
        setIsFetching(false);
        if (!response.status) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        addAlert(createTranslatedSuccessAlert('common:bankAccount.edited-alert'));
        onUpdate(BankAccount.fromServer(response.data));
    }

    return (
        <Accordion.Item eventKey={account.id.toString()}>
            <Accordion.Header className='my-0'>
                <span className='w-50'>{account.label}</span>
                <CurrenciesDisplay currencies={account.currencies} />
            </Accordion.Header>
            <Accordion.Body>
                <BankingForm
                    input={account}
                    onSubmit={onSubmit}
                    isFetching={isFetching}
                    onDelete={() => onDelete(account)}
                />
            </Accordion.Body>
        </Accordion.Item>
    );
}

type CurrenciesDisplayProps = Readonly<{
    currencies: Currency[];
}>;

function CurrenciesDisplay({ currencies }: CurrenciesDisplayProps) {
    const { i18n } = useTranslation();

    return (
        <div className='d-flex flex-wrap gap-3 gap-row-2'>
            {currencies.map(currency => (
                <span className='border border-1 border-primary rounded-5 text-primary py-2 px-3' key={currency.code}>{currency.displaySymbol(i18n.language)}</span>
            ))}
        </div>
    );
}

type CreateNewBankAccountProps = Readonly<{
    onCreate: (newAccount: BankAccount) => void;
}>;

function CreateNewBankAccount({ onCreate }: CreateNewBankAccountProps) {
    const [ isFetching, setIsFetching ] = useState(false);
    const { t } = useTranslation('pages', { keyPrefix: 'payments' });
    const { addAlert } = useNotifications();
    const [ key, setKey ] = useState(0);

    async function onSubmit(init: BankAccountUpdate) {
        setIsFetching(true);
        const response = await api.settings.createBankAccount(init);
        setIsFetching(false);
        if (!response.status) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        setKey(key + 1);
        addAlert(createTranslatedSuccessAlert('common:bankAccount.created-alert'));
        onCreate(BankAccount.fromServer(response.data));
    }

    return (
        <Accordion.Item eventKey='new'>
            <Accordion.Header className='my-0 text-primary'>
                <IoIosAddCircleOutline size={20} className='me-3' />
                <span>{t('create-new-account-label')}</span>
            </Accordion.Header>
            <Accordion.Body>
                <BankingForm
                    key={key}
                    onSubmit={onSubmit}
                    isFetching={isFetching}
                />
            </Accordion.Body>
        </Accordion.Item>
    );
}

type DeleteBankAccountModalProps = Readonly<{
    account?: BankAccount;
    onClose: () => void;
    onDelete: (deletedLocation: BankAccount) => void;
}>;

function DeleteBankAccountModal({ account, onClose, onDelete }: DeleteBankAccountModalProps) {
    const [ isFetching, setIsFetching ] = useState(false);
    const { t } = useTranslation('pages', { keyPrefix: 'payments.deleteBankAccountModal' });
    const { addAlert } = useNotifications();

    async function deleteAccount() {
        if (!account)
            return;

        setIsFetching(true);
        const response = await api.settings.deleteBankAccount(account);
        setIsFetching(false);
        if (!response.status) {
            addAlert(createTranslatedErrorAlert());
            onClose();
            return;
        }

        onDelete(account);
    }

    return (
        <Modal show={!!account} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='text-center'>
                    <Col>{t('text')}<br /></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <SpinnerButton
                    variant='danger'
                    isFetching={isFetching}
                    onClick={deleteAccount}
                    className='mx-auto'
                >
                    {t('confirm')}
                </SpinnerButton>
            </Modal.Footer>
        </Modal>
    );
}

function BankIntegration() {
    const { t } = useTranslation('pages', { keyPrefix: 'payments.bankIntegration' });
    const { team, subscription } = useMaster();
    const [ showBankIntegrationModal, setShowBankIntegrationModal ] = useState(false);

    const [ showInfoModal, setShowInfoModal ] = useState(false);
    const isConnected = !!team.paymentEmail && subscription.isBankIntegrationEnabled;

    return (<>
        <BankIntegrationModal
            show={showBankIntegrationModal}
            onClose={() => setShowBankIntegrationModal(false)}
        />
        {!isConnected ? (<>
            {subscription.isBankIntegrationEnabled ? (
                <InfoCard
                    infoKey='bankIntegration'
                    primaryButton={
                        <Button
                            onClick={() => setShowBankIntegrationModal(true)}
                            className='compact sh-shadow-sm-dark'
                        >
                            {t('connect-bank-button')}
                        </Button>
                    }
                />
            ) : (
                <InfoCard
                    infoKey='bankIntegration'
                    primaryButton={
                        <Link to={routes.subscription}>
                            <Button variant='primary' className='compact sh-shadow-sm-dark'>
                                {t('upgrade-plan-button')}
                            </Button>
                        </Link>
                    }
                    extraContent={
                        <div className='mt-3 fw-medium'>
                            {t('upgrade-plan-description')}
                        </div>
                    }
                />
            )}
        </>) : (<>
            <Card className='border'>
                <Card.Body className='px-4 pb-4 d-flex flex-column'>
                    <div className='mb-3'>{bankIntegrationIcon}</div>
                    <h2 className='mt-0 mb-2 fw-semibold'>{t('title')}</h2>
                    <div className='flex-grow-1 sh-description-no-border mb-0'>
                        <Trans
                            t={t}
                            i18nKey='description'
                            components={{
                                email: <FormControl disabled value={team.paymentEmail} className='my-1' />,
                            }}
                        />
                    </div>
                    <div className='d-flex gap-2 mt-4'>
                        <Button className='compact' onClick={() => setShowBankIntegrationModal(true)}>
                            {t('try-again-button')}
                        </Button>
                        <Button variant='light' className='compact sh-shadow-sm-dark' onClick={() => setShowInfoModal(true)}>
                            {t('show-info-button')}
                        </Button>
                    </div>
                </Card.Body>
            </Card>
            <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)} className='sh-modal-800'>
                <InfoCard
                    infoKey='bankIntegration'
                    primaryButton={
                        <Button className='compact' onClick={() => setShowInfoModal(false)}>
                            {t('hide-info-button')}
                        </Button>
                    }
                />
            </Modal>
        </>)}
    </>);
}

type BankIntegrationModalProps = Readonly<{
    show: boolean;
    onClose: () => void;
}>;

// First, we show the integration modal. It contains the generated email. Until the user clicks the finish button, we don't do anything.
// After the user clicks the finish button, we send a request to the backend to save the payment email. From now on, the user is considered connected.
// If the user is already connected, he can show this modal again. Then we just show the saved email and the same instructions. The finish button is now a close button.

function BankIntegrationModal({ show, onClose }: BankIntegrationModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'payments.bankIntegrationModal' });
    const { team, setTeam } = useMaster();
    const email = team.paymentEmail ?? generatePaymentEmail(team);
    const isConnected = !!team.paymentEmail;

    const [ isFetching, setIsFetching ] = useState(false);
    const { addAlert } = useNotifications();

    async function finishIntegration() {
        setIsFetching(true);
        const response = await api.team.createPaymentEmail({ email });
        setIsFetching(false);
        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            return;
        }

        const updatedUser = Team.fromServer(response.data);
        setTeam(updatedUser);
        onClose();
    }

    return (
        <Modal
            className='sh-modal-800'
            show={show}
            onHide={onClose}
            {...isFetching ? { backdrop: 'static', keyboard: false } : {}}
        >
            <Modal.Header>
                <Modal.Title className='d-flex gap-3 align-items-center'>
                    {bankIntegrationIcon}
                    {t('title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ol className='d-flex flex-column gap-2 m-0'>
                    <li>{t('step-1')}</li>
                    <li>{t('step-2')}</li>
                    <FormControl disabled value={email} />
                    <li>{t('step-3')}</li>
                    <li>{t('step-4')}</li>
                </ol>
            </Modal.Body>
            <Modal.Footer>
                {!isConnected ? (<>
                    <Button variant='outline-secondary' className='compact' onClick={onClose} disabled={isFetching}>
                        {t('cancel-button')}
                    </Button>
                    <SpinnerButton className='compact' onClick={finishIntegration} isFetching={isFetching}>
                        {t('finish-button')}
                    </SpinnerButton>
                </>) : (
                    <Button className='compact' onClick={onClose}>
                        {t('finish-button')}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

function generatePaymentEmail(team: Team) {
    const shortUuid = uuidToBase32(team.id.toString());
    return `p+${shortUuid}@flowlance.com`;
}
