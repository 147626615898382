import { createDELETE, createGET, createPOST, createPUT } from '../rawApiObject';
import type { HydraCollection } from '@/types/api/result';
import { type Id } from '@/types/Id';
import { type LocationFromServer } from '@/types/location';
import { type PhysicalLocationToServer } from '@/types/location/PhysicalLocation';
import { type VideoLocationToServer } from '@/types/location/VideoLocation';

export const location = {
    get: createGET<{ id: Id }, LocationFromServer>(
        u => `${u.id.toIRI()}`,
    ),
    getAll: createGET<HydraCollection<LocationFromServer>>(
        '/locations',
    ),
    createPhysical: createPOST<LocationFromServer, PhysicalLocationToServer>(
        '/physical-locations/create',
    ),
    createVideo: createPOST<LocationFromServer, VideoLocationToServer>(
        '/video-locations/create',
    ),
    update: createPUT<{ id: Id }, LocationFromServer, PhysicalLocationToServer | VideoLocationToServer>(
        u => `${u.id.toIRI()}`,
    ),
    delete: createDELETE<{ id: Id }, LocationFromServer>(
        u => `${u.id.toIRI()}`,
    ),
};
