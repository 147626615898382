import React, { useCallback } from 'react';
import { Client, ClientState, type ClientInfo } from '@/types/Client';
import { getStringEnumValues } from '@/utils/common';
import { api } from '@/utils/api/backend';
import { ClientUpdateType } from '@/utils/api/backend/endpoints/client';
import { useToggle, useUpdating } from '@/hooks';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type ClientStateBadgeProps = Readonly<{
    state: ClientState;
    className?: string;
}>;

export function ClientStateBadge({ state, className }: ClientStateBadgeProps) {
    const { t } = useTranslation('common', { keyPrefix: 'client.state' });
    const color = stateColors[state];

    return (
        <span className={clsx(`text-${color} bg-white border border-${color} rounded px-2 py-1`, className)}>{t(state)}</span>
    );
}

const stateColors: Record<ClientState, string> = {
    [ClientState.Lead]: 'info',
    [ClientState.Active]: 'success',
    [ClientState.Inactive]: 'danger',
};

type ClientStateEditProps = Readonly<{
    client: ClientInfo;
    setClient: (client: Client) => void;
}>;

export function ClientStateEdit({ client, setClient }: ClientStateEditProps) {
    const syncState = useCallback(async (newValue: ClientState) => {
        if (client.state === newValue)
            return false;

        const response = await api.client.update({ id: client.id, type: ClientUpdateType.State }, { state: newValue });
        if (!response.status)
            return false;

        const updatedClient = Client.fromServer(response.data);
        setClient(updatedClient);

        return true;
    }, [ client, setClient ]);

    const [ state, updateState, isUpdatingState ] = useUpdating(client.state, syncState);
    const [ show, setShow ] = useToggle(false);

    const onOptionClick = useCallback((option: ClientState) => {
        setShow.false();
        updateState(option);
    }, [ setShow, updateState ]);

    return (
        <div>
            <Dropdown autoClose='outside' show={show} onToggle={setShow.toggle} className='sh-dropdown-remove-indicator'>
                <Dropdown.Toggle
                    variant='outline-secondary'
                    onClick={setShow.toggle}
                    className='sh-dropdown-custom-icon compact border-0 p-0 min-h-0'
                    disabled={isUpdatingState}
                >
                    <ClientStateBadge state={state} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='p-0'>
                    {CLIENT_STATE_VALUES.map(option => (
                        <Dropdown.Item
                            key={option}
                            onClick={() => onOptionClick(option)}
                            className='d-flex py-2'
                        >
                            <ClientStateBadge state={option} />
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export const CLIENT_STATE_VALUES: ClientState[] = getStringEnumValues(ClientState);
