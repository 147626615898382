import { stringToServer } from '@/utils/common';
import { type CountryCode } from './i18n';
import { type BlankId, type BlankNode, type OmitId } from './Id';

export type AddressFromServer = {
    '@id': string;
    city?: string;
    country?: CountryCode;
    line1?: string;
    line2?: string;
    postalCode?: string;
    state?: string;
};

export class Address implements BlankNode {
    private constructor(
        readonly id: BlankId,
        readonly city?: string,
        readonly country?: CountryCode,
        readonly line1?: string,
        readonly line2?: string,
        readonly postalCode?: string,
        readonly state?: string,
    ) {}

    static fromServer(input: AddressFromServer): Address {
        return new Address(
            input['@id'],
            input.city,
            input.country,
            input.line1,
            input.line2,
            input.postalCode,
            input.state,
        );
    }

    toEditable(): EditableAddress {
        return {
            city: this.city ?? '',
            country: this.country ?? '',
            line1: this.line1 ?? '',
            line2: this.line2 ?? '',
            postalCode: this.postalCode ?? '',
            state:this.state ??  '',
        };
    }
}

/**
 * The reason for this shenanigans is that on the server, an empty string and null are different - i.e., when an invoice is created, null value is skipped, however the empty string will be use as a valid value. The same applies for whitespace-only values.
 * However, on frontend it is impossible to differentiate these two (if they are passed through a html input).
 */
export type AddressToServer = OmitId<AddressFromServer>;

export type EditableAddress = Required<AddressToServer>;

export const defaultEditableAddress: EditableAddress = Address.fromServer({ '@id': '' }).toEditable();

export function addressToServer(input: EditableAddress): AddressToServer {
    return {
        city: stringToServer(input.city),
        country: stringToServer(input.country),
        line1: stringToServer(input.line1),
        line2: stringToServer(input.line2),
        postalCode: stringToServer(input.postalCode),
        state: stringToServer(input.state),
    };
}

export function isAddressEqual(form: EditableAddress, address: Address | EditableAddress): boolean {
    return form.city === (address.city ?? '')
        && form.country === (address.country ?? '')
        && form.line1 === (address.line1 ?? '')
        && form.line2 === (address.line2 ?? '')
        && form.postalCode === (address.postalCode ?? '')
        && form.state === (address.state ?? '');
}
