import React, { useState, useEffect, useCallback, useMemo, type RefObject, type MutableRefObject } from 'react';
import clsx from 'clsx';

import { getSlotMetrics } from './utils/TimeSlots';
import TimeSlotGroup from './TimeSlotGroup';
import { localizer } from '.';
import { type DateTime } from 'luxon';

/**
 * Since the TimeGutter only displays the 'times' of slots in a day, and is separate
 * from the Day Columns themselves, we check to see if the range contains an offset difference
 * and, if so, change the beginning and end 'date' by a day to properly display the slots times
 * used.
 */
function adjustForDST(min: DateTime, max: DateTime) {
    if (localizer.getTimezoneOffset(min) !== localizer.getTimezoneOffset(max)) {
        return {
            start: localizer.add(min, -1, 'day'),
            end: localizer.add(max, -1, 'day'),
        };
    }
    return { start: min, end: max };
}

type TimeGutterProps = {
    min: DateTime;
    max: DateTime;
    getNow: () => DateTime;
    gutterRef?: RefObject<HTMLDivElement>;
};

function TimeGutter({ min, max, getNow, gutterRef }: TimeGutterProps) {
    const { start, end } = useMemo(() => adjustForDST(min, max),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ min?.toISO(), max?.toISO() ],
    );
    const [ slotMetrics, setSlotMetrics ] = useState(getSlotMetrics({
        min: start,
        max: end,
    }));

    useEffect(() => {
        if (!slotMetrics)
            return;

        setSlotMetrics(slotMetrics.update({
            min: start,
            max: end,
        }));
    /**
     * We don't want this to fire when slotMetrics is updated as it would recursively bomb
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ start?.toISO(), end?.toISO() ]);

    const renderSlot = useCallback((dateTime: DateTime, index: number) => {
        if (index) 
            return null; // don't return the first (0) index

        const isNow = slotMetrics.dateIsInGroup(getNow(), index);
        return (
            <span className={clsx('rbc-label', isNow && 'rbc-now')}>
                {localizer.fullFormat(dateTime, 'timeGutterFormat')}
            </span>
        );
    }, [ slotMetrics, getNow ]);

    return (
        <div className='rbc-time-gutter rbc-time-column' ref={gutterRef}>
            {(slotMetrics.groups as DateTime[][]).map((group, index) => {
                return (
                    <TimeSlotGroup
                        key={index}
                        group={group}
                        renderSlot={renderSlot}
                    />
                );
            })}
        </div>
    );
}

export default React.forwardRef<HTMLDivElement, TimeGutterProps>((props, ref) => (
    <TimeGutter gutterRef={ref as MutableRefObject<HTMLDivElement>} {...props} />
));
