import React from 'react';

type IconProps = Readonly<{
    size?: string | number;
    className?: string;
}>;

export default function FlowlanceLogo({ size, className }: IconProps) {
    return <img src='/flowlance_logo_6.svg' style={{ width: size, height: size }} className={className} />;
}
