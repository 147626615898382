import { compareStringsAscii } from '@/utils/common';
import { type Entity, Id, type IRI, type OmitId } from './Id';

export type ClientTagFromServer = {
    '@id': IRI;
    name: string;
    /** Hex code (6 characters). */
    color: string;
};

export class ClientTag implements Entity {
    private constructor(
        readonly id: Id,
        readonly name: string,
        /** Hex code (6 characters). */
        readonly color: string,
    ) {
    }

    static fromServer(input: ClientTagFromServer): ClientTag {
        return new ClientTag(
            Id.fromIRI(input['@id']),
            input.name,
            input.color,
        );
    }
}

export type ClientTagInit = OmitId<ClientTagFromServer> & {
    /** Client/ClientInfo IRI of the first client to add the tag to. */
    client?: IRI;
};

export type ClientTagUpdate = OmitId<ClientTagFromServer>;

export function sortClientTags(tags: ClientTag[]): ClientTag[] {
    return tags.sort((a, b) => compareStringsAscii(a.id.toString(), b.id.toString()));
}
