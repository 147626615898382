import { type CountryCode, type LocaleCode, type TimezoneCode } from '@/types/i18n';
import { type Entity, Id, type IRI } from '@/types/Id';
import type { Person } from './Person';

export type SettingsFromServer = {
    '@id': IRI;
    timezone: TimezoneCode;
    locale: LocaleCode;
    country: CountryCode;
    // currency: CurrencyIRI;
    // pricings: PricingFromServer[];
    hideEventPrices: boolean;
};

export type PreferencesSettingsUpdate = Partial<Omit<SettingsFromServer, 'pricings' | 'id'>>;

export type PersonSettingsUpdate = Omit<Person, 'email'>;

/**
 * This type represents settings that are not yet implemented on the backend, so they are hard-coded here.
 */
const NOT_INCLUDED_SETTINGS = {
    dateFormat: 'dd. MM. yyyy',
    dateTimeFormat: 'dd. MM. yyyy HH:mm',
};

export class Settings implements Entity {
    private constructor(
        readonly id: Id,
        readonly timezone: TimezoneCode,
        readonly locale: LocaleCode,
        readonly country: CountryCode,
        // readonly currency: Currency,
        // readonly pricings: Pricing[],
        readonly dateFormat: string,
        readonly dateTimeFormat: string,
        readonly hideEventPrices: boolean,
    ) {}

    static fromServer(input: SettingsFromServer) {
        return new Settings(
            Id.fromIRI(input['@id']),
            input.timezone,
            input.locale,
            input.country,
            // getCurrency(input.currency),
            // input.pricings.map(Pricing.fromServer),
            NOT_INCLUDED_SETTINGS.dateFormat,
            NOT_INCLUDED_SETTINGS.dateTimeFormat,
            input.hideEventPrices,
        );
    }
}
