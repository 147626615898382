import React, { useCallback, useEffect, useState, type FocusEvent } from 'react';
import { api } from '@/utils/api/backend';
import { Button, Form, Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { type Event, MAX_NOTES_LENGTH } from '@/types/Event';
import { useTranslation } from 'react-i18next';
import { EditingPhase, useCached, useEditing, useToggle, type UseToggleSet } from '@/hooks';
import useNotifications from '@/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { CheckIcon, EditNotesIcon, NotesIcon, SendIcon } from '../icons';
import { SpinnerButton } from '../common';
import TextareaAutosize from 'react-textarea-autosize';
import clsx from 'clsx';
import { EventGroup } from '@/types/EventGroup';
import { type ClientInfo } from '@/types/Client';
import type { UseEventDispatch, UseEventState } from './useEvent';
import { OnlyToYouLabel } from '../calendar/PricingsEditor';
import { DeleteButton } from '../forms/buttons';
import { Link } from 'react-router-dom';
import { routes } from '@/router';

type StateDispatchProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
}>;

export default function EventNotes({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes' });
    const [ isShow, setIsShow ] = useState(!!state.event?.notes);

    if (!isShow) {
        return (
            <Button
                variant='outline-secondary'
                onClick={() => setIsShow(true)}
            >
                <NotesIcon size={18} className='me-2' />
                {t('open-notes-button')}
            </Button>
        );
    }

    return state.event ? (
        <EditableEventNotes state={state} dispatch={dispatch} setIsShow={setIsShow} />
    ) : (
        <InitialEventNotes state={state} dispatch={dispatch} setIsShow={setIsShow} />
    );
}

type InitialEventNotesProps = StateDispatchProps & Readonly<{
    setIsShow: (value: boolean) => void;
}>;

function InitialEventNotes({ state, dispatch, setIsShow }: InitialEventNotesProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes' });
    const [ isFocus, setIsFocus ] = useToggle(false);

    function onDelete() {
        dispatch({ type: 'input', field: 'initialNotes', value: '' });
        setIsShow(false);
    }

    return (
        <div>
            <div className='d-flex align-items-center pt-3 pb-2'>
                <h3 className='m-0 lha-3'>{t('notes-title')}</h3>
                <div className='flex-grow-1'/>
                <OnlyToYouLabel />
                <DeleteButton aria={t('close-notes-button')} onClick={onDelete} />
            </div>
            <div className='pt-1'></div>
            <div className='sh-event-notes-inner'>
                <Form.Control
                    placeholder={t('notes-placeholder')}
                    as={TextareaAutosize}
                    className={isFocus ? undefined : 'text-truncate-lines-3'}
                    minRows={3}
                    maxRows={isFocus ? undefined : 3}
                    value={state.form.initialNotes}
                    onChange={e => dispatch({ type: 'input', field: 'initialNotes', value: e.target.value })}
                    onBlur={setIsFocus.false}
                    onFocus={setIsFocus.true}
                />
            </div>
        </div>
    );
}

function maxLengthRule(value: string) {
    return value.length <= MAX_NOTES_LENGTH;
}

type EditableEventNotesProps = StateDispatchProps & Readonly<{
    setIsShow: (value: boolean) => void;
}>;

function EditableEventNotes({ state, dispatch, setIsShow }: EditableEventNotesProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes' });
    const [ isFocus, setIsFocus ] = useToggle(false);
    const event = state.update?.event ?? state.event!;
    const onUpdate = useCallback((updatedEvent: Event) => dispatch({ type: 'update', notes: updatedEvent.notes, eventId: updatedEvent.id }), [ dispatch ]);
    const { innerValue, phase, setValue, onBlur, doUpdate } = useEditableEventNotes(event, onUpdate, setIsFocus);

    async function deleteNotes() {
        // The synced value is empty, we just close the input.
        if (!event.notes) {
            setIsShow(false);
            return;
        }

        const result = await doUpdate({ value: '' });
        if (result)
            setIsShow(false);
    }

    const isUpdating = phase === EditingPhase.Updating;

    return (
        <div>
            <div>
                <div className='d-flex align-items-center pt-3 pb-2'>
                    {event.guests.length === 1 ? (
                        <Link
                            to={routes.clients.detail.resolve(
                                { id: event.guests[0].client.id.toString(), key: 'notes' },
                                { '#fragment': event.id.toString() },
                            )}
                            className='text-reset text-decoration-none hoverable-underline d-flex align-items-center gap-3'
                        >
                            <EditNotesIcon size={20} />
                            <span className='fw-medium'>{t('notes-title')}</span>
                        </Link>
                    ) : (
                        <div className='d-flex align-items-center gap-3'>
                            <EditNotesIcon size={20} />
                            <span className='fw-medium'>{t('notes-title')}</span>
                        </div>
                    )}
                    <div className='flex-grow-1'/>
                    <OnlyToYouLabel />
                    <DeleteButton aria={t('close-notes-button')} onClick={deleteNotes} className='sh-blur-button-target' disabled={isUpdating} />
                </div>
            </div>
            <div>
                <div className='d-flex sh-event-notes-inner'>
                    <Form.Control
                        placeholder={t('notes-placeholder')}
                        as={TextareaAutosize}
                        className={isFocus ? undefined : 'text-truncate-lines-3'}
                        minRows={3}
                        maxRows={isFocus ? undefined : 3}
                        value={innerValue}
                        onChange={e => setValue(e.target.value)}
                        onBlur={onBlur}
                        disabled={isUpdating}
                        onFocus={setIsFocus.true}
                    />
                    <div className='sh-editable-notes-icon'>
                        {isUpdating ? (
                            <div><Spinner size='sm' variant='secondary' animation='border' /></div>
                        ) : (
                            <div><CheckIcon size={16} /></div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function useEditableEventNotes(event: Event, onUpdate: (event: Event) => void, setIsFocus: UseToggleSet) {
    const { addAlert } = useNotifications();

    const syncNotes = useCallback(async (notes: string) => {
        const response = await api.event.update({ id : event.id }, { notes });
        if (!response.status) {
            addAlert(createTranslatedErrorAlert());
            return false;
        }
        const newEvent = EventGroup.fromServer(response.data).events.find(e => e.id.equals(event.id));
        if (!newEvent)
            return false;

        onUpdate(newEvent);
        addAlert(createTranslatedSuccessAlert('components:eventNotes.notes-update-success-alert'));

        return true;
    }, [ addAlert, onUpdate, event.id ]);

    const { state: { value: innerValue, phase }, setValue, doUpdate } = useEditing(event.notes, syncNotes, { rule: maxLengthRule });

    async function onBlur(event: FocusEvent) {
        const isTargetButton = event.relatedTarget?.classList.contains('sh-blur-button-target');
        if (isTargetButton) {
            // If the user clicks on the delete button, we want to only fire deleting the notes.
            setIsFocus.false();
            return;
        }

        await doUpdate();
        setIsFocus.false();
    }

    return { innerValue, phase, setValue, onBlur, doUpdate };
}

type SendNotesButtonProps = Readonly<{
    event: Event;
    client?: ClientInfo;
    className?: string;
    disabled?: boolean;
    compact?: boolean;
}>;

export function SendNotesButton({ event, client, className, disabled, compact }: SendNotesButtonProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes' });
    const [ showModal, setShowModal ] = useToggle(false);

    return (<>
        <SendNotesModal
            event={showModal ? event : undefined}
            client={client}
            onHide={setShowModal.false}
        />
        <Button
            variant='outline-secondary'
            className={clsx('compact', className)}
            onClick={setShowModal.true}
            disabled={disabled}
        >
            <SendIcon size={18} />
            {!compact && (
                <span className='ms-2'>{t('send-notes-button', { count: event.guests.length })}</span>
            )}
        </Button>
    </>);
}

enum FetchingType {
    One = 'one',
    All = 'all',
}

type SendNotesModalProps = Readonly<{
    event?: Event;
    client?: ClientInfo;
    onHide: () => void;
}>;

function SendNotesModal({ event: uncachedEvent, client, onHide }: SendNotesModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes.sendNotesModal' });
    const [ fetching, setFetching ] = useState<FetchingType>();

    const event = useCached(uncachedEvent);

    useEffect(() => {
        if (event)
            setFetching(undefined);
    }, [ event ]);

    const { addAlert } = useNotifications();

    async function sendNotes(type: FetchingType) {
        if (!event)
            return;

        const clients = (client && type === FetchingType.One)
            ? [ client.id.toIRI() ]
            : event.guests.map(p => p.client.id.toIRI());

        setFetching(type);
        const response = await api.event.sendNotes({ id: event.id }, { clients });
        setFetching(undefined);

        if (!response.status) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        addAlert(createTranslatedSuccessAlert('components:eventNotes.sendNotesModal.success-alert'));
        onHide();
    }

    if (!event)
        return null;

    return (
        <Modal show={!!uncachedEvent} onHide={onHide} className='sh-send-notes-modal'>
            <div className='header'>
                {t('title')}
            </div>
            <div className='body'>
                {client ? (<>
                    <SpinnerButton
                        onClick={() => sendNotes(FetchingType.One)}
                        variant='primary'
                        isFetching={fetching === FetchingType.One}
                        disabled={fetching === FetchingType.All}
                    >
                        {t('send-to-one-button')}
                    </SpinnerButton>
                    {event.guests.length > 1 && (
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip>
                                    {event?.guests.map(p => <div key={p.id.toString()} className='lha-3'>{p.client.name}</div>)}
                                </Tooltip>
                            }
                        >
                            <div>
                                <SpinnerButton
                                    onClick={() => sendNotes(FetchingType.All)}
                                    variant='outline-primary'
                                    isFetching={fetching === FetchingType.All}
                                    disabled={fetching === FetchingType.One}
                                    className='w-100'
                                >
                                    {t('send-to-all-button')}
                                </SpinnerButton>
                            </div>
                        </OverlayTrigger>
                    )}
                </>) : (
                    // If the client isn't selected, there is always only one button.
                    <SpinnerButton
                        onClick={() => sendNotes(FetchingType.All)}
                        variant='primary'
                        isFetching={fetching === FetchingType.All}
                    >
                        {t('send-button', { count: event.guests.length })}
                    </SpinnerButton>
                )}
                <Button variant='outline-danger' onClick={onHide} disabled={!!fetching}>
                    {t('cancel-button')}
                </Button>
            </div>
        </Modal>
    );
}
