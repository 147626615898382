import { CloseIcon, PlusIcon } from '@/components/icons';
import clsx from 'clsx';
import React, { useState, type Dispatch, type SetStateAction, useCallback, type ReactNode, useMemo, useEffect, useRef } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export type FilterMenuProps<TState> = Readonly<{
    state: TState;
    setState: (newState: TState) => void;
}>;

export type FilterItemBadgeProps<TItem> = Readonly<{
    item: TItem;
    onClose: (item: TItem) => void;
}>;

type InnerFilterDefinition<TItem, TState, TServer> = {
    name: string;
    defaultValues: TState;
    FilterToggleMenu: React.FC<FilterMenuProps<TState>>;
    FilterRowMenu: React.FC<FilterMenuProps<TState>>;
    FilterItemBadge: React.FC<FilterItemBadgeProps<TItem>>;
    remove: (state: TState, item: TItem) => TState;
    toItems: (state: TState) => TItem[];
    toServer: (state: TState, previous: TServer | undefined) => TServer;
};

export type FilterFunction<TData> = (data: TData) => boolean;

export type FilterDefinition<TItem, TState, TData> = InnerFilterDefinition<TItem, TState, unknown> & {
    createFilterFunction: (state: TState) => FilterFunction<TData>;
};

export type Filter = FilterDefinition<unknown, unknown, unknown>;

function computeInitialState<TState>(filters: InnerFilterDefinition<unknown, TState, unknown>[]): TotalState<TState> {
    return filters.reduce((ans, filter) => {
        ans[filter.name] = filter.defaultValues;
        return ans;
    }, {} as TotalState<TState>);
}

type TotalState<TState> = {
    [key: string]: TState;
}

type DefaultFilterItemBadgeProps<TItem> = FilterItemBadgeProps<TItem> & {
    children: ReactNode;
};

type ReducedFilterControl = {
    state: TotalState<unknown>;
    setState: Dispatch<SetStateAction<TotalState<unknown>>>;
    filters: InnerFilterDefinition<unknown, unknown, unknown>[];
};

type FilterRowProps = Readonly<{
    control: ReducedFilterControl;
    className?: string;
}>;

export default function FilterRow({ control, className }: FilterRowProps) {
    const { filters, state: totalState, setState: setTotalState } = control;

    return (
        <div className={clsx(className, 'd-flex align-items-center gap-2 flex-wrap')}>
            {/* <FiltersToggleSelect filterDefinitions={filters} totalState={totalState} setTotalState={setTotalState} /> */}
            <FiltersRowSelect filterDefinitions={filters} totalState={totalState} setTotalState={setTotalState} />
            {filters.flatMap(filter => (
                filter.toItems(totalState[filter.name]).map((item, index) => (
                    <filter.FilterItemBadge
                        key={`${filter.name}.${index}`}
                        item={item}
                        onClose={item => setTotalState({ ...totalState, [filter.name]: filter.remove(totalState[filter.name], item) })}
                    />
                ))
            ))}
        </div>
    );
}

type FiltersSelectProps<TItem, TState> = Readonly<{
    filterDefinitions: InnerFilterDefinition<TItem, TState, unknown>[];
    totalState: TotalState<TState>;
    setTotalState: Dispatch<SetStateAction<TotalState<TState>>>;
}>;

// Old design, maybe we'll use it again in the future
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function FiltersToggleSelect<TItem, TState>({ filterDefinitions, totalState, setTotalState }: FiltersSelectProps<TItem, TState>) {
    const { t } = useTranslation('common', { keyPrefix: 'filters' });
    const [ showMenu, setShowMenu ] = useState(false);
    const [ selectedName, setSelectedName ] = useState<string>();

    const toggleShowMenu = useCallback((newValue: boolean) => {
        if (newValue)
            setSelectedName(undefined);
        setShowMenu(newValue);
    }, []);

    return (
        <Dropdown show={showMenu} onToggle={toggleShowMenu} drop='down' autoClose='outside'>
            <Dropdown.Toggle variant='outline-secondary' className='sh-dropdown-custom-icon compact'>
                <PlusIcon size={18} className='me-2' />{t('add-filter-button')}
            </Dropdown.Toggle>
            <FiltersMenu
                filterDefinitions={filterDefinitions}
                totalState={totalState}
                setTotalState={setTotalState}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
            />
        </Dropdown>
    );
}

type FiltersMenuProps<TItem, TState> = Readonly<{
    filterDefinitions: InnerFilterDefinition<TItem, TState, unknown>[];
    totalState: TotalState<TState>;
    setTotalState: Dispatch<SetStateAction<TotalState<TState>>>;
    selectedName?: string;
    setSelectedName: Dispatch<SetStateAction<string | undefined>>;
}>;

function FiltersMenu<TItem, TState>({ filterDefinitions, totalState, setTotalState, selectedName, setSelectedName }: FiltersMenuProps<TItem, TState>) {
    const { t } = useTranslation('common', { keyPrefix: 'filters' });
    const selectedFilter = filterDefinitions.find(filter => filter.name === selectedName);

    if (!selectedFilter) {
        return (
            <Dropdown.Menu className='sh-dropdown-menu' style={{ width: '300px' }}>
                <div className='sh-dropdown-menu-inner'>
                    {filterDefinitions.map(filter => (
                        <Button variant='outline-secondary' key={filter.name} onClick={() => setSelectedName(filter.name)}>
                            {t(`${filter.name}.menu-button`)}
                        </Button>
                    ))}
                </div>
            </Dropdown.Menu>
        );
    }

    return (
        <Dropdown.Menu style={{ width: '300px' }}>
            <selectedFilter.FilterToggleMenu
                state={totalState[selectedFilter.name]}
                setState={newState => setTotalState({ ...totalState, [selectedFilter.name]: newState })}
            />
        </Dropdown.Menu>
    );
}

function FiltersRowSelect<TItem, TState>({ filterDefinitions, totalState, setTotalState }: FiltersSelectProps<TItem, TState>) {
    return (
        <div className='d-flex flex-row align-items-center gap-2'>
            {filterDefinitions.map(filter => (
                <filter.FilterRowMenu
                    key={filter.name}
                    state={totalState[filter.name]}
                    setState={newState => setTotalState({ ...totalState, [filter.name]: newState })}
                />
            ))}
        </div>
    );
}

export function createDefaultFilterRowDropdown<TState>(filterName: string, ToggleMenu: React.FC<FilterMenuProps<TState>>): React.FC<FilterMenuProps<TState>> {
    return ({ state, setState }: FilterMenuProps<TState>) => {
        const { t } = useTranslation('common', { keyPrefix: 'filters' });

        return (
            <Dropdown drop='down' autoClose='outside'>
                <Dropdown.Toggle variant='outline-secondary' className='sh-dropdown-custom-icon compact'>
                    {t(`${filterName}.menu-button`)}
                </Dropdown.Toggle>
                <Dropdown.Menu className='p-0 overflow-hidden'>
                    <ToggleMenu state={state} setState={setState} />
                </Dropdown.Menu>
            </Dropdown>
        );
    };
}

export function DefaultFilterItemBadge<TItem>({ item, onClose, children }: DefaultFilterItemBadgeProps<TItem>) {
    return (
        <div className='sh-filter-item-badge'>
            {children}
            <CloseIcon size={14} className='clickable ms-2' onClick={() => onClose(item)} />
        </div>
    );
}

// Hook

export type UseFiltersControl = {
    state: TotalState<unknown>;
    setState: Dispatch<SetStateAction<TotalState<unknown>>>;
    filters: Filter[];
    toServer: (name: string) => unknown;
};

export function useFilters(filters: Filter[]): UseFiltersControl {
    const [ state, setState ] = useState<TotalState<unknown>>(computeInitialState(filters));

    useEffect(() => {
        setState(computeInitialState(filters));
    }, [ filters ]);

    const previousToServer = useRef(new Map<string, unknown>());

    const control = useMemo(() => {
        // The double-caching is needed because sometimes, the state does change, however the toServer output does not.
        // For example, when filtering by clients, there are no clients first (both available and selected). When we fetch the clients, the state will change, so we compute this agaein. The toServer output will be [] (again), however, [] !== [].
        // A solution might be to only include data that directly changes toServer output in the state. So we would need to store the available clients somewhere else.
        // Maybe the FilterMenu component could be dynamically passed to the FilterRow?
        const toServerCached = new Map(filters.map(filter => {
            const previous = previousToServer.current.get(filter.name);
            const current = filter.toServer(state[filter.name], previous);
            return [ filter.name, current ];
        }));
        previousToServer.current = toServerCached;

        const toServer = (name: string) => toServerCached.get(name);

        return {
            state,
            setState,
            filters,
            toServer,
        };
    }, [ state, filters ]);

    return control;
}

// The apply function isn't on the default useFilters hook, because then it wouldn't be possible to combine filters with different TData types.
export function useFiltersApply<TData>({ state, filters }: UseFiltersControl, name?: string): FilterFunction<TData> {
    return useMemo(() => createFilterFunction({ state, filters }, name), [ state, filters, name ]);
}

export function createFilterFunction<TData>({ state, filters }: Pick<UseFiltersControl, 'state' | 'filters'>, name?: string): FilterFunction<TData> {
    if (!name) {
        const filterFunctions = filters.map(filter => filter.createFilterFunction(state[filter.name]));
        return (data: TData) => filterFunctions.every(filterFunction => filterFunction(data));
    }

    const filterFunction = filters.find(f => f.name === name)?.createFilterFunction(state[name]);
    if (!filterFunction)
        throw new Error(`Filter '${name}' not found`);

    return filterFunction;
}
