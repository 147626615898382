import React, { useCallback, useMemo, useState } from 'react';
import { DefaultFilterItemBadge, type Filter, type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps } from './FilterRow';
import { Dropdown } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { type ClientInfo } from '@/types/Client';
import { compareArrays } from '@/utils/common';
import type { CalendarEvent } from '@/types/calendar/Calendar';
import { SingleContinuousParticipantSelect } from '@/components/client/ContinuousParticipantSelect';
import { getParticipantName, type Participant } from '@/types/EventParticipant';

export const filterName = 'calendarEventParticipant';

type FilterState = {
    selected: Participant[];
    all: ClientInfo[];
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <Dropdown.Item className='p-3 bg-white sh-design text-dark'>
            <InnerFilter state={state} setState={setState} />
        </Dropdown.Item>
    );
}

function FilterRowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <div className='sh-design compact text-dark' style={{ width: 300 }}>
            <InnerFilter state={state} setState={setState} />
        </div>
    );
}

function InnerFilter({ state, setState }: FilterMenuProps<FilterState>) {
    const availableClients = useMemo(() => state.all.filter(client => !state.selected.some(
        participant => 'info' in participant && participant.info.id.equals(client.id),
    )), [ state ]);
    const [ value, setValue ] = useState<Participant>();

    const handleOnChange = useCallback((participant?: Participant) => {
        if (!participant)
            return;

        setState({ ...state, selected: [ ...state.selected, participant ] });
        setValue(undefined);
    }, [ state, setState ]);
    
    return (
        <SingleContinuousParticipantSelect
            // This forces the element to re-render when a new item is added. The reason is that we want to reset the filter options (so that the user can't select the same client twice). However, this is (most probably) not possible with AsyncSelect, so we have to force a re-render.
            key={state.selected.length}
            clients={availableClients}
            value={value}
            onChange={handleOnChange}
            autoFocus
        />
    );
}

function FilterItemBadge({ item, onClose }: FilterItemBadgeProps<Participant>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });
    const name = getParticipantName(item);

    return (
        <DefaultFilterItemBadge item={item} onClose={onClose}>
            <Trans t={t} i18nKey='badge-label' components={{ sm: <span className='fw-medium' /> }} values={{ name }} />
        </DefaultFilterItemBadge>
    );
}

function remove(state: FilterState, item: Participant): FilterState {
    return {
        ...state,
        selected: state.selected.filter(participant => participant.identifier !== item.identifier),
    };
}

function toItems(state: FilterState): Participant[] {
    return state.selected;
}

function createFilterFunction(state: FilterState): FilterFunction<CalendarEvent> {
    if (state.selected.length === 0)
        return () => true;

    const set = new Set<string>(state.selected.map(participant => participant.identifier));

    return (data: CalendarEvent) => {
        if (data.resource.type === 'event') {
            return data.resource.event.guests.some(participant => set.has(participant.client.id.toString()))
                || data.resource.event.clients.some(participant => set.has(participant.client.id.toString()));
        }
    
        if (data.resource.type === 'google')
            return data.resource.event.guests.some(participant => set.has(participant));
    
        return true;
    };
}

type ClientParticipant = { info: ClientInfo, identifier: string };

function toServer(state: FilterState, previous: string[] | undefined): string[] {
    const current = state.selected
        .filter((participant): participant is ClientParticipant => 'info' in participant)
        .map(participant => participant.info.id.toString()).sort();

    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

function createFilter(allClients: ClientInfo[]): FilterDefinition<Participant, FilterState, CalendarEvent> {
    return {
        name: filterName,
        defaultValues: {
            all: allClients,
            selected: [],
        },
        FilterToggleMenu,
        FilterRowMenu,
        FilterItemBadge,
        remove,
        toItems,
        createFilterFunction,
        toServer: toServer as (state: FilterState, previous: unknown | undefined) => string[],
    };
}

export default createFilter as (allClients: ClientInfo[]) => Filter;