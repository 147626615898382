import React, { createRef } from 'react';
import clsx from 'clsx';

import Selection, { getBoundsForNode, isEvent } from './Selection';
import { isSelected } from './utils/selection';

import TimeSlotGroup from './TimeSlotGroup';
import TimeGridEvent from './TimeGridEvent';

import DayColumnWrapper from './DayColumnWrapper';
import { DateTime } from 'luxon';
import { withTranslation } from 'react-i18next';
import { localizer } from '.';
import EventContainerWrapper from './addons/dragAndDrop/EventContainerWrapper';
import { getSlotMetrics } from './utils/TimeSlots';
import { getStyledEvents } from './utils/layout-algorithms';
import { rightToLeft, step, timeslots } from './utils/common';

class DayColumn extends React.Component {
    state = { selecting: false, timeIndicatorPosition: null };
    intervalTriggered = false;

    constructor(...args) {
        super(...args);

        this.slotMetrics = getSlotMetrics(this.props);
        this.containerRef = createRef();
    }

    componentDidMount() {
        this.props.selectable && this._selectable();

        if (this.props.isNow) 
            this.setTimeIndicatorPositionUpdateInterval();
    
    }

    componentWillUnmount() {
        this._teardownSelectable();
        this.clearTimeIndicatorInterval();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selectable && !this.props.selectable) 
            this._selectable();
        if (!nextProps.selectable && this.props.selectable)
            this._teardownSelectable();

        this.slotMetrics = this.slotMetrics.update(nextProps);
    }

    componentDidUpdate(prevProps, prevState) {
        const { getNow, isNow, date, min, max } = this.props;
        const getNowChanged = localizer.neq(prevProps.getNow(), getNow(), 'minutes');

        if (prevProps.isNow !== isNow || getNowChanged) {
            this.clearTimeIndicatorInterval();

            if (isNow) {
                const tail =
          !getNowChanged &&
          localizer.eq(prevProps.date, date, 'minutes') &&
          prevState.timeIndicatorPosition === this.state.timeIndicatorPosition;

                this.setTimeIndicatorPositionUpdateInterval(tail);
            }
        }
        else if (
            isNow &&
      (localizer.neq(prevProps.min, min, 'minutes') ||
        localizer.neq(prevProps.max, max, 'minutes'))
        ) {
            this.positionTimeIndicator();
        }
    }

    /**
   * @param tail {Boolean} - whether `positionTimeIndicator` call should be
   *   deferred or called upon setting interval (`true` - if deferred);
   */
    setTimeIndicatorPositionUpdateInterval(tail = false) {
        if (!this.intervalTriggered && !tail) 
            this.positionTimeIndicator();
    

        this._timeIndicatorTimeout = window.setTimeout(() => {
            this.intervalTriggered = true;
            this.positionTimeIndicator();
            this.setTimeIndicatorPositionUpdateInterval();
        }, 60000);
    }

    clearTimeIndicatorInterval() {
        this.intervalTriggered = false;
        window.clearTimeout(this._timeIndicatorTimeout);
    }

    positionTimeIndicator() {
        const { min, max, getNow } = this.props;
        const current = getNow();

        if (current >= min && current <= max) {
            const top = this.slotMetrics.getCurrentTimePosition(current);
            this.intervalTriggered = true;
            this.setState({ timeIndicatorPosition: top });
        }
        else {
            this.clearTimeIndicatorInterval();
        }
    }

    render() {
        const { date, isNow, dayIndex } = this.props;
        const { selecting, top, height, startDate, endDate } = this.state;
        const selectDates = { start: startDate, end: endDate };

        return (
            <DayColumnWrapper
                ref={this.containerRef}
                date={date}
                className={clsx(
                    'rbc-day-slot',
                    'rbc-time-column',
                    isNow && 'rbc-now',
                    isNow && 'rbc-today', // WHY
                    selecting && 'rbc-slot-selecting',
                )}
                slotMetrics={this.slotMetrics}
            >
                {this.slotMetrics.groups.map((group, index) => (
                    <TimeSlotGroup
                        key={index}
                        group={group}
                        date={date}
                    />
                ))}
                <EventContainerWrapper slotMetrics={this.slotMetrics}>
                    <div className={clsx('rbc-events-container', rightToLeft && 'rtl')}>
                        {this.renderEvents(this.props.backgroundEvents, true)}
                        {this.renderEvents(this.props.events)}
                    </div>
                </EventContainerWrapper>

                {selecting && (
                    <div className='rbc-slot-selection' style={{ top, height }}>
                        <span>{localizer.fullFormat(selectDates, 'selectRangeFormat')}</span>
                    </div>
                )}
                {isNow && this.intervalTriggered && (
                    <div
                        className='rbc-current-time-indicator'
                        style={{ top: `${this.state.timeIndicatorPosition}%` }}
                    />
                )}
            </DayColumnWrapper>
        );
    }

    renderEvents = (events, isBackgroundEvent = false) => {
        const { slotMetrics } = this;
        const { t } = this.props;

        const styledEvents = getStyledEvents({
            events,
            slotMetrics,
            minimumStartDifference: Math.ceil((step * timeslots) / 2),
        });

        return styledEvents.map(({ event, style }, index) => {
            const end = event.end;
            const start = event.start;
            
            const startsBeforeDay = slotMetrics.startsBeforeDay(start);
            const startsAfterDay = slotMetrics.startsAfterDay(end);
            
            let format = 'eventTimeRangeFormat';
            if (startsBeforeDay) 
                format = 'eventTimeRangeEndFormat';
            else if (startsAfterDay) 
                format = 'eventTimeRangeStartFormat';

            const label = (startsBeforeDay && startsAfterDay) 
                ? t('calendar.allDay')
                : localizer.fullFormat({ start, end }, format);

            const continuesPrior = startsBeforeDay || slotMetrics.startsBefore(start);
            const continuesAfter = startsAfterDay || slotMetrics.startsAfter(end);

            return (
                <TimeGridEvent
                    style={style}
                    event={event}
                    label={label}
                    key={'evt_' + index}
                    continuesPrior={continuesPrior}
                    continuesAfter={continuesAfter}
                    selected={isSelected(event, this.props.selected)}
                    onClick={e => this.props.onSelectEvent(event, e)}
                    isBackgroundEvent={isBackgroundEvent}
                    onKeyPress={e => this.props.onKeyPressEvent(event, e)}
                />
            );
        });
    };

    _selectable = () => {
        const node = this.containerRef.current;
        const selector = (this._selector = new Selection(() => node));

        const maybeSelect = (box) => {
            const onSelecting = this.props.onSelecting;
            const current = this.state || {};
            const state = selectionState(box);

            if (onSelecting) {
                if (
                    (localizer.eq(current.startDate, state.startDate, 'minutes') &&
                    localizer.eq(current.endDate, state.endDate, 'minutes')) ||
                    onSelecting({ start: state.startDate, end: state.endDate }) === false
                )
                    return;
            }

            if (
                this.state.start !== state.start ||
                this.state.end !== state.end ||
                this.state.selecting !== state.selecting
            ) 
                this.setState(state);
      
        };

        const selectionState = (point) => {
            let currentSlot = this.slotMetrics.closestSlotFromPoint(point, getBoundsForNode(node));

            if (!this.state.selecting) 
                this._initialSlot = currentSlot;
      
            let initialSlot = this._initialSlot;
            if (localizer.lte(initialSlot, currentSlot)) 
                currentSlot = this.slotMetrics.nextSlot(currentSlot);
            else if (localizer.gt(initialSlot, currentSlot)) 
                initialSlot = this.slotMetrics.nextSlot(initialSlot);
      
            const selectRange = this.slotMetrics.getRange(
                localizer.min(initialSlot, currentSlot),
                localizer.max(initialSlot, currentSlot),
            );

            return {
                ...selectRange,
                selecting: true,

                top: `${selectRange.top}%`,
                height: `${selectRange.height}%`,
            };
        };

        const selectorClicksHandler = (box, actionType) => {
            if (!isEvent(this.containerRef.current, box)) {
                const { startDate, endDate } = selectionState(box);
                this._selectSlot({
                    startDate,
                    endDate,
                    action: actionType,
                    box,
                });
            }
            this.setState({ selecting: false });
        };

        selector.on('selecting', maybeSelect);
        selector.on('selectStart', maybeSelect);

        selector.on('beforeSelect', (box) => {
            if (this.props.selectable !== 'ignoreEvents') 
                return;

            return !isEvent(this.containerRef.current, box);
        });

        selector.on('click', (box) => selectorClicksHandler(box, 'click'));

        selector.on('doubleClick', (box) => selectorClicksHandler(box, 'doubleClick'));

        selector.on('select', (bounds) => {
            if (this.state.selecting) {
                this._selectSlot({ ...this.state, action: 'select', bounds });
                this.setState({ selecting: false });
            }
        });

        selector.on('reset', () => {
            if (this.state.selecting) 
                this.setState({ selecting: false });
      
        });
    };

    _teardownSelectable = () => {
        if (!this._selector) 
            return;
        this._selector.teardown();
        this._selector = null;
    };

    _selectSlot = ({ startDate, endDate, action, bounds, box }) => {
        let current = startDate;
        const slots = [];

        while (localizer.lte(current, endDate)) {
            slots.push(current);
            current = DateTime.fromMillis(+current + step * 60 * 1000); // using Date ensures not to create an endless loop the day DST begins
        }

        this.props.onSelectSlot({
            slots,
            start: startDate,
            end: endDate,
            action,
            bounds,
            box,
        });
    };
}

// DayColumn.propTypes = {
//   events: PropTypes.array.isRequired,
//   backgroundEvents: PropTypes.array.isRequired,
//   date: PropTypes.instanceOf(DateTime).isRequired,
//   min: PropTypes.instanceOf(DateTime).isRequired,
//   max: PropTypes.instanceOf(DateTime).isRequired,
//   getNow: PropTypes.func.isRequired,
//   isNow: PropTypes.bool,

//   selected: PropTypes.object,
//   selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),
//   eventOffset: PropTypes.number,

//   onSelecting: PropTypes.func,
//   onSelectEvent: PropTypes.func.isRequired,
//   onKeyPressEvent: PropTypes.func,

//   className: PropTypes.string,
//   dragThroughEvents: PropTypes.bool,
// }

DayColumn.defaultProps = {
    dragThroughEvents: true,
};

export default withTranslation('components')(DayColumn);
