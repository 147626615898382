import React from 'react';

type IconProps = Readonly<{
    height?: string | number;
    className?: string;
}>;

export default function StripeSLogo({ height, className }: IconProps) {
    return <img src='/stripe-s-logo.svg' style={{ height }} className={className} />;
}
