import { createGET, createPATCH, createPOST } from '../rawApiObject';
import { type Id } from '@/types/Id';
import { type AppUserFromServer } from '@/types/AppUser';
import { type InvoicingProfileFromServer } from '@/types/Invoicing';
import { type CurrencyFromServer, type TaxRateFromServer } from '@/modules/money';
import { type OnboardingFromServer } from '@/types/Onboarding';
import { type BankAccountFromServer } from '@/types/BankAccount';
import { type SubscriptionFromServer } from '@/types/Subscription';
import { type PersonSettingsUpdate, type SettingsFromServer } from '@/types/Settings';
import { type UserRole, type TeamFromServer, type TeamMemberFromServer } from '@/types/Team';
import { type TeamSettingsFromServer } from '@/types/TeamSettings';
import { type ClientTagFromServer } from '@/types/ClientTag';

export type StartFromServer = {
    currencies: CurrencyFromServer[];
    taxRates: TaxRateFromServer[];
    subscription: SubscriptionFromServer;
    appUser: AppUserFromServer;
    team: TeamFromServer;
    teamMembers: TeamMemberFromServer[];
    onboarding: OnboardingFromServer;
    appUserSettings: SettingsFromServer;
} & ({
    role: UserRole.Scheduler;
} | {
    role: UserRole.Master | UserRole.Freelancer;
    teamSettings: TeamSettingsFromServer;
    profiles: InvoicingProfileFromServer[];
    bankAccounts: BankAccountFromServer[];
    clientTags: ClientTagFromServer[];
});

type RegisterErrorFromServer = {
    type: 'register.email-already-exists';
    property: 'email';
};

export const appUser = {
    get: createGET<{ id: Id }, AppUserFromServer>(
        u => `${u.id.toIRI()}`,
    ),
    // This is patch for a all user data, so the input type can be extended.
    update: createPATCH<{ id: Id }, AppUserFromServer, PersonSettingsUpdate>(
        u => `${u.id.toIRI()}`,
    ),
    start: createGET<StartFromServer>(
        '/start',
    ),
    registerValidate: createPOST<{ errors: RegisterErrorFromServer[] }, { email: string }>(
        '/app-users/register-validate',
    ),
    updatePassword: createPOST<{ error?: string }, { oldPassword: string, newPassword: string }>(
        '/users/change-password',
    ),
    resetPassword: createPOST<{ error?: string }, { email: string }>(
        '/users/reset-password',
    ),
};
