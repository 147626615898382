import React, { useCallback, useEffect, useReducer, useState, type Dispatch, type SetStateAction } from 'react';
import type { Client, ClientGeneralUpdate } from '@/types/Client';
import { useForm } from 'react-hook-form';
import { type Currency, priceToServer, tryGetCurrencyByCode } from '@/modules/money';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useTransform } from '@/utils/forms';
import CurrencySelect from '../forms/CurrencySelect';
import { api } from '@/utils/api/backend';
import { CreditAccount, CreditTransaction } from '@/types/CreditAccount';
import { MoneyDisplay, Skeleton, SpinnerButton, Table } from '@/components/common';
import { dehydrate } from '@/types/api/result';
import { CircleEmptyIcon, CircleFullIcon, TrashIcon } from '../icons';
import OrderStateBadge from '../orders/OrderStateBadge';
import EventStateBadge from '../event/EventStateBadge';
import DateTimeDisplay from '../common/DateTimeDisplay';
import useNotifications from '@/context/NotificationProvider';
import { useTranslation } from 'react-i18next';
import { createErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { useMaster } from '@/context/UserProvider';
import { EditingPhase, useEditing } from '@/hooks';
import { getRandomSkeletonArray, isValidNumber, toNumber, transformToValidNumberOrEmpty } from '@/utils/math';
import { MdModeEdit } from 'react-icons/md';
import FormErrorMessage from '../forms/FormErrorMessage';
import EditableTextInput from '../forms/EditableTextInput';
import { type Id } from '@/types/Id';
import { CANCELED_ERROR } from '@/utils/api/ApiAuthorizer';
import { Link, useParams } from 'react-router-dom';
import { InfoCard } from '../settings/InfoCard';
import { routes } from '@/router';

type ClientCreditFormData = {
    title: string;
    amount: number | '' | '-';
};

const defaultValues: ClientCreditFormData = {
    title: '',
    amount: '',
};

type ClientCreditFormProps = Readonly<{
    onSubmit: (output: ClientGeneralUpdate) => void;
    defaultValue: Client;
    onChange?: (isDirty: boolean) => void;
    saveButton?: React.FC<{ onClick: () => void }>;
    onUpdate: Dispatch<SetStateAction<Client | undefined>>;
}>;

export default function ClientCreditForm({ defaultValue, onUpdate }: ClientCreditFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientForm.credit' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const { addAlert } = useNotifications();
    const form = useForm<ClientCreditFormData>({ defaultValues });
    const transform = useTransform(form.register, form.setValue);

    const { teamSettings } = useMaster();
    const { currency: currencyCode } = useParams();
    const defaultCurrency = (currencyCode && tryGetCurrencyByCode(currencyCode)) || teamSettings.currency;
    const [ state, dispatch ] = useReducer(reducer, computeDefalultState(defaultCurrency, defaultValue.creditAccounts));
    const [ addingTransaction, setAddingTransaction ] = useState(false);

    // Propagate the accounts' change back to the client.
    useEffect(() => {
        if (!defaultValue || !state.accounts)
            return;

        onUpdate(defaultValue.setCreditAccounts(state.accounts));
    }, [ state.accounts ]);

    async function fetchTransactions(creditAccount: CreditAccount, signal?: AbortSignal) {
        dispatch({ type: 'set', transactions: undefined });
        setAddingTransaction(false);
        form.reset(defaultValues);

        const response = await api.client.getTransactions(creditAccount, signal);
        if (!response.status) {
            if (response.error !== CANCELED_ERROR)
                addAlert(createErrorAlert(response.error));

            return;
        }

        // TODO pagination
        const items = dehydrate(response).items;
        const transactions = items.map(item => CreditTransaction.fromServer(item, creditAccount.totalAmount.currency));
        dispatch({ type: 'set', transactions });
    }

    useEffect(() => {
        const currentAccount = state.accounts?.find(account => account.id.equals(state.selectedAccountId));
        if (!currentAccount)
            return;

        const [ signal, abort ] = api.prepareAbort();
        fetchTransactions(currentAccount, signal);
        return abort;
    }, [ state.selectedAccountId?.toIRI() ]);

    const selectedAccount = state.accounts?.find(account => account.id.equals(state.selectedAccountId));

    async function createTransaction(data: ClientCreditFormData) {
        setAddingTransaction(true);
        // If the selected account is undefined, the user user doesn't have any account. Therefore, we select the default currency.
        const currency = selectedAccount ? selectedAccount.totalAmount.currency : defaultCurrency;
        const response = await api.client.createTransaction(defaultValue, {
            amount: priceToServer(toNumber(data.amount)),
            currency: currency.toIRI(),
            title: data.title.trim(),
        });
        
        setAddingTransaction(false);
        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            return;
        }

        const newTransaction = CreditTransaction.fromServer(response.data, currency);
        if (!selectedAccount) {
            // The user didn't have any account so a new one was created.
            const newAccount = CreditAccount.fromNewTransaction(response.data.creditAccount, currency);
            dispatch({ type: 'create', transaction: newTransaction, account: newAccount });
        }
        else {
            dispatch({ type: 'create', transaction: newTransaction });
        }

        addAlert(createTranslatedSuccessAlert('components:clientForm.credit.alerts.transaction-created'));
        form.reset(defaultValues);
    }

    async function deleteTransaction(transaction: CreditTransaction) {
        const response = await api.client.deleteTransaction(transaction);
        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            return;
        }

        dispatch({ type: 'delete', transaction });
        addAlert(createTranslatedSuccessAlert('components:clientForm.credit.alerts.transaction-deleted'));
    }

    return (
        <div>
            <InfoCard
                infoKey='creditsOffboarding'
                className='mb-4'
                primaryButton={
                    <Link to={routes.dashboard}>
                        <Button className='compact sh-shadow-sm-dark'>{t('discover-products')}</Button>
                    </Link>
                }
            />
            {defaultValue && defaultValue.creditAccounts.length > 1 && (
                <div className='mb-2 w-25'>
                    <CurrencySelect
                        value={selectedAccount?.totalAmount.currency}
                        onChange={currency => dispatch({ type: 'currency', currency })}
                        options={defaultValue.creditAccounts.map(acc => acc.totalAmount.currency)}
                    />
                </div>
            )}

            <div className='d-flex flex-row align-items-center gap-2 mb-3'>
                <span className='fs-1'>
                    {t('account-balance')}{' '}
                    <MoneyDisplay money={selectedAccount?.totalAmount ?? { amount: 0, currency: defaultCurrency }} />
                </span>
            </div>

            <div className='d-flex flex-row align-items-top gap-3 mb-3'>
                <span className='fs-6' style={{ paddingTop: '11px' }}>{t('add-transaction')}</span>
                <div className='d-flex flex-column gap-1 w-50'>
                    <Form noValidate className='d-flex flex-row gap-3 sh-design' onSubmit={form.handleSubmit(createTransaction)}>
                        <Form.Control
                            {...form.register('title')}
                            placeholder={t('title-placeholder')}
                            aria-label={t('title-placeholder')}
                        />
                        <Form.Control
                            {...transform.registerPriceNegative('amount', { required: tf('price-required') })}
                            type='number'
                            placeholder={t('amount-placeholder')}
                            aria-label={t('amount-placeholder')}
                        />
                        <SpinnerButton isFetching={addingTransaction} type='submit'>{t('add-credit-button')}</SpinnerButton>
                    </Form>
                    <FormErrorMessage errors={form.formState.errors} name='amount' />
                </div>
            </div>

            <Table>
                <Table.Header>
                    <Table.HeaderCol>
                        <span>{t('title')}</span>
                    </Table.HeaderCol>
                    <Table.HeaderCol xs={2} className='text-end'>
                        <span>{t('amount')}</span>
                    </Table.HeaderCol>
                    <Table.HeaderCol xs={2}>
                        <span>{t('date')}</span>
                    </Table.HeaderCol>
                    <Table.HeaderCol xs={2}>
                        <span>{t('type')}</span>
                    </Table.HeaderCol>
                    <Table.HeaderCol xs={2}>
                        <span>{t('status')}</span>
                    </Table.HeaderCol>
                    <Table.HeaderCol xs='auto'><div style={{ width: '18px' }}/></Table.HeaderCol>
                </Table.Header>
                <Table.Body>
                    {selectedAccount ? (
                        <TransactionsTableList
                            state={state}
                            dispatch={dispatch}
                            deleteTransaction={deleteTransaction}
                        />
                    ) : (
                        <Table.Row>
                            <Table.Col colSpan={7} className='text-center fs-4 py-5'>
                                {t('no-credit-yet')}
                            </Table.Col>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </div>
    );
}

type TransactionsTableListProps = Readonly<{
    state: State;
    dispatch: Dispatch<Action>;
    deleteTransaction: (transaction: CreditTransaction) => Promise<void>;
}>;

function TransactionsTableList({ state, dispatch, deleteTransaction }: TransactionsTableListProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientForm.credit' });

    if (!state.transactions) {
        return (<>{getRandomSkeletonArray().map(id => (
            <Table.Row key={id}>
                <Table.Col><Skeleton height={19} /></Table.Col>
                <Table.Col><Skeleton height={19} /></Table.Col>
                <Table.Col><Skeleton height={19} /></Table.Col>
                <Table.Col><Skeleton height={19} /></Table.Col>
                <Table.Col colSpan={2}><Skeleton height={19} /></Table.Col>
            </Table.Row>
        ))}</>);
    }

    if (!state.transactions.length) {
        return (
            <Table.Row>
                <Table.Col colSpan={7} className='text-center fs-4 py-5'>
                    {t('no-transactions')}
                </Table.Col>
            </Table.Row>
        );
    }

    return (<>{state.transactions.map(transaction => (
        <TransactionRow
            key={transaction.id.toString()}
            transaction={transaction}
            deleteTransaction={deleteTransaction}
            dispatch={dispatch}
        />
    ))}</>);
}

type TransactionRowProps = Readonly<{
    transaction: CreditTransaction;
    deleteTransaction: (transaction: CreditTransaction) => Promise<void>;
    dispatch: Dispatch<Action>;
}>;

function TransactionRow({ transaction, deleteTransaction, dispatch }: TransactionRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientForm.credit' });
    const { addAlert } = useNotifications();
    const [ deletingTransaction, setDeletingTransaction ] = useState(false);

    const syncAmount = useCallback(async (newAmount: number | '' | '-') => {
        if (!isValidNumber(newAmount))
            return false;
        const response = await api.client.updateTransaction(transaction, {
            amount: priceToServer(newAmount),
            title: transaction.title,
        });
        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            return false;
        }

        const newTransaction = CreditTransaction.fromServer(response.data, transaction.total.currency);
        dispatch({ type: 'update', transaction: newTransaction });

        addAlert(createTranslatedSuccessAlert('components:clientForm.credit.alerts.amount-updated'));

        return true;
    }, [ transaction, addAlert, dispatch ]);

    const syncTitle = useCallback(async (newTitle: string) => {
        const response = await api.client.updateTransaction(transaction, {
            amount: priceToServer(transaction.total.amount),
            title: newTitle.trim(),
        });
        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            return false;
        }

        const newTransaction = CreditTransaction.fromServer(response.data, transaction.total.currency);
        dispatch({ type: 'update', transaction: newTransaction });

        addAlert(createTranslatedSuccessAlert('components:clientForm.credit.alerts.title-updated'));

        return true;
    }, [ transaction, addAlert, dispatch ]);

    async function handleDeleteTransaction() {
        setDeletingTransaction(true);
        await deleteTransaction(transaction);
        setDeletingTransaction(false);
    }

    const { state: { value: amount, phase }, setValue: setAmount, doUpdate, setEditing } = useEditing<number | '' | '-'>(transaction.total.amount, syncAmount);

    return (
        <Table.Row style={{ height: '48px' }}>
            <Table.Col className='sh-design compact py-0'>
                <EditableTextInput
                    className='d-flex align-items-center gap-2'
                    value={transaction.title}
                    syncFunction={syncTitle}
                    iconSize={16}
                />
            </Table.Col>
            <Table.Col xs={2} className='fw-medium py-0'>
                <div className='d-flex align-items-center justify-content-end gap-2 sh-design compact'>
                    {phase === EditingPhase.View && (<>
                        <MoneyDisplay money={{ ...transaction.total, amount: isValidNumber(amount) ? amount : 0 }} />
                        <MdModeEdit size={16} className='sh-editing-icon clickable' role='button' onClick={setEditing} />
                    </>)}
                    {phase === EditingPhase.Editing && (
                        <Form.Control
                            className='text-end'
                            autoFocus
                            value={amount}
                            onKeyDown={event => event.key === 'Enter' && doUpdate()}
                            onChange={event => setAmount(event.target.value === '-' ? '-' : transformToValidNumberOrEmpty(event.target.value))}
                            onBlur={() => doUpdate()}
                        />
                    )}
                    {phase === EditingPhase.Updating && (<>
                        <MoneyDisplay money={{ ...transaction.total, amount: isValidNumber(amount) ? amount : 0 }} className='text-muted' />
                        <Spinner size='sm' variant='secondary' animation='border' className='sh-updating-spinner' />
                    </>)}
                </div>
            </Table.Col>
            <Table.Col xs={2}>
                <DateTimeDisplay dateTime={transaction.createdAt} />
            </Table.Col>
            <Table.Col xs={2}>
                {t(`type-${transaction.reason.type}`)}
            </Table.Col>
            <Table.Col xs={2}>
                <TransactionStateBadge transaction={transaction} />
            </Table.Col>
            <Table.Col xs='auto'>
                {!deletingTransaction ? (
                    <TrashIcon size={18} className='clickable' role='button' onClick={handleDeleteTransaction} />
                ) : (
                    <Spinner size='sm' variant='secondary' animation='border' className='sh-updating-spinner' />
                )}
            </Table.Col>
        </Table.Row>
    );
}

type TransactionStateBadgeProps = Readonly<{
    transaction: CreditTransaction;
}>;

function TransactionStateBadge({ transaction }: TransactionStateBadgeProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientForm.credit' });

    if (transaction.reason.type === 'invoice')
        return <OrderStateBadge state={transaction.reason.state} />;

    if (transaction.reason.type === 'event')
        return <EventStateBadge state={transaction.reason.state} />;

    return (
        <div className='d-flex align-items-center gap-2 fw-medium'>
            {transaction.total.amount >= 0 ? (<>
                <CircleFullIcon size={16} className='text-success' />
                <span>{t('manual-positive')}</span>
            </>) : (<>
                <CircleEmptyIcon size={16} className='text-danger' />
                <span>{t('manual-negative')}</span>
            </>)}
        </div>
    );
}

type State = {
    /** This one is displayed when the user has no account. Otherwise, the initial account is found by this currency. */
    defaultCurrency: Currency;
    accounts?: CreditAccount[];
    selectedAccountId?: Id;
    transactions?: CreditTransaction[];
};

type Action = SetAction | CurrencyAction | CreateAction | UpdateAction | DeleteAction;

function reducer(state: State, action: Action): State {
    switch (action.type) {
    // Sort the transactions by date (descending order).
    case 'set': return { ...state, transactions: action.transactions?.sort((a, b) => +b.createdAt - +a.createdAt) };
    case 'currency': return currency(state, action);
    case 'create': return create(state, action);
    case 'update': return update(state, action);
    case 'delete': return deleteFunction(state, action);
    }
}

type SetAction = {
    type: 'set';
    transactions?: CreditTransaction[];
};

function computeDefalultState(defaultCurrency: Currency, accounts: CreditAccount[]): State {
    const selectedAccount = accounts.find(a => a.totalAmount.currency === defaultCurrency) ?? accounts[0];

    return {
        defaultCurrency,
        accounts,
        selectedAccountId: selectedAccount?.id,
    };
}

type CurrencyAction = {
    type: 'currency';
    currency?: Currency;
};

function currency(state: State, { currency }: CurrencyAction): State {
    if (!currency || !state.accounts)
        return state;

    const account = state.accounts.find(account => account.totalAmount.currency.id.equals(currency.id));
    if (!account || account.id.equals(state.selectedAccountId))
        return state;

    return { ...state, selectedAccountId: account.id };
}

type CreateAction = {
    type: 'create';
    transaction: CreditTransaction;
    /** New credit account because this is the first transaction with this currency. */
    account?: CreditAccount;
};

function create(state: State, { transaction, account }: CreateAction): State {
    if (account)
        return createNewAccount(state, transaction, account);

    const oldTransactions = state.transactions;
    if (!oldTransactions)
        return state;

    const index = oldTransactions.findIndex(t => t.id.equals(transaction.id));
    if (index !== -1)
        return state;

    const accounts = updateAccounts(state.accounts, transaction.accountId, transaction.total.amount);
    const transactions = [ transaction, ...oldTransactions ];

    return { ...state, transactions, accounts };
}

function createNewAccount(state: State, transaction: CreditTransaction, account: CreditAccount): State {
    return {
        ...state,
        accounts: [ account.setTotalAmount(transaction.total.amount) ],
        selectedAccountId: account.id,
        transactions: [ transaction ],
    };
}

type UpdateAction = {
    type: 'update';
    transaction: CreditTransaction;
};

function update(state: State, { transaction }: UpdateAction): State {
    const oldTransactions = state.transactions;
    if (!oldTransactions)
        return state;

    const index = oldTransactions.findIndex(t => t.id.equals(transaction.id));
    if (index === -1)
        return state;

    const difference = transaction.total.amount - oldTransactions[index].total.amount;
    const accounts = updateAccounts(state.accounts, transaction.accountId, difference);

    const transactions = [ ...oldTransactions ];
    transactions[index] = transaction;

    return { ...state, transactions, accounts };
}

type DeleteAction = {
    type: 'delete';
    transaction: CreditTransaction;
};

function deleteFunction(state: State, { transaction }: DeleteAction): State {
    const oldTransactions = state.transactions;
    if (!oldTransactions)
        return state;

    const index = oldTransactions.findIndex(t => t.id.equals(transaction.id));
    if (index === -1)
        return state;

    const accounts = updateAccounts(state.accounts, transaction.accountId, -transaction.total.amount);

    const transactions = [ ...oldTransactions ];
    transactions.splice(index, 1);

    return { ...state, transactions, accounts };
}

function updateAccounts(accounts: CreditAccount[] | undefined, accountId: Id, difference: number): CreditAccount[] | undefined {
    if (!accounts || difference === 0)
        return accounts;

    return accounts.map(account => {
        if (!account.id.equals(accountId))
            return account;

        return account.setTotalAmount(account.totalAmount.amount + difference);
    });
}
