import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { api } from '@/utils/api/backend';
import { type OrderToServer, type ChargeError, isChargeError, type CustomOrderInit, type ProductOrderInit, customOrderToServer, productOrderToServer, type EventOrderInit, eventOrderToServer } from '@/types/orders/Order';
import { isDurationError, type DurationError } from '@/types/Event';
import { getCurrency, priceFromServer } from '@/modules/money';
import { MoneyDisplay } from '@/components/common';
import { isPlanExceededError, type PlanExceededError } from '@/types/Subscription';
import { useCached } from '@/hooks';
import { useMaster } from '@/context/UserProvider';
import { CheckoutType } from './useCheckout';

export type InvoicePreviewInit = {
    type: CheckoutType.Custom;
    init: CustomOrderInit;
} | {
    type: CheckoutType.Product;
    init: ProductOrderInit;
} | {
    type: CheckoutType.Event;
    init: EventOrderInit;
};

type InvoicePreviewModalProps = Readonly<{
    invoicePreview?: InvoicePreviewInit;
    onHide: () => void;
    onPlanExceededError: (error: PlanExceededError) => void;
}>;

export function InvoicePreviewModal({ invoicePreview, onHide, onPlanExceededError }: InvoicePreviewModalProps) {
    const { t, i18n } = useTranslation('components', { keyPrefix: 'invoicePreviewModal' });
    const [ pdfBlobUrl, setPdfBlobUrl ] = useState('');
    const [ error, setError ] = useState<PreviewError>();

    const fetchInvoicePreview = useCallback(async (data: OrderToServer, signal: AbortSignal) => {
        const response = await api.order.preview(data, { signal });
        if (!response.status) {
            const newError = await getError(response.error);
            if (isPlanExceededError(newError)) {
                onPlanExceededError(newError);
                onHide();
            }
            else {
                setError(newError);
            }

            return;
        }

        const blobUrl = URL.createObjectURL(response.data);
        setPdfBlobUrl(blobUrl);
    }, [ onHide, onPlanExceededError ]);

    const cached = useCached(invoicePreview);
    const masterContext = useMaster();

    useEffect(() => {
        if (!cached)
            return;

        setPdfBlobUrl('');
        setError(undefined);

        const [ signal, abort ] = api.prepareAbort();

        switch (cached.type) {
        case CheckoutType.Custom: {
            const custom = customOrderToServer(cached.init, masterContext, i18n);
            fetchInvoicePreview({ custom }, signal);
            break;
        }
        case CheckoutType.Product: {
            const product = productOrderToServer(cached.init, masterContext, i18n);
            fetchInvoicePreview({ product }, signal);
            break;
        }
        case CheckoutType.Event: {
            const event = eventOrderToServer(cached.init, i18n);
            fetchInvoicePreview({ event }, signal);
            break;
        }
        }

        return abort;
    }, [ cached, fetchInvoicePreview ]);

    return (
        <Modal show={!!invoicePreview} onHide={onHide} onExited={() => setPdfBlobUrl('')} size='xl' contentClassName='h-100' dialogClassName='h-85'>
            <Modal.Header closeButton>
                <Modal.Title>{t(error ? 'modal-error-title' : 'modal-title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfBlobUrl ? (
                    <iframe className='w-100 h-100' src={pdfBlobUrl} />
                ) : (
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        {error && invoicePreview ? (
                            <ErrorDisplay error={error} />
                        ) : (
                            <Spinner className='mx-auto' animation='border' />
                        )}
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}

type PreviewError = ChargeError | DurationError | PlanExceededError;

async function getError(error: unknown): Promise<PreviewError | undefined> {
    if (!error || !(error instanceof Blob))
        return undefined;

    const json = await error.text();
    const errorObject = JSON.parse(json)?.error;

    return isChargeError(errorObject) || isDurationError(errorObject) || isPlanExceededError(errorObject)
        ? errorObject
        : undefined;
}

type ErrorDisplayProps = Readonly<{
    error: PreviewError;
}>;

function ErrorDisplay({ error }: ErrorDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'invoicePreviewModal.error' });

    return (
        <span className='fs-4 text-danger'>
            {t(error.type)}
            {isChargeError(error) && (<>
                {' '}<MoneyDisplay money={{ amount: priceFromServer(error.required), currency: getCurrency(error.currency) }}/>
            </>)}
        </span>
    );
}
