import React, { useState, type ReactNode } from 'react';
import useAuth from '@/context/AuthProvider';
import { Button, Dropdown } from 'react-bootstrap';
import { CircleUserIcon, FlowlanceLogo } from '@/components/icons';
import { settingSectionMenuGroups, SubMenuItem, overviewMenuItems, isActive } from './Menu';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '@/router';
import { toMaster, useUser } from '@/context/UserProvider';
import { getName, getShortName } from '@/types/AppUser';
import clsx from 'clsx';
import { SpinnerButton } from '../common';
import { Subscription, SubscriptionCode, SubscriptionPeriod } from '@/types/Subscription';
import { api } from '@/utils/api/backend';
import { getClientReferenceId, goToCustomerPortal } from '@/pages/Subscriptions';
import { UserRole } from '@/types/Team';

export default function Topbar() {
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });
    const location = useLocation();
    const userContext = useUser();
    const { role, team } = userContext;
    const isMasterOrFreelancer = !!toMaster(userContext);

    return (
        <div className='sh-topbar'>
            <div className='sh-topbar-content'>
                <div className='sh-topbar-left'>
                    <Link to={routes.root} className='d-inline-block text-reset text-decoration-none pe-2'>
                        <FlowlanceLogo size={40} /><span className='ms-2 fw-bold align-middle' style={{ fontSize: '18px' }}>Flowlance</span>
                    </Link>
                    {role !== UserRole.Freelancer && (
                        <span className='fw-bold text-primary' style={{ fontSize: '18px' }}>
                            {team.title}
                        </span>
                    )}
                </div>
                <div className='sh-topbar-center'>
                    <div className='sh-tab-buttons'>
                        {overviewMenuItems.filter(item => !item.masterOnly || role === UserRole.Master).map(item => {
                            const isHere = item.to === location.pathname;
                            const isSelected = isActive(item, location.pathname);

                            return (
                                <Link
                                    key={item.nameTranslationId}
                                    className={clsx('sh-tab-button', isSelected && 'selected', isHere && 'pe-none')}
                                    to={item.to}
                                >
                                    {t(item.nameTranslationId)}
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <div className='sh-topbar-right'>
                    <div className='flex-shrink-0'>
                        <AppUserIcon />
                    </div>
                    {isMasterOrFreelancer && (<>
                        <Link to={routes.orders.newCustom}>
                            <Button variant='outline-primary' className='compact'>{t('custom-order')}</Button>
                        </Link>
                        <Link to={routes.orders.newBackpay}>
                            <Button variant='outline-primary' className='compact'>{t('backpay-order')}</Button>
                        </Link>
                    </>)}
                </div>
            </div>
        </div>
    );
}

function AppUserIcon() {
    const userContext = useUser();
    const { subscription, setSubscription, appUser } = userContext;
    const isMasterOrFreelancer = !!toMaster(userContext);
    const { auth } = useAuth();
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });

    const [ isFetchingUpgrade, setIsFetchingUpgrade ] = useState(false);

    const logout = async () => {
        auth.logout();
    };

    async function buySubscription(code: SubscriptionCode, paymentPeriod: SubscriptionPeriod) {
        // If the buy subscription is same as the current redirect appUser to customer portal without code and paymentPeriod.
        if (
            subscription?.isActive &&
            subscription?.code === code &&
            subscription?.paymentPeriod === paymentPeriod
        )
            goToCustomerPortal(setIsFetchingUpgrade);


        // If none of the above is true, we redirect him to a page where he can buy a new subscription.
        setIsFetchingUpgrade(true);
        const response = await (subscription.isActive ? api.team.updateSubscription : api.team.createSubscription)({
            code,
            paymentPeriod,
            clientReferenceId: getClientReferenceId(),
        });
        setIsFetchingUpgrade(false);
        if (!response.status) {
            // TODO display error
            return;
        }

        if ('subscription' in response.data) {
            const subscription = Subscription.fromServer(response.data.subscription);
            setSubscription(subscription);
        }
        else {
            window.location.href = response.data.subscriptionSessionUrl;
        }
    }

    async function upgradeToPro() {
        await buySubscription(SubscriptionCode.Paid2, SubscriptionPeriod.Year);
    }

    const showUpgradeButton = isMasterOrFreelancer && (!subscription || subscription.code !== SubscriptionCode.Paid2 || subscription.isTrial);

    return (
        <Dropdown drop='down'>
            <Dropdown.Toggle as={CustomDropdownToggle}>
                <div className='sh-navigation-button rounded-4 py-1 ps-3 pe-3 d-flex align-items-center'>
                    <div className='me-3'>{getShortName(appUser)}</div>
                    <div><CircleUserIcon size={24} /></div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className='sh-topbar-menu' style={{ width: 247, '--bs-dropdown-padding-y': 0 }}>
                <div className='py-3 px-3 border-bottom'>
                    <div className='fw-medium mb-1'>{getName(appUser)}</div>
                    <div className='text-muted'>{appUser.email}</div>
                </div>
                <div>
                    {settingSectionMenuGroups.items[0].subMenu?.map(item => (
                        <SubMenuItem key={item.to} {...item} />
                    ))}
                </div>
                <div className='d-flex flex-column gap-2 border-top px-3 py-3 bg-border-light rounded-bottom-dropdown'>
                    <Button variant='outline-primary' onClick={logout}>{t('logout')}</Button>
                    {showUpgradeButton && (
                        <SpinnerButton isFetching={isFetchingUpgrade} variant='primary' className='w-100' onClick={upgradeToPro}>{t('upgrade-to-pro')}</SpinnerButton>
                    )}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}

type CustomDropdownToggleProps = {
    children: ReactNode;
    onClick: () => void;
};

const CustomDropdownToggle = React.forwardRef<HTMLDivElement, CustomDropdownToggleProps>(({ children, onClick }, ref) => (
    <div onClick={onClick} ref={ref}>
        {children}
    </div>
));
