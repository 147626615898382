import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '@/utils/api/backend';
import { SpinnerButton } from '../common';

type GoogleRegisterButtonProps = Readonly<{
    title?: string;
}>;

export function GoogleRegisterButton({ title }: GoogleRegisterButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'register' });
    const [ isFetching, setIsFetching ] = useState(false);

    const handleGoogleRegister = useCallback(async () => {
        setIsFetching(true);
        const response = await api.auth.googleRegister();
        setIsFetching(false);
        if (!response.status || !response.data.connectUrl)
            return;

        window.location.href = response.data.connectUrl;
    }, []);

    return (
        <GoogleButton
            title={title ?? t('register-google-button')}
            onClick={handleGoogleRegister}
            isFetching={isFetching}
        />
    );
}

export function GoogleLoginButton() {
    const { t } = useTranslation('pages', { keyPrefix: 'login' });
    const [ isFetching, setIsFetching ] = useState(false);
    
    const handleGoogleLogin = useCallback(async () => {
        setIsFetching(true);
        const response = await api.auth.googleLogin();
        setIsFetching(false);
        if (!response.status || !response.data.connectUrl)
            return;
    
        window.location.href = response.data.connectUrl;
    }, []);
    
    return (
        <GoogleButton
            title={t('login-google-button')}
            onClick={handleGoogleLogin}
            isFetching={isFetching}
        />
    );
}

type GoogleButtonProps = Readonly<{
    title: string;
    onClick: () => void;
    isFetching: boolean;
}>;

function GoogleButton({ title, onClick, isFetching }: GoogleButtonProps) {
    return (
        <SpinnerButton
            variant='outline-primary'
            onClick={onClick}
            isFetching={isFetching}
            className='w-100 position-relative'
            icon={<img src='/integration-icons/google-logo.svg' width={30} className='position-absolute non-draggable' style={{ left: '15%' }} />}
        >
            {title}
        </SpinnerButton>
    );
}
