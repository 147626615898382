import { getTaxRate, moneyFromServer, type CurrencyIRI, type Money, type TaxRate, type TaxRateIRI } from '@/modules/money';
import { type IRI, Id } from '../Id';
import { CreditOrderItem, type CreditOrderItemFromServer } from './CreditOrderItem';
import { CustomOrderItem } from './CustomOrderItem';
import { EventOrderItem, type EventOrderItemFromServer } from './EventOrderItem';
import { ProductOrderItem, type SchedulerProductItem, type ProductOrderItemFromServer } from './ProductOrderItem';
import { DateTime } from 'luxon';
import { type PartialBy } from '@/utils/common';
import { type ExceptScheduler, type UserRole } from '../Team';

// API

export type OrderItemFromServer = BaseFromServer & SpecificFromServer;

export function orderItemFromServer(input: OrderItemFromServer, order: OrderDataFromServer): OrderItem {
    const { currency, createdAt } = order;
    const base = baseFromServer({ ...input, currency, createdAt });
    return specificFromServer(base, input);
}

export type MasterProductItemFromServer = GeneralFromServer & {
    type: 'product';
    productOrderItem: ProductOrderItemFromServer;
};

export function masterProductItemFromServer(input: MasterProductItemFromServer): ProductOrderItem {
    const base = baseFromServer(input);
    return ProductOrderItem.fromServer(base, input.productOrderItem);
}

export type SchedulerProductItemFromServer = Omit<MasterProductItemFromServer, PriceFields>;

export function schedulerProductItemFromServer(input: SchedulerProductItemFromServer): SchedulerProductItem {
    const base = baseFromServer(input);
    return ProductOrderItem.fromServer(base, input.productOrderItem);
}

export interface OrderItem<TRole extends UserRole = UserRole.Master | UserRole.Freelancer> {
    readonly id: Id;
    readonly title: string;
    readonly quantity: number;
    readonly index: number;
    readonly createdAt: DateTime;
    readonly unitPrice: ExceptScheduler<TRole, Money>;
    readonly vat: ExceptScheduler<TRole, TaxRate>;
}

// Internal types

type BaseFromServer = {
    '@id': IRI;
    title: string;
    quantity: number;
    index: number;
    unitPrice: number;
    vat: TaxRateIRI;
};

type OrderDataFromServer = {
    currency: CurrencyIRI;
    createdAt: string;
};

type GeneralFromServer = BaseFromServer & OrderDataFromServer;

type SpecificFromServer = {
    type: 'custom';
} | {
    type: 'event';
    eventOrderItem: EventOrderItemFromServer;
} | {
    type: 'credit';
    creditOrderItem: CreditOrderItemFromServer;
} | {
    type: 'product';
    productOrderItem: ProductOrderItemFromServer;
};

type PriceFields = 'unitPrice' | 'vat' | 'currency';

// Internal implementation

function baseFromServer(input: GeneralFromServer): OrderItem;
function baseFromServer(input: PartialBy<GeneralFromServer, PriceFields>): OrderItem<UserRole.Scheduler>;

function baseFromServer(input: PartialBy<GeneralFromServer, PriceFields>): OrderItem<UserRole> {
    const common: OrderItem<UserRole.Scheduler> = {
        id: Id.fromIRI(input['@id']),
        title: input.title,
        quantity: input.quantity,
        index: input.index,
        createdAt: DateTime.fromISO(input.createdAt),
        unitPrice: undefined,
        vat: undefined,
    };
    if (input.unitPrice === undefined || !input.currency || !input.vat)
        return common;

    return {
        ...common,
        unitPrice: moneyFromServer(input.unitPrice, input.currency),
        vat: getTaxRate(input.vat),
    };
}

function specificFromServer(base: OrderItem, specific: SpecificFromServer): OrderItem {
    switch (specific.type) {
    case 'custom': {
        return CustomOrderItem.fromServer(base);
    }
    case 'event': {
        return EventOrderItem.fromServer(base, specific.eventOrderItem);
    }
    case 'credit': {
        return CreditOrderItem.fromServer(base, specific.creditOrderItem);
    }
    case 'product': {
        return ProductOrderItem.fromServer(base, specific.productOrderItem);
    }
    default: {
        throw new Error('xd');
    }
    }
}
