import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useForm, type FieldValues, type Control, type FieldErrors, type UseFormSetValue, type UseFormWatch } from 'react-hook-form';
import { type InvoicingProfile, type InvoicingProfileInit } from '@/types/Invoicing';
import { SpinnerButton } from '@/components/common';
import { ControlledLocaleSelect } from '@/components/forms';
import { useTranslation } from 'react-i18next';
import type { LocaleCode } from '@/types/i18n';
import type { IRI } from '@/types/Id';
import { useMaster } from '@/context/UserProvider';
import FormErrorMessage from '../forms/FormErrorMessage';
import { last, stringToServer } from '@/utils/common';
import { EMAIL_VALIDATION_PATTERN, canonizeEmail, useTransform } from '@/utils/forms';
import { MAX_TITLE_LENGTH } from '@/types/Event';
import { InfoTooltip } from '../forms/buttons';

export type NewInvoicingProfileFormData = {
    title: string;
    locale: LocaleCode;
    templateIRI: string;
    dueDays: number;
    invoicingEmail: string;
    hideEmailOnInvoice: boolean;
};

type FormDataType = NewInvoicingProfileFormData;
type OutputType = InvoicingProfileInit;

function inputToForm(profile: InvoicingProfile): FormDataType {
    return {
        title: '',
        locale: profile.locale,
        templateIRI: profile.templateId.toIRI() as unknown as string,
        dueDays: profile.dueDays,
        invoicingEmail: profile.email ?? '',
        hideEmailOnInvoice: false,
    };
}

function formToOutput(data: FormDataType, profile: InvoicingProfile): InvoicingProfileInit {
    return {
        general: {
            address: profile.address ?? {},
            cin: profile.cin,
            tin: profile.tin,
            vat: profile.vat.id.toIRI(),
            legalName: profile.legalName,
            title: data.title,
            locale: data.locale,
            invoicingTemplate: data.templateIRI as unknown as IRI,
            dueDays: data.dueDays,
            email: stringToServer(canonizeEmail(data.invoicingEmail)),
            hideEmailOnInvoice: data.hideEmailOnInvoice,
        },
        custom: {
            condensedInvoice: profile.isCondensedInvoice,
            header: profile.header,
            footer: profile.footer,
            customKey1: profile.customKey1,
            customValue1: profile.customValue1,
            customKey2: profile.customKey2,
            customValue2: profile.customValue2,
            logo: undefined,
        },
    };
}

type NewInvoicingProfileFormProps = Readonly<{
    onSubmit: (output: OutputType) => void;
    isFetching: boolean;
}>;

export default function NewInvoicingProfileForm({ onSubmit, isFetching }: NewInvoicingProfileFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'newInvoicingProfileForm' });

    const { profiles } = useMaster();
    const lastProfile = last(profiles);
    const { handleSubmit, control, formState: { errors }, setValue, watch } = useForm<FormDataType>({
        defaultValues: inputToForm(lastProfile),
    });

    function onValid(data: FormDataType) {
        onSubmit(formToOutput(data, lastProfile));
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onValid)} className='sh-design'>
            <NewInvoicingProfileFormInner
                control={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
                autoFocus
            />
            <div className='d-flex justify-content-end mt-4'>
                <SpinnerButton
                    type='submit'
                    isFetching={isFetching}
                >
                    {t('create-button')}
                </SpinnerButton>
            </div>
        </Form>
    );
}


type NewInvoicingProfileFormInnerProps<T extends FieldValues> = Readonly<{
    control: Control<T>;
    errors: FieldErrors<T>;
    setValue: UseFormSetValue<T>;
    watch: UseFormWatch<T>;
    autoFocus?: boolean;
    hideName?: boolean;
}>;

export function NewInvoicingProfileFormInner<T extends FormDataType>(props: NewInvoicingProfileFormInnerProps<T>) {
    const { t } = useTranslation('components', { keyPrefix: 'newInvoicingProfileForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });

    const control = props.control as unknown as Control<FormDataType>;
    const errors = props.errors as FieldErrors<FormDataType>;
    const setValue = props.setValue as unknown as UseFormSetValue<FormDataType>;
    const watch = props.watch as unknown as UseFormWatch<FormDataType>;

    const { registerPositiveInteger } = useTransform(control.register, setValue);

    const hideEmailOnInvoice = watch('hideEmailOnInvoice');

    return (<>
        {!props.hideName && (
            <Row className='mb-3'>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>
                        {t('title-label')}
                        <InfoTooltip text={t('title-tooltip')} className='ms-2' />
                    </Form.Label>
                    <Form.Control
                        {...control.register('title', { required: tf('title-required'), maxLength: { value: MAX_TITLE_LENGTH, message: tf('text-too-long', { count: MAX_TITLE_LENGTH }) } })}
                        autoFocus={props.autoFocus ?? false}
                    />
                    <FormErrorMessage errors={errors} name='title' />
                </Form.Group>
            </Row>
        )}
        <Row>
            <Form.Group as={Col} xs={6}>
                <Form.Label>{t('due-days-label')}</Form.Label>
                <Form.Control
                    {...registerPositiveInteger('dueDays', { required: tf('due-days-required') })}
                />
                <FormErrorMessage errors={errors} name='dueDays' />
            </Form.Group>
            <Form.Group as={Col} xs={6}>
                <Form.Label>{t('invoicing-email-label')}</Form.Label>
                <Form.Control
                    {...control.register('invoicingEmail', {
                        pattern: {
                            value: EMAIL_VALIDATION_PATTERN,
                            message: tf('email-invalid'),
                        },
                    })}
                    placeholder={t('invoicing-email-placeholder')}
                    type='email'
                    disabled={hideEmailOnInvoice}
                    className='sh-allow-disabled'
                />
                <FormErrorMessage errors={errors} name='invoicingEmail' />
            </Form.Group>
        </Row>
        <Row className='mt-3'>
            <Form.Group as={Col} xs={6}>
                <Form.Label>{t('locale-label')}</Form.Label>
                <ControlledLocaleSelect
                    control={control}
                    name='locale'
                    type='invoice'
                />
            </Form.Group>
            <Col>
                <Form.Check
                    type='switch'
                    className='sh-form-check'
                >
                    <label>
                        <Form.Check.Input {...control.register('hideEmailOnInvoice')} />
                        <span className='form-check-label'>{t('hide-email-on-invoice-label')}</span>
                    </label>
                </Form.Check>    
            </Col>
        </Row>
    </>);
}
