import React from 'react';
import { Navigate } from './utils/common';
import TimeGrid from './TimeGrid';
import { DateTime } from 'luxon';
import { localizer } from '.';

class WeekView extends React.Component {
    render() {
        // This allows us to default min, max, and scrollToTime using our localizer. This is necessary until such time as TimeGrid is converted to a functional component.
        const {
            date,
            min = localizer.startOf(DateTime.now(), 'day'),
            max = localizer.endOf(DateTime.now(), 'day'),
            scrollToTime = localizer.startOf(DateTime.now(), 'day'),
            ...props
        } = this.props;
        const range = getRange(date, this.props);

        return (
            <TimeGrid
                {...props}
                range={range}
                eventOffset={15}
                min={min}
                max={max}
                scrollToTime={scrollToTime}
            />
        );
    }
}

// WeekView.propTypes = {
//   date: PropTypes.instanceOf(DateTime).isRequired,
//   min: PropTypes.instanceOf(DateTime),
//   max: PropTypes.instanceOf(DateTime),
//   scrollToTime: PropTypes.instanceOf(DateTime),
// }

WeekView.defaultProps = TimeGrid.defaultProps;

function navigateTo(date, action) {
    switch (action) {
    case Navigate.PREVIOUS:
        return localizer.add(date, -1, 'week');

    case Navigate.NEXT:
        return localizer.add(date, 1, 'week');

    default:
        return date;
    }
}

function getRange(date) {
    const start = localizer.startOf(date, 'week');
    const end = localizer.endOf(date, 'week');

    return localizer.range(start, end);
}

const viewObject = {
    component: WeekView,
    navigateTo,
    getRange,
};

export default viewObject;
