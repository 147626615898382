import React, { useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SpinnerButton } from '../common';
import { useTranslation } from 'react-i18next';
import { useMaster, type MasterContext } from '@/context/UserProvider';
import { Id, type IRI } from '@/types/Id';
import type { Product, ProductEditToServer, ProductInitToServer } from '@/types/Product';
import type { CurrencyIRI, TaxRateIRI } from '@/modules/money';
import { priceToServer, toNumber } from '@/utils/math';
import { minutesToSeconds, objectUpdateToServer, stringToServer, stringUpdateToServer } from '@/utils/common';
import { ControlledCurrencySelect } from '../forms/CurrencySelect';
import { ControlledVatSelect } from '../forms/VatSelect';
import { ReceiptIcon } from '../icons';
import FormErrorMessage from '../forms/FormErrorMessage';
import { useTransform } from '@/utils/forms';
import { ControlledEmojiPicker } from '../forms/EmojiPicker';

type ProductFormProps = Readonly<{
    onInitSubmit: (output: ProductInitToServer) => void;
    onEditSubmit: (output: ProductEditToServer, product: Product) => void;
    onClose: () => void;
    fetching?: string ;
    defaultValue?: Product;
}>;

export default function ProductForm({ onInitSubmit, onEditSubmit, onClose, fetching, defaultValue }: ProductFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const { isTaxPayer } = useMaster();

    const { form, transform, handleSubmit } = useProductForm(onInitSubmit, onEditSubmit, defaultValue);
    const { control, register, formState: { errors } } = form;

    return (
        <Form className='sh-design' noValidate onSubmit={handleSubmit}>
            <Form.Group className='mb-3'>
                <ControlledEmojiPicker
                    control={control}
                    name='icon'
                />
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>{t('title-label')}</Form.Label>
                <Form.Control {...register('title', { required: tf('title-required') })} />
                <FormErrorMessage errors={errors} name='title' />
            </Form.Group>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>{t('sessions-count-label')}</Form.Label>
                    <Form.Control
                        {...transform.registerPositiveInteger('sessionsCount', { required: t('sessions-count-required') })}
                        placeholder={t('sessions-count-placeholder')}
                    />
                    <FormErrorMessage errors={errors} name='sessionsCount' />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{t('sessions-duration-label')}</Form.Label>
                    <Form.Control
                        {...transform.registerPositiveInteger('sessionsDuration', { required: t('sessions-duration-required') })}
                        placeholder={t('sessions-duration-placeholder')}
                    />
                    <FormErrorMessage errors={errors} name='sessionsDuration' />
                </Form.Group>
            </Row>

            <h2 className='d-flex align-items-center gap-3 mb-3 mt-4'><ReceiptIcon size={20}/>{t('price-row-title')}</h2>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>{t('price-label')}</Form.Label>
                    <Form.Control
                        {...transform.registerPrice('price', { required: tf('price-required') })}
                        placeholder={t('price-placeholder')}
                    />
                    <FormErrorMessage errors={errors} name='price' />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{t('currency-label')}</Form.Label>
                    <ControlledCurrencySelect
                        control={control}
                        name='currencyIRI'
                    />
                </Form.Group>
            </Row>
            {isTaxPayer && (
                <Row className='mt-3'>
                    <Form.Group as={Col} xs={6}>
                        <Form.Label>{t('vat-label')}</Form.Label>
                        <ControlledVatSelect
                            control={control}
                            name='vatIRI'
                        />
                    </Form.Group>
                </Row>
            )}

            <div className='d-flex justify-content-end gap-3 mt-5'>
                <Button
                    variant='outline-secondary'
                    disabled={!!fetching}
                    onClick={onClose}
                    className='px-4'
                >
                    {t('cancel-button')}
                </Button>
                <SpinnerButton
                    type='submit'
                    fid={FID_SAVE}
                    fetching={fetching}
                    className='px-4'
                >
                    {t('save-button')}
                </SpinnerButton>
            </div>
        </Form>
    );
}

export const FID_SAVE = 'save';

export function useProductForm(
    onInitSubmit: (output: ProductInitToServer) => void,
    onEditSubmit: (output: ProductEditToServer, product: Product) => void,
    defaultValue?: Product,
) {
    const form = useForm<ProductFormData>();
    const transform = useTransform(form.register, form.setValue);
    const masterContext = useMaster();

    function onValidSubmit(data: ProductFormData) {
        if (defaultValue)
            onEditSubmit(formToEdit(data, defaultValue), defaultValue);
        else
            onInitSubmit(formToInit(data));
    }

    useEffect(() => {
        form.reset(inputToForm(defaultValue, masterContext));
    }, [ defaultValue ]);

    return {
        form,
        transform,
        handleSubmit: form.handleSubmit(onValidSubmit),
    };
}

export type ProductFormData = {
    title: string;
    icon: string;
    sessionsCount: number | '';
    /** In minutes. */
    sessionsDuration: number | '';

    price: number | '';
    currencyIRI: CurrencyIRI;
    vatIRI: TaxRateIRI;

    locationIRI?: IRI;
};

function inputToForm(product: Product | undefined, { teamSettings, profiles }: MasterContext): ProductFormData {
    return {
        title: product?.title ?? '',
        icon: product?.icon ?? '',
        sessionsCount: product?.sessionsCount ?? '',
        sessionsDuration: product?.sessionsDuration ? product.sessionsDuration : '',
    
        price: product?.price.amount ?? '',
        currencyIRI: (product?.price ?? teamSettings).currency.toIRI(),
        vatIRI: (product ?? profiles[0]).vat.toIRI(),
    
        locationIRI: product?.locationId?.toIRI(),
    };
}

function formToInit(data: ProductFormData): ProductInitToServer {
    return {
        title: data.title,
        icon: stringToServer(data.icon),
        sessionsCount: toNumber(data.sessionsCount),
        sessionsDuration: minutesToSeconds(toNumber(data.sessionsDuration)),
    
        price: priceToServer(toNumber(data.price)),
        currency: data.currencyIRI,
        vat: data.vatIRI,
    
        location: data.locationIRI,
    };
}

function formToEdit(data: ProductFormData, product: Product): ProductEditToServer {
    const locationId = data.locationIRI ? Id.fromIRI(data.locationIRI) : undefined;

    return {
        title: data.title,
        icon: stringUpdateToServer(product.icon, data.icon),
        sessionsCount: toNumber(data.sessionsCount),
        sessionsDuration: minutesToSeconds(toNumber(data.sessionsDuration)),
    
        price: priceToServer(toNumber(data.price)),
        currency: data.currencyIRI,
        vat: data.vatIRI,
    
        location: objectUpdateToServer(product.locationId, locationId),
    };
}
