import { type DateTime } from 'luxon';
import { Frequency, type ByDay, type Recurrence, type Day, WEEK_DAYS, PredefinedOption, createDefaultRecurrence, getWeekDay, WORK_WEEK_DAYS } from './Recurrence';

// Some recurrences can lead to specific problems (sorted by probability):
//  (a) The day might not exists in a month. Sometimes, it's predictable (e.g., 31st February). Sometimes, not so much (e.g., the 5th Monday in the February of 2024).
//  (b) The time might not exists in a day. E.g., the 2:15 in the night of the DST spring transition from 2:00 to 3:00.
//  (c) The time might be duplicated in a day. E.g., the 2:15 in the night of the DST fall transition, where the time goes like:
//      2:00, ..., 2:15, ..., 2:59, 2:00, ..., 2:15, ..., 3:00
//  (d) The date is entirely skipped and it isn't predictable at all. E.g., In December 2021, the 30th was skipped in Samoa and Tokelau, because they changed the timezone to one on the other side of the international date line. This is exceptionally rare, but it can happen.
//  (e) A day is added. Didn't happen in the recent history. It's theroetically possible (e.g., if a country changes the timezone to one on the other side of the international date line), but it's very unlikely.
//
// We will deal with these problems exactly like Google Calendar does:
//  (a) The instance is skipped. However, the next one is generated as if the skipped one existed.
//      E.g., we want the 31st day of each month with the interval of 3. We start with 31st January. The next instance should be on the 31st April, but that doesn't exist. So we try the 31st of July, which does exist.
//  (d) The same logic applies. We just skip the instance.
//  (b), (c) Assuming the date is valid, we will always generate the instance. Then we will treat it as an absolute time from the start of the day.
//      E.g., we want the 2:15 in the night of the DST spring transition. By that we mean "135 minutes from the start of the day", which is, in this case, 3:15.
//      E.g., we want the 2:15 in the night of the DST fall transition. So we use the first occurence of 2:15, not the second one.
//      However, all "normal" times in both days are unaffected. E.g., the 3:15 on the spring transition day is still 3:15, not 4:15.
//  (e) Don't know, didn't happen. We will use the Ostritch algorithm.
//
// In order to keep our sanity, we don't want to deal with the validity of the dates by ourselves. So, the algorithm should work like this:
//  1. We generate the date in a plain form (e.g., year, month, and day).
//  2. We will check with luxon whether the date is valid (thus solving (a) and (d)). We might also solve (e) this way, but it's not guaranteed.
//  3. We will then set the time. Luxon should automatically solve (b) and (c) for us.

/**
 * Generates all recurrence dates after the given date.
 * @param after If it conforms to the recurrence rule, this is the first date that will be generated. Otherwise, the first conforming date will be generated.
 */
export function computeRecurrenceDates(recurrence: Recurrence, after: DateTime): DateTime[] {
    const generator = getDateGenerator(recurrence, after);

    let index = 0;
    let current = after;
    const output: DateTime[] = [];
    const isEnded = getIsEndedCondition(recurrence);

    while (!isEnded(index, current)) {
        current = generator();
        output.push(current);
        index++;
    }

    return output;
}

/**
 * Computes how many recurrence repertitions there are. In some cases, this is much faster than the `computeRecurrenceDates` function.
 */
export function computeRecurrenceCount(recurrence: Recurrence, after: DateTime): number {
    if (!recurrence.frequency)
        return 1;
    if (recurrence.count)
        return recurrence.count;
    if (!recurrence.until)
        return Infinity;

    return computeRecurrenceDates(recurrence, after).length;
}

export function toLimitedCount(recurrence: Recurrence | undefined, after: DateTime, limit: number): Recurrence | undefined {
    if (!recurrence || limit <= 1)
        return undefined;

    const count = computeRecurrenceCount(recurrence, after);
    if (count <= 1)
        return undefined;
    if (count <= limit)
        return recurrence;

    return { ...recurrence, count: limit, until: undefined };
}

/**
 * A function that returns true if the generated date sequence has ended. Should be called before the first date is generated, because there might be no dates to generate.
 */
type IsEndedCondition = (index: number, date: DateTime) => boolean;

function getIsEndedCondition(recurrence: Recurrence): IsEndedCondition {
    if (!recurrence.frequency)
        return (index: number) => index >= 0;

    if (recurrence.count) {
        const count = recurrence.count;
        return (index: number) => index >= count;
    }

    if (!recurrence.until)
        return () => true;

    const until = recurrence.until;
    return (_: number, date: DateTime) => date >= until;
}

/**
 * A function that generates dates based on the given recurrence. The first generated date is the start date of the recurrence.
 */
type DateGenerator = () => DateTime;

function getDateGenerator(recurrence: Recurrence, after: DateTime): DateGenerator {
    if (!recurrence.frequency)
        return () => after;

    switch (recurrence.frequency) {
    case Frequency.Daily:
        return dailyDates(after, recurrence.interval);
    case Frequency.Weekly:
        return (!recurrence.byWeekDay || recurrence.byWeekDay.length === 0)
            ? weeklyDates(after, recurrence.interval)
            : weeklyDatesByDay(after, recurrence.interval, recurrence.byWeekDay);
    case Frequency.Monthly:
        if (recurrence.byMonthDay)
            return monthDatesByMonthDay(after, recurrence.interval, recurrence.byMonthDay);
        if (recurrence.byDay)
            return monthDatesByDay(after, recurrence.interval, recurrence.byDay);

        return monthDates(after, recurrence.interval);
    }
}

// TODO change all generators to plain dates first according to the description above.

function dailyDates(after: DateTime, interval: number): DateGenerator {
    let current = after;

    return () => {
        const output = current;
        current = current.plus({ days: interval });

        return output;
    };
}

/** E.g., every 2 weeks. */
function weeklyDates(after: DateTime, interval: number): DateGenerator {
    let current = after;

    return () => {
        const output = current;
        current = current.plus({ weeks: interval });

        return output;
    };
}

/** E.g., every 2 weeks on Monday and Tuesday. */
function weeklyDatesByDay(after: DateTime, interval: number, byDay: Day[]): DateGenerator {
    const dayMask = WEEK_DAYS.map(day => byDay.includes(day));
    // How many days to skip to get to the next valid day.
    const daySkip = dayMask.map((_, index) => {
        // TODO this should be optimized.
        let i = 1;
        while (!dayMask[(i + index) % DAYS_IN_WEEK])
            i++;

        return i;
    });

    // The first valid date.
    const first = dayMask[after.weekday - 1] ? after : after.plus({ days: daySkip[after.weekday - 1] });
    let current = first;

    return () => {
        const output = current;
        const currentIndex = current.weekday - 1;
        const nextDay = current.plus({ days: daySkip[currentIndex] });

        const nextIndex = nextDay.weekday - 1;
        current = (nextIndex <= currentIndex && interval > 1)
            // Check if we went to the next week. If yes, we have to also increase the week count (if it isn't every week).
            ? nextDay.plus({ weeks: interval - 1 })
            : nextDay;

        return output;
    };
}

const DAYS_IN_WEEK = WEEK_DAYS.length;

/** E.g., every 2 months. The month day index will be the same as the index of the `after` argument. */
function monthDates(after: DateTime, interval: number): DateGenerator {
    let current = after;
    const monthDay = after.day;

    return () => {
        const output = current;

        // When adding months to luxon, the day number isn't always preserved. E.g., adding 1 month to 31st January will result in 29th February. So we have to manually find the next valid date.
        for (let i = 1; i < Number.MAX_SAFE_INTEGER; i++) {
            const next = current.plus({ month: interval * i });
            if (next.day === monthDay) {
                current = next;
                break;
            }
        }

        return output;
    };
}

/** E.g., every 2 months. The month day index is determined by the `byMonthDay` argument. */
function monthDatesByMonthDay(after: DateTime, interval: number, byMonthDay: number): DateGenerator {
    // We need to find the first valid date, i.e., the first date that has the same day as the `byMonthDay` argument.
    if (after.day === byMonthDay)
        return monthDates(after, interval);

    // If the day can still be found in the same month, we try it.
    if (after.day < byMonthDay) {
        const sameMonthStart = after.set({ day: byMonthDay });
        // If the month doesn't have enough days, luxon will just use another month. So we have to check if the month is still the same.
        if (sameMonthStart.month === after.month)
            return monthDates(sameMonthStart, interval);
    }

    // If the day can't be found in the same month, we have to find the next month that has the same day.
    for (let i = 1; i < Number.MAX_SAFE_INTEGER; i++) {
        const nextMonth = after.plus({ months: i });
        const start = nextMonth.set({ day: byMonthDay });
        // The same check for the month as above
        if (nextMonth.month === start.month)
            return monthDates(start, interval);
    }

    throw new Error('The next valid date could not be found.');
}

/** E.g., every second Tuesday every 2 months. Or every last friday every 3 months. */
function monthDatesByDay(after: DateTime, interval: number, byDay: ByDay): DateGenerator {
    let current = monthByDayStart(after, byDay);

    return () => {
        const output = current;
        current = computeNextValidByDay(current, interval, byDay);

        return output;
    };
}

function monthByDayStart(after: DateTime, byDay: ByDay) {
    // This should be by far the most common case.
    if (isByDay(after, byDay))
        return after;
    
    const currentNumber = byDayToDayNumber(after, byDay);
    if (!isNaN(currentNumber) && currentNumber > after.day)
        return after.set({ day: currentNumber });

    return computeNextValidByDay(after, 2, byDay);
}

/**
 * We assume the date is a valid byDay, i.e., we have to start with the following month.
 */
function computeNextValidByDay(date: DateTime, interval: number, byDay: ByDay): DateTime {
    for (let i = 1; i < Number.MAX_SAFE_INTEGER; i++) {
        const current = date.plus({ month: interval * i });
        const currentNumber = byDayToDayNumber(current, byDay);

        if (!isNaN(currentNumber))
            return current.set({ day: currentNumber });
    }

    throw new Error('The next valid date could not be found.');
}

function isByDay(date: DateTime, byDay: ByDay) {
    if (WEEK_DAYS[date.weekday - 1] !== byDay.weekDay)
        return false;

    // Some high level math right here!
    // At this point, we don't consider any edge-edge-cases like missing a date or something. It would be too complicated.
    const index = byDay.occurence > 0
        ? date.day + DAYS_IN_WEEK - DAYS_IN_WEEK * byDay.occurence
        : date.day - date.daysInMonth - DAYS_IN_WEEK * byDay.occurence;
    
    return index >= 1 && index <= DAYS_IN_WEEK;
}

/** Finds a day number (starting with 1) in the given month corresponding to the given byDay. If there is no such day, a Nan is returned. */
function byDayToDayNumber(date: DateTime, byDay: ByDay): number {
    // The week index of the target day.
    const targetDayIndex = WEEK_DAYS.indexOf(byDay.weekDay);
    const monthDays = date.daysInMonth;

    if (byDay.occurence > 0) {
        // The week index of the first day in this month. We don't have to subtract 1 from the date.weekday, because date.day is also 1-based.
        const firstDayIndex = ((monthDays * DAYS_IN_WEEK) + date.weekday - date.day) % DAYS_IN_WEEK;

        // The month index of the first target day in the month.
        const firstTargetDayIndex = targetDayIndex - firstDayIndex + (targetDayIndex < firstDayIndex ? DAYS_IN_WEEK : 0);
        const outputNumber = firstTargetDayIndex + 1 + (byDay.occurence - 1) * DAYS_IN_WEEK;

        return outputNumber <= monthDays ? outputNumber : NaN;
    }
    
    // The week index of the last day in this month. We do have to subtract 1 because we are adding monthDays which is also 1-based.
    const lastDayIndex = ((monthDays * DAYS_IN_WEEK) + date.weekday - date.day + monthDays - 1) % DAYS_IN_WEEK;
    const firstTargetDayNumber = monthDays + targetDayIndex - lastDayIndex - (targetDayIndex > lastDayIndex ? DAYS_IN_WEEK : 0);

    const outputNumber = firstTargetDayNumber + (byDay.occurence + 1) * DAYS_IN_WEEK;

    return outputNumber >= 1 ? outputNumber : NaN;
}

/** Gets the byDay corresponding to this date. */
export function computeByDay(date: DateTime, isFromStart: boolean): ByDay {
    const occurence = isFromStart
        ? Math.floor((date.day - 1) / DAYS_IN_WEEK) + 1
        : Math.floor((date.daysInMonth - date.day) / DAYS_IN_WEEK) + 1;

    return {
        weekDay: WEEK_DAYS[date.weekday - 1],
        occurence,
    };
}

// Generating predefined options for the recurrence input.

export type PredefinedOptions = Record<Exclude<PredefinedOption, PredefinedOption.Never>, Recurrence | undefined>;

export function createPredefinedOptions(date: DateTime, count?: number): PredefinedOptions {
    const finite = {
        [ PredefinedOption.Custom ]: createDefaultRecurrence(date, count),
    };

    const isOnlyFinite = count === undefined;
    
    return {
        [ PredefinedOption.Daily ]: isOnlyFinite ? undefined : {
            frequency: Frequency.Daily,
            interval: 1,
        },
        [ PredefinedOption.Weekdaily ]: isOnlyFinite ? undefined : {
            frequency: Frequency.Weekly,
            interval: 1,
            byWeekDay: WORK_WEEK_DAYS,
        },
        [ PredefinedOption.Weekly ]: isOnlyFinite ? undefined : {
            frequency: Frequency.Weekly,
            interval: 1,
            byWeekDay: [ getWeekDay(date) ],
        },
        [ PredefinedOption.TwoWeekly ]: isOnlyFinite ? undefined : {
            frequency: Frequency.Weekly,
            interval: 2,
            byWeekDay: [ getWeekDay(date) ],
        },
        [ PredefinedOption.MonthlyByDay ]: isOnlyFinite ? undefined : {
            frequency: Frequency.Monthly,
            interval: 1,
            byMonthDay: date.day,
        },
        [ PredefinedOption.MonthlyFromStart ]: isOnlyFinite ? undefined : {
            frequency: Frequency.Monthly,
            interval: 1,
            byDay: { weekDay: getWeekDay(date), occurence: 1 },
        },
        [ PredefinedOption.MonthlyFromEnd ]: isOnlyFinite ? undefined : {
            frequency: Frequency.Monthly,
            interval: 1,
            byDay: { weekDay: getWeekDay(date), occurence: -1 },
        },
        ...finite,
    };
}

/** If undefined is returned, it means that no predefined option was matched. */
export function matchPredefinedOption(recurrence: Recurrence, options: PredefinedOptions): Exclude<PredefinedOption, PredefinedOption.Never> | undefined {
    if (recurrence.count || recurrence.until)
        return;

    if (recurrence.interval === 2) {
        if (
            options[PredefinedOption.TwoWeekly] &&
            recurrence.frequency === Frequency.Weekly &&
            recurrence.byWeekDay?.length === 1 &&
            recurrence.byWeekDay[0] === options[PredefinedOption.TwoWeekly].byWeekDay![0]
        )
            return PredefinedOption.TwoWeekly;

        return;
    }

    if (recurrence.interval !== 1)
        return;

    if (options[PredefinedOption.Daily] && recurrence.frequency === Frequency.Daily)
        return PredefinedOption.Daily;
    
    if (recurrence.frequency === Frequency.Weekly) {
        if (
            options[PredefinedOption.Weekly] &&
            recurrence.byWeekDay?.length === 1 &&
            recurrence.byWeekDay[0] === options[PredefinedOption.Weekly].byWeekDay![0]
        )
            return PredefinedOption.Weekly;

        if (
            options[PredefinedOption.Weekdaily] &&
            recurrence.byWeekDay?.length === 5 &&
            recurrence.byWeekDay.every(day => WORK_WEEK_DAYS.includes(day))
        )
            return PredefinedOption.Weekdaily;

        return;
    }

    if (recurrence.frequency === Frequency.Monthly) {
        if (recurrence.byMonthDay) {
            return (options[PredefinedOption.MonthlyByDay] && recurrence.byMonthDay === options[PredefinedOption.MonthlyByDay].byMonthDay)
                ? PredefinedOption.MonthlyByDay
                : undefined;
        }

        if (recurrence.byDay) {
            if (
                options[PredefinedOption.MonthlyFromStart] &&
                recurrence.byDay.occurence === 1 &&
                recurrence.byDay.weekDay === options[PredefinedOption.MonthlyFromStart].byDay!.weekDay
            )
                return PredefinedOption.MonthlyFromStart;

            if (
                options[PredefinedOption.MonthlyFromEnd] &&
                recurrence.byDay.occurence === -1 &&
                recurrence.byDay.weekDay === options[PredefinedOption.MonthlyFromEnd].byDay!.weekDay
            )
                return PredefinedOption.MonthlyFromEnd;
        }
    }
}