import React, { useEffect, useMemo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { type UseFormReturn, useForm } from 'react-hook-form';
import { ClientAccessType, type Client, type ClientGeneralUpdate } from '@/types/Client';
import FormErrorMessage from '@/components/forms/FormErrorMessage';
import { useTranslation } from 'react-i18next';
import { EMAIL_VALIDATION_PATTERN, canonizeEmail } from '@/utils/forms';
import { type RequiredBy, stringToServer } from '@/utils/common';
import TextareaAutosize from 'react-textarea-autosize';
import { EditNotesIcon, UsersIcon, WalletIcon } from '../icons';
import { ClientStatsView } from './ClientStats';
import { OnlyToYouLabel, PricingsEditor, type PricingFormItem, computeDefaultPricings, pricingsFormDataToNewPricings } from '../calendar/PricingsEditor';
import { type UserContext, toMaster, useUser } from '@/context/UserProvider';
import { InfoTooltip } from '../forms/buttons';

type ClientGeneralFormProps = Readonly<{
    onSubmit: (output: ClientGeneralUpdate) => void;
    defaultValue: Client;
    onChange?: (isDirty: boolean) => void;
    saveButton?: React.FC<{ onClick: () => void }>;
}>;

export default function ClientGeneralForm({ onSubmit, defaultValue, onChange, saveButton }: ClientGeneralFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });

    const { form, handleSubmit, isEditable } = useClientGeneralForm(onSubmit, defaultValue, onChange);
    const { register, formState: { errors } } = form;
    const isMasterOrFreelancer = !!toMaster(useUser());

    return (<>
        <ClientStatsView client={defaultValue} />
        <Form noValidate onSubmit={handleSubmit} className='sh-design'>
            <h5 className='m-0 d-flex align-items-center'><UsersIcon size={24} className='me-3' />{t('general-title')}</h5>
            <Row className='mt-3'>
                <Col>
                    <Form.Label>{t('email-label')}</Form.Label>
                    <Form.Control
                        {...register('email', {
                            required: tf('email-required'),
                            pattern: {
                                value: EMAIL_VALIDATION_PATTERN,
                                message: tf('email-invalid'),
                            },
                        })}
                        placeholder={t('email-placeholder')}
                        type='email'
                        disabled={!isEditable}
                    />
                    <FormErrorMessage errors={errors} name='email' />
                </Col>
                <Col>
                    <Form.Label>{t('phone-number-label')}</Form.Label>
                    <Form.Control
                        {...register('phoneNumber', { required: false })}
                        placeholder={t('phone-number-placeholder')}
                        disabled={!isEditable}
                    />
                </Col>
            </Row>
            {isMasterOrFreelancer && (
                <div className='mt-4'>
                    <h5 className='mt-0 mb-3 d-flex align-items-center'>
                        <WalletIcon size={24} className='me-3' />
                        {t('pricings-label')}
                        <InfoTooltip text={t('pricings-tooltip')} className='ms-2 align-self-start'/>
                    </h5>
                    <PricingsEditor form={form as UseFormReturn<RequiredBy<ClientGeneralFormData, 'pricings'>>} allowEmpty />
                </div>
            )}
            <Form.Group className='mt-4'>
                <div className='d-flex align-items-center justify-content-between'>
                    <h5 className='m-0 d-flex align-items-center'><EditNotesIcon size={24} className='me-3' />{t('note-label')}</h5>
                    <OnlyToYouLabel className='my-2' />
                </div>
                <Form.Control
                    {...register('note', { required: false, maxLength: { value: 1000, message: tf('text-too-long', { count: 1000 }) } })}
                    placeholder={t('note-placeholder')}
                    type='email'
                    as={TextareaAutosize}
                    minRows={4}
                    className='mt-2'
                    disabled={!isEditable}
                />
                <FormErrorMessage errors={errors} name='note' />
            </Form.Group>
            <>{saveButton?.({ onClick: handleSubmit })}</>
        </Form>
    </>);
}

export function useClientGeneralForm(
    onSubmit: (output: ClientGeneralUpdate) => void,
    defaultValue: Client,
    onChange?: (isDirty: boolean) => void,
) {
    const form = useForm<ClientGeneralFormData>();
    const isDirty = form.formState.isDirty;
    const userContext = useUser();
    const isMasterOrFreelancer = !!toMaster(useUser());

    const isEditable = useMemo(() => {
        if (isMasterOrFreelancer)
            return true;
        return defaultValue.getAccess(userContext.appUser)?.accessType === ClientAccessType.Owner;
    }, [ defaultValue, userContext, isMasterOrFreelancer ]);

    useEffect(() => onChange?.(isDirty), [ isDirty, onChange ]);

    function onValidSubmit(data: ClientGeneralFormData) {
        onSubmit(formToOutput({ ...data, email: canonizeEmail(data.email) }));
    }

    useEffect(() => {
        form.reset(inputToForm(defaultValue, userContext));
    }, [ defaultValue ]);

    return {
        form,
        handleSubmit: form.handleSubmit(onValidSubmit),
        isEditable,
    };
}

type ClientGeneralFormData = {
    email: string;
    name: string;
    phoneNumber: string;
    note: string;
    pricings?: PricingFormItem[];
};

function inputToForm(client: Client, userContext: UserContext): ClientGeneralFormData {
    const masterContext = toMaster(userContext);

    return {
        name: client.name,
        email: client.email,
        phoneNumber: client.phoneNumber ?? '',
        note: client.note ?? '',
        pricings: masterContext && computeDefaultPricings(client.pricings, masterContext.teamSettings),
    };
}

function formToOutput(data: ClientGeneralFormData): ClientGeneralUpdate {
    return {
        email: data.email,
        name: stringToServer(data.name),
        phoneNumber: stringToServer(data.phoneNumber),
        note: stringToServer(data.note),
        pricings: data.pricings && pricingsFormDataToNewPricings({ pricings: data.pricings }),
    };
}
