import React, { createContext, type ReactNode, useContext, useState, useMemo, type SetStateAction, type Dispatch, type ComponentType } from 'react';
import { type InvoicingProfile, isTaxPayer } from '@/types/Invoicing';
import { type AppUser } from '@/types/AppUser';
import { type Onboarding } from '@/types/Onboarding';
import { setLocale, setTimezone } from '@/types/i18n';
import { type BankAccount } from '@/types/BankAccount';
import type { GoogleUserInfo } from '@/types/GoogleUser';
import { extract } from '@/hooks/api/utils';
import { type Settings } from '@/types/Settings';
import { type Team, type TeamMembers, UserRole } from '@/types/Team';
import { type TeamSettings } from '@/types/TeamSettings';
import { type Subscription } from '@/types/Subscription';
import { type ClientTag } from '@/types/ClientTag';

const authorizedContext = createContext<UserContext | undefined>(undefined);

type UserState = SchedulerState | MasterState;
export type UserDefaults = SchedulerDefaults | MasterDefaults;

type UserProviderProps = Readonly<{
    children: ReactNode;
    defaults: UserDefaults;
}>;

export function UserProvider({ children, defaults }: UserProviderProps) {
    const [ state, setState ] = useState<UserState>(computeDefaultState(defaults));

    const setters = useMemo(() => ({
        setAppUser: (input: SetStateAction<AppUser>) => setState(state => ({
            ...state, appUser: extract(input, state.appUser),
        })),
        setSettings: (input: SetStateAction<Settings>) => setState(state => {
            const settings = extract(input, state.settings);
            // Synchronize locale with the settings.
            setTimezone(settings.timezone);
            setLocale(settings.locale);

            return { ...state, settings };
        }),
        setOnboarding: (input: SetStateAction<Onboarding>) => setState(state => ({
            ...state, onboarding: extract(input, state.onboarding),
        })),
        setSubscription: (input: SetStateAction<Subscription>) => setState(state => ({
            ...state, subscription: extract(input, state.subscription),
        })),
        setTeam: (input: SetStateAction<Team>) => setState(state => state && ({
            ...state, team: extract(input, state.team),
        })),
        setTeamSettings: (input: SetStateAction<TeamSettings>) => setState(state => ({
            ...state, teamSettings: extract(input, (state as MasterState).teamSettings),
        })),
        setProfiles: (input: SetStateAction<InvoicingProfile[]>) => setState(state => {
            const profiles = extract(input, (state as MasterState).profiles);
            return { ...state, profiles, isTaxPayer: isTaxPayer(profiles) };
        }),
        setBankAccounts: (input: SetStateAction<BankAccount[]>) => setState(state => ({
            ...state, bankAccounts: extract(input, (state as MasterState).bankAccounts),
        })),
        setClientTags: (input: SetStateAction<ClientTag[]>) => setState(state => ({
            ...state, clientTags: extract(input, (state as MasterState).clientTags),
        })),
    }), []);

    const value = useMemo(() => ({
        ...state,
        ...setters,
    }), [ state, setters ]);

    return (
        <authorizedContext.Provider value={value}>
            { children }
        </authorizedContext.Provider>
    );
}

function computeDefaultState(defaults: UserDefaults): () => UserState {
    return () => {

        if (defaults.role === UserRole.Scheduler)
            return defaults;

        return { ...defaults, isTaxPayer: isTaxPayer(defaults.profiles) };
    };
}

type SchedulerState = {
    role: UserRole.Scheduler;
    team: Team;
    teamMembers: TeamMembers;
    appUser: AppUser;
    settings: Settings;
    onboarding: Onboarding;
    subscription: Subscription;
    googleUserInfo: GoogleUserInfo | undefined;
};

export type SchedulerDefaults = SchedulerState;

export type SchedulerContext = SchedulerState & {
    setTeam: Dispatch<SetStateAction<Team>>;
    setAppUser: Dispatch<SetStateAction<AppUser>>;
    setSettings: Dispatch<SetStateAction<Settings>>;
    setOnboarding: Dispatch<SetStateAction<Onboarding>>;
    setSubscription: Dispatch<SetStateAction<Subscription>>;
};

/**
 * Returns data only for user role scheduler. Throws error otherwise.
 */
export function useScheduler(): SchedulerContext  {
    const context = useContext(authorizedContext);
    if (context === undefined)
        throw new Error('useScheduler must be used within an AuthProvider');

    const schedulerContext = toScheduler(context);
    if (!schedulerContext)
        throw new Error(`useScheduler can't be used with a ${context.role} role`);

    return schedulerContext;
}

export function toScheduler(context: UserContext): SchedulerContext | undefined {
    return context.role === UserRole.Scheduler ? context : undefined;
}

type MasterState = {
    role: UserRole.Master | UserRole.Freelancer;
    team: Team;
    teamMembers: TeamMembers;
    appUser: AppUser;
    settings: Settings;
    onboarding: Onboarding;
    subscription: Subscription;
    googleUserInfo: GoogleUserInfo | undefined;
    teamSettings: TeamSettings;
    profiles: InvoicingProfile[];
    bankAccounts: BankAccount[];
    clientTags: ClientTag[];

    isTaxPayer: boolean;
};

export type MasterDefaults = Omit<MasterState, 'isTaxPayer'>;

export type MasterContext = MasterState & {
    setTeam: Dispatch<SetStateAction<Team>>;
    setTeamSettings: Dispatch<SetStateAction<TeamSettings>>;
    setProfiles: Dispatch<SetStateAction<InvoicingProfile[]>>;
    setBankAccounts: Dispatch<SetStateAction<BankAccount[]>>;
    setClientTags: Dispatch<SetStateAction<ClientTag[]>>;
    setAppUser: Dispatch<SetStateAction<AppUser>>;
    setSettings: Dispatch<SetStateAction<Settings>>;
    setOnboarding: Dispatch<SetStateAction<Onboarding>>;
    setSubscription: Dispatch<SetStateAction<Subscription>>;
};

/**
 * Returns data only for user roles master or freelancer. Throws error otherwise.
 */
export function useMaster(): MasterContext {
    const context = useContext(authorizedContext);
    if (context === undefined)
        throw new Error('useMaster must be used within an AuthProvider');

    const masterContext = toMaster(context);
    if (!masterContext)
        throw new Error(`useMaster can't be used with a ${context.role} role`);

    return masterContext;
}

export function toMaster(context: UserContext): MasterContext | undefined {
    return (context.role === UserRole.Master || context.role === UserRole.Freelancer) ? context : undefined;
}

export function masterComponent<TProps>(Component: ComponentType<TProps>): ComponentType<TProps> {
    return function MasterComponentWrapper(props: TProps) {
        const masterContext = toMaster(useUser());
        if (!masterContext)
            return null;

        return <Component {...props} masterContext={masterContext} />;
    };
}

export type UserContext = SchedulerContext | MasterContext;

export function useUser(): UserContext {
    const context = useContext(authorizedContext);
    if (context === undefined)
        throw new Error('useUser must be used within an AuthProvider');

    return context;
}
