import axios, { type AxiosRequestConfig } from 'axios';
import { ApiAuthorizer } from '../ApiAuthorizer';
import { type Id } from '@/types/Id';
import { RawApi } from '../rawApi';
import type { PullRoute, PullRouteFixed, RequestBody, PushRoute, PushRouteFixed, QueryParams, Url, UrlParams } from '@/types/api/endpoint';

const authorizer = new ApiAuthorizer();

export const BACKEND_API_PREFIX = import.meta.env.VITE_BACKEND_API_PREFIX;

function actionToPath(action: Id | string): string {
    if (typeof action !== 'string')
        return action.toIRI() as unknown as string;

    return action.startsWith(BACKEND_API_PREFIX) ? action : (BACKEND_API_PREFIX + action);
}

const axiosInstance = axios.create({
    baseURL: '',
    headers: {
        Accept: 'application/ld+json',
        //Accept: 'application/json',
    },
    params: {}, // do not remove this, its added to add params later in the config
});

const PUBLIC_ENDPOINTS = [
    actionToPath('/token/refresh'),
    actionToPath('/token/invalidate'),
    actionToPath('/version'),
];

axiosInstance.interceptors.request.use(
    config => {
        const token = authorizer.getToken();
        // Unfortunatelly, the backend is still trying to authentize by the access token first, even when we are refreshing it with a valid refresh token ...
        // The same holds for invalidate.
        if (token && config.headers && config.url && !PUBLIC_ENDPOINTS.includes(config.url))
            config.headers['Authorization'] = 'Bearer ' + token;
        
        return config;
    },
);

const rawApiObject = new RawApi(
    axiosInstance,
    authorizer,
    actionToPath,
);

export default rawApiObject;

export function createGET<
    TUrlParams extends UrlParams,
    TData,
    TQueryParams extends QueryParams = void,
>(url: Url<TUrlParams>): PullRoute<TUrlParams, TData, TQueryParams>;
export function createGET<
    TData,
    TQueryParams extends QueryParams = void,
>(url: string): PullRouteFixed<TData, TQueryParams>;
export function createGET<
    TUrlParams extends UrlParams,
    TData,
    TQueryParams extends QueryParams = void,
>(url: Url<TUrlParams> | string) {
    return typeof url === 'function'
        ? (urlParams: TUrlParams, signal?: AbortSignal, queryParams?: TQueryParams) => rawApiObject.GET<TData>(url(urlParams), signal, queryParams ?? {})
        : (signal?: AbortSignal, queryParams?: TQueryParams) => rawApiObject.GET(url, signal, queryParams ?? {});
}

export function createPOST<
    TUrlParams extends UrlParams,
    TData,
    TRequestBody extends RequestBody = void,
>(url: Url<TUrlParams>): PushRoute<TUrlParams, TData, TRequestBody>;
export function createPOST<
    TData,
    TRequestBody extends RequestBody = void,
>(url: string): PushRouteFixed<TData, TRequestBody>;
export function createPOST<
    TUrlParams extends UrlParams,
    TData,
    TRequestBody extends RequestBody = void,
>(url: Url<TUrlParams> | string) {
    return typeof url === 'function'
        ? (urlParams: TUrlParams, data: TRequestBody, config?: AxiosRequestConfig) => rawApiObject.POST<TData, TRequestBody>(url(urlParams), data, config)
        : (data: TRequestBody, config?: AxiosRequestConfig) => rawApiObject.POST<TData, TRequestBody>(url, data, config);
}

export function createPUT<
    TUrlParams extends UrlParams,
    TData,
    TRequestBody extends RequestBody = void,
>(url: Url<TUrlParams>): PushRoute<TUrlParams, TData, TRequestBody>;
export function createPUT<
    TData,
    TRequestBody extends RequestBody = void,
>(url: string): PushRouteFixed<TData, TRequestBody>;
export function createPUT<
    TUrlParams extends UrlParams,
    TData,
    TRequestBody extends RequestBody = void,
>(url: Url<TUrlParams> | string) {
    return typeof url === 'function'
        ? (urlParams: TUrlParams, data: TRequestBody, config?: AxiosRequestConfig) => rawApiObject.PUT<TData, TRequestBody>(url(urlParams), data, config)
        : (data: TRequestBody, config?: AxiosRequestConfig) => rawApiObject.PUT<TData, TRequestBody>(url, data, config);
}

// Delete doesn't have a body (for now) so it doesn't make sense to create a function without url params.
export function createDELETE<TUrlParams extends UrlParams, TData>(url: Url<TUrlParams>): PullRoute<TUrlParams, TData, void> {
    return (urlParams: TUrlParams) => rawApiObject.DELETE<TData>(url(urlParams));
}

export function createPATCH<
    TUrlParams extends UrlParams,
    TData,
    TRequestBody extends RequestBody = void,
>(url: Url<TUrlParams>): PushRoute<TUrlParams, TData, TRequestBody>;
export function createPATCH<
    TData,
    TRequestBody extends RequestBody = void,
>(url: string): PushRouteFixed<TData, TRequestBody>;
export function createPATCH<
    TUrlParams extends UrlParams,
    TData,
    TRequestBody extends RequestBody = void,
>(url: Url<TUrlParams> | string) {
    return typeof url === 'function'
        ? (urlParams: TUrlParams, data: TRequestBody, config?: AxiosRequestConfig) => rawApiObject.PATCH<TData, TRequestBody>(url(urlParams), data, config)
        : (data: TRequestBody, config?: AxiosRequestConfig) => rawApiObject.PATCH<TData, TRequestBody>(url, data, config);
}

export function transformVariable<
    TUrlParams extends UrlParams,
    TData,
    TRequestBodyOld extends RequestBody,
    TRequestBodyNew extends RequestBody,
>(
    route: PushRoute<TUrlParams, TData, TRequestBodyOld>,
    transform: (data: TRequestBodyNew) => TRequestBodyOld,
    transformConfig?: (config: AxiosRequestConfig) => AxiosRequestConfig,
): PushRoute<TUrlParams, TData, TRequestBodyNew> {
    return (urlParams: TUrlParams, data: TRequestBodyNew, config?: AxiosRequestConfig) => route(
        urlParams,
        transform(data),
        transformConfig ? transformConfig(config ?? {}) : config,
    );
}

export function transformFixed<
    TData,
    TRequestBodyOld extends RequestBody,
    TRequestBodyNew extends RequestBody,
>(
    route: PushRouteFixed<TData, TRequestBodyOld>,
    transform: (data: TRequestBodyNew) => TRequestBodyOld,
    transformConfig?: (config: AxiosRequestConfig) => AxiosRequestConfig,
): PushRouteFixed<TData, TRequestBodyNew> {
    return (data: TRequestBodyNew, config?: AxiosRequestConfig) => route(
        transform(data),
        transformConfig ? transformConfig(config ?? {}) : config,
    );
}
