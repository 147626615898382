import React, { useCallback, useMemo } from 'react';
import { type Currency, type CurrencyIRI, getAllCurrencies, getCurrency } from '@/modules/money';
import FormSelect from '@/components/forms/FormSelect';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { createOnChange, createValue, type OnChange, type Value } from '@/utils/forms';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';

type Option = {
    value: Currency;
    label: string;
};

function currencyToOption(currency: Currency, t: TFunction, isCompact: boolean): Option {
    return {
        value: currency,
        label: isCompact ? currency.label : t(`currencies.${currency.code}`),
    };
}

type CurrencySelectProps<IsMulti extends boolean> = Readonly<{
    value: Value<Currency, IsMulti>;
    onChange: OnChange<Currency, IsMulti>;
    options?: Currency[];
    isMulti?: IsMulti;
    className?: string;
}>;

// TODO - there is a select first and plain select later, but in the TaxRateSelect it is reversed ...
export default function CurrencySelect<IsMulti extends boolean = false>({ value, onChange, options, isMulti, className }: CurrencySelectProps<IsMulti>) {
    const { t } = useTranslation('common', { keyPrefix: 'select' });
    const innerOptions = useMemo(() => {
        return (options ?? getAllCurrencies()).map(currency => currencyToOption(currency, t, false));
    }, [ options, t ]);

    const handleOnChange = useMemo(() => createOnChange(
        (option: Option) => option.value,
        isMulti,
        onChange, 
    ), [ isMulti, onChange ]);

    const isCondensed = useMemo(() => !!className?.includes('rs-condensed'), [ className ]);

    const innerValue = useMemo(() => createValue(
        value => currencyToOption(value, t, isCondensed),
        isMulti,
        value,
    ), [ isMulti, value, t, isCondensed ]);

    return (
        <FormSelect
            placeholder={t('currency-placeholder')}
            options={innerOptions}
            value={innerValue === undefined ? null : innerValue}
            onChange={handleOnChange}
            isMulti={isMulti}
            className={clsx('sh-currency-select', className)}
        />
    );
}

type PlainCurrencySelectProps<IsMulti extends boolean> = Readonly<{
    iri: Value<CurrencyIRI, IsMulti>;
    onChange: OnChange<CurrencyIRI, IsMulti>;
    options?: Currency[];
    isMulti?: IsMulti;
    className?: string;
}>;

function PlainCurrencySelect<IsMulti extends boolean = false>({ iri, onChange, options, isMulti, className }: PlainCurrencySelectProps<IsMulti>) {
    const handleOnChange = useMemo(() => createOnChange(
        (currency: Currency) => currency.toIRI(),
        isMulti,
        onChange,
    ), [ isMulti, onChange ]);

    const innerIri = useMemo(() => createValue(
        getCurrency,
        isMulti,
        iri,
    ), [ isMulti, iri ]);

    return (
        <CurrencySelect
            value={innerIri}
            onChange={handleOnChange}
            options={options}
            isMulti={isMulti}
            className={className}
        />
    );
}

type ControlledCurrencySelectProps<TFieldValues extends FieldValues, IsMulti extends boolean> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    options?: Currency[];
    isMulti?: IsMulti;
    className?: string;
};

export function ControlledCurrencySelect<TFieldValues extends FieldValues, IsMulti extends boolean>({ control, name, options, isMulti, className }: ControlledCurrencySelectProps<TFieldValues, IsMulti>) {
    const InnerSelect = useCallback(({ field }: { field: { value: Value<CurrencyIRI, IsMulti>, onChange: OnChange<CurrencyIRI, IsMulti> } }) => {
        return (
            <PlainCurrencySelect
                iri={field.value}
                onChange={field.onChange}
                options={options}
                isMulti={isMulti}
                className={className}
            />
        );
    }, [ options, isMulti, className ]);
    
    return (
        <Controller
            control={control as Control<FieldValues>}
            name={name}
            render={InnerSelect}
        />
    );
}
