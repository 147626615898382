import { createDELETE, createGET, createPOST, createPUT, transformFixed } from '../rawApiObject';
import { type IRI, type Id } from '@/types/Id';
import type { NotificationToServer, OrderEditToServer, OrderFromServer, OrderInfoFromServer, OrderState, OrderToServer, SchedulerOrderInfoFromServer, TransitionName } from '@/types/orders/Order';
import type { HydraCollection } from '@/types/api/result';
import { type ClientFromServer } from '@/types/Client';
import { type SchedulerProductItemFromServer, type MasterProductItemFromServer } from '@/types/orders/OrderItem';
import { type SortOrder } from '@/utils/common';

type OrderTransitionToServer = {
    transition: TransitionName;
};

type OrderSendNotificationToServer = {
    order: IRI;
    notification: NotificationToServer;
};

export const order = {
    get: createGET<{ id: string }, OrderFromServer>(
        u => `/orders/${u.id}`,
    ),
    getAll: createGET<HydraCollection<OrderInfoFromServer | SchedulerOrderInfoFromServer>, OrderQueryParams>(
        '/orders',
    ),
    create: createPOST<{ orders: OrderFromServer[], newClients: ClientFromServer[] }, OrderToServer>(
        '/orders/create',
    ),
    preview: transformFixed(
        createPOST<Blob, OrderToServer>(
            '/orders/preview',
        ),
        (data: OrderToServer) => data,
        config => ({ ...config, responseType: 'blob' }),
    ),
    update: createPUT<{ id: Id }, OrderFromServer, OrderEditToServer>(
        u => `${u.id.toIRI()}`,
    ),
    transition: createPUT<{ id: Id }, OrderFromServer, OrderTransitionToServer>(
        u => `${u.id.toIRI()}`,
    ),
    delete: createDELETE<{ id: Id }, void>(
        u => `${u.id.toIRI()}`,
    ),
    sendNotification: createPOST<OrderFromServer, OrderSendNotificationToServer>(
        '/orders/send-created-notification',
    ),
    getProductItems: createGET<HydraCollection<MasterProductItemFromServer | SchedulerProductItemFromServer>, ProductItemQueryParams>(
        '/product-items',
    ),
    export: createPOST<Blob, ExportOrders>(
        '/orders/export',
    ),
    countExport: createPOST<{ count: number }, ExportOrders>(
        '/orders/export-count',
    ),
};

export enum ExportType {
    PdfZip = 'pdf-zip',
    Pdf = 'pdf',
    Csv = 'csv',
    Isdoc = 'isdoc',
}

export type ExportOrders = {
    format: ExportType;
    states?: OrderState[];
    /** Client IRI */
    clients?: IRI[];
    /** DateTime UTC ISO */
    createdAfter?: string;
    /** DateTime UTC ISO */
    createdStrictlyBefore?: string;
};

export type OrderQueryParams = {
    state?: unknown;
    client?: unknown;
    'supplier.invoicingProfile'?: unknown;
    'createdAt[after]'?: unknown;
    'createdAt[strictly_before]'?: unknown;
    orderBy?: OrderOrderBy;
    page?: number;
};

type ProductItemQueryParams = {
    guestId?: string;
    onlyActive?: boolean;
};

export type OrderOrderBy = {
    createdAt?: SortOrder;
    index?: SortOrder;
};
