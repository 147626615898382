import { type GoogleUserInfoFromServer } from '@/types/GoogleUser';
import googleApiObject from './rawApiObject';
import type { ClientContactFromServer } from '@/types/Client';
import type { GoogleCalendarEventsFromServer, GoogleCalendarFromServer, GoogleEventFromServer } from '@/types/calendar/Google';
import { type DateTime } from 'luxon';
import { type Result, DataResultSuccess } from '@/types/api/result';

function getUserInfo(signal?: AbortSignal) {
    return googleApiObject.GET<GoogleUserInfoFromServer>('https://www.googleapis.com/oauth2/v1/userinfo', signal);
}

function getContacts(signal?: AbortSignal) {
    return googleApiObject.GET<{ otherContacts: ClientContactFromServer[], totalSize: number }>('https://people.googleapis.com/v1/otherContacts', signal, {
        readMask: 'emailAddresses,names',
        pageSize: 1000, // The maximum allowed by the API.
    });
}

function searchContacts(query: string, signal?: AbortSignal) {
    return googleApiObject.GET<{ results?: { person: ClientContactFromServer }[] }>('https://people.googleapis.com/v1/otherContacts:search', signal, {
        query,
        readMask: 'emailAddresses,names',
    });
}

function getCalendars(signal?: AbortSignal) {
    return googleApiObject.GET<{ items: GoogleCalendarFromServer[] }>('https://www.googleapis.com/calendar/v3/users/me/calendarList', signal);
}

async function getCalendarEvents(start: DateTime, end: DateTime, calendarId: string, signal?: AbortSignal): Promise<Result<GoogleEventFromServer[]>> {
    const output: GoogleEventFromServer[] = [];
    
    let nextPageToken: string | undefined;
    do {
        const response = await getCalendarEventsPage(start, end, calendarId, signal, nextPageToken);
        if (!response.status)
            return response;
    
        output.push(...response.data.items);
        nextPageToken = response.data.nextPageToken;
    } while (nextPageToken);

    return DataResultSuccess(output);
}

function getCalendarEventsPage(start: DateTime, end: DateTime, calendarId: string, signal?: AbortSignal, pageToken?: string) {
    const encodedId = encodeURIComponent(calendarId);

    return googleApiObject.GET<GoogleCalendarEventsFromServer>(`https://www.googleapis.com/calendar/v3/calendars/${encodedId}/events`, signal, {
        timeMin: start.toISO(),
        timeMax: end.toISO(),
        singleEvents: true,
        pageToken,
        maxResults: 2500, // The maximum allowed by the API.
    });
}

export const googleApi = {
    authorizer: googleApiObject.authorizer,
    prepareAbort: () => googleApiObject.prepareAbort(),
    getUserInfo,
    getContacts,
    searchContacts,
    getCalendars,
    getCalendarEvents,
};
