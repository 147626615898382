import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@/context/UserProvider';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { api } from '@/utils/api/backend';
import { MILLISECONDS_IN_SECOND, navigateNewTab } from '@/utils/common';
import { Team } from '@/types/Team';

type NoStripeModalProps = Readonly<{
    show: boolean;
    onClose: (isSuccess: boolean) => void;
}>;

export function NoStripeModal({ show, onClose }: NoStripeModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'noStripeModal' });
    const { team, setTeam } = useUser();
    const [ connectionUrl, setConnectionUrl ] = useState<string>();
    const [ isConnecting, setIsConnecting ] = useState(false);

    const fetchConnectionUrl = useCallback(async (signal: AbortSignal) => {
        const response = await api.team.connectStripe({}, { signal });
        if (response.status)
            setConnectionUrl(response.data.onboardingUrl);
    }, []);

    useEffect(() => {
        // The link should be fetched in two cases:
        // - The modal was just opened (and wasn't opened before).
        // - The user clicked on the new-link-button.
        if (!show || connectionUrl || isConnecting)
            return;

        const [ signal, abort ] = api.prepareAbort();
        fetchConnectionUrl(signal);

        return abort;
    }, [ show, connectionUrl, isConnecting, fetchConnectionUrl ]);

    const connectToStripe = useCallback(() => {
        if (!connectionUrl)
            return;

        setIsConnecting(true);
        setConnectionUrl(undefined);
        navigateNewTab(connectionUrl);
    }, [ connectionUrl ]);

    const fetchIsTeamConnected = useCallback(async (signal: AbortSignal) => {
        const response = await api.team.get(team, signal);
        if (!response.status)
            return;

        const newTeam = Team.fromServer(response.data);
        if (newTeam.isStripeConnected) {
            setTeam(newTeam);
            setIsConnecting(false);
            onClose(true);
        }
    }, [ team.id, onClose, setTeam ]);

    useEffect(() => {
        if (!isConnecting)
            return;

        const [ signal, abort ] = api.prepareAbort();
        const interval = setInterval(() => fetchIsTeamConnected(signal), MILLISECONDS_IN_SECOND);

        return () => {
            clearInterval(interval);
            abort();
        };
    }, [ isConnecting, fetchIsTeamConnected ]);

    return (
        <Modal size='lg' show={show} onHide={() => onClose(false)}>
            <Modal.Header>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='sh-description-no-border'>{t('text')}</div>
                <div className='mt-5'>
                    {isConnecting ? (<>
                        <div>
                            {t('wait-for-connection-text')}
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <span className='me-3'>{t('new-link-text')}</span>
                            <Button onClick={() => setIsConnecting(false)} variant='outline-primary' className='compact'>
                                {t('new-link-button')}
                            </Button>
                        </div>
                    </>) : (connectionUrl ? (
                        <Button onClick={connectToStripe}>
                            {t('connect-stripe-button')}
                        </Button>
                    ) : (
                        <div style={{ height: '42px' }} className='d-flex align-items-center'>
                            <span className='me-3'>{t('wait-for-url-text')}</span>
                            <Spinner size='sm' variant='dark' animation='border' />
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
}
