import React, { useMemo, type ReactNode } from 'react';
import { type Product } from '@/types/Product';
import { MoneyDisplay, Skeleton } from '../common';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { type TFunction } from 'i18next';
import { EditButton } from '../forms/buttons';
import { CalendarPlusIcon, CartIcon, PlusIcon } from '../icons';
import { Button } from 'react-bootstrap';
import { type GenericProductItem } from '@/types/orders/ProductOrderItem';
import { type Money } from '@/modules/money';
import DateTimeDisplay from '../common/DateTimeDisplay';
import { getRandomSkeletonArray } from '@/utils/math';
import ClientIconLink from '../client/ClientIconLink';
import { type Id } from '@/types/Id';

type ProductTileProps = Readonly<{
    product: Product;
    on: (product: Product, action: 'edit' | 'sell') => void;
    isActive?: boolean;
}>;

export function ProductTile({ product, on, isActive }: ProductTileProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productDisplay' });

    return (
        <div
            className={clsx('sh-product-tile d-flex flex-column bg-white rounded-4 sh-hover-trigger', isActive && 'sh-hover-trigger-triggered')}
        >
            <div className='d-flex'>
                <span className='fs-3' style={{ height: '22px' }}>
                    {product.icon}
                </span>
                <div className='flex-grow-1' />
                <EditButton aria={t('edit-product-button')} onClick={() => on(product, 'edit')} className='trim-end text-secondary sh-hover-target' />
            </div>
            <h3 className='fs-5 lha-3 mt-3 text-truncate' style={{ marginBottom: '12px' }}>{product.title}</h3>
            {productStats(product, t, 'd-flex flex-column align-items-top gap-2')}
            <div className='flex-grow-1' />
            <div className='d-flex'>
                <ProductPriceDisplay price={product.price} />
                <div className='flex-grow-1' />
                <Button className='compact px-2 bg-black text-white sh-hover-target' onClick={() => on(product, 'sell')}>
                    <CartIcon size={18} className='me-2' />{t('sell-product-button')}
                </Button>
            </div>
        </div>
    );
}

type NewProductTileProps = Readonly<{
    onClick: () => void;
}>;

export function NewProductTile({ onClick }: NewProductTileProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productDisplay' });

    return (
        <button
            onClick={onClick}
            className='sh-product-tile rounded-4 bg-transparent d-flex flex-column border border-2 border-dashed sh-primary-border-on-hover'
        >
            <div className='d-flex align-items-center justify-content-center rounded-circle bg-primary' style={{ width: '24px', height: '24px' }}>
                <PlusIcon size={20} className='text-white'/>
            </div>
            <div className='mt-3 text-primary fw-medium fs-5'>{t('create-product-button')}</div>
        </button>
    );
}

type ProductPriceDisplayProps = Readonly<{
    price: Money;
}>;

export function ProductPriceDisplay({ price }: ProductPriceDisplayProps) {
    return (
        <span className='py-2 px-3 rounded' style={{ height: '32px', backgroundColor: '#F5F5F5' }}>
            <MoneyDisplay money={price} />
        </span>
    );
}

type ProductItemTileProps = Readonly<{
    productItem: GenericProductItem;
    onSchedule?: (productItem: GenericProductItem) => void;
    isActive?: boolean;
    hideClientId?: Id;
}>;

export function ProductItemTile({ productItem, onSchedule, isActive, hideClientId }: ProductItemTileProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productDisplay' });
    const toSchedule = productItem.sessionsCount - productItem.scheduledCount;

    return (
        <div
            className={clsx(
                'sh-product-item-tile d-flex flex-column rounded-4 sh-hover-trigger',
                isActive && 'sh-hover-trigger-triggered',
                productItem.isCompleted ? 'bg-muted' : 'bg-white',
            )}
        >
            <span className='fs-3' style={{ height: '22px' }}>
                {productItem.icon}
            </span>
            <h3 className='fs-5 lha-3 mt-3 text-truncate' style={{ marginBottom: '12px' }}>{productItem.title}</h3>
            {productStats(productItem, t, 'd-flex flex-column align-items-top gap-2')}
            {!productItem.isCompleted && (<>
                <div className='sh-divider-light flex-shrink-0' style={{ margin: '12px 0px' }} />
                <div className='d-flex flex-column'>
                    {toSchedule > 0 ? (
                        <span>
                            <span className='fw-medium me-1'>{toSchedule}</span>
                            <span className='fs-small text-muted'>{t('to-schedule-count-label', { count: toSchedule })}</span>
                        </span>
                    ) : (
                        <span className='fs-small text-muted'>{t('all-scheduled-label')}</span>
                    )}
                    <span className='mt-2'>
                        <span className='fs-small text-muted'>{t('created-at-label')}</span>{' '}<DateTimeDisplay dateTime={productItem.createdAt} date />
                    </span>
                </div>
            </>)}
            {!productItem.guest.id.equals(hideClientId) && (
                <div className='mt-2'>
                    <ClientIconLink client={productItem.guest} />
                </div>
            )}
            <div className='flex-grow-1' />
            <div className='d-flex'>
                {productItem.price && (
                    <ProductPriceDisplay price={productItem.price} />
                )}
                <div className='flex-grow-1' />
                {toSchedule > 0 && (
                    <Button className='compact px-2 bg-black text-white sh-hover-target' onClick={() => onSchedule?.(productItem)}>
                        <CalendarPlusIcon size={18} className='me-2' />{t('schedule-item-button')}
                    </Button>
                )}
            </div>
        </div>
    );
}

type ProductBarProps = Readonly<{
    product: Product;
    rightItems?: ReactNode;
    className?: string;
}>;

export function ProductBar({ product, rightItems, className }: ProductBarProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productDisplay' });

    return (
        <div className={clsx('p-3 d-flex align-items-center gap-2 bg-white rounded shadow-sm', className)}>
            <div className='text-truncate'>
                <div className='d-flex align-items-center gap-2 lha-3'>
                    {product.icon && (
                        <span className='fs-3' style={{ height: '22px' }}>{product.icon}</span>
                    )}
                    <h3 className='fs-base mb-0 lha-3 text-truncate'>{product.title}</h3>
                </div>
                {productStats(product, t, 'd-flex gap-3 mt-2')}
            </div>
            <div className='flex-grow-1' />
            {rightItems}
        </div>
    );
}

type ProductForStats = {
    sessionsDuration: number;
    sessionsCount: number;
};

function productStats(product: ProductForStats, t: TFunction, className: string): ReactNode {
    return (
        <div className={className}>
            <span>
                <span className='fw-medium me-1'>{product.sessionsDuration}</span>
                <span className='fs-small text-muted'>{t('minutes-count-label', { count: product.sessionsDuration })}</span>
            </span>
            <span>
                <span className='fw-medium me-1'>{product.sessionsCount}</span>
                <span className='fs-small text-muted'>{t('sessions-count-label', { count: product.sessionsCount })}</span>
            </span>
        </div>
    );
}

type ProductSkeletonsProps = Readonly<{
    variant: 'product' | 'product-item';
}>;

export function ProductTileSkeletons({ variant }: ProductSkeletonsProps) {
    const skeletonArray = useMemo(() => getRandomSkeletonArray(1, 4), []);

    return (<>
        {skeletonArray.map((key) => (
            <ProductTileSkeleton key={key} variant={variant} />
        ))}
    </>);
}

function ProductTileSkeleton({ variant }: ProductSkeletonsProps) {
    const height = variant === 'product' ? 240 : 285;

    return (
        <div className={`sh-${variant}-tile p-0`}>
            <Skeleton className='rounded-4' height={height} />
        </div>
    );
}
