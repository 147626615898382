import { type Entity, Id, type OmitId, type IRI, type BlankId, type BlankNode } from '@/types/Id';
import { Address, addressToServer, type AddressFromServer, type AddressToServer, type EditableAddress } from '@/types/Address';
import { getTaxRate, type TaxRate, type TaxRateFromServer, type TaxRateIRI } from '@/modules/money';
import type { LocaleCode } from './i18n';
import { stringOrUndefinedToServer } from '@/utils/common';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Common

type CommonSettingsFromServer = {
    '@id': IRI;
    hideEmailOnInvoice: boolean;
    /** This email overrides the client email on the invoice. */
    email?: string;
    address?: AddressFromServer;
    cin?: string;
    tin?: string;
};

abstract class CommonSettings implements Entity {
    protected constructor(
        readonly id: Id,
        readonly hideEmailOnInvoice: boolean,
        /** This email overrides the client email on the invoice. */
        readonly email?: string,
        readonly address?: Address,
        readonly cin?: string,
        readonly tin?: string,
    ) {}
}

type CommonSettingsToServer = Omit<OmitId<CommonSettingsFromServer>, 'address'> & {
    address: AddressToServer;
};


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// File

export type FileToServer = {
    /** base64-encoded, see fileToBase64 */
    data: string;
    /** mime-type, eg. image/png */
    type: string;
    name: string;
}

type FileFromServer = {
    '@id': IRI;
    originalName: string;
    hashName: string;
    type: string;
    size: number;
}

class File implements Entity {
    private constructor(
        readonly id: Id,
        readonly originalName: string,
        readonly hashName: string,
        readonly type: string,
        readonly size: number,
        readonly url: string,
    ) {}

    public static fromServer(input: FileFromServer) {
        return new File(
            Id.fromIRI(input['@id']),
            input.originalName,
            input.hashName,
            input.type,
            input.size,
            `/uploads/${input.hashName.slice(0, 2)}/${input.hashName}`,
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Supplier Settings

export type GeneralSupplierSettings = CommonSettingsToServer & {
    vat: TaxRateIRI;
    title: string;
    locale: LocaleCode;
    invoicingTemplate: IRI;
    dueDays: number;
    legalName?: string;
};

type PersonalizationSettings = {
    logoFile: FileFromServer | null;
    condensedInvoice: boolean;
    header?: string;
    footer?: string;
    customKey1?: string;
    customValue1?: string;
    customKey2?: string;
    customValue2?: string;
};

export type PersonalizationSettingsToServer = Omit<PersonalizationSettings, 'logoFile'> & { logo: FileToServer | null | undefined };

export type InvoicingProfileFromServer = CommonSettingsFromServer & {
    // There is this *feature* on server that it is practically impossible to return tax rate as an IRI when using the start route.
    vat: TaxRateIRI | TaxRateFromServer;
    title: string;
    locale: LocaleCode;
    invoicingTemplate: IRI;
    dueDays: number;
    legalName?: string;
} & PersonalizationSettings;

export class InvoicingProfile extends CommonSettings {
    private constructor(
        id: Id,
        readonly vat: TaxRate,
        readonly title: string,
        readonly locale: LocaleCode,
        readonly templateId: Id,
        readonly dueDays: number,
        readonly legalName: string | undefined,
        hideEmailOnInvoice: boolean,
        email: string | undefined,
        address: Address | undefined,
        cin: string | undefined,
        tin: string | undefined,
        readonly logoFile: File | null,
        readonly isCondensedInvoice: boolean,
        readonly header?: string,
        readonly footer?: string,
        readonly customKey1?: string,
        readonly customValue1?: string,
        readonly customKey2?: string,
        readonly customValue2?: string,
    ) {
        super(id, hideEmailOnInvoice, email, address, cin, tin);
    }

    public static fromServer(input: InvoicingProfileFromServer): InvoicingProfile {
        return new InvoicingProfile(
            Id.fromIRI(input['@id']),
            (typeof input.vat === 'object' && '@id' in input.vat)
                ? getTaxRate(input.vat['@id'])
                : getTaxRate(input.vat),
            input.title,
            input.locale,
            Id.fromIRI(input.invoicingTemplate),
            input.dueDays,
            input.legalName,
            input.hideEmailOnInvoice,
            input.email,
            input.address ? Address.fromServer(input.address) : undefined,
            input.cin,
            input.tin,
            input.logoFile && File.fromServer(input.logoFile),
            input.condensedInvoice,
            input.header,
            input.footer,
            input.customKey1,
            input.customValue1,
            input.customKey2,
            input.customValue2,
        );
    }

    get isTaxPayer(): boolean {
        return !!this.tin || !this.vat.isZero;
    }
}

export type InvoicingProfileInit = {
    general: GeneralSupplierSettings;
    custom: PersonalizationSettingsToServer;
};

export type InvoicingProfileUpdate = GeneralSupplierSettings | PersonalizationSettingsToServer;

export function isTaxPayer(profiles: InvoicingProfile[]): boolean {
    return profiles.some(p => p.isTaxPayer);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Subscriber Settings

export type SubscriberSettingsFromServer = CommonSettingsFromServer;

export type SubscriberSettingsToServer = CommonSettingsToServer & {
    name: string;
};

export class SubscriberSettings extends CommonSettings {
    private constructor(
        id: Id,
        readonly name: string,
        hideEmailOnInvoice: boolean,
        email?: string,
        address?: Address,
        cin?: string,
        tin?: string,
    ) {
        super(id, hideEmailOnInvoice, email, address, cin, tin);
    }

    public static fromServer(input: SubscriberSettingsFromServer, name: string): SubscriberSettings {
        return new SubscriberSettings(
            Id.fromIRI(input['@id']),
            name,
            input.hideEmailOnInvoice,
            input.email,
            input.address ? Address.fromServer(input.address) : undefined,
            input.cin,
            input.tin,
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Identity

export type InvoicingIdentityFromServer = {
    '@id': BlankId;
    name: string;
    email?: string;
    address?: AddressFromServer;
    cin?: string;
    tin?: string;
};

export class InvoicingIdentity implements BlankNode {
    private constructor(
        readonly id: BlankId,
        readonly name: string,
        readonly email?: string,
        readonly address?: Address,
        readonly cin?: string,
        readonly tin?: string,
    ) {}

    public static fromServer(input: InvoicingIdentityFromServer): InvoicingIdentity {
        return new InvoicingIdentity(
            input['@id'],
            input.name,
            input.email,
            'address' in input && input.address ? Address.fromServer(input.address) : undefined,
            'cin' in input ? input.cin : undefined,
            'tin' in input ? input.tin : undefined,
        );
    }
}

export type InvoicingIdentityUpdateToServer = Omit<InvoicingIdentityFromServer, '@id' | 'address'> & {
    address: AddressToServer;
};

export type InvoicingIdentityUpdate = {
    readonly name: string;
    readonly email: string;
    readonly address: EditableAddress;
    readonly cin: string;
    readonly tin: string;
};

export function invoicingIdentityUpdateToServer(input: InvoicingIdentityUpdate): InvoicingIdentityUpdateToServer {
    return {
        name: input.name,
        email: stringOrUndefinedToServer(input.email),
        address: addressToServer(input.address),
        cin: stringOrUndefinedToServer(input.cin),
        tin: stringOrUndefinedToServer(input.tin),
    };
}

export type InvoicingOverrideFromServer = {
    '@id': IRI;
    condensedInvoice?: boolean;
    header?: string;
    footer?: string;
    customKey1?: string;
    customValue1?: string;
    customKey2?: string;
    customValue2?: string;
    locale?: string;
}

export class InvoicingOverride implements Entity {
    private constructor(
        readonly id: Id,
        readonly isInvoicingOverride?: boolean,
        readonly header?: string,
        readonly footer?: string,
        readonly customKey1?: string,
        readonly customValue1?: string,
        readonly customKey2?: string,
        readonly customValue2?: string,
        readonly locale?: LocaleCode,
    ) {}

    public static fromServer(input: InvoicingOverrideFromServer): InvoicingOverride {
        return new InvoicingOverride(
            Id.fromIRI(input['@id']),
            input.condensedInvoice,
            input.header,
            input.footer,
            input.customKey1,
            input.customValue1,
            input.customKey2,
            input.customValue2,
            input.locale,
        );
    }
}

/**
 * We don't use undefined values because we have to distinguish between undefined (the value shouldn't override the value from the invoicing profile) and an empty string (the value should override, i.e., delete the profile's value).
 * The dueDays is an exception because an invalid number is treated as no override.
 */
export type InvoicingOverrideToServer = Required<Omit<OmitId<InvoicingOverrideFromServer>, 'dueDays'>> & {
    // The dueDays isn't included in the InvoicingOverrideFromServer, because it's stored on the client.
    // However, it's a part of the invoicing overrides (logically), so it's updated with them.
    dueDays: number | undefined;
};
