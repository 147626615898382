import { useEffect, useRef, useState } from 'react';
import { api } from '@/utils/api/backend';
import { type UserFormData, type RegisterError } from '@/components/auth/RegisterUserForm';
import { type PersonFormData } from '@/components/auth/RegisterPersonForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from '@/router';
import useAuth from '@/context/AuthProvider';
import { getAppUserDefaults } from '@/types/i18n';
import { fetchCurrenciesAndTaxRates } from '../../pages/auth/Register';
import { getDefaultTaxRate } from '@/modules/money';

export enum RegisterState {
    UserForm = 'UserForm',
    PersonForm = 'PersonForm',
}

export function useRegisterEmail() {
    const { t } = useTranslation('common', { keyPrefix: 'error' });
    const [ isFetching, setIsFetching ] = useState(false);
    const [ userData, setUserData ] = useState<UserFormData>();
    const [ state, setState ] = useState(RegisterState.UserForm);

    const metadataPromiseRef = useRef<Promise<boolean>>();
    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        metadataPromiseRef.current = fetchCurrenciesAndTaxRates(signal);

        return abort;
    }, []);

    // We have to check if the email doesn't exist already. The errors are then passed to the form so it can display them.
    async function submitUserForm(data: UserFormData): Promise<RegisterError[]> {
        if (userData && userData.email === data.email) {
            // We have already checked the email before, so we can skip this step an go directly to the next form.
            setUserData(data);
            setState(RegisterState.PersonForm);
            return [];
        }

        setIsFetching(true);
        const response = await api.appUser.registerValidate({ email: data.email });
        setIsFetching(false);
        if (!response.status)
            // TODO handle error
            return [];

        const errors = response.data.errors.map(error => ({
            name: error.property,
            error: {
                type: 'custom',
                message: t(error.type),
            },
        }));

        if (errors.length !== 0)
            return errors;

        // Everything should be fine now so we can finally continue with the next step.

        setUserData(data);
        setState(RegisterState.PersonForm);

        return [];
    }

    const { auth } = useAuth();
    const navigate = useNavigate();

    async function submitPersonForm(personData: PersonFormData) {
        if (!userData)
            return;

        setIsFetching(true);
        const metadataSuccess = await metadataPromiseRef.current;
        if (!metadataSuccess) {
            setIsFetching(false);
            // TODO: handle error?
            return;
        }
    
        const result = await auth.register({
            type: 'email',
            ...userData,
            ...personData,
            ...getAppUserDefaults(),
            vat: getDefaultTaxRate().toIRI(),
        });
        setIsFetching(false);
    
        if (!result)
            // TODO handle error
            return;
    
        navigate(routes.root);
    }

    return {
        state,
        userData,
        isFetching,
        submitUserForm,
        submitPersonForm,
        onBack: () => setState(RegisterState.UserForm),
    };
}