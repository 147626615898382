import { compareCurrencies, priceFromServer } from '@/modules/money';
import { Query, capitalize, getNameFromEmail, getShortcutFromString } from '@/utils/common';
import { CreditAccount, type CreditAccountFromServer } from './CreditAccount';
import { type ClientLocaleCode, type CountryCode, type LocaleCode, type TimezoneCode } from './i18n';
import { Id, type OmitId, type IRI, type Entity, type BlankId, type BlankNode } from './Id';
import { getPersonName } from './Person';
import { InvoicingOverride, SubscriberSettings, type InvoicingOverrideFromServer, type SubscriberSettingsFromServer, type SubscriberSettingsToServer, type InvoicingOverrideToServer } from './Invoicing';
import { canonizeEmail } from '@/utils/forms';
import { Pricing, type PricingFromServer, type PricingToServer } from './TeamSettings';
import { type AppUser } from './AppUser';

export type ClientInfoFromServer = {
    '@id': IRI;
    email: string;
    name: string;
    phoneNumber?: string;
    state: ClientState;
    deleted?: boolean;
    invoicingProfile: IRI;
    locale: ClientLocaleCode;
    dueDays?: number;
    pricings: PricingFromServer[];
    tags: IRI[];
};

export class ClientInfo implements Entity {
    readonly query: Query;
    readonly shortcut: string;

    protected constructor(
        readonly id: Id,
        readonly email: string,
        readonly name: string,
        readonly phoneNumber: string | undefined,
        readonly state: ClientState,
        readonly isDeleted: boolean,
        readonly invoicingProfileId: Id,
        readonly locale: ClientLocaleCode,
        /** If defined, this value overrides the value from the invoicing profile. */
        readonly dueDays: number | undefined,
        readonly pricings: Pricing[],
        readonly tagIds: Id[],
    ) {
        this.query = new Query(...name.split(' '), getNameFromEmail(email));
        this.shortcut = computeClientShortcut(name, email);
    }

    static fromServer(input: ClientInfoFromServer): ClientInfo {
        return new ClientInfo(
            Id.fromIRI(input['@id']),
            input.email,
            input.name,
            input.phoneNumber,
            input.state,
            !!input.deleted,
            Id.fromIRI(input.invoicingProfile),
            input.locale,
            input.dueDays,
            input.pricings.map(Pricing.fromServer),
            input.tags.map(Id.fromIRI),
        );
    }

    static createExample(): ClientInfo {
        return new ClientInfo(Id.createExample('clients'), '', '', undefined, ClientState.Active, false, Id.createExample('invoicing-profiles'), 'en-US', 14, [], []);
    }
}

export enum ClientState {
    Lead = 'lead',
    Active = 'active',
    Inactive = 'inactive',
}

export function computeClientShortcut(name: string, email: string): string {
    if (name.length < 2)
        return capitalize(email.slice(0, 2));

    return getShortcutFromString(name);
}


function creditAccountsFromServer(input: CreditAccountFromServer[]): CreditAccount[] {
    const accounts = input.map(creditAccount => CreditAccount.fromServer((creditAccount)));
    sortCreditAccounts(accounts);
    return accounts;
}

export type ClientFromServer = ClientInfoFromServer & {
    timezone: TimezoneCode;
    country: CountryCode;
    note?: string;
    subscriberSettings: SubscriberSettingsFromServer;
    invoicingOverride: InvoicingOverrideFromServer;
    creditAccounts: CreditAccountFromServer[];
    accesses: ClientAccessFromServer[];
};

export class Client extends ClientInfo {
    private constructor(
        id: Id,
        email: string,
        name: string,
        phoneNumber: string | undefined,
        state: ClientState,
        deleted: boolean,
        invoicingProfileId: Id,
        locale: ClientLocaleCode,
        dueDays: number | undefined,
        pricings: Pricing[],
        tagIds: Id[],
        readonly creditAccounts: CreditAccount[],
        readonly timezone: TimezoneCode,
        readonly country: CountryCode,
        readonly note: string | undefined,
        readonly subscriberSettings: SubscriberSettings,
        readonly invoicingOverride: InvoicingOverride,
        readonly accesses: ClientAccess[],
    ) {
        super(id, email, name, phoneNumber, state, deleted, invoicingProfileId, locale, dueDays, pricings, tagIds);
    }

    static fromServer(input: ClientFromServer): Client {
        return new Client(
            Id.fromIRI(input['@id']),
            input.email,
            input.name,
            input.phoneNumber,
            input.state,
            !!input.deleted,
            Id.fromIRI(input.invoicingProfile),
            input.locale,
            input.dueDays,
            input.pricings.map(Pricing.fromServer),
            input.tags.map(Id.fromIRI),
            creditAccountsFromServer(input.creditAccounts),
            input.timezone,
            input.country,
            input.note,
            SubscriberSettings.fromServer(input.subscriberSettings, input.name),
            InvoicingOverride.fromServer(input.invoicingOverride),
            input.accesses.map(ClientAccess.fromServer),
        );
    }

    getAccess(appUser: AppUser): ClientAccess | undefined {
        return this.accesses.find(a => a.appUserId.equals(appUser.id));
    }

    updateFromInfo(info: ClientInfo): Client {
        return new Client(
            info.id,
            info.email,
            info.name,
            info.phoneNumber,
            info.state,
            info.isDeleted,
            info.invoicingProfileId,
            info.locale,
            info.dueDays,
            info.pricings,
            info.tagIds,
            this.creditAccounts,
            this.timezone,
            this.country,
            this.note,
            this.subscriberSettings,
            this.invoicingOverride,
            this.accesses,
        );
    }

    setCreditAccounts(creditAccounts: CreditAccount[]): Client {
        sortCreditAccounts(creditAccounts);

        return new Client(
            this.id,
            this.email,
            this.name,
            this.phoneNumber,
            this.state,
            this.isDeleted,
            this.invoicingProfileId,
            this.locale,
            this.dueDays,
            this.pricings,
            this.tagIds,
            creditAccounts,
            this.timezone,
            this.country,
            this.note,
            this.subscriberSettings,
            this.invoicingOverride,
            this.accesses,
        );
    }
}

/** We want the accounts to always appear in the same order. */
function sortCreditAccounts(accounts: CreditAccount[]): CreditAccount[] {
    return accounts.sort((a, b) => compareCurrencies(a.totalAmount.currency, b.totalAmount.currency));
}

export type ClientGeneralInit = {
    email: string;
    name: string;
    phoneNumber?: string;
    note?: string;
};
export type ClientUpdate = ClientGeneralUpdate | ClientInvoicingUpdate | ClientPreferencesUpdate | ClientStateUpdate;

export type ClientGeneralUpdate = Partial<ClientGeneralInit> & {
    pricings?: PricingToServer[];
};

export type ClientPreferencesInit = {
    timezone: TimezoneCode;
    locale: LocaleCode;
    country: CountryCode;
    invoicingProfile: IRI;
};

export type ClientPreferencesUpdate = Partial<ClientPreferencesInit>;

export type ClientInvoicingUpdate = {
    subscriber: SubscriberSettingsToServer;
    override: InvoicingOverrideToServer;
};

export type ClientStateUpdate = {
    state: ClientState;
};

export type ClientInit = Omit<OmitId<ClientFromServer>, 'appUser' | 'subscriberSettings' | 'invoicingOverride' | 'creditAccounts' | 'pricings' | 'invoicingProfile' | 'accesses' | 'state' | 'tags'> & {
    invoiceAccount?: OmitId<SubscriberSettingsToServer>;
    invoicingProfile?: IRI;
};

export type ClientContactFromServer = {
    names?: {
        displayName?: string;
        familyName?: string;
        givenName?: string;
    }[];
    emailAddresses?: {
        value?: string;
    }[];
};

export class ClientContact {
    readonly query: Query;
    readonly shortcut: string;
    readonly canonicalEmail: string;

    private constructor(
        /** Display email. For identifying purposes use the canonicalEmail instead! */
        readonly email: string,
        readonly name: string,
    ) {
        this.query = new Query(name, getNameFromEmail(email));
        this.shortcut = computeClientShortcut(name, email);
        this.canonicalEmail = canonizeEmail(email);
    }

    static fromServer(input: ClientContactFromServer): ClientContact | undefined {
        const email = !!input.emailAddresses?.length && input.emailAddresses[0].value;
        if (!email)
            return undefined;

        const name = !!input.names?.length && input.names[0] || undefined;

        return new ClientContact(
            email,
            name?.displayName ?? getPersonName({ email, firstName: name?.givenName, lastName: name?.familyName }),
        );
    }

    static fromEmail(email: string): ClientContact {
        return new ClientContact(email, email);
    }
}

export type ClientStatsFromServer = {
    ordersTotal: number;
    ordersUnpaid: number;
    orderCount: number;
    eventsInvoiced: number;
};

export class ClientStats {
    private constructor(
        readonly ordersTotal: number,
        readonly ordersUnpaid: number,
        readonly orderCount: number,
        readonly eventsInvoiced: number,
    ) {
    }

    static fromServer(input: ClientStatsFromServer): ClientStats {
        return new ClientStats(
            priceFromServer(input.ordersTotal),
            priceFromServer(input.ordersUnpaid),
            input.orderCount,
            input.eventsInvoiced,
        );
    }
}

export enum ClientAccessType {
    Owner = 'owner',
    ReadOnly = 'read_only',
}

type ClientAccessFromServer = {
    '@id': BlankId;
    /** AppUser IRI */
    appUser: IRI;
    access: ClientAccessType;
}

export class ClientAccess implements BlankNode {
    private constructor(
        readonly id: BlankId,
        readonly appUserId: Id,
        readonly accessType: ClientAccessType,
    ) {
    }

    static fromServer(input: ClientAccessFromServer): ClientAccess {
        return new ClientAccess(
            input['@id'],
            Id.fromIRI(input.appUser),
            input.access,
        );
    }
}
