import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { type Client, type ClientInvoicingUpdate } from '@/types/Client';
import { SubscriberFormInner, formToOutput as subscriberFormToOutput, type FormDataType as SubscriberFormData, inputToForm as subscriberInputToForm } from '../settings/SubscriberForm';
import { formToOutput as invoicingOverrideFormToOutput, type InvoicingOverrideFormData, inputToForm as invoicingOverrideInputToForm, InvoicingOverrideFormInner } from '../settings/InvoicingOverrideForm';
import { useTranslation } from 'react-i18next';
import { SpoilerButtonRow } from '../common/Collapse';
import { useMaster } from '@/context/UserProvider';

type ClientInvoicingFormProps = Readonly<{
    onSubmit: (output: ClientInvoicingUpdate) => void;
    defaultValue: Client;
    onChange?: (isDirty: boolean) => void;
    saveButton?: React.FC<{ onClick: () => void }>;
}>;

export default function ClientInvoicingForm({ onSubmit, defaultValue, onChange, saveButton }: ClientInvoicingFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'client' });
    const { form, handleSubmit, profile } = useClientInvoicingForm(onSubmit, defaultValue, onChange);
    const [ isShowOverride, setIsShowOverride ] = useState(false);

    return (
        <Form noValidate onSubmit={handleSubmit} className='sh-design mb-4'>
            <SubscriberFormInner
                form={form}
            />
            <SpoilerButtonRow title={t('invoicing-override-toggle-button')} isShow={isShowOverride} setIsShow={setIsShowOverride} className='mt-5' />
            {isShowOverride && (<>
                <h2>{t('invoicing-override-title')}</h2>
                <div className='sh-description-no-border'>{t('invoicing-override-description')}</div>
                <InvoicingOverrideFormInner
                    form={form}
                    profile={profile}
                />
            </>)}
            <>{saveButton?.({ onClick: handleSubmit })}</>
        </Form>
    );
}

function useClientInvoicingForm(
    onSubmit: (output: ClientInvoicingUpdate) => void,
    defaultValue: Client,
    onChange?: (isDirty: boolean) => void,
) {
    const { profiles } = useMaster();
    const profile = useMemo(() => profiles.find(profile => profile.id.equals(defaultValue.invoicingProfileId)), [ profiles, defaultValue ]);
    if (!profile)
        throw new Error(`Profile "${defaultValue.invoicingProfileId.toString()}" not found for client "${defaultValue.id.toString()}".`);

    const form = useForm<SubscriberFormData & InvoicingOverrideFormData>({
        defaultValues: {
            ...subscriberInputToForm(defaultValue.subscriberSettings),
            ...invoicingOverrideInputToForm(defaultValue.invoicingOverride, profile, defaultValue),
        },
    });
    const isDirty = form.formState.isDirty;

    useEffect(() => onChange?.(isDirty), [ isDirty, onChange ]);

    function onValidSubmit(data: SubscriberFormData & InvoicingOverrideFormData) {
        onSubmit({
            subscriber: subscriberFormToOutput(data),
            override: invoicingOverrideFormToOutput(data),
        });
    }

    useEffect(() => {
        form.reset({
            ...subscriberInputToForm(defaultValue.subscriberSettings),
            ...invoicingOverrideInputToForm(defaultValue.invoicingOverride, profile, defaultValue),
        });
    }, [ defaultValue ]);

    return {
        form,
        handleSubmit: form.handleSubmit(onValidSubmit),
        profile,
        showProfileSelect: profiles.length > 1,
    };
}
