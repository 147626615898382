import React, { useEffect, useMemo, useReducer } from 'react';
import { PaymentMethod } from '@/types/orders/Order';
import { getStringEnumValues } from '@/utils/common';
import { useMaster, type MasterContext } from '@/context/UserProvider';
import { isCurrencySupported } from '@/types/BankAccount';
import { type CurrencyIRI } from '@/modules/money';
import NoBankAccountModal from './NoBankAccountModal';
import { NoStripeModal } from './NoStripeModal';
import { PaymentMethodRadio, type PaymentMethodRadioOption } from './Radio';

type PaymentMethodSelectProps = Readonly<{
    value?: PaymentMethod;
    onChange: (value: PaymentMethod) => void;
    currencyIRIs?: CurrencyIRI[];
    options?: PaymentMethod[];
}>;

export function PaymentMethodSelect({ value, onChange, currencyIRIs, options }: PaymentMethodSelectProps) {
    const [ state, dispatch ] = usePaymentMethods(options, currencyIRIs);
    const optionComponents = useMemo(() => state.options.map(pm => paymentMethodToTabOption(pm, state, dispatch)), [ state, dispatch ]);

    return (<>
        <PaymentMethodRadio
            value={value}
            onChange={onChange}
            options={optionComponents}
        />
        {state.isBankModalNeeded && (
            <NoBankAccountModal
                show={state.isBankModalOpen}
                onClose={() => dispatch({ type: 'bankModal', isOpen: false })}
                currencyIRIs={state.unsupportedIRIs}
            />
        )}
        {state.isStripeModalNeeded && (
            <NoStripeModal
                show={state.isStripeModalOpen}
                onClose={() => dispatch({ type: 'stripeModal', isOpen: false })}
            />
        )}
    </>);
}

function paymentMethodToTabOption(method: PaymentMethod, state: PaymentMethodsState, dispatch: PaymentMethodsDispatch): PaymentMethodRadioOption {
    const output: PaymentMethodRadioOption = {
        method,
        isDisabled: !state.enabledOptions.includes(method),
        onEnableClick: undefined,
    };

    if (method === PaymentMethod.BankTransfer)
        output.onEnableClick = () => dispatch({ type: 'bankModal', isOpen: true });
    if (method === PaymentMethod.Stripe)
        output.onEnableClick = () => dispatch({ type: 'stripeModal', isOpen: true });

    return output;
}

function usePaymentMethods(
    options?: PaymentMethod[],
    currencyIRIs?: CurrencyIRI[],
) {
    const masterContext = useMaster();
    const initialState = useMemo(() => computeInitialState({ masterContext, currencyIRIs, options }), [ masterContext, currencyIRIs, options ]);
    const [ state, dispatch ] = useReducer(paymentMethodsReducer, initialState);

    useEffect(() => {
        dispatch({ type: 'input', input: { masterContext, currencyIRIs, options } });
    }, [ masterContext, currencyIRIs, options ]);

    return [ state, dispatch ] as const;
}

type PaymentMethodsInput = {
    options?: PaymentMethod[];
    currencyIRIs?: CurrencyIRI[];
    masterContext: MasterContext;
};

type PaymentMethodsState = {
    input: PaymentMethodsInput;
    unsupportedIRIs: CurrencyIRI[];
    options: PaymentMethod[];
    enabledOptions: PaymentMethod[];
    isBankModalOpen: boolean;
    isBankModalNeeded: boolean;
    isStripeModalOpen: boolean;
    isStripeModalNeeded: boolean;
};

function computeInitialState(input: PaymentMethodsInput): PaymentMethodsState {
    return {
        input: input,
        ...computeOptions(input),
        isBankModalOpen: false,
        isStripeModalOpen: false,
    };
}

function computeOptions(input: PaymentMethodsInput, previousState?: PaymentMethodsState) {
    const unsupportedIRIs = input.currencyIRIs?.filter(iri => !isCurrencySupported(input.masterContext.bankAccounts, iri)) ?? [];
    const options = input.options ?? getStringEnumValues(PaymentMethod);
    const enabledOptions = options.filter(pm => isPaymentMethodSupported(pm, input.currencyIRIs ?? [], input.masterContext));

    return {
        unsupportedIRIs,
        options,
        enabledOptions,
        // If the modal was needed in the previous state, we include it as well so that it doesn't just disappear.
        isBankModalNeeded: unsupportedIRIs.length > 0 || !!previousState?.isBankModalNeeded,
        isStripeModalNeeded: !input.masterContext.team.isStripeConnected || !!previousState?.isStripeModalNeeded,
    };
}

type PaymentMethodAction = InputAction | ModalAction;
type PaymentMethodsDispatch = (action: PaymentMethodAction) => void;

type InputAction = {
    type: 'input';
    input: PaymentMethodsInput;
};

type ModalAction = {
    type: 'bankModal' | 'stripeModal';
    isOpen: boolean;
};

function paymentMethodsReducer(state: PaymentMethodsState, action: PaymentMethodAction) {
    console.log('Reduce:', state, action);
    switch (action.type) {
    case 'input': return {
        ...state,
        input: action.input,
        ...computeOptions(action.input, state),
    };
    case 'bankModal': return { ...state, isBankModalOpen: action.isOpen };
    case 'stripeModal': return { ...state, isStripeModalOpen: action.isOpen };
    }
}

/**
 * Returns the first supported payment method from the available ones.
 * @param available The available payment methods.
 * @param currencyIRIs The currency IRIs for which the payment method should be supported.
 */
export function getSupportedPaymentMethod(available: PaymentMethod[], currencyIRIs: CurrencyIRI[], masterContext: MasterContext): PaymentMethod | undefined {
    for (const method of available) {
        if (isPaymentMethodSupported(method, currencyIRIs, masterContext))
            return method;
    }
}

function isPaymentMethodSupported(method: PaymentMethod, currencyIRIs: CurrencyIRI[], masterContext: MasterContext): boolean {
    switch (method) {
    case PaymentMethod.BankTransfer:
        return currencyIRIs.every(iri => isCurrencySupported(masterContext.bankAccounts, iri));
    case PaymentMethod.Stripe:
        return masterContext.team.isStripeConnected;
    default:
        return true;
    }
}
