import { type Currency, type CurrencyIRI, type FormMoney, type Money } from './types';
import { priceFromServer } from '@/utils/math';
import { compareStringsAscii } from '@/utils/common';

export function moneyFromServer(amount: number, currencyIRI: CurrencyIRI): Money {
    const currency = getCurrency(currencyIRI);
    if (!currency)
        throw new Error(`Currency code ${currencyIRI} not found.`);

    return {
        amount: priceFromServer(amount),
        currency,
    };
}

const ALL_CURRENCIES_MAP: Map<CurrencyIRI, Currency> = new Map;

export function getAllCurrencies(): Currency[] {
    return [ ...ALL_CURRENCIES_MAP.values() ];
}

export function addCurrency(currency: Currency) {
    ALL_CURRENCIES_MAP.set(currency.toIRI(), currency);
}

export function tryGetCurrency(iri: CurrencyIRI): Currency | undefined {
    return ALL_CURRENCIES_MAP.get(iri);
}

export function getCurrency(iri: CurrencyIRI): Currency {
    const currency = tryGetCurrency(iri);
    if (!currency)
        throw new Error(`Currency with iri ${iri} not found.`);

    return currency;
}

export function tryGetCurrencyByCode(code: string): Currency | undefined {
    return [ ...ALL_CURRENCIES_MAP.values() ].find(currency => currency.code === code);
}

export function getCurrencyByCode(code: string): Currency {
    const currency = tryGetCurrencyByCode(code);
    if (!currency)
        throw new Error(`Currency with code ${code} not found.`);

    return currency;
}

export function compareCurrencies(a: Currency, b: Currency): number {
    return compareStringsAscii(a.code, b.code);
}

export function getDefaultCurrency(): Currency {
    return getAllCurrencies()[0];
}

/** amount 0, default currency */
export function getDefaultMoney(): Money {
    return { amount: 0, currency: getDefaultCurrency() };
}

export function timesBy(money: Money, multiplier: number): Money {
    return {
        amount: money.amount * multiplier,
        currency: money.currency,
    };
}

export function divideBy(money: Money, divisor: number): Money {
    return {
        amount: money.amount / divisor,
        currency: money.currency,
    };
}

export function moneyEquals(a: FormMoney, b: FormMoney) {
    return a.amount === b.amount && a.currency === b.currency;
}

export function isUnderMinimalAmount(money: Money): boolean {
    return money.amount < money.currency.minimalAmount;
}

export { priceFromServer, priceToServer } from '@/utils/math';
