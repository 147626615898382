import type { EventEditToServer, EventFromServer, EventInitToServer, EventScheduleToServer, EventState, EventTransitionToServer, RecurrenceRange } from '@/types/Event';
import { createGET, createPOST, createPUT } from '../rawApiObject';
import { type Id, type IRI } from '@/types/Id';
import type { HydraCollection } from '@/types/api/result';
import type { EventGroupFromServer } from '@/types/EventGroup';
import { type GoogleEventToServer } from '@/types/calendar/Google';
import type { EventParticipantUpdate, UpdatedEventParticipantFromServer } from '@/types/EventParticipant';
import { type SortOrder } from '@/utils/common';

export const event = {
    get: createGET<{ id: string }, EventFromServer>(
        u => `/events/${u.id}`,
    ),
    getAll: createGET<HydraCollection<EventFromServer>, EventQueryParams>(
        '/events',
    ),
    getWithoutOrder: createGET<HydraCollection<EventFromServer>>(
        '/events-without-order',
    ),
    create: createPOST<EventGroupFromServer, EventInitToServer>(
        '/event-groups/create',
    ),
    createFromGoogle: createPOST<EventGroupFromServer, GoogleEventToServer>(
        '/event-groups/create-from-google',
    ),
    schedule: createPOST<EventGroupFromServer, EventScheduleToServer>(
        '/event-groups/create-from-product',
    ),
    delete: createPOST<{ id: Id }, EventGroupFromServer, DeleteEventData>(
        u => `${u.id.toIRI()}/delete`,
    ),
    update: createPUT<{ id: Id }, EventGroupFromServer, EventEditToServer | EventTransitionToServer>(
        u => `${u.id.toIRI()}`,
    ),
    updateParticipant: createPUT<{ id: Id }, UpdatedEventParticipantFromServer, EventParticipantUpdate>(
        u => `${u.id.toIRI()}/clients`,
    ),
    sendNotes: createPOST<{ id: Id }, void, { clients: IRI[] }>(
        u => `${u.id.toIRI()}/send-notes`,
    ),
};

type EventQueryParams = {
    state?: EventState[];
    participants?: string[];
    page?: number;
    orderBy?: EventOrderBy;
    dateFrom?: {
        after?: string;
        strictly_before?: string;
    };
    notes?: string;
    pagination?: false;
    itemsPerPage?: number;
};

type EventOrderBy = {
    dateFrom?: SortOrder;
};

type DeleteEventData = {
    sendNotification?: boolean;
    range?: RecurrenceRange;
}
