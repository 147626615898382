import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { routes } from '.';
import Register from '@/pages/auth/Register';
import RegisterGoogleCallback from '@/pages/auth/RegisterGoogleCallback';
import Login from '@/pages/auth/Login';
import LoginGoogleCallback from '@/pages/auth/LoginGoogleCallback';
import ResetPassword from '@/pages/auth/ResetPassword';

export default function UnauthorizedRoutes() {
    const location = useLocation();

    return (
        <Routes>
            <Route path={routes.login.index} element={<Login />} />
            <Route path={routes.login.google} element={<LoginGoogleCallback />} />
            <Route path={routes.register.index} element={<Register />} />
            <Route path={routes.register.google} element={<RegisterGoogleCallback />} />
            <Route path={routes.resetPassword} element={<ResetPassword />} />
            <Route
                path='*'
                element={
                    <Navigate
                        to={routes.login.index}
                        state={{
                            from: location,
                        }}
                        replace
                    />
                }
            />
        </Routes>
    );
}
