import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { api } from '@/utils/api/backend';
import { type BaseLocation, PlatformType, getLocationFromServer } from '@/types/location';
import LocationForm, { type LocationFormData } from './LocationForm';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '@/components/notifications';
import useNotifications from '@/context/NotificationProvider';
import { useTranslation } from 'react-i18next';

type NewLocationModalProps = Readonly<{
    defaultTitle?: string;
    show: boolean;
    onClose(): void;
    onLocationCreated?: (location: BaseLocation) => void;
}>;

export default function NewLocationModal({ defaultTitle, show, onClose, onLocationCreated }: NewLocationModalProps) {
    const { t } = useTranslation('pages');
    const { addAlert } = useNotifications();
    const [ isFetching, setIsFetching ] = useState(false);

    async function onSubmit(data: LocationFormData) {
        setIsFetching(true);
        const response = await createLocation(data);
        setIsFetching(false);

        if (!response.status) {
            addAlert(createTranslatedErrorAlert());

            onClose();
            return;
        }

        const newLocation = getLocationFromServer(response.data);
        if (onLocationCreated)
            onLocationCreated(newLocation);
        addAlert(createTranslatedSuccessAlert('common:locationCreatedAlert'));
        
        onClose();
    }

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('newLocation.modal-title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LocationForm
                    defaultValues={{ title: defaultTitle }}
                    isFetching={isFetching}
                    onSubmit={onSubmit}
                />
            </Modal.Body>
        </Modal>
    );
}

function createLocation(data: LocationFormData) {
    if (data.platformType === PlatformType.physicalLocation) {
        const init = {
            title: data.title,
            address: {
                city: data.city,
                line1: data.line1,
                postalCode: data.postalCode,
                country: data.country,
            },
        };
        return api.location.createPhysical(init);
    }
    else {
        const init = {
            title: data.title,
            platform: data.platformType === PlatformType.custom ? data.platform : data.platformType,
            url: data.url,
        };
        return api.location.createVideo(init);
    }
}
