import React, { type Dispatch, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaster, type MasterContext } from '@/context/UserProvider';
import { Order, type ProductOrderInit, productOrderToServer, type CustomOrderInit, customOrderToServer, type OrderToServer, type EventOrderInit, eventOrderToServer } from '@/types/orders/Order';
import { api } from '@/utils/api/backend';
import { createErrorAlert, createTranslatedSuccessAlert } from '../../notifications';
import useNotifications from '@/context/NotificationProvider';
import { routes } from '@/router';
import { useAnalytics } from '@/types/analytics';
import { PlanExceededErrorModal } from '../SubscriptionErrorModal';
import { type PlanExceededError, isPlanExceededError } from '@/types/Subscription';
import { ClientInfo } from '@/types/Client';
import { type CheckoutInput, CheckoutPhase, parseCcEmails, useCheckout, CheckoutType, type CheckoutState } from './useCheckout';
import { Overview } from './Overview';
import { EmailPreview } from './EmailPreview';
import { InvoicePreviewModal } from './InvoicePreviewModal';
import { type i18n } from 'i18next';

export type CheckoutOutput = {
    type: 'back' | 'close' | 'planExceeded';
} | {
    type: 'addClients';
    clients: ClientInfo | ClientInfo[];
} | {
    type: 'finish';
    orders: Order[];
};

type CheckoutProps = Readonly<{
    input: CheckoutInput;
    output: Dispatch<CheckoutOutput>;
    showCloseButton?: boolean;
}>;

export function Checkout({ input, output, showCloseButton }: CheckoutProps) {
    const { i18n } = useTranslation('components', { keyPrefix: 'checkout' });
    const masterContext = useMaster();
    const { state, dispatch } = useCheckout(input);

    const [ isFetching, setIsFetching ] = useState(false);
    const { addAlert } = useNotifications();
    const analytics = useAnalytics();
    const [ planExceededError, setPlanExceededError ] = useState<PlanExceededError>();

    async function createOrder() {
        const toServer = createOrderInit(input, state, masterContext, i18n);

        setIsFetching(true);
        const response = await api.order.create(toServer);
        setIsFetching(false);

        if (!response.status) {
            if (isPlanExceededError(response.error)) {
                setPlanExceededError(response.error);
                output({ type: 'planExceeded' });
                return;
            }

            addAlert(createErrorAlert(response.error));
            return;
        }

        const orders = response.data.orders.map(Order.fromServer);
        orders.forEach(order => {
            analytics.orderCreated(order, input.type, input.type);
            // TODO Replace by the general alert when all checkouts are united. But be careful about the wording!
            if (input.type === CheckoutType.Product) {
                addAlert(createTranslatedSuccessAlert('common:productOrderCreatedAlert', { links: {
                    a: routes.orders.detail.resolve({ id: order.id.toString() }),
                }, count: input.items.length }));
            }
            else {
                addAlert(createTranslatedSuccessAlert('common:orderCreatedAlert', { links: {
                    a: routes.orders.detail.resolve({ id: order.id.toString() }),
                } }));
            }
        });

        const newClients = response.data.newClients.map(ClientInfo.fromServer);
        newClients.forEach(client => analytics.clientCreated(client));
        output({ type: 'addClients', clients: newClients });

        output({ type: 'finish', orders });
    }

    const hidePreview = useCallback(() => dispatch({ type: 'invoicePreview' }), [ dispatch ]);

    return (<>
        <InvoicePreviewModal
            invoicePreview={state.invoicePreview}
            onHide={hidePreview}
            onPlanExceededError={setPlanExceededError}
        />
        <PlanExceededErrorModal error={planExceededError} onHide={() => setPlanExceededError(undefined)} />
        {state.phase === CheckoutPhase.Overview && (
            <Overview
                output={output}
                state={state}
                dispatch={dispatch}
                isFetching={isFetching}
                createOrder={createOrder}
                showCloseButton={showCloseButton}
            />
        )}
        {state.phase === CheckoutPhase.EmailPreview && (
            <EmailPreview state={state} dispatch={dispatch} />
        )}
    </>);
}

function createOrderInit(input: CheckoutInput, state: CheckoutState, masterContext: MasterContext, i18n: i18n): OrderToServer {
    const { paymentMethod, isSendNotification } = state.overview;
    const notification = isSendNotification
        ? { ...state.emailPreview.form, cc: parseCcEmails(state.emailPreview.form.cc) }
        : undefined;

    switch (input.type) {
    case CheckoutType.Custom: {
        const init: CustomOrderInit = {
            client: input.client,
            dueDays: input.dueDays,
            items: input.items,
            discountItems: state.cache.invoicesForClients[0].invoices.flatMap(invoice => invoice.discountItems),
            paymentMethod,
            notification,
        };
        return { custom: customOrderToServer(init, masterContext, i18n) };
    }
    case CheckoutType.Product: {
        const init: ProductOrderInit = {
            client: input.client,
            guest: input.guest,
            scheduler: input.scheduler,
            items: input.items,
            discountItems: state.cache.invoicesForClients[0].invoices.flatMap(invoice => invoice.discountItems),
            paymentMethod,
            notification,
        };
        return { product: productOrderToServer(init, masterContext, i18n) };
    }
    case CheckoutType.Event: {
        const init: EventOrderInit = {
            paymentMethod,
            notification,
            forClients: input.forClients,
        };
        return { event: eventOrderToServer(init, i18n) };
    }
    }
}
