import React, { useEffect, useMemo, useState, type KeyboardEvent, useCallback } from 'react';
import { api } from '@/utils/api/backend';
import { Task } from '@/types/Task';
import { dehydrate } from '@/types/api/result';
import { Button, Form } from 'react-bootstrap';
import { EditingPhase, useEditing, useToggle, useUpdating } from '@/hooks';
import { ChevronDownIcon, ChevronRightIcon, CrossedEyeIcon, EyeIcon, PlusIcon } from '../icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DeleteButton } from '../forms/buttons';

export function TaskList() {
    const { t } = useTranslation('components', { keyPrefix: 'taskList' });
    const [ tasks, setTasks ] = useState<Task[]>();
    const [ completedVisible, setCompletedVisible ] = useToggle(false);
    const [ tasksVisible, setTasksVisible ] = useToggle(true);

    async function fetchTasks(signal?: AbortSignal) {
        const response = await api.task.getAll(signal);
        if (!response.status)
            return;

        const data = dehydrate(response);
        const tasks = data.items.map(Task.fromServer);
        setTasks(tasks);
    }

    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchTasks(signal);
        return abort;
    }, []);

    const updateTask = useCallback((action: UpdateTaskAction) => {
        setTasks(oldTasks => computeTaskUpdate(oldTasks ?? [], action));
    }, []);

    const uncompleted = useMemo(() => tasks?.filter(task => !task.isCompleted), [ tasks ]);
    const completed = useMemo(() => tasks?.filter(task => task.isCompleted), [ tasks ]);

    return (
        <div className='sh-task-list'>
            <h2>
                <Button
                    className='default-empty compact me-1'
                    variant='ghost-secondary'
                    onClick={setTasksVisible.toggle}
                    aria-label={t('toggle-tasks-aria')}
                >
                    {tasksVisible ? (
                        <ChevronDownIcon size={24} />
                    ) : (
                        <ChevronRightIcon size={24} />
                    )}
                </Button>
                <span>{t('title')}</span>
            </h2>
            {tasksVisible && (<>
                <NewTaskRow onUpdate={updateTask} />
                <div className='d-flex flex-column'>
                    {uncompleted?.map(task => (
                        <TaskRow
                            key={task.id.toString()}
                            task={task}
                            onUpdate={updateTask}
                        />
                    ))}
                </div>
                {!!completed?.length && (
                    <Button
                        variant='border-light'
                        className='compact text-secondary d-flex align-items-center gap-1 ms-2 my-1'
                        onClick={setCompletedVisible.toggle}
                    >
                        {completedVisible ? (<>
                            <span>{t('hide-completed')}</span>
                            <CrossedEyeIcon size={18} />
                        </>) : (<>
                            <span>{t('show-completed')}</span>
                            <EyeIcon size={18} />
                        </>)}
                    </Button>
                )}
                {completedVisible && completed?.map(task => (
                    <TaskRow
                        key={task.id.toString()}
                        task={task}
                        onUpdate={updateTask}
                    />
                ))}
            </>)}
        </div>
    );
}

export type UpdateTaskAction = {
    type: 'create' | 'update' | 'delete';
    task: Task;
};

function computeTaskUpdate(tasks: Task[], { type, task }: UpdateTaskAction): Task[] {
    if (type === 'create')
        return [ task, ...tasks ];

    if (type === 'delete')
        return tasks.filter(t => !task.id.equals(t.id));

    const index = tasks.findIndex(t => task.id.equals(t.id));
    if (index === -1)
        return tasks;

    const newTasks = [ ...tasks ];
    newTasks[index] = task;

    return newTasks;
}


type TaskRowProps = Readonly<{
    task: Task;
    onUpdate: (action: UpdateTaskAction) => void;
}>;

function TaskRow({ task, onUpdate }: TaskRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'taskList' });
    const id = task.id;

    const syncText = useCallback(async (newValue: string) => {
        const response = await api.task.update({ id }, { text: newValue });
        if (!response.status)
            return false;

        onUpdate({ type: 'update', task: Task.fromServer(response.data) });

        return true;
    }, [ onUpdate, id ]);

    const { state: { value: text, phase: textPhase }, setValue: setText, doUpdate: updateText } = useEditing<string>(task.text, syncText);

    const syncIsCompleted = useCallback(async (newValue: boolean) => {
        const response = await api.task.update({ id }, { completed: newValue });
        if (!response.status)
            return false;

        onUpdate({ type: 'update', task: Task.fromServer(response.data) });

        return true;
    }, [ onUpdate, id ]);

    const [ isCompleted, updateIsCompleted, isUpdatingIsCompleted ] = useUpdating<boolean>(task.isCompleted, syncIsCompleted);

    const [ isRemoving, setIsRemoving ] = useState(false);

    async function remove() {
        setIsRemoving(true);
        const response = await api.task.delete({ id });
        setIsRemoving(false);
        if (!response.status)
            return;

        onUpdate({ type: 'delete', task });
    }

    return (
        <div className='sh-task-row d-flex align-items-center justify-content-around p-2 ps-3 gap-2 rounded rounded-2'>
            <Form.Check.Input
                checked={isCompleted}
                onChange={e => updateIsCompleted(e.target.checked)}
                className='sh-task-check'
                aria-label={t('toggle-task-aria')}
                disabled={isUpdatingIsCompleted || isRemoving}
            />
            <TaskTextInput
                text={text}
                onChange={setText}
                placeholder={t('empty-task')}
                // We have to do this here because the doUpdate function behaves differently if there is a value passed to it.
                onConfirm={() => updateText()}
                disabled={textPhase === EditingPhase.Updating || isRemoving}
            />
            <DeleteButton aria={t('remove-task-aria')} className='sh-task-remove' onClick={remove} />
        </div>
    );
}

type NewTaskRowProps = Readonly<{
    onUpdate: (action: UpdateTaskAction) => void;
}>;

function NewTaskRow({ onUpdate }: NewTaskRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'taskList' });
    const [ text, setText ] = useState('');
    const [ isFetching, setIsFetching ] = useState(false);

    async function create() {
        setIsFetching(true);
        const response = await api.task.create({ text });
        setIsFetching(false);
        if (!response.status)
            return;

        onUpdate({ type: 'create', task: Task.fromServer(response.data) });
        setText('');
    }

    return (
        <div className='sh-task-row d-flex align-items-center w-100 bg-border-light text-secondary rounded rounded-2 py-2 px-2 mb-1'>
            <PlusIcon size={24} style={{ margin: '0px 2px' }} />
            <div className='flex-grow-1'>
                <TaskTextInput
                    text={text}
                    onChange={setText}
                    placeholder={t('new-task')}
                    onConfirm={create}
                    disabled={isFetching}
                />
            </div>
        </div>
    );
}

type TaskTextInputProps = Readonly<{
    text: string;
    placeholder?: string;
    disabled: boolean;
    onChange: (newText: string) => void;
    onConfirm: () => void;
}>;

function TaskTextInput({ text, placeholder, disabled, onChange, onConfirm }: TaskTextInputProps) {
    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLInputElement).blur();
        }
    }

    return (
        <Form.Control
            value={text}
            placeholder={placeholder}
            onChange={e => onChange(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={onConfirm}
            disabled={disabled}
            className={clsx('sh-task-input ps-1 text-truncate', disabled && 'text-secondary')}
            aria-label={text || placeholder}
        />
    );
}
