import { DateTime } from 'luxon';
import { type Currency } from '@/modules/money';
import { type Entity, Id, type IRI } from './Id';
import { getStringEnumValues, stringToServer, objectUpdateToServer } from '@/utils/common';
import { serializeRecurrence, type Recurrence } from './recurrence/Recurrence';
import { EventParticipant, participantIsBillable, participantsToServer, type EventParticipantFromServer, type NewParticipantInitToServer, type Participant, type ParticipantInitToServer, type PayingParticipant, type EditablePayingParticipant } from './EventParticipant';
import { type ClientInit, type ClientInfo } from './Client';
import { type GenericProductItem } from './orders/ProductOrderItem';
import { type Settings } from './Settings';
import { type TeamMember } from './Team';

export const MAX_NOTES_LENGTH = 2000;
export const MAX_TITLE_LENGTH = 255;

export enum EventState {
    Ready = 'ready',
    Finished = 'finished',
    Canceled = 'canceled',
}

const EVENT_STATE_VALUES = getStringEnumValues(EventState);

export function getAllEventStates(): EventState[] {
    return EVENT_STATE_VALUES;
}

export type EventInfoFromServer = {
    '@id': IRI;
    title: string;
    state: EventState;
    dateFrom: string;
};

export class EventInfo implements Entity {
    protected constructor(
        readonly id: Id,
        readonly title: string,
        readonly state: EventState,
        readonly dateFrom: DateTime,
    ) {}

    static fromServer(input: EventInfoFromServer): EventInfo {
        return new EventInfo(
            Id.fromIRI(input['@id']),
            input.title,
            input.state,
            DateTime.fromISO(input.dateFrom),
        );
    }

    static compareAsc(a: EventInfo, b: EventInfo): number {
        return +a.dateFrom - +b.dateFrom;
    }

    static compareDesc(a: EventInfo, b: EventInfo): number {
        return +b.dateFrom - +a.dateFrom;
    }
}

export type EventFromServer = EventInfoFromServer & {
    participants: EventParticipantFromServer[];
    recurrenceIndex?: number;
    notes: string;
    description: string;
    location?: IRI;
    dateTo: string;
    /** In seconds. */
    duration: number;
    eventGroup: IRI;
    googleEventId?: string;
    googleEventInstanceId?: string;
    googleCalendarId?: string;
    deleted: boolean;
    productIndex?: number;
    productSessionsCount?: number;
    /** The creator / scheduler of the event. */
    appUser: IRI;
};

export class Event extends EventInfo {
    /** recurrence index / package progress + title */
    readonly displayTitle: string;

    private constructor(
        id: Id,
        title: string,
        state: EventState,
        dateFrom: DateTime,
        readonly guests: EventParticipant[],
        readonly clients: PayingParticipant[],
        readonly recurrenceIndex: number | undefined,
        readonly notes: string,
        readonly description: string,
        readonly locationId: Id | undefined,
        readonly dateTo: DateTime,
        /** In seconds. */
        readonly duration: number,
        readonly eventGroupId: Id,
        /** Id of the corresponding google event. */
        readonly googleId: string | undefined,
        readonly googleCalendarId: string | undefined,
        readonly isDeleted: boolean,
        /** If the event was created by scheduling a package, this is its index in that package. */
        readonly productIndex: number | undefined,
        readonly productSessionsCount: number | undefined,
        readonly ownerId: Id,
    ) {
        super(id, title, state, dateFrom);
        const recurrencePrefix = this.recurrenceIndex !== undefined && `#${this.recurrenceIndex + 1}`;
        const prefix = this.packageProgress ?? recurrencePrefix;
        this.displayTitle = (prefix ? `${prefix} ` : '') + this.title;
    }

    static fromServer(input: EventFromServer): Event {
        const guests = input.participants.map(EventParticipant.fromServer).filter(p => p.isGuest);
        const clients = input.participants.map(EventParticipant.fromServer).filter((p): p is PayingParticipant => !!p.isPaying);

        return new Event(
            Id.fromIRI(input['@id']),
            input.title,
            input.state,
            DateTime.fromISO(input.dateFrom),
            // getTaxRate(input.vat),
            guests,
            clients,
            input.recurrenceIndex,
            input.notes,
            input.description,
            input.location && Id.fromIRI(input.location),
            DateTime.fromISO(input.dateTo),
            input.duration,
            Id.fromIRI(input.eventGroup),
            input.googleEventInstanceId ?? input.googleEventId,
            input.googleCalendarId,
            input.deleted,
            input.productIndex,
            input.productSessionsCount,
            Id.fromIRI(input.appUser),
        );
    }

    toUpdated({ guests, clients, notes }: EventUpdateObject): Event {
        return new Event(
            this.id,
            this.title,
            this.state,
            this.dateFrom,
            guests ?? this.guests,
            clients ?? this.clients,
            this.recurrenceIndex,
            notes ?? this.notes,
            this.description,
            this.locationId,
            this.dateTo,
            this.duration,
            this.eventGroupId,
            this.googleId,
            this.googleCalendarId,
            this.isDeleted,
            this.productIndex,
            this.productSessionsCount,
            this.ownerId,
        );
    }

    get isInPackage(): boolean {
        return this.productIndex !== undefined;
    }

    get packageProgress(): string | undefined {
        return this.isInPackage
            ? `(${this.productIndex! + 1}/${this.productSessionsCount})`
            : undefined;
    }
}

export type EventUpdateObject = {
    guests?: EventParticipant[];
    clients?: PayingParticipant[];
    notes?: string;
};

export function eventIsBillable(event: Event) {
    return event.clients.some(participant => participantIsBillable(participant) && !participant.isInOrder);
}

export function eventIsTransparent(event: Event): boolean {
    return event.state === EventState.Canceled;
}

export function eventHasClientWithCurrency(event: Event, client: ClientInfo, currency: Currency): boolean {
    return event.clients.some(c => c.id.equals(client.id) && c.payment.price.currency === currency);
}

export enum ErrorType {
    DurationZeroOrLess = 'event.durationZeroOrLess'
}

export type DurationError = {
    type: ErrorType.DurationZeroOrLess;
};

export function isDurationError(value: unknown): value is DurationError {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value))
        return false;

    return value.type === ErrorType.DurationZeroOrLess;
}

export enum RecurrenceRange {
    This = 'this',
    Following = 'following',
    All = 'all',
}

export enum TransitionName {
    Finish = 'finish',
    Cancel = 'cancel',
    Revoke = 'revoke',
}

export type EventInit = {
    title: string;
    description: string;
    locationId?: Id;
    branch: RecurrenceBranch;
    guests: Participant[];
    clients: EditablePayingParticipant[];
    initialNotes: string;
    isBillLater: boolean;
    scheduler?: TeamMember;
};

export type RecurrenceBranch = {
    startDate: DateTime;
    endDate: DateTime;
    recurrence?: Recurrence;
};

export type EventEdit = {
    event: Event;
    title: string;
    description: string;
    locationId?: Id;
    startDate: DateTime;
    endDate: DateTime;
    guests: Participant[];
    clients: EditablePayingParticipant[];
    isBillLater: boolean;
    // notes: string; // TODO
};

export enum SyncType {
    Create = 'create',
    Update = 'update',
    Transition = 'transition',
    Delete = 'delete',
    Schedule = 'schedule',
}

type TEventSync<TType extends SyncType, TData> = {
    sendNotification?: boolean;
    range?: RecurrenceRange;
    type: TType;
    data: TData;
};

export type EventCreate = TEventSync<SyncType.Create, EventInit>;
export type EventUpdate = TEventSync<SyncType.Update, EventEdit>;
export type EventTransition = TEventSync<SyncType.Transition, { transition: TransitionName }>;
export type EventDelete = TEventSync<SyncType.Delete, undefined>;

export type EventSync = EventCreate | EventUpdate | EventTransition | EventDelete | EventSchedule;

/**
 * In seconds.
 */
export function getTimeDifference(startDate: DateTime, endDate: DateTime): number {
    const { year, month, day } = startDate;

    return endDate.set({ year, month, day }).diff(startDate, 'second').seconds;
}

type RecurrenceToServer = {
    recurrence?: string;
    /** create using [DateTime].toUTC().toISO() */
    dateFrom: string;
    /** In seconds. */
    duration: number;
};

export type EventInitToServer = {
    title: string;
    description: string;
    location?: IRI;
    recurrence: RecurrenceToServer;
    participants: ParticipantInitToServer[];
    newParticipants: NewParticipantInitToServer[];
    /** default false */
    sendNotification?: boolean;
    /** default false */
    paid?: boolean;
    notes?: string;
    scheduler?: IRI;
};

export function eventCreateToServer(create: EventCreate, settings: Settings): EventInitToServer {
    const init = create.data;
    // The form does not change the end date, so we derive it from the start date.
    const duration = getTimeDifference(init.branch.startDate, init.branch.endDate);
    const recurrence = {
        recurrence: init.branch.recurrence && serializeRecurrence(init.branch.recurrence),
        dateFrom: init.branch.startDate.toUTC().toISO(),
        duration,
    };

    const { participants, newParticipants } = participantsToServer(init.clients, init.guests, settings);

    return {
        title: init.title,
        description: init.description,
        location: init.locationId?.toIRI(),
        recurrence,
        participants,
        newParticipants,
        sendNotification: create.sendNotification,
        notes: stringToServer(init.initialNotes),
        scheduler: init.scheduler?.id.toIRI(),
    };
}

export type EventSchedule = TEventSync<SyncType.Schedule, {
    title: string;
    locationId?: Id;
    branch: RecurrenceBranch;
    guests: Participant[];
    productItem: GenericProductItem;
    scheduler?: TeamMember;
}>;

export type EventScheduleToServer = {
    title: string;
    location?: IRI;
    recurrence: RecurrenceToServer;
    /** ClientInfo */
    guests: IRI[];
    newGuests: ClientInit[];
    /** default false */
    sendNotification?: boolean;
    /** GenericProductItem */
    orderItem: IRI;
    /** TeamMember */
    scheduler?: IRI;
};

export function eventScheduleToServer(schedule: EventSchedule, settings: Settings): EventScheduleToServer {
    const init = schedule.data;
    const duration = getTimeDifference(init.branch.startDate, init.branch.endDate);
    const recurrence = {
        recurrence: init.branch.recurrence && serializeRecurrence(init.branch.recurrence),
        dateFrom: init.branch.startDate.toUTC().toISO(),
        duration,
    };

    const { participants, newParticipants } = participantsToServer([], init.guests, settings);

    return {
        title: init.title,
        location: init.locationId?.toIRI(),
        recurrence,
        guests: participants.map(p => p.client),
        newGuests: newParticipants.map(p => p.contact),
        orderItem: init.productItem.id.toIRI(),
        scheduler: init.scheduler?.id.toIRI(),
        sendNotification: schedule.sendNotification,
    };
}

export type EventEditToServer = {
    title?: string;
    description?: string;
    location?: IRI | false;
    dateFrom?: string;
    /** In seconds. */
    duration?: number;
    participants?: ParticipantInitToServer[];
    newParticipants?: NewParticipantInitToServer[];
    notes?: string;
    range?: RecurrenceRange;
    sendNotification?: boolean;
    paid?: boolean;
};

export function eventUpdateToServer(update: EventUpdate, settings: Settings): EventEditToServer {
    const edit = update.data;
    const event = edit.event;
    const duration = getTimeDifference(edit.startDate, edit.endDate);

    const { participants, newParticipants } = participantsToServer(edit.clients, edit.guests, settings);

    return {
        title: edit.title !== event.title ? edit.title : undefined,
        description: edit.description !== event.description ? edit.description : undefined,
        location: objectUpdateToServer(event.locationId, edit.locationId, id => id.toIRI()),
        dateFrom: !edit.startDate.equals(event.dateFrom) ? edit.startDate.toUTC().toISO() : undefined,
        duration: duration !== event.duration ? duration : undefined,
        participants,
        newParticipants,
        // notes: edit.notes,
        range: update.range,
        sendNotification: update.sendNotification,
    };
}

export type EventTransitionToServer = {
    transition: TransitionName;
    range?: RecurrenceRange;
    sendNotification?: boolean;
};

export function eventTransitionToServer(action: EventTransition): EventTransitionToServer {
    return {
        transition: action.data.transition,
        range: action.range,
        sendNotification: action.sendNotification,
    };
}
