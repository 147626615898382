import { type Entity, Id, type IRI } from './Id';
import { getCurrency, type Currency, type CurrencyIRI } from '@/modules/money';
import { type Person } from './Person';
import { type AppUser } from './AppUser';

export type TeamFromServer = {
    '@id': IRI;
    title: string;
    legalName: string;
    email: string;
    stripeCurrency: CurrencyIRI; // The currency used when buying subscriptions.
    stripeConnected: boolean;
    paymentEmail: string | undefined;
};

export class Team implements Entity {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly legalName: string,
        readonly email: string,
        readonly stripeCurrency: Currency,
        readonly isStripeConnected: boolean,
        readonly paymentEmail: string | undefined,
    ) {}

    static fromServer(input: TeamFromServer): Team {
        return new Team(
            Id.fromIRI(input['@id']),
            input.title,
            input.legalName,
            input.email,
            getCurrency(input.stripeCurrency),
            input.stripeConnected,
            input.paymentEmail,
        );
    }
}

export type TeamUpdateToServer = {
    title?: string;
    legalName?: string;
    email?: string;
};

export type TeamMemberFromServer = {
    '@id': IRI;
    role: UserRole;
    appUser: {
        '@id': IRI;
        firstName: string;
        lastName?: string;
        email: string;
        phoneNumber?: string;
    };
};

export class TeamMember implements Person {
    private constructor(
        readonly id: Id,
        readonly appUserId: Id,
        readonly firstName: string,
        readonly lastName: string | undefined,
        readonly email: string,
        readonly phoneNumber: string | undefined,
        readonly role: UserRole,
    ) {}

    static fromServer(input: TeamMemberFromServer): TeamMember {
        return new TeamMember(
            Id.fromIRI(input['@id']),
            Id.fromIRI(input.appUser['@id']),
            input.appUser.firstName,
            input.appUser.lastName,
            input.appUser.email,
            input.appUser.phoneNumber,
            input.role,
        );
    }
}

export class TeamMembers {
    readonly all: TeamMember[];
    readonly other: TeamMember[];

    constructor(
        appUser: AppUser,
        teamMembers: TeamMember[],
    ) {
        this.all = teamMembers;
        this.other = teamMembers.filter(member => !member.appUserId.equals(appUser.id));
        this.map = new Map(teamMembers.map(member => [ member.appUserId.toString(), member ]));
        this.appUserId = appUser.id;
    }

    private readonly map: Map<string, TeamMember>;
    private readonly appUserId: Id;

    getByAppUserId(id: Id): TeamMember | undefined {
        return this.map.get(id.toString());
    }

    getOtherByAppUserId(id: Id): TeamMember | undefined {
        const found = this.getByAppUserId(id);
        return found?.appUserId.equals(this.appUserId) ? undefined : found;
    }
}

export enum UserRole {
    Scheduler = 'scheduler',
    Master = 'master',
    Freelancer = 'freelancer',
}

export type ForRole<
    TRequiredRole extends UserRole,
    TRole extends UserRole,
    TTrue,
    TFalse = undefined,
> = TRole extends TRequiredRole ? TTrue : TFalse;

export type ForScheduler<TRole extends UserRole, TData> = ForRole<UserRole.Scheduler, TRole, TData>;
export type ForMaster<TRole extends UserRole, TData> = ForRole<UserRole.Master, TRole, TData>;
export type ForFreelancer<TRole extends UserRole, TData> = ForRole<UserRole.Freelancer, TRole, TData>;

export type ExceptScheduler<TRole extends UserRole, TData> = ForRole<UserRole.Scheduler, TRole, undefined, TData>;
