import React, { useCallback, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { type FieldValues, type UseFormReturn } from 'react-hook-form';
import { type SubscriberSettings, type SubscriberSettingsToServer } from '@/types/Invoicing';
import { addressToServer, defaultEditableAddress, type EditableAddress } from '@/types/Address';
import { ControlledCountrySelect } from '@/components/forms';
import { useTranslation } from 'react-i18next';
import { stringToServer } from '@/utils/common';
import { MapPinIcon } from '../icons';
import { SpinnerButton } from '../common';
import { useUser } from '@/context/UserProvider';
import { useAres, type AresInfo } from './useAres';
import FormErrorMessage from '../forms/FormErrorMessage';
import { EMAIL_VALIDATION_PATTERN, canonizeEmail } from '@/utils/forms';

type InputType = Omit<SubscriberSettings, 'id'>;
export type FormDataType = EditableAddress & {
    cin: string;
    tin: string;
    name: string;
    invoicingEmail: string;
    hideEmailOnInvoice: boolean;
};
type OutputType = SubscriberSettingsToServer;

export function inputToForm(input: InputType): FormDataType {
    return {
        ...(input.address?.toEditable() ?? defaultEditableAddress),
        cin: input.cin ?? '',
        tin: input.tin ?? '',
        name: input.name,
        invoicingEmail: input.email ?? '',
        hideEmailOnInvoice: input.hideEmailOnInvoice,
        //currencyIri: input.settings.currency.toIRI(),
    };
}

export function formToOutput(data: FormDataType): OutputType {
    return {
        address: addressToServer(data),
        cin: stringToServer(data.cin),
        tin: stringToServer(data.tin),
        name: data.name,
        email: stringToServer(canonizeEmail(data.invoicingEmail)),
        hideEmailOnInvoice: data.hideEmailOnInvoice,
    };
}

type SubscriberFormInnerProps<T extends FieldValues> = Readonly<{
    form: UseFormReturn<T>;
    isNew?: boolean;
}>;

export function SubscriberFormInner<T extends FormDataType>(props: SubscriberFormInnerProps<T>) {
    const { t } = useTranslation('components', { keyPrefix: 'invoicingForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const { settings } = useUser();

    const form = props.form as unknown as UseFormReturn<FormDataType>;
    const { control, register, setValue, formState: { errors } } = form;

    const [ aresCin, setAresCin ] = useState<string>();

    const fillFromAres = useCallback((ares: AresInfo) => {
        ares.legalName && setValue('name', ares.legalName, { shouldDirty: true });
        // The cin is always present.
        setValue('cin', ares.cin, { shouldDirty: true });
        ares.tin && setValue('tin', ares.tin, { shouldDirty: true });
        ares.address?.city && setValue('city', ares.address.city, { shouldDirty: true });
        ares.address?.line1 && setValue('line1', ares.address.line1, { shouldDirty: true });
        ares.address?.postalCode && setValue('postalCode', ares.address.postalCode, { shouldDirty: true });
        ares.address?.country && setValue('country', ares.address.country, { shouldDirty: true });

        setAresCin(undefined);
    }, [ setValue ]);

    const { fetchAres, isFetchingAres } = useAres(fillFromAres);

    const hideEmailOnInvoice = form.watch('hideEmailOnInvoice');

    return (<>
        {settings.country === 'CZ' && (
            <div className='mb-4 pb-4 border-bottom'>
                <Row>
                    <Col className='d-flex align-items-center justify-content-end'>
                        <Form.Label className='mb-0 ps-5'>{t(props.isNew ? 'ares-cin-label-new' : 'ares-cin-label-old')}</Form.Label>
                    </Col>
                    <Col>
                        <Form.Control value={aresCin} onChange={e => setAresCin(e.target.value)} placeholder={t('cin-placeholder')} />
                    </Col>
                    <Col>
                        <SpinnerButton isFetching={isFetchingAres} onClick={() => fetchAres(aresCin)} className='w-100'>
                            {t(props.isNew ? 'ares-cin-button-new' : 'ares-cin-button-old')}
                        </SpinnerButton>
                    </Col>
                    <Col />
                </Row>
            </div>
        )}
        {!props.isNew && (
            <Row className='mb-3'>
                <Form.Group as={Col} xs={12}>
                    <Form.Label>{t('name-company-label')}</Form.Label>
                    <Form.Control {...register('name', { required: tf('name-company-required') })} placeholder={t('name-company-placeholder')} />
                    <FormErrorMessage errors={errors} name='name' />
                </Form.Group>
            </Row>
        )}
        <Row className='mb-3'>
            <Form.Group as={Col} xs={6}>
                <Form.Label>{t('invoicing-email-label')}</Form.Label>
                <Form.Control
                    {...register('invoicingEmail', {
                        pattern: {
                            value: EMAIL_VALIDATION_PATTERN,
                            message: tf('email-invalid'),
                        },
                    })}
                    placeholder={t('invoicing-email-placeholder')}
                    type='email'
                    disabled={hideEmailOnInvoice}
                    className='sh-allow-disabled'
                />
                <FormErrorMessage errors={errors} name='invoicingEmail' />
            </Form.Group>
            <Col className='d-flex flex-column justify-content-end'>
                <div className='d-flex align-items-center' style={{ height: 42 }}>
                    <Form.Check
                        {...register('hideEmailOnInvoice')}
                        type='switch'
                        label={t('hide-email-on-invoice-label')}
                        id='hide-email-on-invoice-toggle'
                    />
                </div>
            </Col>
        </Row>
        <Row className='mb-3'>
            <Form.Group as={Col} xs={3}>
                <Form.Label>{t('cin-label')}</Form.Label>
                <Form.Control {...register('cin')} placeholder={t('cin-placeholder')} />
            </Form.Group>
            <Form.Group as={Col} xs={3}>
                <Form.Label>{t('tin-label')}</Form.Label>
                <Form.Control {...register('tin')} placeholder={t('tin-placeholder')} />
            </Form.Group>
        </Row>
        <h5 className='mb-3 mt-4 d-flex align-items-center'><MapPinIcon size={24} className='me-3' />{t('invoicing-address-title')}</h5>
        <Form.Group className='mb-3'>
            <Form.Label>{t('city-label')}</Form.Label>
            <Form.Control
                {...register('city')}
                placeholder={t('city-placeholder')}
            />
        </Form.Group>
        <Form.Group className='mb-3'>
            <Form.Label>{t('line1-label')}</Form.Label>
            <Form.Control
                {...register('line1')}
                placeholder={t('line1-placeholder')}
            />
        </Form.Group>
        <Row>
            <Form.Group as={Col} xs={6}>
                <Form.Label>{t('postal-code-label')}</Form.Label>
                <Form.Control
                    {...register('postalCode')}
                    placeholder={t('postal-code-placeholder')}
                />
            </Form.Group>
            <Form.Group as={Col} xs={6}>
                <Form.Label>{t('country-label')}</Form.Label>
                <ControlledCountrySelect
                    control={control}
                    name='country'
                />
            </Form.Group>
        </Row>
    </>);
}
