import { createGET } from '../rawApiObject';
import type { HydraCollection } from '@/types/api/result';
import { type VersionFromServer } from '@/components/notifications/NewVersionNotification';
import { type TaxRateFromServer, type CurrencyFromServer } from '@/modules/money';

export const other = {
    getVersion: createGET<VersionFromServer | null>(
        '/version',
    ),
    getCurrencies: createGET<HydraCollection<CurrencyFromServer>>(
        '/currencies',
    ),
    getTaxRates: createGET<HydraCollection<TaxRateFromServer>>(
        '/tax-rates',
    ),
};
