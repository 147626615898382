import React, { type ReactElement, type ReactNode } from 'react';
import { useLocalStorageState } from '@/utils/localStorage';
import { Button, Card } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { CloseButton } from '../forms/buttons';
import { DoubleArrowIcon, BankIcon, CalendarCheckIcon, CalendarClockIcon, ChainIcon, CircleSlashIcon, DiagonalArrowsIcon, EyeIcon, FlowlanceLogo, InvoiceIcon, ReceiptIcon, RepeatIcon, SmileIcon, UsersIcon, WalletIcon } from '../icons';
import clsx from 'clsx';
import StripeSLogo from '../icons/StripeSLogo';
import { IoWarningOutline } from 'react-icons/io5';
import { AiOutlineSafety } from 'react-icons/ai';
import { FaCirclePlay } from 'react-icons/fa6';
import { routes } from '@/router';
import { Link } from 'react-router-dom';

const BLOG_URL_PREFIX = 'https://flowlance.com/blog/';
const INFO_CARDS_KEY = 'info-cards';

type InfoKey = 'orders' | 'clients' | 'creditsOffboarding' | 'bankAccounts' | 'bankIntegration' | 'backpay' | 'pricingsGlobal' | 'stripeIntegration' | 'invoicingProfiles';

type InfoCardProps = Readonly<{
    infoKey: InfoKey;
    className?: string;
    primaryButton?: ReactNode;
    extraContent?: ReactNode;
}>;

export function InfoCard({ infoKey, className, primaryButton, extraContent }: InfoCardProps) {
    const { t } = useTranslation('common', { keyPrefix: `infoCards.${infoKey}` });
    const { t: td } = useTranslation('common', { keyPrefix: 'infoCards.default' });
    const info = infos[infoKey];

    const secondary: LinkProps | undefined = info.readMoreLink === undefined
        ? undefined
        : {
            label: t('secondary-button-label', { defaultValue: false }) || td('secondary-button-label'),
            href: info.readMoreLink,
        };

    return (
        <InfoCardCloseable
            infoKey={infoKey}
            title={t('title')}
            description={<Trans components={transComponents}>{t('description', { defaultValue: false })}</Trans>}
            icon={info.icon}
            mediaContent={info.mediaContent}
            extraContent={extraContent}
            primary={primaryButton}
            secondary={secondary}
            closeable={!info.notCloseable}
            className={className}
        />
    );
}

const transComponents: Record<string, ReactElement> = {
    s: <span className='fw-semibold' />,
    pr: <span className='text-primary' />,
    ol: <ol className='mb-0' />,
    ul: <ul className='mb-0' />,
    li: <li />,
    callLink: <a href='https://app.lemcal.com/@patrikkruntorad' target='_blank' rel='noreferrer' />,
    dashboardLink: <Link to={routes.dashboard} />,
    backpayLink: <Link to={routes.orders.newBackpay} />,
    customOrderLink: <Link to={routes.orders.newCustom} />,
};

type InfoCardCloseableProps = Omit<InfoCardCommonProps, 'onClose'> & {
    infoKey: InfoKey;
};

type InfoCardLocalStorageState = {
    closed: boolean;
};

function InfoCardCloseable({ infoKey, ...commonProps }: InfoCardCloseableProps) {
    const { cardState, close } = useInfoCardState(infoKey);

    if (cardState.closed)
        return null;

    return (
        <InfoCardCommon {...commonProps} onClose={close} />
    );
}

export function useInfoCardState(infoKey: InfoKey) {
    const [ infoCardsState, setInfoCardsState ] = useLocalStorageState<Record<string, InfoCardLocalStorageState>>(INFO_CARDS_KEY);
    const currentState = infoCardsState?.[infoKey] ?? { closed: false };
    if (infos[infoKey].notCloseable)
        currentState.closed = false;

    function open() {
        setInfoCardsState({ ...infoCardsState, [infoKey]: { ...currentState, closed: false } });
    }

    function close() {
        setInfoCardsState({ ...infoCardsState, [infoKey]: { ...currentState, closed: true } });
    }

    return {
        infoCardsState,
        setInfoCardsState,
        cardState: currentState,
        open,
        close,
    };
}

type LinkProps = Readonly<{
    href: string;
    label: ReactNode;
}>;

type InfoCardCommonProps = Readonly<{
    title: string;
    description?: ReactNode;
    icon?: ReactNode;
    mediaContent?: ReactNode;
    extraContent?: ReactNode;
    primary?: ReactNode;
    secondary?: LinkProps;
    onClose: () => void;
    closeable?: boolean;
    className?: string;
}>;

function InfoCardCommon({ title, description, icon, mediaContent, extraContent, onClose, primary, secondary, closeable = true, className }: InfoCardCommonProps) {
    return (
        <div className={clsx('d-flex justify-content-center', className)}>
            <Card className='sh-info-card border-0 w-100' style={{ maxWidth: '800px' }}>
                <Card.Body className='d-flex gap-3 pt-3 pb-3 ps-4' style={{ paddingRight: '3px' }}>
                    <div className='d-flex flex-column flex-grow-1 ps-1 pb-3'>
                        {icon && (
                            <div className='mb-3'>
                                {icon}
                            </div>
                        )}
                        <h2 className='mt-0 mb-2 fw-semibold'>{title}</h2>
                        <div className='flex-grow-1 sh-description-no-border mb-0'>{description}</div>
                        {extraContent}
                        {(primary || secondary) && (
                            <div className='d-flex gap-2 mt-4'>
                                {primary}
                                {secondary && (
                                    <a href={secondary.href} target='_blank' rel='noreferrer'>
                                        <Button className='compact sh-shadow-sm-dark' variant='light'>{secondary.label}</Button>
                                    </a>
                                )}
                            </div>
                        )}
                    </div>
                    {mediaContent && (
                        <div className='flex-shrink-0 py-3 d-flex align-items-center'>
                            {mediaContent}
                        </div>
                    )}
                    {closeable ? (
                        <div>
                            <CloseButton aria={`Close ${title}`} onClick={onClose} />
                        </div>
                    ) : (
                        <div className='flex-shrink-0' style={{ width: '40px' }} />
                    )}
                </Card.Body>
            </Card>
        </div>
    );
}

type Info = {
    icon: ReactNode;
    mediaContent?: ReactNode;
    readMoreLink?: string;
    notCloseable?: boolean;
}

export const infos: Record<InfoKey, Info> = {
    orders: {
        icon: roundedIcon(<InvoiceIcon size={16} />),
        mediaContent: (
            <VideoThumbnail
                href='https://www.youtube.com/watch?v=k0ySZEAoSsE'
                img='/images/info-cards/video-thumbnail.png'
                duration='0:59'
                text={'Learn how\nto create\ninvoices\nin seconds'}
            />
        ),
        // readMoreLink: BLOG_URL_PREFIX + 'how-to-set-up-payment',  // not up-to-date as of May 29, 2024
    },
    clients: {
        icon: doubleIcon(roundedIcon(<UsersIcon size={18} />), roundedIcon(<FlowlanceLogo size={24} />, 'bg-white')),
        mediaContent: <ClientsMediaContent />,
    },
    creditsOffboarding: {
        icon: roundedIcon(<IoWarningOutline size={24} style={{ paddingBottom: 2 }} />, 'bg-warning'),
        readMoreLink: BLOG_URL_PREFIX,
        notCloseable: true,
    },
    bankAccounts: {
        icon: roundedIcon(<BankIcon size={18} />),
        mediaContent: (
            <VideoThumbnail
                href='https://www.youtube.com/watch?v=e1gSbGwShqA'
                img='/images/info-cards/video-thumbnail.png'
                duration='1:16'
                text={'Set up your\npayments\nand start\nmaking money'}
            />
        ),
    },
    bankIntegration: {
        icon: doubleIcon(roundedIcon(<FlowlanceLogo size={24} />, 'bg-white'), roundedIcon(<BankIcon size={18} />)),
        mediaContent: <BankIntegrationMediaContent />,
        readMoreLink: 'https://flowlance.notion.site/Integrace-s-bankami-eaed6736122b46ac815cfad64bb6d8da',
        notCloseable: true,
    },
    backpay: {
        icon: roundedIcon(<CalendarCheckIcon size={18} />),
        mediaContent: (
            <VideoThumbnail
                href='https://www.youtube.com/watch?v=vCEO53U6beM'
                img='/images/info-cards/video-thumbnail.png'
                duration='0:36'
                text={'Get paid for\nall your\nclients\nat once'}
            />
        ),
    },
    pricingsGlobal: {
        icon: roundedIcon(<ReceiptIcon size={20} />),
    },
    stripeIntegration: {
        icon: doubleIcon(roundedIcon(<FlowlanceLogo size={24} />, 'bg-white'), <StripeSLogo />),
        mediaContent: <StripeMediaContent />,
        readMoreLink: 'https://stripe.com',
        notCloseable: true,
    },
    invoicingProfiles: {
        icon: roundedIcon(<DiagonalArrowsIcon size={22} />),
        mediaContent: (
            <VideoThumbnail
                href='https://www.youtube.com/watch?v=kFdVC4LGlas'
                img='/images/info-cards/video-thumbnail.png'
                duration='0:29'
                text={'Set up your\ncompany\'s\nbilling info'}
            />
        ),
    },
};

function roundedIcon(icon: ReactNode, className?: string): ReactNode {
    return (
        <div
            className={clsx('rounded-circle bg-primary text-white d-flex align-items-center justify-content-center sh-shadow-sm-dark', className)}
            style={{ width: '36px', height: '36px', lineHeight: '36px' }}
        >
            {icon}
        </div>
    );
}

function doubleIcon(icon1: ReactNode, icon2: ReactNode): ReactNode {
    return (
        <div className='d-flex align-items-center gap-2'>
            {icon1}
            <DoubleArrowIcon size={17} />
            {icon2}
        </div>
    );
}

type VideoThumbnailCommonProps = Readonly<{
    /** eg. {'Invoices from\nevents: Learn the\npower of\nFlowlance'} */
    text: string;
    /** eg. 'https://www.youtube.com/watch?v=dQw4w9WgXcQ' */
    href: string;
    /**
     * eg. '/images/info-cards/video-thumbnail-backpay.png'
     * Store in the /public/ directory. The img path is relative to it.
     */
    img: string;
    /** eg. '1:34' */
    duration: string;
}>;

function VideoThumbnail({ text, href, img, duration }: VideoThumbnailCommonProps) {
    const { t } = useTranslation('common', { keyPrefix: 'infoCards.default' });

    return (
        <a href={href} target='_blank' rel='noreferrer' className='user-select-none text-decoration-none non-draggable'>
            <div className='sh-video-thumbnail position-relative'>
                <span className='text-white text-decoration-none pre-wrap fw-semibold w-50 ps-2'>
                    {text}
                </span>
                <div className='w-50 px-2'>
                    <img src={img} className='rounded-3 w-100' alt={text} />
                </div>

                <div className='d-flex align-items-center justify-content-center position-absolute w-100 h-100'>
                    <div className='d-flex align-items-center bg-primary rounded-5 p-1 text-white'>
                        <FaCirclePlay size={26} />
                        <span className='fw-semibold text-center px-2'>{t('video-thumbnail-watch')}</span>
                    </div>
                </div>

                <div className='position-absolute bottom-0 end-0 pe-1 pb-2'>
                    <span className='bg-opacity-50 bg-black rounded-2 py-1 px-2 text-white fw-medium'>
                        {duration}
                    </span>
                </div>
            </div>
        </a>
    );
}

function ClientsMediaContent() {
    const { t } = useTranslation('common', { keyPrefix: 'infoCards.clients' });
    return (
        <div className='d-flex flex-column gap-3'>
            <div className='d-flex align-items-center'><EyeIcon size={16} className='text-primary me-3'/>{t('media-eye')}</div>
            <div className='d-flex align-items-center'><InvoiceIcon size={16} className='text-primary me-3'/>{t('media-invoice')}</div>
            <div className='d-flex align-items-center'><CircleSlashIcon size={16} className='text-primary me-3'/>{t('media-slash')}</div>
        </div>
    );
}

function StripeMediaContent() {
    const { t } = useTranslation('common', { keyPrefix: 'infoCards.stripeIntegration' });
    return (
        <div className='d-flex flex-column gap-3'>
            <div className='d-flex align-items-center'><CalendarClockIcon size={16} className='text-primary me-3'/>{t('media-clock')}</div>
            <div className='d-flex align-items-center'><ChainIcon size={16} className='text-primary me-3'/>{t('media-chain')}</div>
            <div className='d-flex align-items-center'><WalletIcon size={16} className='text-primary me-3'/>{t('media-wallet')}</div>
            <div className='d-flex align-items-center'><SmileIcon size={16} className='text-primary me-3'/>{t('media-smile')}</div>
        </div>
    );
}

function BankIntegrationMediaContent() {
    const { t } = useTranslation('common', { keyPrefix: 'infoCards.bankIntegration' });
    return (
        <div className='d-flex flex-column gap-3'>
            <div className='d-flex align-items-center'><RepeatIcon size={16} className='text-primary me-3'/>{t('media-up-to-date')}</div>
            <div className='d-flex align-items-center'><ChainIcon size={16} className='text-primary me-3'/>{t('media-automatic')}</div>
            <div className='d-flex align-items-center'><AiOutlineSafety size={16} className='text-primary me-3'/>{t('media-safety')}</div>
            <div className='d-flex align-items-center'><IoWarningOutline size={16} className='text-primary me-3'/>{t('media-experimental')}</div>
        </div>
    );
}
