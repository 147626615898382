import React, { useCallback, useState } from 'react';
import { api } from '@/utils/api/backend';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CheckIcon, EyeIcon } from '@/components/icons';
import Portal, { portals } from '@/components/common/Portal';
import { SpinnerButton } from '@/components/common';
import { useMaster } from '@/context/UserProvider';
import { useBlockerModal } from '@/hooks';
import BlockNavigationModal from '@/components/BlockNavigationModal';
import GeneralSupplierForm from '@/components/settings/GeneralSupplierForm';
import PersonalizationForm from '@/components/settings/PersonalizationForm';
import { InvoicingProfile, type InvoicingProfileUpdate } from '@/types/Invoicing';
import useNotifications from '@/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '@/components/notifications';
import { InfoTooltip } from '@/components/forms/buttons';
import { AddProfileButton } from './InvoicingProfilesEditor';
import { InfoCard } from '@/components/settings/InfoCard';

type InvoicingProfileEditorProps = Readonly<{
    type: 'general' | 'custom';
}>;

export default function InvoicingProfileEditor({ type }: InvoicingProfileEditorProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profile' });
    const { profiles, setProfiles } = useMaster();
    const profile = profiles[0];

    const setProfile = useCallback((profile: InvoicingProfile) => {
        setProfiles(oldProfiles => {
            const index = oldProfiles.findIndex(p => p.id.equals(profile.id));
            const newProfiles = [ ...oldProfiles ];
            newProfiles[index] = profile;

            return newProfiles;
        });
    }, [ setProfiles ]);

    const [ isDirty, setIsDirty ] = useState(false);
    const { control } = useBlockerModal(isDirty);

    return (
        <div>
            <BlockNavigationModal control={control} />
            <div className='d-flex align-items-top mb-4'>
                <h1 className='m-0'>{t(`${type}-page-title`)}</h1>
                {type === 'general' && (<>
                    <div className='flex-grow-1' />
                    <div style={{ height: '28.8px' }}>
                        <AddProfileButton className='compact' />
                    </div>
                    <div style={{ position: 'relative', left: '7px' }}>
                        <InfoTooltip text={t('new-profile-tooltip')} />
                    </div>
                </>)}
            </div>
            <InfoCard infoKey='invoicingProfiles' className='mb-4' />
            <InvoicingProfileFormPart profile={profile} setProfile={setProfile} isDirty={isDirty} setIsDirty={setIsDirty} type={type} />
        </div>
    );
}

const formParts = {
    general: GeneralSupplierForm,
    custom: PersonalizationForm,
};

type InvoicingProfileFormPartProps = {
    profile: InvoicingProfile;
    setProfile: (newProfile: InvoicingProfile) => void;
    type: keyof typeof formParts;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
};

function InvoicingProfileFormPart({ profile, setProfile, type, isDirty, setIsDirty }: InvoicingProfileFormPartProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profile' });
    const [ isFetching, setIsFetching ] = useState(false);
    const { addAlert } = useNotifications();

    async function onSubmit(update: InvoicingProfileUpdate) {
        setIsFetching(true);
        const response = await api.settings.updateInvoicingProfile({ id: profile.id, type }, update);
        setIsFetching(false);
        if (!response.status){
            addAlert(createTranslatedErrorAlert());
            return;
        }

        setProfile(InvoicingProfile.fromServer(response.data));
        addAlert(createTranslatedSuccessAlert('pages:invoicing-profile.save-success-alert'));
    }

    const saveButton = useCallback(({ onClick }: { onClick: () => void }) => <SaveButton onClick={onClick} isFetching={isFetching} />, [ isFetching ]);
    const InnerForm = formParts[type];

    return (<>
        <InnerForm
            onSubmit={onSubmit}
            input={profile}
            onChange={setIsDirty}
            saveButton={isDirty ? saveButton : undefined}
        />
        <div className='d-flex gap-3 mt-4'>
            <a href={`${profile.id.toIRI()}/example`} target='_blank' rel='noreferrer' className='text-decoration-none'>
                <Button variant='outline-primary'>
                    <EyeIcon size={22} className='me-2'/>
                    {t('example-invoice-button')}
                </Button>
            </a>
            <div id={portals.invoicingProfile.save} />
        </div>
    </>);
}

type SaveButtonProps = Readonly<{
    onClick: () => void;
    isFetching: boolean;
}>;

function SaveButton({ onClick, isFetching }: SaveButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'invoicing-profile' });

    return (
        <Portal to={portals.invoicingProfile.save}>
            <SpinnerButton
                type='submit'
                isFetching={isFetching}
                variant='primary'
                onClick={onClick}
            >
                <CheckIcon size={22} />
                {t('save-button')}
            </SpinnerButton>
        </Portal>
    );
}
