import React, { useState, type ReactNode } from 'react';
import { Container, Row, Col, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { useUser } from '@/context/UserProvider';
import { api } from '@/utils/api/backend';
import { DateTime } from 'luxon';
import { SubscriptionPeriod, SubscriptionCode, Subscription } from '@/types/Subscription';
import { getStringEnumValues } from '@/utils/common';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import ErrorMessage from '@/components/forms/ErrorMessage';
import { MoneyDisplay, SpinnerButton } from '@/components/common';
import { IoSettingsSharp } from 'react-icons/io5';
import { type Money, type Currency } from '@/modules/money';
import { useDaysLeft } from '@/hooks';
import type { TFunction } from 'i18next';
import { UserRole } from '@/types/Team';

const TEAM_OPTION_BUTTON_LINK = 'https://app.lemcal.com/@stepancalta';

export default function Subscriptions() {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const { subscription, role } = useUser();

    return (
        <Container className='sh-subscription-page pb-5'>
            <h2>{t('current-subscription-section-title')}</h2>
            <Card className='mb-2'>
                <Card.Body className='d-flex align-items-baseline'>
                    {subscription
                        ? (
                            <SubscriptionInner subscription={subscription} />
                        ) : (
                            <>{t('no-subscription-label')}</>
                        )
                    }
                </Card.Body>
            </Card>
            {!subscription?.isActive && (
                <ErrorMessage message={t('no-plan-selected-text')} />
            )}
            {role === UserRole.Freelancer && (
                <div className='mt-5'>
                    <SubscriptionSelect />
                </div>
            )}
            {role === UserRole.Master && (
                <div className='mt-5 text-center w-100 fs-4 pre-line'>
                    <Trans
                        t={t}
                        i18nKey='master-text'
                        components={{
                            email: <a href='mailto:info@flowlance.com' target='_blank' rel='noreferrer' className='text-decoration-none' />,
                        }}
                    />
                </div>
            )}
        </Container>
    );
}

type SubscriptionInnerProps = Readonly<{
    subscription: Subscription;
}>;

function SubscriptionInner({ subscription }: SubscriptionInnerProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });

    const { label, className } = getPlanLabelAndClassName(subscription);

    return <>
        <span className='fs-2 me-3'>{t(`${subscription.code}.label`)}</span>
        <span className={clsx('fs-2 fw-semibold', className)}>
            {t(label)}
        </span>
        <span className='flex-grow-1'/>
        {subscription.isTrial ? (
            <DaysLeft dateTo={subscription.dateToTrialEnd} />
        ) : (
            <ManagePlan />
        )}
    </>;
}

function getPlanLabelAndClassName(subscription: Subscription) {
    if (subscription.isTrial) {
        return subscription.isActive
            ? { label: 'trial-active-label', className: 'text-warning' }
            : { label: 'trial-ended-label', className: 'text-danger' };
    }

    return subscription.isActive
        ? { label: 'plan-active-label', className: 'text-success' }
        : { label: 'plan-ended-label', className: 'text-danger' };
}

type DaysLeftProps = Readonly<{
    dateTo: DateTime;
}>;

function DaysLeft({ dateTo }: DaysLeftProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const count = useDaysLeft(dateTo);

    return (
        <span>
            {t('trial-days-left-label', { count })}
        </span>
    );
}

export async function goToCustomerPortal(setIsFetching: React.Dispatch<React.SetStateAction<boolean>>) {
    setIsFetching(true);

    const response = await api.team.createSubscriptionCustomerPortal({});
    if (!response.status) {
        // TODO display error
        setIsFetching(false);
        return;
    }

    window.location.href = response.data.customerPortalUrl;
}

function ManagePlan() {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const [ isFetching, setIsFetching ] = useState(false);

    function managePlan() {
        goToCustomerPortal(setIsFetching);
    }

    return (
        <SpinnerButton
            isFetching={isFetching}
            onClick={managePlan}
            className='sh-button-inverse'
        >
            {t('manage-plan-button')} <IoSettingsSharp size={20} className='ms-2' />
        </SpinnerButton>
    );
}

/** This extends SubscriptionCode by the team option, which can be bought, but then it's mapped to the Paid2 tier. */
enum SubscriptionType {
    Free = 'free',
    Paid2 = 'paid2',
    Team = 'team',
}

function subscriptionCodeFromType(type: SubscriptionType): SubscriptionCode {
    if (type === SubscriptionType.Free)
        return SubscriptionCode.Free;
    if (type === SubscriptionType.Paid2)
        return SubscriptionCode.Paid2;

    throw new Error(`Unknown subscription type: ${type}`);
}

function subscriptionCodeEqualsType(code: SubscriptionCode, type: SubscriptionType): boolean {
    return '' + code === type;
}

const SUBSCRIPTION_TYPES = getStringEnumValues(SubscriptionType);

function SubscriptionSelect() {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const [ paymentPeriod, setPaymentPeriod ] = useState(SubscriptionPeriod.Year);

    return (<>
        <Row className='mb-5'>
            <Col>
                <Button
                    className={clsx('sh-subscription-button float-end', { 'sh-button-inverse': paymentPeriod === SubscriptionPeriod.Year })}
                    onClick={() => setPaymentPeriod(SubscriptionPeriod.Month)}
                    disabled={paymentPeriod === SubscriptionPeriod.Month}
                >
                    {t('month-button')}
                </Button>
            </Col>
            <Col>
                <Button
                    className={clsx('sh-subscription-button', { 'sh-button-inverse': paymentPeriod === SubscriptionPeriod.Month })}
                    onClick={() => setPaymentPeriod(SubscriptionPeriod.Year)}
                    disabled={paymentPeriod === SubscriptionPeriod.Year}
                >
                    {t('year-button')}
                </Button>
            </Col>
        </Row>
        <div className='sh-subscription-container'>
            {SUBSCRIPTION_TYPES.map(type => (
                <SubscriptionOption
                    key={type}
                    type={type}
                    paymentPeriod={paymentPeriod}
                />
            ))}
        </div>
    </>);
}

export function getClientReferenceId(): string {
    const referenceId = 'Rewardful' in window
        && window.Rewardful
        && typeof window.Rewardful === 'function'
        && 'referral' in window.Rewardful
        && window.Rewardful.referral
        || ('checkout_'+ DateTime.now().toMillis());

    return '' + referenceId;
}

type SubscriptionOptionProps = Readonly<{
    type: SubscriptionType;
    paymentPeriod: SubscriptionPeriod;
}>;

function SubscriptionOption({ type, paymentPeriod }: SubscriptionOptionProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const { t: tType } = useTranslation('pages', { keyPrefix: `subscription.${type}` });

    const { subscription, setSubscription, team } = useUser();
    const [ isFetching, setIsFetching ] = useState(false);

    async function buySubscription(type: SubscriptionType, paymentPeriod: SubscriptionPeriod) {
        const code = subscriptionCodeFromType(type);
        if (!code)
            return;

        // If the buy subscription is same as the current redirect appUser to customer portal without code and paymentPeriod.
        if (
            subscription?.isActive &&
            subscription?.code === code &&
            subscription?.paymentPeriod === paymentPeriod
        ) {
            goToCustomerPortal(setIsFetching);
            return;
        }

        // If the current subscription is active, we just redirect appUser to the customer portal.

        // If none of the above is true, we redirect him to a page where he can buy a new subscription.
        setIsFetching(true);
        const response = subscription.isActive
            ? await api.team.updateSubscription({ code, paymentPeriod })
            : await api.team.createSubscription({ code, paymentPeriod, clientReferenceId: getClientReferenceId() });
        setIsFetching(false);
        if (!response.status) {
            // TODO display error
            return;
        }

        if ('subscription' in response.data) {
            const newSubscription = Subscription.fromServer(response.data.subscription);
            setSubscription(newSubscription);
        }
        else {
            window.location.href = response.data.subscriptionSessionUrl;
        }
    }

    const isSelected = type === SubscriptionType.Paid2;
    const isActive = subscription?.isActive
        && !subscription.isTrial
        && subscriptionCodeEqualsType(subscription.code, type)
        && (paymentPeriod === subscription.paymentPeriod || type === SubscriptionType.Free);

    return (
        <div className={clsx('sh-subscription-option', { 'sh-selected': isSelected })}>
            <div className='d-flex align-items-baseline justify-content-between'>
                <h2 className='mb-4'>{tType('label')}</h2>
                {isSelected && (
                    <span className='sh-most-popular rounded-5 p-2'>{t('most-popular-label')}</span>
                )}
            </div>
            {priceRow(t, paymentPeriod, type, team.stripeCurrency)}
            <div className='mt-4'>
                {type === SubscriptionType.Team ? (
                    <a href={TEAM_OPTION_BUTTON_LINK} target='_blank' rel='noreferrer'>
                        <Button className='rounded-5 w-100 py-2'>
                            {tType('button')}
                        </Button>
                    </a>
                ) : (
                    <SpinnerButton
                        isFetching={isFetching}
                        onClick={() => buySubscription(type, paymentPeriod)}
                        className='rounded-5 w-100 py-2'
                    >
                        {isActive
                            ? t('manage-plan-button')
                            : tType('button')
                        }
                    </SpinnerButton>
                )}
            </div>
            <div className='mt-4 sh-subscription-option-description fw-medium'>
                <Trans
                    t={tType}
                    i18nKey='description'
                    components={{ pr: <span className='text-primary-300 fw-semibold' /> }}
                />
            </div>
            {/* <div className='mt-4'>
                {tType('features').split(';').map(text => (
                    <BulletPoint text={text} key={text} />
                ))}
            </div> */}
        </div>
    );
}

function priceRow(t: TFunction, period: SubscriptionPeriod, type: SubscriptionType, currency: Currency) {
    const monthPrice = SUBSCRIPTION_PRICES[currency.code][SubscriptionPeriod.Month][type];
    const month = toFixedMoney(monthPrice, currency);
    const monthSeat = toSeatMoney(monthPrice, currency);

    if (period === SubscriptionPeriod.Month) {
        return (
            <div>
                <MoneyDisplay money={month} className='fs-1 fw-semibold' />
                <span className='ms-2 fs-2 text-muted'>{t('month-unit')}</span>
                {monthSeat && (<>
                    <span className='ms-3'>+ </span>
                    <MoneyDisplay money={monthSeat} />
                    <span className='ms-2 text-muted'>{t('seat-unit')}</span>
                </>)}
            </div>
        );
    }

    const yearPrice = SUBSCRIPTION_PRICES[currency.code][SubscriptionPeriod.Year][type];
    const year = toFixedMoney(yearPrice, currency);
    const yearSeat = toSeatMoney(yearPrice, currency);

    return (<>
        <div>
            <MoneyDisplay money={year} className='fs-1 fw-semibold' />
            <span className='ms-2 fs-2 text-muted'>{t('month-unit')}</span>
            {yearSeat && (<>
                <span className='ms-3'>+ </span>
                <MoneyDisplay money={yearSeat} />
                <span className='ms-2 text-muted'>{t('seat-unit')}</span>
            </>)}
        </div>
        {type !== SubscriptionType.Free && (
            <div className='h-0 position-relative'>
                <MoneyDisplay money={month} className='text-decoration-line-through text-muted' />
                <span className='ms-2 text-muted'>{t('month-unit')}</span>
                {monthSeat && yearSeat && (<>
                    <span className='ms-3'>+ </span>
                    <MoneyDisplay money={monthSeat} className='text-decoration-line-through text-muted' />
                    <span className='ms-2 text-muted'>{t('seat-unit')}</span>
                </>)}
            </div>
        )}
    </>);
}

type BulletPointProps = Readonly<{
    text: string;
}>;

function BulletPoint({ text }: BulletPointProps) {
    return (
        <div className='d-flex align-items-center mb-2'>
            <span className='text-primary'>
                <IoIosCheckmarkCircle size={36} />
            </span>
            <span className='ms-3 pb-1'>
                <Trans
                    components={{ a: <ClientsTooltip /> }}
                >
                    {text}
                </Trans>
            </span>
        </div>
    );
}

type ClientsTooltipProps = Readonly<{
    children?: ReactNode;
}>;

function ClientsTooltip({ children }: ClientsTooltipProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    return (
        <OverlayTrigger
            placement='left'
            overlay={<Tooltip>{t('clients-tooltip')}</Tooltip>}
        >
            <span className='text-decoration-underline'>
                {children}
            </span>
        </OverlayTrigger>
    );
}

type PriceNumber = number | { fixed: number, seat: number };
function toFixedMoney(price: PriceNumber, currency: Currency): Money {
    const amount = typeof price === 'number' ? price : price.fixed;
    return { amount, currency };
}

function toSeatMoney(price: PriceNumber, currency: Currency): Money | undefined {
    if (typeof price === 'number')
        return;

    return { amount: price.seat, currency };
}

const SUBSCRIPTION_PRICES: {
    [key: string]: {
        [key in SubscriptionPeriod]: {
            [key in SubscriptionType]: PriceNumber;
        };
    };
} = {
    'CZK': {
        [SubscriptionPeriod.Month]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 1200,
            [SubscriptionType.Team]: { fixed: 2500, seat: 150 },
        },
        [SubscriptionPeriod.Year]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 960,
            [SubscriptionType.Team]: { fixed: 2000, seat: 99 },
        },
    },
    'EUR': {
        [SubscriptionPeriod.Month]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 49,
            [SubscriptionType.Team]: { fixed: 99, seat: 6 },
        },
        [SubscriptionPeriod.Year]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 39,
            [SubscriptionType.Team]: { fixed: 79, seat: 4 },
        },
    },
    'USD': {
        [SubscriptionPeriod.Month]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 49,
            [SubscriptionType.Team]: { fixed: 99, seat: 6 },
        },
        [SubscriptionPeriod.Year]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 39,
            [SubscriptionType.Team]: { fixed: 79, seat: 4 },
        },
    },
};
