import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { type PreferencesSettingsUpdate, type Settings } from '@/types/Settings';
import { SpinnerButton } from '../common';
import { type CountryCode, type LocaleCode, type TimezoneCode } from '@/types/i18n';
import { useTranslation } from 'react-i18next';
import { ControlledCountrySelect, ControlledLocaleSelect, ControlledTimezoneSelect } from '@/components/forms';

type PreferencesFormData = {
    timezone: TimezoneCode;
    locale: LocaleCode;
    country: CountryCode;
    // currencyIRI: CurrencyIRI;
    hideEventPrices: boolean;
};

type PreferencesFormProps = Readonly<{
    input: Settings;
    onSubmit: (output: PreferencesSettingsUpdate) => Promise<void>;
}>;

export default function PreferencesForm({ input, onSubmit }: PreferencesFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });

    const [ isFetching, setIsFetching ] = useState(false);

    const { register, handleSubmit, reset, control } = useForm<PreferencesFormData>();

    useEffect(() => {
        reset({
            timezone: input.timezone,
            locale: input.locale,
            country: input.country,
            // currencyIRI: input.currency.toIRI(),
            hideEventPrices: input.hideEventPrices,
        });
    }, [ input ]);

    async function onValid(data: PreferencesFormData) {
        setIsFetching(true);
        await onSubmit({
            timezone: data.timezone,
            locale: data.locale,
            country: data.country,
            // currency: data.currencyIRI,
            hideEventPrices: data.hideEventPrices,
        });
        setIsFetching(false);
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onValid)} className='sh-design'>
            <Row className='gap-row-3'>
                {/* TODO move this to team settings.
                <Form.Group as={Col} xs={6}>
                    <Form.Label>
                        {t('default-currency-label')}
                        <InfoTooltip text={t('default-currency-tooltip')} className='ms-2'/>
                    </Form.Label>
                    <ControlledCurrencySelect
                        control={control}
                        name='currencyIRI'
                    />
                </Form.Group>*/}
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('timezone-label')}</Form.Label>
                    <ControlledTimezoneSelect
                        control={control}
                        name='timezone'
                    />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('locale-label')}</Form.Label>
                    <ControlledLocaleSelect
                        control={control}
                        name='locale'
                        type='appUser'
                    />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('country-label')}</Form.Label>
                    <ControlledCountrySelect
                        control={control}
                        name='country'
                    />
                </Form.Group>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <Form.Check
                        {...register('hideEventPrices')}
                        type='switch'
                        label={t('hide-event-prices-toggle')}
                        id='hide-event-prices-toggle'
                    />
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <SpinnerButton
                        type='submit'
                        isFetching={isFetching}
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </Col>
            </Row>
        </Form>
    );
}
