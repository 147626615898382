import { eventIsBillable, type Event } from '../Event';
import { localizer } from '@/lib/calendar';
import { computeIfAbsent, MILLISECONDS_IN_SECOND, secondsToMinutes } from '@/utils/common';
import type { GoogleCalendar, GoogleEvent } from './Google';
import { type DateTime } from 'luxon';
import { type Money } from '@/modules/money';

export enum EventType {
    Ready = 'ready',
    Finished = 'finished',
    Canceled = 'canceled',
}

type CalendarEventResource<T extends string> = {
    type: T;
};

export type FullEventResource = CalendarEventResource<'event'> & {
    event: Event;
    totalPrice?: Money;
    calendar?: GoogleCalendar;
};

export type GoogleEventResource = CalendarEventResource<'google'> & {
    event: GoogleEvent;
    calendar: GoogleCalendar;
};

export type DraftEventResource = CalendarEventResource<'draft'>;

export type AnyEventResource = FullEventResource | DraftEventResource | GoogleEventResource;

// according to
// https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/props--events
export type CalendarEvent<TResource extends AnyEventResource = AnyEventResource> = {
    title: string;
    start: DateTime;
    end: DateTime;
    allDay?: boolean;
    resource: TResource;
    startDay: DateTime;
    /** in whole days (so usually 0) */
    duration: number;
    /** If not set, the title is used instead. */
    tooltip?: string;
    id: string;
    isBillable: boolean;
};

function durationInDays(start: DateTime, end: DateTime): number {
    return Math.max(localizer.diff(start, localizer.ceil(end, 'day'), 'day'));
}

export function eventsToCalendarEvents(events: Event[], calendars: GoogleCalendar[], activeIds: Set<string>, defaultCalendarId?: string): CalendarEvent<FullEventResource>[] {
    const enabledCalendars = calendars.filter(calendar => activeIds.has(calendar.id));
    const output: CalendarEvent<FullEventResource>[] = [];

    events.map(eventToCalendarEvent).forEach(event => {
        const calendarId = event.resource.event.googleCalendarId;
        // The event doesn't have a calendar - it's our event, so we don't need to filter it.
        // Or, the event is in the default calendar. That means it's our event that was written to the default Google calendar, so it should be displayed as-is.
        if (!calendarId || calendarId === defaultCalendarId) {
            output.push(event);
            return;
        }

        // could be activeIds.has(calendarId), but it's possible an activeId is from a no-longer-accessible calendar (or something idk)
        /** corresponding enabled (visible) calendar of the current user */
        const calendar = enabledCalendars.find(c => c.id === calendarId);
        // The event has a calendar, but the calendar is hidden by the user OR the calendar is from a different account (team member)
        if (!calendar) {
            /** corresponding calendar of the current user */
            const userCalendar = calendars.find(c => c.id === calendarId);
            if (!userCalendar)  // the calendar is not accessible (the event is from a team member)
                output.push(event);
            return;
        }

        event.resource.calendar = calendar;
        output.push(event);
    });

    return output;
}

export function eventToCalendarEvent(event: Event): CalendarEvent<FullEventResource> {
    return {
        title: event.title,
        start: event.dateFrom,
        end: event.dateTo,
        resource: {
            type: 'event',
            event,
            totalPrice: getEventPrice(event),
        },
        startDay: localizer.startOf(event.dateFrom, 'day'),
        duration: durationInDays(event.dateFrom, event.dateTo),
        id: event.id.toIRI() as unknown as string,
        isBillable: eventIsBillable(event),
    };
}

function getEventPrice(event: Event): Money | undefined {
    if (event.isInPackage)
        return undefined;

    const pricesByCurrency = new Map<string, Money>();
    event.clients.forEach(client => {
        const { amount, currency } = client.payment.price;
        const current = computeIfAbsent(pricesByCurrency, currency.code, () => ({ amount: 0, currency }));
        current.amount += amount;
    });

    if (pricesByCurrency.size === 0)
        return undefined;

    // TODO what if there are multiple currencies? It shouldn't be possible now, but might be eventually.
    // TODO what if there are no currencies?

    return pricesByCurrency.values().next().value;
}

let nextDraftId = 0;

export function createDraftEvent(start: DateTime, end: DateTime, title: string): CalendarEvent<DraftEventResource> {
    return {
        start,
        end,
        title,
        resource: {
            type: 'draft',
        },
        startDay: localizer.startOf(start, 'day'),
        duration: durationInDays(start, end),
        id: 'draft_' + nextDraftId++,
        isBillable: false,
    };
}

export function isDraftEvent(event: CalendarEvent): event is CalendarEvent<DraftEventResource> {
    return event.resource.type === 'draft';
}

export function isFullEvent(event: CalendarEvent): event is CalendarEvent<FullEventResource> {
    return event.resource.type === 'event';
}

export function isGoogleEvent(event: CalendarEvent): event is CalendarEvent<GoogleEventResource> {
    return event.resource.type === 'google';
}

const MINUTES_PER_LINE = 20;

export function getEventSize(event: CalendarEvent): number | 'all' {
    if (event.allDay)
        return 'all';

    const durationInMinutes = secondsToMinutes((event.end.toMillis() - event.start.toMillis()) / MILLISECONDS_IN_SECOND);
    return Math.floor(durationInMinutes / MINUTES_PER_LINE);
}
