import React from 'react';
import { type InvoicingProfile } from '@/types/Invoicing';
import OrdersTable from '../orders/OrdersTable';


type InvoicingProfileOrdersProps = Readonly<{
    profile: InvoicingProfile;
}>;

export default function InvoicingProfileOrders({ profile }: InvoicingProfileOrdersProps) {
    return (
        <OrdersTable filterProfile={profile} />
    );
}
