import React, { useCallback } from 'react';
import FormSelect from './FormSelect';
import { localeToOption, type LocaleCode, SUPPORTED_LOCALES, type LocaleType } from '@/types/i18n';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';

type LocaleSelectProps = Readonly<{
    value?: LocaleCode;
    onChange: (value?: LocaleCode) => void;
    type: LocaleType;
    placeholder?: string;
    disabled?: boolean;
}>;

export function LocaleSelect({ value, onChange, type, placeholder, disabled }: LocaleSelectProps) {

    const options = SUPPORTED_LOCALES[type].map(localeToOption);

    return (
        <FormSelect
            options={options}
            value={value ? localeToOption(value) : undefined}
            onChange={option => onChange(option?.value)}
            placeholder={placeholder}
            isDisabled={disabled}
        />
    );
}

type ControlledLocaleSelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    type: LocaleType;
    placeholder?: string;
    disabled?: boolean;
};

export function ControlledLocaleSelect<TFieldValues extends FieldValues>({ control, name, type, placeholder, disabled }: ControlledLocaleSelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value?: LocaleCode, onChange: (value?: LocaleCode ) => void } }) => {
        return (
            <LocaleSelect
                value={field.value}
                onChange={field.onChange}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
            />
        );
    }, [ type ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}
