import { api } from '@/utils/api/backend';
import { dehydrate } from '@/types/api/result';
import { useCallback, useEffect, useState } from 'react';
import type { DefaultValue } from './utils';
import { Product } from '@/types/Product';

export function useProducts(options: { default: DefaultValue<Product[]> }): {
    products: Product[];
    updateProducts: (product: Product, isDeleted?: boolean) => void;
}

export function useProducts(): {
    products: Product[] | undefined;
    updateProducts: (product: Product, isDeleted?: boolean) => void;
};

export function useProducts(options?: { default?: DefaultValue<Product[]> }) {
    const [ products, setProducts ] = useState(options?.default);

    const fetchProducts = useCallback(async (signal?: AbortSignal) => {
        const response = await api.product.getAll(signal);
        if (response.status) {
            const { items } = dehydrate(response);
            setProducts(items.map(Product.fromServer));
        }
    }, []);

    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchProducts(signal);

        return abort;
    }, [ fetchProducts ]);

    const updateProducts = useCallback((product: Product, isDeleted?: boolean) => {
        setProducts(products => {
            if (isDeleted)
                return products?.filter(p => !p.id.equals(product.id));

            if (!products)
                return [ product ];

            const newProducts = [ ...products ];
            const index = newProducts.findIndex(p => p.id.equals(product.id));
            if (index === -1)
                newProducts.push(product);
            else
                newProducts[index] = product;

            return newProducts;
        });
    }, []);

    return {
        products,
        updateProducts,
    };
}
