import { type SubscriptionInit, type SubscriptionResponseFromServer, type SubscriptionUpdate } from '@/types/Subscription';
import { createGET, createPOST, createPUT } from '../rawApiObject';
import { type Id } from '@/types/Id';
import { type TeamMemberFromServer, type TeamFromServer, type TeamUpdateToServer } from '@/types/Team';
import { type HydraCollection } from '@/types/api/result';
import { type OrdersStatsFromServer } from '@/types/orders/OrdersStats';
import { type EmptyObject } from '@/utils/common';

export const team = {
    get: createGET<{ id: Id }, TeamFromServer>(
        u => `${u.id.toIRI()}`,
    ),
    getMembers: createGET<HydraCollection<TeamMemberFromServer>>(
        '/team-members',
    ),
    update: createPUT<TeamFromServer, TeamUpdateToServer>(
        '/team',
    ),
    createPaymentEmail: createPOST<TeamFromServer, { email: string }>(
        '/teams/payment-email',
    ),
    getOrdersStats: createPOST<OrdersStatsFromServer, { periods: { dateFrom: string, dateTo: string }[] }>(
        '/teams/order-stats',
    ),
    connectStripe: createPOST<{ onboardingUrl: string }, EmptyObject>(
        '/teams/connect-stripe',
    ),
    createSubscription: createPOST<SubscriptionResponseFromServer, SubscriptionInit>(
        '/teams/subscriptions/create',
    ),
    updateSubscription: createPOST<SubscriptionResponseFromServer, SubscriptionUpdate>(
        '/teams/subscriptions/update',
    ),
    createSubscriptionCustomerPortal: createPOST<{ customerPortalUrl: string }, EmptyObject>(
        '/teams/customer-portal',
    ),
};
