import { type Currency, type Money, type CurrencyIRI, getCurrency, moneyFromServer, getDefaultCurrency, tryGetCurrency } from '@/modules/money';
import { floatToPercent } from '@/utils/math';
import { DateTime } from 'luxon';
import { OrderInfo, type OrderInfoFromServer, OrderState } from './Order';
import { PERIODS_COUNT } from '@/components/orders/OrdersStatsDisplay';

export type OrdersStatsFromServer = {
    /** From the latest one to the earliest. */
    periods: PeriodFromServer[];
    currencies: PerCurrencyFromServer[];
};

type PeriodFromServer = {
    dateFrom: string;
    dateTo: string;
};

export type DateRange = {
    from: DateTime;
    to: DateTime;
};

type PerPeriodBaseFromServer = {
    productsSold: number;
    activeClients: number;
};

type PerPeriodFullFromServer = PerPeriodBaseFromServer & {
    [key in OrderStatsState]: number;
};

type PerCurrencyFromServer = {
    currency: CurrencyIRI;
    periods: [
        PerPeriodFullFromServer,
        PerPeriodFullFromServer,
        PerPeriodBaseFromServer,
        PerPeriodBaseFromServer,
    ];
    orders: OrderInfoFromServer[];
};

export class OrdersStats {
    readonly currencies: Currency[];

    private constructor(
        readonly perCurrency: PerCurrency[],
        /** Inclusive. */
        readonly periods: DateRange[],
    ) {
        this.currencies = perCurrency.map(currency => currency.currency);
    }

    static fromServer(input: OrdersStatsFromServer): OrdersStats {
        const periods: DateRange[] = input.periods.map(period => ({ from: DateTime.fromISO(period.dateFrom), to: DateTime.fromISO(period.dateTo) }));
        // The current period might be shorter/longer than the previous one. So we want to adjust the values to be comparable.
        const rangeCoefficient = computePeriodLength(periods[1]) / computePeriodLength(periods[0]);

        const pointRanges = computePointRanges(periods);
        const perCurrency = input.currencies.map(currency => perCurrencyFromServer(currency, pointRanges, rangeCoefficient));

        // We use point ranges here because we want the inclusive `to` date.
        return new OrdersStats(perCurrency, pointRanges);
    }

    static createExample(): OrdersStats {
        return new OrdersStats([ generateExampleStats() ], []);
    }
}

export type PerCurrency = {
    currency: Currency;
    productsSold: TimeChartData<number>;
    activeClients: TimeChartData<number>;
    totalValue: Money;
    /** In percent. */
    totalIncrease: number;
    states: {
        [key in OrderStatsState]: OrderStateStats;
    };
    /** In percent. This is needed to properly align the percent displays. */
    maxStatePercent: number;
    orders: OrderInfo[];
};

export type TimeChartData<Type> = {
    currentValue: Type;
    /** From the earliest to the latest. */
    history: TimeChartPoint<Type>[];
};

/** The `to` date is inclusive. */
type TimeChartPoint<Type> = PointRange & {
    value: Type;
};

type PointRange = DateRange & {
    middle: DateTime;
};

export const orderStatsStateKeys = [ OrderState.Fulfilled, OrderState.New, OrderState.Overdue ] as const;
export type OrderStatsState = typeof orderStatsStateKeys[number];

export type OrderStateStats = {
    state: OrderStatsState;
    currentValue: Money;
    /** In percent. */
    shareFromTotal: number;
};

function computePointRanges(periods: DateRange[]): PointRange[] {
    return periods.map(period => {
        const daysDiff = computePeriodLength(period);
        return {
            from: period.from,
            to: period.to.minus({ days: 1 }),
            middle: period.from.plus({ days: Math.round(daysDiff / 2) }),
        };
    });
}

/** In days. */
function computePeriodLength({ from, to }: DateRange): number {
    return to.diff(from, [ 'days' ]).days;
}

function perCurrencyFromServer(input: PerCurrencyFromServer, pointRanges: PointRange[], rangeCoefficient: number): PerCurrency {
    const currency = getCurrency(input.currency);
    const productsSold = computeTimeChartData('productsSold', input, pointRanges);
    const activeClients = computeTimeChartData('activeClients', input, pointRanges);

    return {
        currency,
        productsSold,
        activeClients,
        ...computeOrderStateStats(input, rangeCoefficient),
        orders: input.orders
            .map(OrderInfo.fromServer)
            .toSorted((a, b) => +a.issueDate - +b.issueDate),
    };
}

function computeTimeChartData(key: 'productsSold' | 'activeClients', input: PerCurrencyFromServer, pointRanges: PointRange[]): TimeChartData<number> {
    const history: TimeChartPoint<number>[] = input.periods.map((period, index) => ({
        value: period[key],
        ...pointRanges[index],
    }))
    // The data from the server comes latest to earliest.
        .toReversed();

    return {
        currentValue: history[history.length - 1].value,
        history,
    };
}

function computeOrderStateStats(input: PerCurrencyFromServer, rangeCoefficient: number): Pick<PerCurrency, 'totalValue' | 'totalIncrease' | 'states' | 'maxStatePercent'> {
    const current = orderStatsStateKeys.map(key => moneyFromServer(input.periods[0][key], input.currency));
    const previous = orderStatsStateKeys.map(key => moneyFromServer(input.periods[1][key], input.currency));

    const currentAmount = current.reduce((ans, value) => ans + value.amount, 0);
    const previousAmount = previous.reduce((ans, value) => ans + value.amount, 0);

    const totalValue = { amount: currentAmount, currency: current[0].currency };
    const totalIncrease = Math.round(floatToPercent((currentAmount - previousAmount) / previousAmount * rangeCoefficient));

    const statesArray: OrderStateStats[] = current.map((value, index) => ({
        state: orderStatsStateKeys[index],
        currentValue: value,
        shareFromTotal: Math.round(floatToPercent(value.amount / currentAmount)),
    }));
    const states = {} as { [key in OrderStatsState]: OrderStateStats };
    statesArray.forEach(state => states[state.state] = state);

    // Just to make sure the sum is 100 even after rounding. It might still be a little awkward if something with zero has suddenly a share, but whatever.
    // TODO what if everything is zero?
    const percentSum = statesArray.reduce((ans, state) => ans + state.shareFromTotal, 0);
    statesArray[statesArray.length - 1].shareFromTotal += 100 - percentSum;

    const maxStatePercent = statesArray.reduce((ans, state) => Math.max(ans, state.shareFromTotal), 0);

    return {
        totalValue,
        totalIncrease,
        states,
        maxStatePercent,
    };
}

function generateExampleStats(): PerCurrency {
    const currency = tryGetCurrency('USD' as unknown as CurrencyIRI) ?? getDefaultCurrency();

    const today = DateTime.now().startOf('day');
    const pointRanges: PointRange[] = [];
    for (let i = PERIODS_COUNT - 1; i >= 0; i--) {
        const middle = today.minus({ months: i });
        pointRanges.push({ from: middle, to: middle, middle });
    }

    const current = [ 1498, 635, 119 ];
    const currentAmount = current.reduce((ans, value) => ans + value, 0);

    const statesArray: OrderStateStats[] = current.map((value, index) => ({
        state: orderStatsStateKeys[index],
        currentValue: { amount: value, currency },
        shareFromTotal: Math.round(floatToPercent(value / currentAmount)),
    }));
    const states = {} as { [key in OrderStatsState]: OrderStateStats };
    statesArray.forEach(state => states[state.state] = state);

    const maxStatePercent = statesArray.reduce((ans, state) => Math.max(ans, state.shareFromTotal), 0);

    return {
        currency,
        productsSold: {
            currentValue: 59,
            history: [ 9, 11, 27, 59 ].map((value, index) => ({ value, ...pointRanges[index] })),
        },
        activeClients: {
            currentValue: 28,
            history: [ 7, 8, 13, 28 ].map((value, index) => ({ value, ...pointRanges[index] })),
        },
        totalValue: { amount: currentAmount, currency },
        totalIncrease: 42,
        states,
        maxStatePercent: maxStatePercent,
        orders: exampleOrders.map(item => OrderInfo.createExample(item.variant, currency, today.minus({ days: item.beforeDays }), item.state)),
    };
}

const exampleOrders = [
    { variant: 1, beforeDays: 3, state: OrderState.New },
    { variant: 2, beforeDays: 5, state: OrderState.Fulfilled },
    { variant: 0, beforeDays: 7, state: OrderState.Fulfilled },
    { variant: 1, beforeDays: 21, state: OrderState.Overdue },
] as const;
