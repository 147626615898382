import apiObject, { BACKEND_API_PREFIX } from './rawApiObject';
import { appUser } from './endpoints/appUser';
import { auth } from './endpoints/auth';
import { client } from './endpoints/client';
import { event } from './endpoints/event';
import { location } from './endpoints/location';
import { order } from './endpoints/order';
import { other } from './endpoints/other';
import { product } from './endpoints/product';
import { settings } from './endpoints/settings';
import { task } from './endpoints/task';
import { team } from './endpoints/team';

const api = {
    appUser,
    auth,
    client,
    event,
    location,
    order,
    other,
    product,
    settings,
    task,
    team,
    prepareAbort: () => apiObject.prepareAbort(),
};

export { api, apiObject as rawBackendApi, BACKEND_API_PREFIX };
