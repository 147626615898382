import type { AccountData } from '@/data/accountData';
import { getCurrency, type Currency, type CurrencyIRI } from '@/modules/money';
import { type Entity, Id, type IRI, type OmitId } from '@/types/Id';

export type BankAccountFromServer = {
    '@id': IRI;
    currencies: CurrencyIRI[];
    country: string;
    label: string;
    iban?: string;
    swift?: string;
    data: AccountData;
};

export class BankAccount implements Entity {
    private constructor(
        readonly id: Id,
        readonly currencies: Currency[],
        readonly country: string,
        /** Domestic bank account (or IBAN, if the country isn't supported). Will be displayed on the invoice (if it's different from IBAN). */
        readonly label: string,
        /** Will be displayed on the invoice (if it's defined). */
        readonly iban: string | undefined,
        readonly swift: string | undefined,
        readonly data: AccountData,
    ) {}

    static fromServer(input: BankAccountFromServer): BankAccount {
        return new BankAccount(
            Id.fromIRI(input['@id']),
            input.currencies.map(getCurrency),
            input.country,
            input.label,
            input.iban,
            input.swift,
            input.data,
        );
    }
}

export type BankAccountUpdate = Omit<OmitId<BankAccountFromServer>, 'data'> & {
    data: string;
};

export function isCurrencySupported(bankAccounts: BankAccount[], currencyIRI: CurrencyIRI): boolean {
    return !!bankAccounts.find(account => !!account.currencies.find(currency => currency.toIRI() === currencyIRI));
}
