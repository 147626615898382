import React, { forwardRef, useImperativeHandle, useRef, type ForwardedRef } from 'react';

type DownloadFileProps = Readonly<{
    fileName: string;
}>;

export type DownloadFileRef = {
    download(blob: Blob): void;
};

function DownloadFileInner(props: DownloadFileProps, ref: ForwardedRef<DownloadFileRef>) {
    const fileRef = useRef<HTMLAnchorElement>(null);

    useImperativeHandle(ref, () => ({
        download: (blob: Blob) => {
            if (fileRef.current === null)
                return;

            const url = URL.createObjectURL(blob);
            const defaultUrl = fileRef.current.href;
            fileRef.current.href = url;
            fileRef.current.click();
            URL.revokeObjectURL(url);
            fileRef.current.href = defaultUrl;
        },
    }));

    return (
        <a // nosonar
            ref={fileRef}
            href='/'
            className='display-none'
            download={props.fileName}
        />
    );
}

const DownloadFile = forwardRef(DownloadFileInner);
export default DownloadFile;