import { useEffect, useReducer, type Dispatch, useMemo } from 'react';
import { api } from '@/utils/api/backend';
import { getClientIdentifier } from '@/types/EventParticipant';
import { useMaster, type MasterContext } from '@/context/UserProvider';
import useNotifications, { type AddAlertFunction } from '@/context/NotificationProvider';
import { createErrorAlert, createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { type TransitionName, Order, type OrderSync, type OrderUpdate, type OrderTransition, orderEditToServer, type OrderDelete, SyncType, type OrderFields, DEFAULT_PREFIX, type EditableOrderFields, type OrderEdit, type ChargeError, type NumberAlreadyUsedError, isChargeError, isNumberAlreadyUsedError, type OrderSendNotification, notificationToServer } from '@/types/orders/Order';
import { useNavigate, type NavigateFunction } from 'react-router-dom';
import type { InvoicingIdentity } from '@/types/Invoicing';
import { defaultEditableAddress, type EditableAddress, isAddressEqual } from '@/types/Address';
import { type TypedAction, ifChanged, type RequiredBy } from '@/utils/common';
import { type DateTime } from 'luxon';
import { isEventItemEqual, type EditableEventItem } from '@/types/orders/EventOrderItem';
import { isBasicItemEqual, type EditableBasicItem } from '@/types/orders/CustomOrderItem';
import { routes } from '@/router';
import { type FormErrors, Updator, type RulesDefinition, type FormPath } from '@/utils/updator';
import { type EmailPreviewAction, emailPreviewReducer, type EmailPreviewState, computeInitialEmailPreviewState, validateEmailPreview, parseCcEmails } from './checkout/useCheckout';

export function useOrder(input: Order) {
    const masterContext = useMaster();
    const [ state, dispatch ] = useReducer(orderReducer, computeInitialState(input, masterContext));

    // When the user context changes, we have to actualise it.
    useEffect(() => {
        dispatch({ type: 'masterContext', masterContext });
    }, [ masterContext ]);

    const { addAlert } = useNotifications();
    const navigate = useNavigate();

    const syncFunctions = useMemo(() => new SyncFunctions(dispatch, addAlert, navigate), [ addAlert, navigate ]);

    useEffect(() => {
        if (state.sync?.fetching) {
            const [ signal, abort ] = api.prepareAbort();
            const sync = state.sync.orderSync;

            switch (sync.type) {
            case SyncType.Update:
                syncFunctions.update(signal, sync, state.order);
                break;
            case SyncType.Transition:
                syncFunctions.transition(signal, sync, state.order);
                break;
            case SyncType.Delete:
                syncFunctions.delete(signal, sync, state.order);
                break;
            case SyncType.SendNotification:
                syncFunctions.sendNotification(signal, sync, state.order);
                break;
            }

            return abort;
        }
    }, [ !!state.sync?.fetching ]);

    return {
        state,
        dispatch,
    };
}

class SyncFunctions {
    public constructor(
        private readonly dispatch: UseOrderDispatch,
        private readonly addAlert: AddAlertFunction,
        private readonly navigate: NavigateFunction,
    ) {}

    async update(signal: AbortSignal, action: OrderUpdate, order: Order) {
        const response = await api.order.update(order, await orderEditToServer(action), { signal });
        if (!response.status) {
            if (isChargeError(response.error)) {
                this.addAlert(createErrorAlert(response.error));
                this.dispatch({ type: 'error', operation: 'charge', value: response.error });
                return;
            }
            if (isNumberAlreadyUsedError(response.error)) {
                this.dispatch({ type: 'error', operation: 'numberAlreadyUsed', value: response.error });
                return;
            }

            this.addAlert(createTranslatedErrorAlert());
            this.dispatch({ type: 'error', operation: 'other', value: response.error });
            return;
        }

        this.addAlert(createTranslatedSuccessAlert('pages:orderDetail.update-success-alert'));
        this.dispatch({ type: 'sync', operation: 'finish', order: Order.fromServer(response.data) });
    }

    async transition(signal: AbortSignal, action: OrderTransition, order: Order) {
        const response = await api.order.transition(order, { transition: action.data.transition }, { signal });
        if (!response.status) {
            this.addAlert(createTranslatedErrorAlert());
            this.dispatch({ type: 'error', operation: 'other', value: response.error });
            return;
        }

        this.addAlert(createTranslatedSuccessAlert(`pages:orderDetail.${action.data.transition}-success-alert`));
        this.dispatch({ type: 'sync', operation: 'finish', order: Order.fromServer(response.data) });
    }

    async delete(signal: AbortSignal, action: OrderDelete, order: Order) {
        // Apiplatform is a bigger whore than your mom
        const response = await api.order.delete({ id: order.id }, signal);
        if (!response.status) {
            this.addAlert(createErrorAlert(response.error));
            return;
        }

        this.addAlert(createTranslatedSuccessAlert('pages:orderDetail.deleteOrderButton.success-alert'));
        this.navigate(routes.orders.list);
    }

    async sendNotification(signal: AbortSignal, action: OrderSendNotification, order: Order) {
        const response = await api.order.sendNotification({
            order: order.id.toIRI(),
            notification: notificationToServer(action.data),
        }, { signal });

        if (!response.status) {
            // TODO enable the user to close the modal.
            this.addAlert(createErrorAlert(response.error));
            return;
        }

        this.addAlert(createTranslatedSuccessAlert('pages:orderDetail.sendNotificationButton.success-alert'));
        this.dispatch({ type: 'sync', operation: 'finish', order: Order.fromServer(response.data) });
    }
}

export type UseOrderState = {
    masterContext: MasterContext;
    /** The current order that is saved on BE. It should be updated whenever we synchronize with BE. */
    order: Order;
    form?: OrderFormState;
    formErrors?: FormErrors;
    sync?: SyncState;
    error?: ErrorState;
    sendNotification?: {
        phase: 'overview' | 'emailPreview';
        emailPreview: EmailPreviewState;
    };
}

function computeInitialState(order: Order, masterContext: MasterContext): UseOrderState {
    return {
        masterContext,
        order,
    };
}

export type UseOrderDispatch = Dispatch<OrderAction>;

type OrderAction = ResetAction | UserContextAction | FormAction | InputAction | SyncAction | ErrorAction | SendNotificationAction;

function orderReducer(state: UseOrderState, action: OrderAction): UseOrderState {
    console.log('Reduce:', state, action);

    switch (action.type) {
    case 'reset': return computeInitialState(action.order ?? state.order, state.masterContext);
    case 'masterContext': return { ...state, masterContext: action.masterContext };
    case 'form': return form(state, action);
    case 'input': return input(state, action);
    case 'sync': return sync(state, action);
    case 'error': return error(state, action);
    case 'sendNotification':
    case 'emailPreview':
        return sendNotification(state, action);
    }
}

// Reset

type ResetAction = TypedAction<'reset', {
    order?: Order;
}>;

// UserContext

type UserContextAction = TypedAction<'masterContext', {
    type: 'masterContext';
    masterContext: MasterContext;
}>;

// Form

export type OrderFormState = {
    issueDate: DateTime;
    dueDate: DateTime;
    taxDate: DateTime;
    isCondensedInvoice: boolean;
    logo: FileList | null | undefined;
    supplier: EditableInvoicingIdentity;
    subscriber: EditableInvoicingIdentity;
    customFields: EditableOrderFields;
    eventItems: EditableEventItem[];
    basicItems: EditableBasicItem[];
    invoice?: {
        /** This value has the last hyphen removed so that it can be displayed independently. */
        formPrefix: string;
        index: number;
        variableSymbol: string;
        generatedVariableSymbol: string;
    };
};

export type OrderFormWithInvoice = RequiredBy<OrderFormState, 'invoice'>;
export function hasInvoice(form: OrderFormState): form is OrderFormWithInvoice {
    return !!form.invoice;
}

type EditableInvoicingIdentity = {
    email: string;
    name: string;
    address: EditableAddress;
    cin: string;
    tin: string;
};

type FormAction = TypedAction<'form', {
    operation: 'edit' | 'discard' | 'generateVariableSymbol';
}>;

function form(state: UseOrderState, action: FormAction): UseOrderState {
    if (action.operation === 'discard')
        return { ...state, form: undefined, formErrors: undefined };

    if (action.operation === 'generateVariableSymbol') {
        return state.form?.invoice
            ? { ...state, form: { ...state.form, invoice: { ...state.form.invoice, variableSymbol: state.form.invoice.generatedVariableSymbol } } }
            : state;
    }

    const { order } = state;

    const form: OrderFormState = {
        issueDate: order.issueDate,
        dueDate: order.dueDate,
        taxDate: order.taxDate,
        isCondensedInvoice: order.isCondensedInvoice,
        logo: undefined,
        supplier: identityToForm(order.supplier),
        subscriber: identityToForm(order.subscriber),
        customFields: fieldsToForm(order.fields),
        eventItems: order.getEventItems().map(item => ({
            id: item.id.toString(),
            event: item.event,
            title: item.title,
            unitPrice: item.unitPrice.amount,
            quantity: item.quantity,
            vat: item.vat,
            isDeleted: false,
        })),
        basicItems: order.getBasicItems().map(item => ({
            id: item.id.toString(),
            title: item.title,
            quantity: item.quantity,
            unitPrice: item.unitPrice.amount,
            vat: item.vat,
            isDeleted: false,
        })),
        invoice: createFormInvoice(state),
    };

    return { ...state, form };
}

function createFormInvoice({ order, masterContext }: UseOrderState): OrderFormState['invoice'] | undefined {
    if (!order.invoice)
        return undefined;

    const parsedPrefix = parsePrefix(order.invoice.prefix, masterContext.subscription.isLogoChangeable);
    const formPrefix = parsedPrefix.slice(0, -1);

    return {
        formPrefix,
        index: order.invoice.index,
        variableSymbol: order.invoice.variableSymbol,
        generatedVariableSymbol: generateVariableSymbol(order.issueDate, order.invoice.index),
    };
}

function parsePrefix(prefix: string, isCustomEnabled: boolean): string {
    if (isCustomEnabled)
        return prefix;

    return prefix.startsWith(DEFAULT_PREFIX) ? prefix.slice(DEFAULT_PREFIX.length) : prefix;
}

function generateVariableSymbol(issueDate: DateTime, index: number): string {
    return issueDate.toFormat('yyMM') + index.toString();
}

function identityToForm(input: InvoicingIdentity): EditableInvoicingIdentity {
    return {
        name: input.name,
        email: input.email ?? '',
        address: input.address?.toEditable() ?? defaultEditableAddress,
        cin: input.cin ?? '',
        tin: input.tin ?? '',
    };
}

function fieldsToForm(input: OrderFields): EditableOrderFields {
    return {
        header: input.header ?? '',
        footer: input.footer ?? '',
        customKey1: input.customKey1 ?? '',
        customValue1: input.customValue1 ?? '',
        customKey2: input.customKey2 ?? '',
        customValue2: input.customValue2 ?? '',
    };
}

// Input

type InputAction = TypedAction<'input', {
    field: FormPath<OrderFormState>;
    value: unknown;
}>;

function input(state: UseOrderState, action: InputAction): UseOrderState {
    if (!state.form)
        return state;

    const { form, formErrors } = Updator.update(state as RequiredBy<UseOrderState, 'form'>, action.field, action.value, rules);
    if ((action.field === 'issueDate' || action.field === 'invoice.index') && hasInvoice(form))
        form.invoice.generatedVariableSymbol = generateVariableSymbol(form.issueDate, form.invoice.index);

    return { ...state, form, formErrors };
}

const rules: RulesDefinition<OrderFormState> = {
    supplier: {
        name: (value: unknown) => value !== '' || 'common:form.name-company-required',
    },
    subscriber: {
        name: (value: unknown) => value !== '' || 'common:form.name-company-required',
    },
    eventItems: {
        title: (value: unknown) => value !== '' || 'common:form.title-required',
    },
    basicItems: {
        title: (value: unknown) => value !== '' || 'common:form.title-required',
    },
};

// Synchronization

export type SyncState = {
    /** The order is being saved. The request is fired when this becomes true. This is an identifier of the button that caused the fetch. */
    fetching?: string;
    orderSync: OrderSync;
};

type SyncAction = TypedAction<'sync', {
    operation: 'save' | 'delete';
    fid?: string;
} | {
    operation: 'transition';
    transition: TransitionName;
    fid?: string;
} | {
    operation: 'sendNotification';
    fid?: string;
} | {
    operation: 'finish';
    order: Order;
}>;

function sync(state: UseOrderState, action: SyncAction): UseOrderState {
    if (action.operation === 'finish')
        return computeInitialState(action.order, state.masterContext);

    return { ...state, sync: { orderSync: createSyncObject(state, action), fetching: action.fid } };
}

function createSyncObject(state: UseOrderState, action: SyncAction): OrderSync {
    if (action.operation === 'delete')
        return { type: SyncType.Delete, data: undefined };
    if (action.operation === 'transition')
        return { type: SyncType.Transition, data: { transition: action.transition } };
    if (action.operation === 'sendNotification') {
        if (!state.sendNotification)
            throw new Error('Invalid state');

        const emailPreview = state.sendNotification.emailPreview;
        const data = { ...emailPreview.form, cc: parseCcEmails(emailPreview.form.cc) };

        return { type: SyncType.SendNotification, data };
    }

    return {
        type: SyncType.Update,
        data: createEditObject(state),
    };
}

function createEditObject({ order, form, masterContext }: UseOrderState): OrderEdit {
    if (!form)
        throw new Error('Invalid order state');

    const originalEventItems = order.getEventItems();
    const originalCustomItems = order.getBasicItems();

    return {
        issueDate: ifChanged(form.issueDate, order.issueDate),
        dueDate: ifChanged(form.dueDate, order.dueDate),
        taxDate: ifChanged(form.taxDate, order.taxDate),
        isCondensedInvoice: ifChanged(form.isCondensedInvoice, order.isCondensedInvoice),
        // logo: // TODO
        supplier: ifChanged(form.supplier, order.supplier, isInvoicingIdentityEqual),
        subscriber: ifChanged(form.subscriber, order.subscriber, isInvoicingIdentityEqual),
        fields: ifChanged(form.customFields, order.fields, isOrderFieldsEqual),
        eventItems: form.eventItems.filter((item, index) => !isEventItemEqual(item, originalEventItems[index])),
        basicItems: form.basicItems.filter((item, index) => !isBasicItemEqual(item, originalCustomItems[index])),
        ...createInvoiceEditObject(order, form, masterContext),
    };
}

function createInvoiceEditObject(order: Order, form: OrderFormState, masterContext: MasterContext): { prefix?: string, index?: number, variableSymbol?: string } {
    if (!order.invoice || !hasInvoice(form))
        return {};

    const fullPrefix = computeFullPrefix(form, masterContext);
    return {
        prefix: order.invoice && ifChanged(fullPrefix, order.invoice.prefix),
        index: order.invoice && ifChanged(form.invoice.index, order.invoice.index),
        variableSymbol: ifChanged(form.invoice.variableSymbol, order.invoice.variableSymbol),
    };
}

export function computeFullPrefix(form: OrderFormWithInvoice, masterContext: MasterContext): string {
    const isCustomEnabled = masterContext.subscription.isLogoChangeable;
    return (isCustomEnabled ? '' : DEFAULT_PREFIX) + form.invoice.formPrefix + '-';
}

// TODO create a local copy of the form data and compare it with the form (instead of the original order).
function isInvoicingIdentityEqual(form: EditableInvoicingIdentity, identity: InvoicingIdentity): boolean {
    const a = form.name === (identity.name ?? '')
        && form.email === (identity.email ?? '')
        && isAddressEqual(form.address, identity.address ?? defaultEditableAddress)
        && form.cin === (identity.cin ?? '')
        && form.tin === (identity.tin ?? '');

    return a;
}

function isOrderFieldsEqual(form: EditableOrderFields, fields: OrderFields): boolean {
    return form.header === (fields.header ?? '')
        && form.footer === (fields.footer ?? '')
        && form.customKey1 === (fields.customKey1 ?? '')
        && form.customValue1 === (fields.customValue1 ?? '')
        && form.customKey2 === (fields.customKey2 ?? '')
        && form.customValue2 === (fields.customValue2 ?? '');
}

// Error

type ErrorState = {
    charge?: ChargeError;
    numberAlreadyUsed?: NumberAlreadyUsedError;
    /** Fallback for all other errors. */
    other?: unknown;
};

type ErrorAction = TypedAction<'error', {
    operation: 'reset';
} | {
    operation: 'charge';
    value: ChargeError | undefined;
} | {
    operation: 'numberAlreadyUsed';
    value: NumberAlreadyUsedError | undefined;
} | {
    operation: 'other';
    value: unknown | undefined;
}>;

function error(state: UseOrderState, action: ErrorAction): UseOrderState {
    if (action.operation === 'reset')
        return { ...state, error: undefined };

    return createErrorState(state, { [action.operation]: action.value } );
}

function createErrorState(state: UseOrderState, error: ErrorState): UseOrderState {
    // In any case except reset, we want to stop the current sync processes.
    return { ...state, sync: undefined, error };
}

// Send notification

type SendNotificationAction = EmailPreviewAction | TypedAction<'sendNotification', {
    operation: 'open' | 'close' | 'overview' | 'emailPreview';
}>;

function sendNotification(state: UseOrderState, action: SendNotificationAction): UseOrderState {
    const sendNotification = state.sendNotification;

    if (action.type === 'emailPreview') {
        if (!sendNotification)
            throw new Error('Invalid state');

        const emailPreview = emailPreviewReducer(sendNotification.emailPreview, action);
        return { ...state, sendNotification: { ...sendNotification, emailPreview } };
    }

    if (action.operation === 'open') {
        // Kinda verbose, but it's the easiest way to pass the clients to the email preview.
        const clients = [ { info: state.order.client, identifier: getClientIdentifier(state.order.client) } ];
        const emailPreview = computeInitialEmailPreviewState(clients, state.masterContext);
        return { ...state, sendNotification: { phase: 'overview', emailPreview } };
    }

    if (action.operation === 'close')
        // If we are fetching, we want to keep the modal open so that the user see the fetching progress.
        return state.sync?.fetching ? state : { ...state, sendNotification: undefined };

    if (!sendNotification)
        throw new Error('Invalid state');

    if (action.operation === 'emailPreview') {
        const emailPreview = { ...sendNotification.emailPreview, formErrors: undefined, wasSubmitted: false };
        return { ...state, sendNotification: { ...sendNotification, emailPreview, phase: 'emailPreview' } };
    }

    const emailPreview = validateEmailPreview(sendNotification.emailPreview);
    const phase = emailPreview.formErrors ? sendNotification.phase : 'overview';
    return { ...state, sendNotification: { ...sendNotification, emailPreview, phase } };
}
