import React, { useCallback, useMemo, type ReactNode } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Controller, useForm, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { type BaseLocation, PlatformType } from '@/types/location';
import { SpinnerButton } from '../common';
import FormSelect from '../forms/FormSelect';
import { ControlledCountrySelect } from '@/components/forms';
import { getStringEnumValues } from '@/utils/common';
import { useTranslation } from 'react-i18next';
import { type CountryCode } from '@/types/i18n';
import { useNestedForm } from '@/utils/forms';
import type { TFunction } from 'i18next';
import { PlatformTypeIcon } from './LocationDisplay';
import { MAX_TITLE_LENGTH } from '@/types/Event';

type LocationFormProps = Readonly<{
    defaultValues?: Partial<BaseLocation>;
    isFetching: boolean;
    onSubmit(data: LocationFormData): void;
    onCancel?: () => void;
    typeIsConstant?: boolean;
}>;

export default function LocationForm({ defaultValues, isFetching, onSubmit, onCancel, typeIsConstant }: LocationFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'newLocation' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const { register, handleSubmit, watch, control } = useForm<LocationFormData>({
        defaultValues: {
            ...DEFAULT_VALUES,
            ...defaultValues,
            ...(defaultValues && 'address' in defaultValues && defaultValues.address || {}),
        },
    });
    const handleNestedSubmit = useNestedForm(handleSubmit);

    const platformType = watch('platformType');

    function submit(data: LocationFormData) {
        onSubmit({ ...data });
    }

    return (
        <Form noValidate onSubmit={handleNestedSubmit(submit)} className='sh-design'>
            <Form.Group>
                <Form.Label>{t('type-label')}</Form.Label>
                <ControlledPlatformTypeSelect
                    name='platformType'
                    control={control}
                    isDisabled={typeIsConstant}
                />
            </Form.Group>
            <Form.Group className='mt-3'>
                <Form.Label>{t('title-label')}</Form.Label>
                <Form.Control
                    {...register('title', { required: t('title-requried'), maxLength: { value: MAX_TITLE_LENGTH, message: tf('text-too-long', { count: MAX_TITLE_LENGTH }) } })}
                    placeholder={t('title-placeholder')}
                />
            </Form.Group>
            {platformType === PlatformType.custom && (
                <Form.Group className='mt-3'>
                    <Form.Label>{t('video.platform-label')}</Form.Label>
                    <Form.Control
                        {...register('platform', { required: t('video.platform-required') })}
                        placeholder={t('video.platform-placeholder')}
                    />
                </Form.Group>
            )}
            {platformType !== PlatformType.physicalLocation && (
                <Form.Group className='mt-3'>
                    <Form.Label>{t('video.link-label')}</Form.Label>
                    <Form.Control
                        {...register('url', { required: t('video.link-required') })}
                        placeholder={t('video.link-placeholder')}
                    />
                </Form.Group>
            )}
            {platformType === PlatformType.physicalLocation && (
                <Form.Group className='mt-3'>
                    <Row>
                        <Col>
                            <Form.Label>{t('physical.country-label')}</Form.Label>
                            <ControlledCountrySelect
                                control={control}
                                name='country'
                            />
                        </Col>
                        <Col>
                            <Form.Label>{t('physical.city-label')}</Form.Label>
                            <Form.Control {...register('city', { required: t('physical.city-required') })} />
                        </Col>
                    </Row>
                </Form.Group>
            )}
            {platformType === PlatformType.physicalLocation && (
                <Form.Group className='mt-3'>
                    <Row>
                        <Col>
                            <Form.Label>{t('physical.postal-code-label')}</Form.Label>
                            <Form.Control {...register('postalCode', { required: t('physical.postal-code-required') })} />
                        </Col>
                        <Col>
                            <Form.Label>{t('physical.line1-label')}</Form.Label>
                            <Form.Control {...register('line1', { required: t('physical.line1-required') })} />
                        </Col>
                    </Row>
                </Form.Group>
            )}
            <Row className='mt-5'>
                {onCancel &&
                    <Col>
                        <Button onClick={onCancel} className='w-100' variant='outline-danger'>
                            {t('cancel-button')}
                        </Button>
                    </Col>
                }
                <Col>
                    <SpinnerButton
                        isFetching={isFetching}
                        type='submit'
                        className='w-100'
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </Col>
            </Row>
        </Form>
    );
}

export type LocationFormData = {
    title: string;
    platformType: PlatformType;
    platform: string;
    url: string;
    city: string;
    country: CountryCode;
    line1: string;
    postalCode: string;
}

const DEFAULT_VALUES: LocationFormData = {
    title: '',
    platformType: PlatformType.googleMeet,
    platform: '',
    url: '',
    city: '',
    country: '',
    line1: '',
    postalCode: '',
};

type Option = {
    value: PlatformType;
    label: ReactNode;
}

function generalPlatformTypeToOption(t: TFunction, type: PlatformType): Option {
    return {
        value: type,
        label: (
            <div className='d-flex align-items-center gap-2'>
                <PlatformTypeIcon type={type} size={22} />
                {t(`option-${type}`)}
            </div>
        ),
    };
}

type ControlledPlatformTypeSelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    isDisabled?: boolean;
};

function ControlledPlatformTypeSelect<TFieldValues extends FieldValues>({ control, name, isDisabled }: ControlledPlatformTypeSelectProps<TFieldValues>) {
    const { t } = useTranslation('pages', { keyPrefix: 'newLocation' });

    const platformTypeToOption = useCallback((type: PlatformType) => generalPlatformTypeToOption(t, type), [ t ]);

    const options = useMemo(() => getStringEnumValues(PlatformType).map(platformTypeToOption), [ platformTypeToOption ]);

    const InnerSelect = useCallback(({ field }: { field: { value: PlatformType, onChange: (value?: PlatformType ) => void } }) => {
        return (
            <FormSelect
                options={options}
                value={platformTypeToOption(field.value)}
                onChange={option => field.onChange(option?.value)}
                isDisabled={isDisabled}
            />
        );
    }, [ options, platformTypeToOption, isDisabled ]);
    
    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}
