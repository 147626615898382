import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Order } from '@/types/orders/Order';
import { type EditableEventItem, type EventOrderItem } from '@/types/orders/EventOrderItem';
import { api } from '@/utils/api/backend';
import { useTranslation } from 'react-i18next';
import DateTimeDisplay from '../common/DateTimeDisplay';
import { MoneyDisplay, Table } from '../common';
import EventStateBadge from '../event/EventStateBadge';
import { addVat, addVatAsNumber, timesBy, type Currency, type Money } from '@/modules/money';
import { Form } from 'react-bootstrap';
import { EventParticipant, type PayingParticipant } from '@/types/EventParticipant';
import { useUpdating } from '@/hooks';
import type { OrderFormState, UseOrderDispatch, UseOrderState } from './useOrder';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';
import { toNumber, transformToPriceNegative } from '@/utils/math';
import { VatSelect } from '../forms/VatSelect';
import { AddButton, DeleteButton } from '../forms/buttons';
import { TranslatedErrorMessage } from '../forms/ErrorMessage';
import { type FormPath } from '@/utils/updator';

type EventItemsTableProps = Readonly<{
    items: EventOrderItem[];
    updateOrder: (newOrder: Order) => void;
}>;

export default function EventItemsTable({ items, updateOrder }: EventItemsTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventItemsTable' });
    const totalPrice = useMemo(() => computeTotalPriceSum(items), [ items ]);
    const showVat = useMemo(() => items.some(item => !item.vat.isZero), [ items ]);

    return (
        <Table>
            <Table.Header>
                <Table.HeaderCol>{t('title-label')}</Table.HeaderCol>
                <Table.HeaderCol className='text-end'>{t('date-label')}</Table.HeaderCol>
                <Table.HeaderCol className='text-start'>{t('state-label')}</Table.HeaderCol>
                <Table.HeaderCol className='text-end'>{t('paid-label')}</Table.HeaderCol>
                <Table.HeaderCol className='text-end'>{t('price-label')}</Table.HeaderCol>
                {showVat && (
                    <Table.HeaderCol className='text-end'>{t('vat-label')}</Table.HeaderCol>
                )}
            </Table.Header>
            <Table.Body>
                {items.map(item => (
                    <EventItemRow key={item.id.toString()} item={item} showVat={showVat} updateOrder={updateOrder} />
                ))}
                <Table.Row>
                    <Table.Col colSpan={5} className='text-end'>
                        <span className='fw-medium me-4'>{t('total-price-sum-label')}</span>
                        <MoneyDisplay money={totalPrice} />
                    </Table.Col>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

type EventItemRowProps = Readonly<{
    item: EventOrderItem;
    showVat: boolean;
    updateOrder: (order: Order) => void;
}>;

function EventItemRow({ item, showVat, updateOrder }: EventItemRowProps) {
    const { event, participant } = item;
    const [ innerParticipant, setInnerParticipant ] = useState(participant);
    useEffect(() => setInnerParticipant(participant), [ participant ]);

    const syncPaid = useCallback(async (newValue: boolean) => {
        const response = await api.event.updateParticipant({ id: event.id }, participant.toUpdate({ isPaid: newValue }));
        if (!response.status)
            return false;

        const newParticipant = EventParticipant.fromServer(response.data.participant) as PayingParticipant;
        setInnerParticipant(newParticipant);
        if (response.data.order) {
            const updatedOrder = Order.fromServer(response.data.order);
            updateOrder(updatedOrder);
        }
        return true;
    }, [ event.id, participant, updateOrder ]);

    const [ paid, setPaid, isUpdating ] = useUpdating(innerParticipant.payment.isPaid, syncPaid);

    return (
        <Table.Row>
            <Table.Col>{item.title}</Table.Col>
            <Table.Col className='text-end'><DateTimeDisplay dateTime={event.dateFrom} /></Table.Col>
            <Table.Col className='text-start'><EventStateBadge event={event} /></Table.Col>
            <Table.Col className='d-flex justify-content-end'>
                {participant.isPaying && (
                    <Form.Check
                        checked={paid}
                        disabled={isUpdating}
                        onChange={e => setPaid(e.target.checked)}
                        type='switch'
                    />
                )}
            </Table.Col>
            <Table.Col className='text-end'><MoneyDisplay money={item.unitPrice} /></Table.Col>
            {showVat && (
                <Table.Col className='text-end'>{item.vat.label}</Table.Col>
            )}
        </Table.Row>
    );
}

/** sum(item => unitPrice * quantity * vat) */
function computeTotalPriceSum(items: EventOrderItem[]): Money {
    const amount = items.reduce((ans, item) => ans + computeItemTotalPrice(item).amount, 0);
    const currency = items[0].unitPrice.currency;

    return { amount, currency };
}

/** unitPrice * quantity * vat */
function computeItemTotalPrice(item: EventOrderItem): Money {
    return addVat(timesBy(item.unitPrice, item.quantity), item.vat).withVat;
}

type EventItemsFormProps = Readonly<{
    state: UseOrderState;
    form: OrderFormState;
    dispatch: UseOrderDispatch;
}>;

export function EventItemsForm({ state, form, dispatch }: EventItemsFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventItemsTable' });
    const common = useMemo(() => ({
        showVat: state.order.items.some(item => !item.vat.isZero),
        prices: computeFormPrices(form.eventItems, state.order.price.currency),
        isRemovingAllowed: form.eventItems.filter(item => !item.isDeleted).length > 1,
    }), [ form.eventItems, state.order.items, state.order.price ]);

    return (
        <div className='sh-event-items-form d-flex flex-column gap-2 flex-grow-1'>
            {form.eventItems.map((item, index) => (
                <EventItemRowForm
                    key={item.id}
                    state={state}
                    dispatch={dispatch}
                    item={item}
                    index={index}
                    common={common}
                />
            ))}
            <div className='d-flex justify-content-end py-2' style={{ paddingRight: '48px' }}>
                <span className='fw-medium me-4'>{t('total-price-sum-label')}</span>
                <MoneyDisplay money={common.prices.total} />
            </div>
        </div>
    );
}

type CommonData = {
    showVat: boolean;
    prices: { items: Money[], total: Money };
    isRemovingAllowed: boolean;
}

type EventItemRowFormProps = Readonly<{
    state: UseOrderState;
    dispatch: UseOrderDispatch;
    item: EditableEventItem;
    index: number;
    common: CommonData;
}>;

function EventItemRowForm({ state, dispatch, item, index, common }: EventItemRowFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventItemsTable' });
    const isFirstRow = index === 0;

    function input(field: FormPath<EditableEventItem>, value: unknown) {
        dispatch({ type: 'input', field: `eventItems.${index}.${field}`, value });
    }

    return (
        <div className={clsx('row gx-2', !isFirstRow && 'sh-remove-labels')}>
            <Form.Group className='col'>
                <Form.Label>{t('title-label')}</Form.Label>
                <Form.Control
                    value={item.title}
                    onChange={e => input('title', e.target.value)}
                    as={TextareaAutosize}
                    minRows={1}
                    className='fw-medium py-1 line-height-21'
                    disabled={item.isDeleted}
                />
                {!item.isDeleted && (
                    <TranslatedErrorMessage translationId={state.formErrors?.[`eventItems.${index}.title`]} />
                )}
            </Form.Group>
            <div className='col-auto'>
                <Form.Label className='px-3'>{t('date-label')}</Form.Label>
                <div className='py-2 px-3'><DateTimeDisplay dateTime={item.event.dateFrom} /></div>
            </div>
            <Form.Group className='col-2'>
                <Form.Label>{t('price-label')}</Form.Label>
                <Form.Control
                    type='number'
                    value={item.unitPrice}
                    onChange={e => input('unitPrice', transformToPriceNegative(e.target.value))}
                    disabled={item.isDeleted}
                />
            </Form.Group>
            {common.showVat && (
                <Form.Group className='col-1'>
                    <Form.Label>{t('vat-label')}</Form.Label>
                    <VatSelect
                        value={item.vat}
                        onChange={value => value && input('vat', value)}
                        className='rs-condensed rs-menu-top'
                        disabled={item.isDeleted}
                    />
                </Form.Group>
            )}
            <div className='col-1'>
                <Form.Label>{t('total-price-label')}</Form.Label>
                <div className={clsx('text-end py-2', item.isDeleted && 'text-decoration-line-through')}>
                    <MoneyDisplay money={common.prices.items[index]} />
                </div>
            </div>
            <div className='col-auto'>
                <Form.Label className='no-width opacity-0 text-nowrap'>{t(item.isDeleted ? 'restore-item-button' : 'remove-item-button')}</Form.Label>
                <div className='py-1'>
                    {item.isDeleted ? (
                        <AddButton aria={t('restore-item-button')} onClick={() => input('isDeleted', false)} />
                    ) : (
                        <DeleteButton aria={t('remove-item-button')} onClick={() => input('isDeleted', true)} disabled={!common.isRemovingAllowed} />
                    )}
                </div>
            </div>
        </div>
    );
}

function computeFormItemTotalPrice(item: EditableEventItem, currency: Currency): Money {
    return {
        amount: addVatAsNumber(toNumber(item.unitPrice), item.vat).withVat,
        currency,
    };
}

function computeFormPrices(items: EditableEventItem[], currency: Currency): { items: Money[], total: Money } {
    const itemPrices = items.map(item => computeFormItemTotalPrice(item, currency));
    const amount = itemPrices.filter((_, i) => !items[i].isDeleted).reduce((ans, price) => ans + price.amount, 0);

    return {
        items: itemPrices,
        total: { amount, currency },
    };
}
