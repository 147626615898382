import { Id } from '@/types/Id';
import { Address, type AddressToServer, type AddressFromServer } from '../Address';
import { BaseLocation, type BaseLocationFromServer, LocationType, PlatformType } from './BaseLocation';

export type PhysicalLocationFromServer = BaseLocationFromServer & {
    address: AddressFromServer;
};

export class PhysicalLocation extends BaseLocation {
    private constructor(
        id: Id,
        title: string,
        displayName: string,
        readonly address: Address,
    ) {
        super(id, LocationType.physical, PlatformType.physicalLocation, PlatformType.physicalLocation, title, displayName);
    }

    public static fromServer(input: PhysicalLocationFromServer) {
        return new PhysicalLocation(
            Id.fromIRI(input['@id']),
            input.title,
            input.displayName,
            Address.fromServer(input.address),
        );
    }
}

export type PhysicalLocationToServer = {
    title: string;
    address: AddressToServer;
};
