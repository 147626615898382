import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { api } from '@/utils/api/backend';
import { SpinnerButton } from '@/components/common';
import { useTranslation } from 'react-i18next';
import { createTranslatedErrorAlert } from '@/components/notifications';
import useNotifications from '@/context/NotificationProvider';
import { type Client } from '@/types/Client';

type DeleteClientModalProps = Readonly<{
    client?: Client;
    onClose(): void;
    onDelete?(deletedClient: Client): void;
}>;

export default function DeleteClientModal({ client, onClose, onDelete }: DeleteClientModalProps) {
    // TODO cache the client

    const [ isFetching, setIsFetching ] = useState(false);

    const { t } = useTranslation('components', { keyPrefix: 'deleteClientModal' });
    const { addAlert } = useNotifications();

    async function deleteClient() {
        if (!client)
            return;

        setIsFetching(true);
        const response = await api.client.delete(client);
        setIsFetching(false);

        if (!response.status) {
            addAlert(createTranslatedErrorAlert());
            onClose();
            return;
        }

        onDelete?.(client);
    }

    return (
        <Modal show={!!client} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='text-center'>
                    <Col>{t('text')}<br /></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <SpinnerButton
                    variant='danger'
                    isFetching={isFetching}
                    onClick={deleteClient}
                    className='mx-auto'
                >
                    {t('confirm-button')}
                </SpinnerButton>
            </Modal.Footer>
        </Modal>
    );
}
