import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { type Client, type ClientInit } from '@/types/Client';
import FormErrorMessage from '@/components/forms/FormErrorMessage';
import { SpinnerButton } from '../common';
import { inputToForm as subscriberInputToForm, formToOutput as subscriberFormToOutput, type FormDataType as SubscriberFormData, SubscriberFormInner } from '@/components/settings/SubscriberForm';
import { useTranslation } from 'react-i18next';
import { EMAIL_VALIDATION_PATTERN, canonizeEmail, useNestedForm } from '@/utils/forms';
import { type UserContext, useUser, toMaster } from '@/context/UserProvider';
import type { ClientLocaleCode, CountryCode, LocaleCode, TimezoneCode } from '@/types/i18n';
import { ControlledInvoicingProfileSelect, ControlledLocaleSelect, ControlledCountrySelect, ControlledTimezoneSelect } from '@/components/forms';
import type { IRI } from '@/types/Id';
import TextareaAutosize from 'react-textarea-autosize';
import { SpoilerButtonRow } from '../common/Collapse';

type ClientFormProps = Readonly<{
    onSubmit: (output: ClientInit) => void;
    isFetching: boolean;
    defaultValue?: Client;
    onChange?: (isDirty: boolean) => void;
}>;

export default function ClientForm({ onSubmit, isFetching, defaultValue, onChange }: ClientFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });

    const {
        form,
        handleSubmit,
        showPreferences,
        setShowPreferences,
        showMore,
        setShowMore,
        showProfileSelect,
    } = useClientForm(onSubmit, defaultValue, onChange);
    const { control, register, formState: { errors } } = form;

    return (
        <Form className='sh-design' noValidate onSubmit={handleSubmit}>
            <Row className='mb-3'>
                <Form.Group as={Col} xs={12}>
                    <Form.Label>{t('name-company-label')}</Form.Label>
                    <Form.Control {...register('name', { required: tf('name-company-required') })} placeholder={t('name-company-placeholder')} />
                    <FormErrorMessage errors={errors} name='name' />
                </Form.Group>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <Form.Label>{t('email-label')}</Form.Label>
                    <Form.Control
                        {...register('email', {
                            required: tf('email-required'),
                            pattern: {
                                value: EMAIL_VALIDATION_PATTERN,
                                message: tf('email-invalid'),
                            },
                        })}
                        placeholder={t('email-placeholder')}
                        type='email'
                    />
                    <FormErrorMessage errors={errors} name='email' />
                </Col>
                <Col>
                    <Form.Label>{t('phone-number-label')}</Form.Label>
                    <Form.Control {...register('phoneNumber', { required: false })} placeholder={t('phone-number-placeholder')} />
                </Col>
            </Row>
            <Form.Group className='mt-3'>
                <Form.Label>{t('note-label')}</Form.Label>
                <Form.Control
                    {...register('note', { required: false, maxLength: { value: 1000, message: tf('text-too-long', { count: 1000 }) } })}
                    placeholder={t('note-placeholder')}
                    type='email'
                    as={TextareaAutosize}
                    minRows={2}
                />
                <FormErrorMessage errors={errors} name='note' />
            </Form.Group>
            <SpoilerButtonRow title={t('show-more-toggle')} isShow={showMore} setIsShow={setShowMore} className='my-4' />
            {showMore && (<>
                <SubscriberFormInner form={form} isNew />
                <Form.Group className='mt-3 mb-3'>
                    <Form.Check
                        type='switch'
                        label={t('preferences-toggle')}
                        checked={showPreferences}
                        onChange={event => setShowPreferences(event.target.checked)}
                        id='preferences-toggle'
                    />
                </Form.Group>
                {showPreferences && (<>
                    <div className='sh-description-no-border'>{t('preferences-description')}</div>
                    <Row className='gap-row-3'>
                        <Form.Group as={Col} xs={6}>
                            <Form.Label>{t('timezone-label')}</Form.Label>
                            <ControlledTimezoneSelect
                                control={control}
                                name='timezone'
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={6}>
                            <Form.Label>{t('locale-label')}</Form.Label>
                            <ControlledLocaleSelect
                                control={control}
                                name='locale'
                                type='client'
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={6}>
                            <Form.Label>{t('country-label')}</Form.Label>
                            <ControlledCountrySelect
                                control={control}
                                name='countryForPreferences'
                            />
                        </Form.Group>
                        {showProfileSelect && (
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>{t('profile-label')}</Form.Label>
                                <ControlledInvoicingProfileSelect
                                    control={control}
                                    name='invoicingProfileIRI'
                                />
                            </Form.Group>
                        )}
                    </Row>
                </>)}
            </>)}
            <Row className='mt-5 justify-content-center'>
                <Col xs={3}>
                    <SpinnerButton
                        type='submit'
                        isFetching={isFetching}
                        className='w-100'
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </Col>
            </Row>
        </Form>
    );
}

function useClientForm(
    onSubmit: (output: ClientInit) => void,
    defaultValue?: Client,
    onChange?: (isDirty: boolean) => void,
) {
    const form = useForm<ClientFormData>();
    const isDirty = form.formState.isDirty;
    const userContext = useUser();
    const masterContext = toMaster(userContext);
    const [ showPreferences, setShowPreferences ] = useState(false);
    const [ showMore, setShowMore ] = useState(false);

    useEffect(() => onChange?.(isDirty), [ isDirty ]);

    const handleNestedSubmit = useNestedForm(form.handleSubmit);

    function onValidSubmit(data: ClientFormData) {
        onSubmit(formToOutput({ ...data, email: canonizeEmail(data.email) }));
    }

    useEffect(() => {
        form.reset(inputToForm(defaultValue, userContext));
    }, [ defaultValue ]);

    return {
        form,
        handleSubmit: handleNestedSubmit(onValidSubmit),
        showPreferences,
        setShowPreferences,
        showMore,
        setShowMore,
        showProfileSelect: masterContext && masterContext.profiles.length > 0,
    };
}

type ClientFormData = {
    name: string;
    email: string;
    phoneNumber: string;

    timezone: TimezoneCode;
    locale: LocaleCode;
    countryForPreferences: CountryCode;
    invoicingProfileIRI: string;

    note: string;
} & SubscriberFormData;

function inputToForm(client: Client | undefined, { settings }: UserContext): ClientFormData {
    const subscriberFormData = subscriberInputToForm(client?.subscriberSettings ?? {
        hideEmailOnInvoice: false,
        name: '',
    });
    // The default country would be '', which is not undefinet but it still isn't valid. So we use the settings country.
    subscriberFormData.country = subscriberFormData.country || settings.country;

    return {
        email: client?.email ?? '',
        phoneNumber: client?.phoneNumber ?? '',
        timezone: client?.timezone ?? settings.timezone,
        locale: client?.locale ?? settings.locale,
        countryForPreferences: client?.country ?? settings.country,
        invoicingProfileIRI: client?.invoicingProfileId.toIRI() as unknown as string,
        note: client?.note ?? '',
        ...subscriberFormData,
    };
}

function formToOutput(data: ClientFormData): ClientInit {
    return {
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        timezone: data.timezone,
        locale: data.locale as ClientLocaleCode,
        country: data.countryForPreferences,
        note: data.note,
        invoicingProfile: data.invoicingProfileIRI as unknown as IRI,
        invoiceAccount: subscriberFormToOutput(data),
    };
}
