import React from 'react';

type IconProps = Readonly<{
    width?: string | number;
    height?: string | number;
    className?: string;
}>;

export default function FlowlanceBanner({ width, height, className }: IconProps) {
    return <img src='/flowlance_logo_banner.svg' style={{ width: width ?? '150px', height }} className={className} />;
}
