import { DateTime } from 'luxon';

export enum SubscriptionCode {
    Free = 'free',
    /** Professional */
    Paid2 = 'paid2',
}

export enum SubscriptionPeriod {
    Month = 'monthly',
    Year = 'yearly',
}

export type SubscriptionFromServer = {
    code: SubscriptionCode;
    paymentPeriod: SubscriptionPeriod;
    dateFrom: string;
    trialDays?: number;
    active: boolean;
    trial: boolean;
    pending: boolean;
    customInvoiceLogoEnabled: boolean;
    bankIntegrationEnabled: boolean;
};

export class Subscription {
    constructor(
        readonly code: SubscriptionCode,
        readonly paymentPeriod: SubscriptionPeriod,
        readonly dateFrom: DateTime,
        readonly dateToTrialEnd: DateTime,
        readonly isActive: boolean,
        readonly isTrial: boolean,
        readonly isPending: boolean,
        readonly isLogoChangeable: boolean,
        readonly isBankIntegrationEnabled: boolean,
    ) {}

    static fromServer(input: SubscriptionFromServer): Subscription {
        const dateFrom = DateTime.fromISO(input.dateFrom);
        const dateToTrialEnd = dateFrom.endOf('day').plus({ days: input.trialDays ?? 0 });

        return new Subscription(
            input.code,
            input.paymentPeriod,
            dateFrom,
            dateToTrialEnd,
            input.active,
            input.trial,
            input.pending,
            input.customInvoiceLogoEnabled,
            input.bankIntegrationEnabled,
        );
    }
}

export enum ErrorType {
    TrialEnded = 'subscription.trialEnded',
    PlanEnded = 'subscription.planEnded',
    PlanExceeded = 'subscription.planExceeded',
    PlanPending = 'subscription.planPending',
}

export type PlanExceededError = {
    type: ErrorType.PlanExceeded;
    available: number;
    cart: number;
};

export function isPlanExceededError(value: unknown): value is PlanExceededError {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value))
        return false;

    return value.type === ErrorType.PlanExceeded;
}

export type SubscriptionEndedError = {
    type: ErrorType.TrialEnded | ErrorType.PlanEnded | ErrorType.PlanPending;
};

export function isSubscriptionEndedError(value: unknown): value is SubscriptionEndedError {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value))
        return false;

    return value.type === ErrorType.TrialEnded || value.type === ErrorType.PlanEnded;
}

export type SubscriptionError = PlanExceededError | SubscriptionEndedError;

export type SubscriptionResponseFromServer = { subscriptionSessionUrl: string } | { subscription: SubscriptionFromServer };

export type SubscriptionUpdate = {
    code: SubscriptionCode;
    paymentPeriod: SubscriptionPeriod;
}

export type SubscriptionInit = SubscriptionUpdate & {
    clientReferenceId: string;
};
