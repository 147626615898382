import React from 'react';
import { type Event, EventState, TransitionName, type EventInfo } from '@/types/Event';
import { useTranslation } from 'react-i18next';
import { CircleFullIcon, CircleSemiFullIcon, CircleEmptyIcon, CircleSlashIcon } from '@/components/icons';
import clsx from 'clsx';
import type { IconType } from 'react-icons';
import { SpinnerButton } from '../common';

const stateDescriptions = {
    [EventState.Ready]: { color: 'warning', label: 'ready', icon: CircleSemiFullIcon },
    [EventState.Finished]: { color: 'success', label: 'finished', icon: CircleFullIcon },
    [EventState.Canceled]: { color: 'danger', label: 'canceled', icon: CircleEmptyIcon },
} as const;

const iconSizes = {
    'small': 16,
    'large': 20,
} as const;

type EventStateBadgeProps = {
    size?: keyof typeof iconSizes;
    className?: string;
} & ({
    event: EventInfo;
} | {
    state: EventState;
});

export default function EventStateBadge(props: EventStateBadgeProps) {
    const { t } = useTranslation('common', { keyPrefix: 'event.state' });
    const { size = 'small' } = props;
    const iconSize = iconSizes[size];
    const state = 'event' in props ? props.event.state : props.state;
    const description = stateDescriptions[state];

    return (
        <div className={clsx('d-flex align-items-center fw-medium gap-col-2', props.className)}>
            <span className={`d-flex text-${description.color}`}>
                {description.icon({ size: iconSize })}
            </span>
            {t(description.label)}
        </div>
    );
}

type EventDraftBadgeProps = Readonly<{
    size?: keyof typeof iconSizes;
    className?: string;
}>;

export function EventDraftBadge({ size, className }: EventDraftBadgeProps) {
    const { t } = useTranslation('common', { keyPrefix: 'event.state' });
    const iconSize = iconSizes[size ?? 'small'];

    return (
        <div className={clsx('d-flex align-items-center fw-medium gap-col-2', className)}>
            <span className='d-flex text-border-light'>
                <CircleEmptyIcon size={iconSize} />
            </span>
            {t('draft')}
        </div>
    );
}

class Transition {
    constructor(
        readonly name: TransitionName,
        readonly from: EventState[],
        readonly to: EventState,
        readonly buttonVariant: {
            compact: string;
            square: string;
        },
        readonly icon: IconType,
    ) {}

    isAvailableFor(state: EventState): boolean {
        return !!this.from.find(s => s === state);
    }
}

const transitions: Transition[] = [
    new Transition(TransitionName.Cancel, [ EventState.Ready, EventState.Finished ], EventState.Canceled, { compact: 'outline-danger', square: 'ghost-danger' }, CircleSlashIcon),
    new Transition(TransitionName.Revoke, [ EventState.Canceled ], EventState.Ready, { compact: 'outline-warning', square: 'ghost-warning' }, CircleSlashIcon),
];

type StateTransitionButtonsProps = Readonly<{
    event: Event;
    onSubmit: (transition: TransitionName, fid: string) => void;
    fetching: string | undefined;
    isOverlay?: boolean;
    className?: string;
    buttonType: 'compact' | 'square';
    buttonClassName?: string;
}>;

export function StateTransitionButtons({ event, onSubmit, fetching, isOverlay, className, buttonType, buttonClassName }: StateTransitionButtonsProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventSidebar' });

    return (
        <div className={className}>
            {transitions.filter(transition => transition.isAvailableFor(event.state)).map(transition => (
                <SpinnerButton
                    key={transition.name}
                    variant={transition.buttonVariant[buttonType]}
                    className={clsx(buttonType, buttonClassName)}
                    fetching={fetching}
                    fid={transition.name}
                    isOverlay={isOverlay}
                    onClick={() => onSubmit(transition.name, transition.name)}
                >
                    {transition.icon(buttonType === 'compact' ? { size: 18, className: 'me-2' } : { size: 22 })}{t(`${transition.name}Button`)}
                </SpinnerButton>
            ))}
        </div>
    );
}