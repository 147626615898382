import { useEffect } from 'react';

export default function useClickOutside({ ref, callback }) {
    useEffect(() => {
        const handleClickOutside = e => {
            if (ref.current && !ref.current.contains(e.target)) 
                callback();
      
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ ref, callback ]);
}
