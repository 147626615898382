import clsx from 'clsx';
import { type CalendarEvent, getEventSize, isGoogleEvent, isDraftEvent, isFullEvent } from '@/types/calendar/Calendar';
import { googleEventIsTransparent } from '@/types/calendar/Google';
import { eventIsTransparent } from '@/types/Event';
import { DateTime } from 'luxon';
import type { CSSProperties } from 'react';

export function getEventStyle(event: CalendarEvent, selected?: boolean): { className?: string | undefined, style?: CSSProperties } {
    if (isGoogleEvent(event)) {
        return {
            className: clsx('google', `sh-event-lines-${getEventSize(event)}`, {
                transparent: googleEventIsTransparent(event.resource.event),
                past: event.end <= DateTime.now(),
                'non-clickable': event.allDay,
            }),
            style: {
                '--sh-event-base-color': event.resource.calendar.color,
            },
        };
    }
    else if (isDraftEvent(event)) {
        return {
            className: `draft sh-event-lines-${getEventSize(event)}`,
        };
    }
    else if (isFullEvent(event)) {
        if (event.resource.calendar) {
            return {
                className: clsx(`sh-event-lines-${getEventSize(event)}`, {
                    transparent: eventIsTransparent(event.resource.event),
                    past: event.end <= DateTime.now(),
                }),
                style: {
                    '--sh-event-base-color': event.resource.calendar.color,
                },
            };
        }
        else {
            return {
                className: clsx(`sh-event-lines-${getEventSize(event)}`, {
                    transparent: eventIsTransparent(event.resource.event),
                    past: event.end <= DateTime.now(),
                }),
            };
        }
    }

    return {};
}
