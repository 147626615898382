import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { type Control, type FieldErrors } from 'react-hook-form';
import FormErrorMessage from '@/components/forms/FormErrorMessage';
import { type UseTransformReturn } from '@/utils/forms';
import { ControlledVatSelect } from '../forms/VatSelect';
import { TrashIcon } from '../icons';
import TextareaAutosize from 'react-textarea-autosize';
import clsx from 'clsx';
import { type CustomOrderFormData } from './CustomOrderForm';

type CustomOrderFormItemFieldsProps = Readonly<{
    index: number;
    transform: UseTransformReturn<CustomOrderFormData>;
    control: Control<CustomOrderFormData>;
    errors: FieldErrors<CustomOrderFormData>;
    isRemovable?: boolean;
    onRemove: () => void;
}>;

export default function CustomOrderFormItemFields({ index, transform, control, errors, isRemovable, onRemove }: CustomOrderFormItemFieldsProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'create-custom-order.items' });
    const isFirstRow = index === 0;

    return (
        <div>
            <div className={clsx('d-flex gap-3', isFirstRow ? 'sh-custom-order-first-row' : 'sh-custom-order-not-first-row')}>
                <Form.Group className='col-1'>
                    <Form.Label>{t('quantity-label')}</Form.Label>
                    <Form.Control
                        type='number'
                        // isn't price, but has the same rules
                        {...transform.registerPrice(`items.${index}.quantity`, { required: t('quantity-required') })}
                    />
                </Form.Group>
                <Form.Group className='flex-grow-1'>
                    <Form.Label>{t('name-label')}</Form.Label>
                    <Form.Control
                        placeholder={t('name-placeholder')}
                        {...transform.register(`items.${index}.label`, { required: t('name-required') })}
                        as={TextareaAutosize}
                        minRows={1}
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                        className='fw-medium'
                    />
                </Form.Group>
                <Form.Group className='col-2'>
                    <Form.Label>{t('price-label')}</Form.Label>
                    <Form.Control
                        type='number'
                        {...transform.registerPrice(`items.${index}.price`, { required: t('price-required') })}
                    />
                </Form.Group>
                <Form.Group className='col-1'>
                    <Form.Label>{t('vat-label')}</Form.Label>
                    <ControlledVatSelect
                        control={control}
                        name={`items.${index}.vatIRI`}
                    />
                </Form.Group>
                {isRemovable && (
                    <div className='sh-remove-button'>
                        <TrashIcon size={18} className='clickable' onClick={onRemove} />
                    </div>
                )}
            </div>
            <FormErrorMessage errors={errors} name={`items.${index}.quantity`} />
            <FormErrorMessage errors={errors} name={`items.${index}.label`} />
            <FormErrorMessage errors={errors} name={`items.${index}.price`} />
        </div>
    );
}
