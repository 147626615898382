import type { NavigateAction, SlotInfo } from '.';
import type { DateTime } from 'luxon';
import type { CalendarEvent } from '@/types/calendar/Calendar';
import type { ClientInfo } from '@/types/Client';

export const Views = {
    MONTH: 'month',
    WEEK: 'week',
    WORK_WEEK: 'work_week',
    DAY: 'day',
    AGENDA: 'agenda',
} as const;

export type View = typeof Views[keyof typeof Views];

type ViewProps = {
    events: CalendarEvent[];
    backgroundEvents: CalendarEvent[];
    date: DateTime;
    getNow: () => DateTime;
    onNavigate?: (newDate: DateTime, view: View, action: NavigateAction) => void;
    onDrillDown?: (date: DateTime, view: View) => void;
    onSelectEvent?: (event: CalendarEvent) => void;
    onKeyPressEvent?: (event: CalendarEvent, e: React.SyntheticEvent<HTMLElement>) => void;
    onSelectSlot?: (slot: SlotInfo) => void;
    onShowMore?: (events: CalendarEvent[], date: DateTime) => void;
    clients: ClientInfo[];
};

export type ViewObject = {
    component: (props: ViewProps) => JSX.Element;
    navigateTo: (date: DateTime, action: NavigateAction) => DateTime;
    getRange: (date: DateTime) => DateTime[];
};
