import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { api } from '@/utils/api/backend';
import PasswordInput from '@/components/forms/PasswordInput';
import FormErrorMessage from '@/components/forms/FormErrorMessage';
import { useForm } from 'react-hook-form';
import { PASSWORD_MINIMAL_LENGTH } from '@/utils/forms';
import { SpinnerButton } from '@/components/common';
import useNotifications from '@/context/NotificationProvider';
import useAuth from '@/context/AuthProvider';
import { Variant, Visibility } from '@/types/notifications';

export type ChangePasswordFormData = {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
};

export default function ChangePassword() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.change-password' });
    const { addAlert } = useNotifications();
    const { auth } = useAuth();
    const [ isFetching, setIsFetching ] = useState(false);

    const { register, getValues, handleSubmit, formState: { errors } } = useForm<ChangePasswordFormData>();

    function validateConfirmPassword(value: string) {
        if (getValues('newPassword') !== value) 
            return t('passwords-do-not-match-error');
        
        return true;
    }
    
    async function onSubmit(data: ChangePasswordFormData) {
        setIsFetching(true);
        const response = await api.appUser.updatePassword({
            oldPassword: data.currentPassword,
            newPassword: data.newPassword,
        });
        setIsFetching(false);
        if (!response.status) {
            // TODO handle error

            //addAlert({ message: 'Error', variant: Variant.Error });
            return;
        }

        addAlert({ message: t('success-alert'), variant: Variant.Success }, Visibility.Global);
        auth.logout();
    }

    return (
        <Container className='content-tiny'>
            <h1 className='mt-0'>{t('page-title')}</h1>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className='mt-3'>
                    <Form.Label className='fw-semibold'>{t('current-password-label')}</Form.Label>
                    <PasswordInput
                        register={register('currentPassword', { required: t('current-password-required-error') })}
                    />
                    <FormErrorMessage errors={errors} name='currentPassword' />
                </Form.Group>
                <Form.Group className='mt-3'>
                    <Form.Label className='fw-semibold'>{t('new-password-label')}</Form.Label>
                    <PasswordInput
                        register={register('newPassword', {
                            required: t('new-password-required-error'),
                            minLength: { value: PASSWORD_MINIMAL_LENGTH, message: t('new-password-too-short', { minimalLength: PASSWORD_MINIMAL_LENGTH }) },
                        })}
                    />
                    <FormErrorMessage errors={errors} name='newPassword' />
                </Form.Group>
                <Form.Group className='mt-3'>
                    <Form.Label className='fw-semibold'>{t('confirm-password-label')}</Form.Label>
                    <PasswordInput
                        register={register('confirmPassword', { validate: validateConfirmPassword })}
                    />
                    <FormErrorMessage errors={errors} name='confirmPassword' />
                </Form.Group>
                <Row className='mt-5'>
                    <Col>
                        <SpinnerButton
                            type='submit'
                            isFetching={isFetching}
                        >
                            {t('save-button')}
                        </SpinnerButton>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}
