import React, { useEffect, useMemo, useState } from 'react';
import { api } from '@/utils/api/backend';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from '@/router';
import { getAppUserDefaults } from '@/types/i18n';
import { getDefaultTaxRate } from '@/modules/money';
import useAuth from '@/context/AuthProvider';
import { fetchCurrenciesAndTaxRates } from './Register';
import { GoogleRegisterButton } from '@/components/auth/googleButton';
import { FlowlanceBanner } from '@/components/icons';
import { Form } from 'react-bootstrap';
import { ControlledStringSelect } from '@/components/forms/FormSelect';
import { useForm } from 'react-hook-form';
import { professionOptions } from '@/components/auth/RegisterPersonForm';
import FormErrorMessage from '@/components/forms/FormErrorMessage';
import { SpinnerButton } from '@/components/common';
import { type Tokens } from '@/utils/AuthManager';
import { type Result } from '@/types/api/result';

type ErrorData = {
    error: string;
    email: string;
}

type RegisterData = {
    email: string;
    firstName: string;
    lastName?: string;
    refreshToken: string;
}

export default function RegisterGoogleCallback() {
    const { t } = useTranslation('pages', { keyPrefix: 'registerGoogle' });
    const [ searchParams ] = useSearchParams();

    const { errorData, registerData } = useMemo(() => {
        const error = searchParams.get('error');
        const email = searchParams.get('email');

        return error
            ? { errorData: {
                error,
                email,
            } as ErrorData }
            : { registerData: {
                email,
                firstName: searchParams.get('firstName'),
                lastName: searchParams.get('lastName'),
                refreshToken: searchParams.get('refreshToken'),
            } as RegisterData };
    }, [ searchParams ]);

    if (errorData?.error === 'app.google.missing_permissions') {
        return (
            <div className='sh-unauthorized-page'>
                <div className='centering-wrapper'>
                    <div className='sh-unauthorized-card'>
                        <div className='sh-unauthorized-content d-flex flex-column align-items-center'>
                            <FlowlanceBanner />
                            <h1 className='fs-1'>{t('missing-access')}</h1>
                            <p>{t('grant-access')}</p>
                            <GoogleRegisterButton title={t('grant-access-button')} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!registerData) {
        return (
            <div className='sh-unauthorized-page'>
                <div className='centering-wrapper'>
                    <div className='sh-unauthorized-card'>
                        <div className='sh-unauthorized-content d-flex flex-column align-items-center'>
                            <FlowlanceBanner />
                            <h1 className='fs-1'>{t('something-went-wrong')}</h1>
                            <p className='fs-3 mb-0'>
                                <Trans
                                    i18nKey='try-again'
                                    t={t}
                                    components={{
                                        a: <Link to={routes.register.index} />,
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <RegisterGoogleFinish data={registerData} />
    );
}

type GoogleFormData = {
    profession: string;
};

type RegisterGoogleFinishProps = Readonly<{
    data: RegisterData;
}>;

function RegisterGoogleFinish({ data }: RegisterGoogleFinishProps) {
    const { auth } = useAuth();
    const [ registerPromise, setRegisterPromise ] = useState<Promise<Result<Tokens>>>();

    // This is a complicated process. We need to register the user because he is already registered from the google's point of view - therefore, if he refereshes/leave the page, he will be able to login again.
    // We also want to ask him some questions. But they are not crucial, if he just refershes the page, he can skip them. Then we update his onboarding info based on the responses and we finally redirect him to the app.
    // This is really hacky, because we need to set the token for the token provider (so that the update request can be sent) but we can't just log him in - because then he would be automatically redirected to the app. Also, we need to set the tokens after the upade is done (because we don't want him to need to login again).

    async function register(signal: AbortSignal) {
        const metadataSuccess = await fetchCurrenciesAndTaxRates(signal);
        if (!metadataSuccess)
            // TODO handle error?
            return;

        const promise = auth.registerWithoutLogin({
            type: 'google',
            ...data,
            ...getAppUserDefaults(),
            vat: getDefaultTaxRate().toIRI(),
        });
        setRegisterPromise(promise);
    }
    
    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        register(signal);

        return abort;
    }, []);
 
    if (!registerPromise)
        return null;

    return (
        <RegisterGoogleForm registerPromise={registerPromise} />
    );
}

type RegisterGoogleFormProps = Readonly<{
    registerPromise: Promise<Result<Tokens>>;
}>;

function RegisterGoogleForm({ registerPromise }: RegisterGoogleFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'registerGoogle' });
    const { t: tp } = useTranslation('pages', { keyPrefix: 'register.personForm.profession' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const [ isFetching, setIsFetching ] = useState(false);
    const { control, handleSubmit, formState: { errors } } = useForm<GoogleFormData>();

    const { auth } = useAuth();
    const navigate = useNavigate();

    async function submitGoogleForm(googleData: GoogleFormData) {
        setIsFetching(true);
        const registerResponse = await registerPromise;
        if (!registerResponse.status)
            // TODO handle error
            return;

        const response = await api.settings.updateInfo({
            profession: googleData.profession,
        });
        if (!response.status)
            // TODO handle error
            return;
    
        auth.loginWithTokens(registerResponse.data);
        navigate(routes.root);
    }

    return (
        <div className='sh-unauthorized-page'>
            <div className='centering-wrapper'>
                <div className='sh-unauthorized-card'>
                    <div className='sh-unauthorized-content d-flex flex-column align-items-center justify-content-center'>
                        <FlowlanceBanner />
                        <h1>{t('page-title')}</h1>
                        <Form noValidate onSubmit={handleSubmit(submitGoogleForm)} className='w-100'>
                            <Form.Group className='sh-form-label-inside mt-3'>
                                <Form.Label>{t('profession-label')}</Form.Label>
                                <ControlledStringSelect
                                    control={control}
                                    name='profession'
                                    options={professionOptions}
                                    t={tp}
                                    rules={{ required: tf('profession-required') }}
                                    placeholder=''
                                    isSearchable={false}
                                />
                                <FormErrorMessage errors={errors} name='profession' />
                            </Form.Group>
                            <SpinnerButton
                                type='submit'
                                className='w-100 mt-3'
                                isFetching={isFetching}
                            >
                                {t('finish-button')}
                            </SpinnerButton>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}
