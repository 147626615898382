import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { api } from '@/utils/api/backend';
import { type BaseLocation } from '@/types/location';
import { SpinnerButton } from '@/components/common';
import { useTranslation } from 'react-i18next';
import { createTranslatedErrorAlert } from '@/components/notifications';
import useNotifications from '@/context/NotificationProvider';

type DeleteLocationModalProps = Readonly<{
    location?: BaseLocation;
    onClose(): void;
    onDelete?(deletedLocation: BaseLocation): void;
}>;

export default function DeleteLocationModal({ location, onClose, onDelete }: DeleteLocationModalProps) {
    const [ isFetching, setIsFetching ] = useState(false);

    const { t } = useTranslation('pages', { keyPrefix: 'settings.locations' });
    const { addAlert } = useNotifications();

    async function deleteLocation() {
        if (!location)
            return;

        setIsFetching(true);
        const response = await api.location.delete(location);
        setIsFetching(false);

        if (!response.status) {
            addAlert(createTranslatedErrorAlert());
            onClose();
            return;
        }

        if (onDelete)
            onDelete(location);
    }

    return (
        <Modal show={!!location} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('delete-location-modal-title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='text-center'>
                    <Col>{t('delete-location-modal-text')}<br /></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <SpinnerButton
                    variant='danger'
                    isFetching={isFetching}
                    onClick={deleteLocation}
                    className='mx-auto'
                >
                    {t('delete-location-modal-confirm')}
                </SpinnerButton>
            </Modal.Footer>
        </Modal>
    );
}
