import React, { useCallback, type RefAttributes, useEffect, useMemo } from 'react';
import { type BlankNode, type Entity, type UniqueType, Id } from '@/types/Id';
import type { ControlledRules } from '@/utils/forms';
import clsx from 'clsx';
import type { TFunction } from 'i18next';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import ReactSelect, { type ClassNamesConfig, type GroupBase, type MultiValue, type OnChangeValue, type SingleValue } from 'react-select';
import type Select from 'react-select/dist/declarations/src/Select';
import { type StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const classNames: ClassNamesConfig<any, boolean, any> = {
    control: (state) => clsx('rs-control', {
        focused: state.isFocused,
    }),
    option: (state) => clsx('rs-option', {
        focused: state.isFocused,
        selected: state.isSelected,
        disabled: state.isDisabled,
    }),
    menu: () => 'rs-menu',
    valueContainer: () => 'rs-value',
    dropdownIndicator: () => 'rs-dropdown-indicator',
    indicatorSeparator: () => 'rs-indicator-separator',
    clearIndicator: () => 'rs-clear-indicator',
    input: () => 'rs-input',
    singleValue: () => 'rs-single-value',
    multiValue: () => 'rs-multi-value',
    multiValueLabel: () => 'rs-multi-value-label',
    multiValueRemove: () => 'rs-multi-value-remove',
    placeholder: () => 'rs-placeholder',
};

type ValueType = string | number | Entity<string> | UniqueType<string, string> | BlankNode;

function optionsInclude<Option extends { value: ValueType }>(options: Option[], option: Option): boolean {
    const { value } = option;
    if (typeof value !== 'object' || !('id' in value))
        return options.some(o => o.value === value);

    if (value.id instanceof Id) {
        const valueEntity = value as Entity;
        return options.some(o => {
            const oEntity = o.value as Entity;
            return oEntity.id.equals(valueEntity.id);
        });
    }

    if (typeof value.id === 'string') {
        const valueBlank = value as BlankNode;
        return options.some(o => {
            const oBlank = o.value as BlankNode;
            return oBlank.id === valueBlank.id;
        });

    }

    return false;
}

export default function FormSelect<
    Option extends { value: ValueType },
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: Omit<StateManagerProps<Option, IsMulti, Group> & RefAttributes<Select<Option, IsMulti, Group>>, 'options'> & {
        options?: Option[];
        ignoreOptionsChanges?: boolean; // Don't remove values that are not present in the new options (whenever options change).
    },
) {
    /**
     * Whenever the options change, we have to manually find all currently selected ones that are not included in the new options and remove them.
     */
    useEffect(() => {
        if (props.ignoreOptionsChanges)
            return;

        const options = props.options;
        if (!options || !props.onChange)
            return;

        if (props.isMulti) {
            const removedValues: Option[] = (props.value as MultiValue<Option>).filter(value => !optionsInclude(options, value));
            if (removedValues.length === 0)
                return;

            const newValues: readonly Option[] = (props.value as MultiValue<Option>).filter(value => optionsInclude(options, value));
            props.onChange(newValues as OnChangeValue<Option, IsMulti>, {
                action: 'clear',
                removedValues,
            });
        }
        else {
            const value = props.value as SingleValue<Option>;
            if (!value || optionsInclude(options, value))
                return;

            props.onChange(null as OnChangeValue<Option, IsMulti>, {
                action: 'clear',
                removedValues: [ value ],
            });
        }
    }, [ props.options, props.ignoreOptionsChanges ]);

    return (
        <ReactSelect
            {...props}
            classNames={classNames}
        />
    );
}

type Option = {
    value: string;
    label: string;
};

type TranslationFunction = (id: string) => string;

function valueToOption(value: string, t: TranslationFunction): Option {
    return {
        value,
        label: t(value),
    };
}

type StringSelectProps = {
    value?: string;
    onChange: (value?: string) => void;
    options: string[];
    t: TranslationFunction;
    placeholder?: string;
    autoFocus?: boolean;
    openMenuOnFocus?: boolean;
    className?: string;
    isSearchable?: boolean;
};

export function StringSelect({ value, onChange, options, t, ...rest }: StringSelectProps) {
    const innerOptions = useMemo(() => options.map(value => valueToOption(value, t)), [ options, t ]);
    const innerValue = useMemo(() => value ? valueToOption(value, t) : null, [ value, t ]);
    const innerOnChange = useCallback((option: SingleValue<Option>) => onChange(option ? option.value : undefined), [ onChange ]);

    return (
        <FormSelect
            value={innerValue}
            onChange={innerOnChange}
            options={innerOptions}
            {...rest}
        />
    );
}

type ControlledStringSelectProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    control: Control<TFieldValues>;
    name: TName;
    rules?: ControlledRules<TFieldValues, TName>;
    options: string[];
    t: TFunction;
    placeholder?: string;
    isSearchable?: boolean;
};

export function ControlledStringSelect<TFieldValues extends FieldValues>({ control, name, rules, options, t, placeholder, isSearchable }: ControlledStringSelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value?: string, onChange: (value?: string) => void } }) => {
        return (
            <StringSelect
                {...field}
                options={options}
                t={t}
                placeholder={placeholder}
                isSearchable={isSearchable}
            />
        );
    }, [ options, t, placeholder, isSearchable ]);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={InnerSelect}
        />
    );
}

/**
 * Workaround for the home and end keys in writeable selects.
 */
export function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement> & React.BaseSyntheticEvent<KeyboardEvent, HTMLInputElement, HTMLInputElement>) {
    const length = event.target.value.length;

    switch(event.key) {
    case 'Home':
        event.preventDefault();
        if (event.shiftKey)
            event.target.selectionStart = 0;
        else
            event.target.setSelectionRange(0,0);
        break;
    case 'End':
        event.preventDefault();
        if (event.shiftKey)
            event.target.selectionEnd = length;
        else
            event.target.setSelectionRange(length,length);
        break;
    }
}
