import React, { useLayoutEffect } from 'react';
import getOffset from 'dom-helpers/offset';

import useClickOutside from './hooks/useClickOutside';
import EventCell from './EventCell';
import { isSelected } from './utils/selection';
import { localizer } from '.';

/**
 * Changes to react-overlays cause issue with auto positioning,
 * so we need to manually calculate the position of the popper,
 * and constrain it to the Month container.
 */
function getPosition({ target, offset, container, box }) {
    const { top, left, width, height } = getOffset(target);
    const {
        top: cTop,
        left: cLeft,
        width: cWidth,
        height: cHeight,
    } = getOffset(container);
    const { width: bWidth, height: bHeight } = getOffset(box);
    const viewBottom = cTop + cHeight;
    const viewRight = cLeft + cWidth;
    const bottom = top + bHeight;
    const right = left + bWidth;
    const { x, y } = offset;
    const topOffset = bottom > viewBottom ? top - bHeight - y : top + y + height;
    const leftOffset = right > viewRight ? left + x - bWidth + width : left + x;

    return {
        topOffset,
        leftOffset,
    };
}

function Pop({
    containerRef,
    selected,
    position,
    show,
    events,
    slotStart,
    slotEnd,
    onSelect,
    onDoubleClick,
    onKeyPress,
    handleDragStart,
    popperRef,
    target,
    offset,
}) {
    useClickOutside({ ref: popperRef, callback: show });
    useLayoutEffect(() => {
        const { topOffset, leftOffset } = getPosition({
            target,
            offset,
            container: containerRef.current,
            box: popperRef.current,
        });
        popperRef.current.style.top = `${topOffset}px`;
        popperRef.current.style.left = `${leftOffset}px`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ offset.x, offset.y, target ]);

    const { width } = position;
    const style = {
        minWidth: width + width / 2,
    };
    return (
        <div style={style} className='rbc-overlay' ref={popperRef}>
            <div className='rbc-overlay-header'>
                {localizer.fullFormat(slotStart, 'dayHeaderFormat')}
            </div>
            {events.map((event, index) => (
                <EventCell
                    key={index}
                    type='popup'
                    event={event}
                    onSelect={onSelect}
                    onDoubleClick={onDoubleClick}
                    onKeyPress={onKeyPress}
                    continuesPrior={localizer.lt(event.end, slotStart, 'day')}
                    continuesAfter={localizer.gte(event.start, slotEnd, 'day')}
                    selected={isSelected(event, selected)}
                    draggable={true}
                    onDragStart={() => handleDragStart(event)}
                    onDragEnd={() => show()}
                />
            ))}
        </div>
    );
}

const Popup = React.forwardRef((props, ref) => (
    <Pop {...props} popperRef={ref} />
));
// Popup.propTypes = {
//   selected: PropTypes.object,
//   position: PropTypes.object.isRequired,
//   show: PropTypes.func.isRequired,
//   events: PropTypes.array.isRequired,
//   slotStart: PropTypes.instanceOf(DateTime).isRequired,
//   slotEnd: PropTypes.instanceOf(DateTime),
//   onSelect: PropTypes.func,
//   onDoubleClick: PropTypes.func,
//   onKeyPress: PropTypes.func,
//   handleDragStart: PropTypes.func,
//   style: PropTypes.object,
//   offset: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
// }
export default Popup;
