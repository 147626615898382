import React from 'react';
import { localizer } from '.';
import { type DateTime } from 'luxon';
import { capitalize } from '@/utils/common';

type HeaderType = 'month' | 'week';

type HeaderProps = {
    date: DateTime;
    type: HeaderType;
};

export default function Header({ date, type }: HeaderProps) {
    return (
        <div className={`sh-${type}-header`}>
            <div className='fw-normal'>{capitalize(localizer.format(date, 'cccc'))}</div>
            {type === 'week' && <div className='sh-header-daynumber'>{localizer.format(date, 'd')}</div>}
        </div>
    );
}
