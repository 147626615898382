import { DateTime } from 'luxon';
import { type Entity, Id, type IRI } from './Id';
import { type Person } from './Person';
import { type CurrencyIRI, type TaxRateIRI } from '@/modules/money';
import type { CountryCode, LocaleCode, TimezoneCode } from './i18n';

export type AppUserFromServer = {
    '@id': IRI;
    firstName: string;
    lastName?: string;
    email: string;
    phoneNumber: string;
    onboardingState: IRI;
    isCalendarEnabled: boolean;
    googleCalendarId: string | undefined;
    googleContactsConnected: boolean;
    googleLoginable: boolean;
    datePastEvents: string;
    creditsAllowed: boolean;
};

export class AppUser implements Entity, Person {
    private constructor(
        readonly id: Id,
        readonly firstName: string,
        readonly lastName: string | undefined,
        readonly email: string,
        readonly phoneNumber: string | undefined,
        readonly isCalendarEnabled: boolean,
        readonly googleCalendarId: string | undefined,
        readonly isContactsEnabled: boolean,
        readonly isGoogleLoginable: boolean,
        readonly datePastEvents: DateTime,
        readonly isCreditsAllowed: boolean,
    ) {}

    static fromServer(input: AppUserFromServer): AppUser {
        return new AppUser(
            Id.fromIRI(input['@id']),
            input.firstName,
            input.lastName,
            input.email,
            input.phoneNumber,
            input.isCalendarEnabled,
            input.googleCalendarId,
            input.googleContactsConnected,
            input.googleLoginable,
            DateTime.fromISO(input.datePastEvents),
            input.creditsAllowed,
        );
    }
}

export function getName(appUser: AppUser): string {
    return appUser.firstName + (appUser.lastName ? ' ' + appUser.lastName : '');
}

export function getShortName(appUser: AppUser): string {
    return appUser.firstName + (appUser.lastName ? ` ${appUser.lastName.at(0)}.` : '');
}

export type AppUserInit = {
    email: string;
    timezone: TimezoneCode;
    locale: LocaleCode;
    country: CountryCode;
    currency: CurrencyIRI;
    vat: TaxRateIRI;
    /** The lenght of the default pricing, in seconds. */
    duration: number;
    /** The price of the default pricing, in cents. */
    price: number;
    firstName: string;
    lastName?: string;
} & ({
    type: 'google';
    refreshToken: string;
} | {
    type: 'email';
    password: string;
});
