import React, { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useAuth from '@/context/AuthProvider';
import { createActionState } from '@/hooks';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../Loading';
import type { PrefillEmail } from './Login';
import { routes } from '@/router';
import { GoogleRegisterButton } from '@/components/auth/googleButton';
import { FlowlanceBanner } from '@/components/icons';

export default function LoginGoogleCallback() {
    const { t } = useTranslation('pages', { keyPrefix: 'loginGoogle' });
    const [ searchParams ] = useSearchParams();
    const { auth } = useAuth();
    const navigate = useNavigate();

    const { error, email, loginToken } = useMemo(() => {
        const error = searchParams.get('error');
        const email = searchParams.get('email');

        if (error)
            return { error, email };

        return {
            loginToken: searchParams.get('loginToken'),
        };
    }, [ searchParams ]);

    async function googleLogin(loginToken: string) {
        const result = await auth.loginWithGoogle(loginToken);
        if (result)
            navigate(routes.root);
    }

    useEffect(() => {
        if (!loginToken)
            return;

        googleLogin(loginToken);
    }, []);

    if (error === 'app.google.wrong_login_method' && email) {
        return (
            <div className='sh-unauthorized-page'>
                <div className='centering-wrapper'>
                    <div className='sh-unauthorized-card'>
                        <div className='sh-unauthorized-content d-flex flex-column align-items-center'>
                            <FlowlanceBanner />
                            <h1 className='fs-1'>{t('we-know-you')}</h1>
                            <p className='fs-3 mb-0'>
                                <Trans
                                    i18nKey='already-email'
                                    t={t}
                                    values={{
                                        email,
                                    }}
                                    components={{
                                        s: <span className='fw-semibold' />,
                                        a: <Link to={routes.login.index} state={createActionState<PrefillEmail>('prefillEmail', email)} />,
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (error === 'app.google.user_not_found') {
        return (
            <div className='sh-unauthorized-page'>
                <div className='centering-wrapper'>
                    <div className='sh-unauthorized-card'>
                        <div className='sh-unauthorized-content d-flex flex-column align-items-center'>
                            <FlowlanceBanner />
                            <h1 className='fs-1'>{t('we-dont-know-you')}</h1>
                            <p className='fs-3'>{t('no-account')}</p>
                            <GoogleRegisterButton />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!loginToken) {
        return (
            <div className='sh-unauthorized-page'>
                <div className='centering-wrapper'>
                    <div className='sh-unauthorized-card'>
                        <div className='sh-unauthorized-content d-flex flex-column align-items-center'>
                            <FlowlanceBanner />
                            <h1 className='fs-1'>{t('something-went-wrong')}</h1>
                            <p className='fs-3 mb-0'>
                                <Trans
                                    i18nKey='try-again'
                                    t={t}
                                    components={{
                                        a: <Link to={routes.login.index} />,
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Loading />
    );
}
