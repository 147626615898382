import { type CalendarEvent, type GoogleEventResource } from './Calendar';
import { DateTime } from 'luxon';
import { googleApi } from '@/utils/api/google';
import { localizer } from '@/lib/calendar';
import { type Settings } from '@/types/Settings';
import { type Money } from '@/modules/money';
import { ClientContact, type ClientInfo } from '../Client';
import { type GoogleUserInfo } from '../GoogleUser';
import { type AppUser } from '../AppUser';
import { clientContactToServer, ParticipantType, type NewParticipantInitToServer, type ParticipantInitToServer } from '../EventParticipant';

export type GoogleCalendarFromServer = {
    id: string;
    summary: string;
    backgroundColor: string;
    primary: boolean | undefined;
};

export class GoogleCalendar {
    private constructor(
        readonly id: string,
        readonly name: string,
        readonly color: string,
        /** The Flowlance Google calendar (where we write the events), not the user's default calendar. */
        readonly isDefault: boolean,
        /** The user's default Google Calendar, usually named with his email address. */
        readonly isPrimary: boolean,
    ) {}

    static fromServer(input: GoogleCalendarFromServer, defaultCalendarId: string | undefined): GoogleCalendar {
        return new GoogleCalendar(
            input.id,
            input.summary,
            input.backgroundColor,
            input.id === defaultCalendarId,
            !!input.primary,
        );
    }
}

export function sortCalendars(calendars: GoogleCalendar[]): GoogleCalendar[] {
    const sortFunction = (a: GoogleCalendar, b: GoogleCalendar) => {
        if (a.isPrimary)
            return -1;
        if (b.isPrimary)
            return 1;

        if (a.id < b.id)
            return -1;
        if (a.id > b.id)
            return 1;

        return 0;
    };

    return calendars.sort(sortFunction);
}

type EventDate = {
    date?: string;
    dateTime?: string;
    timezone?: string;
};

export type GoogleEventFromServer = {
    id: string;
    recurringEventId: string | undefined;
    calendarId: string;
    summary?: string;
    description?: string;
    start?: EventDate; // If the event is canceled, it doesn't have start and end
    end?: EventDate;
    attendees?: { email: string }[];
};

export class GoogleEvent {
    totalPrice?: Money;

    private constructor(
        /** Either instanceId or id. The primary identifier of the event. */
        readonly id: string,
        /**
         * Id of the recurrence branch or of the event (if it isn't recurrent).
         * Use only for communication with backend.
         */
        readonly branchId: string,
        /**
         * Id of the instance (if it's a recurrent event).
         * Use only for communication with backend.
         */
        readonly instanceId: string | undefined,
        readonly calendarId: string,
        readonly title: string,
        readonly description: string | undefined,
        readonly isAllDay: boolean,
        readonly start: DateTime,
        readonly end: DateTime,
        /** either empty (if only the app user is going) or 2 or more emails (including the user's email) */
        readonly guests: string[],
    ) {}

    static fromServer(input: GoogleEventFromServer): GoogleEvent | undefined {
        if (!input.start || !input.end)
            return undefined;

        const isAllDay = !!(input.start.date && input.end.date);
        const branchId = input.recurringEventId ? input.recurringEventId : input.id;
        const instanceId = input.recurringEventId ? input.id : undefined;

        return new GoogleEvent(
            input.id, // Same as instanceId ?? branchId
            branchId,
            instanceId,
            input.calendarId,
            input.summary ?? '',
            input.description,
            isAllDay,
            eventDateFromServer(input.start),
            eventDateFromServer(input.end),
            input.attendees?.map(a => a.email) ?? [],
        );
    }
}

export function googleEventIsTransparent(event: GoogleEvent): boolean {
    // TODO should be transparent if the user isn't attending
    return false;
}

function eventDateFromServer(input: EventDate): DateTime {
    if (input.date)
        return DateTime.fromFormat(input.date, 'yyyy-MM-dd');

    if (!input.dateTime)
        throw new Error('Calendar event has invalid date: ' + JSON.stringify(input));

    return DateTime.fromISO(input.dateTime, { zone: input.timezone });
}

export type GoogleCalendarEventsFromServer = {
    items: GoogleEventFromServer[];
    nextPageToken?: string;
};

export async function fetchGoogleEvents(start: DateTime, end: DateTime, calendarId: string, signal?: AbortSignal): Promise<GoogleEvent[] | undefined> {
    const response = await googleApi.getCalendarEvents(start, end, calendarId, signal);
    if (!response.status)
        return undefined;

    return response.data
        .map(e => {
            e.calendarId = calendarId;
            return GoogleEvent.fromServer(e);
        })
        .filter((e): e is GoogleEvent => !!e);
}

export function googleEventsToCalendarEvents(events: GoogleEvent[], calendars: GoogleCalendar[], activeIds: Set<string>): CalendarEvent[] {
    const enabledCalendars = calendars.filter(calendar => activeIds.has(calendar.id));
    return events.map(event => googleEventToCalendarEvent(event, enabledCalendars))
        .filter((event): event is CalendarEvent => !!event);
}

function googleEventToCalendarEvent(event: GoogleEvent, calendars: GoogleCalendar[]): CalendarEvent | undefined {
    const calendar = calendars.find(c => c.id === event.calendarId);
    if (!calendar)
        return undefined;

    const resource: GoogleEventResource = {
        type: 'google',
        event: event,
        calendar,
    };

    return {
        title: event.title,
        allDay: event.isAllDay,
        start: event.start,
        end: event.end,
        resource,
        startDay: localizer.startOf(event.start, 'day'),
        duration: Math.max(localizer.diff(event.start, localizer.ceil(event.end, 'day'), 'day')),
        id: event.id,
        isBillable: false,
    };
}

export type GoogleEventToServer = {
    participants: ParticipantInitToServer[];
    newParticipants: NewParticipantInitToServer[];
    googleCalendarId: string;
    googleEventId: string;
    googleEventInstanceId?: string;
    paid: boolean;
}

export function googleEventToServer(
    eventInstance: GoogleEvent,
    settings: Settings,
    clients: ClientInfo[],
    googleUserInfo: GoogleUserInfo,
    appUser: AppUser,
): GoogleEventToServer {
    const { participants, newParticipants } = createParticipantsFromEvent(eventInstance, settings, googleUserInfo, clients);
    const isPaid = +eventInstance.start < +appUser.datePastEvents;

    return {
        participants,
        newParticipants,
        googleCalendarId: eventInstance.calendarId,
        googleEventInstanceId: eventInstance.instanceId,
        googleEventId: eventInstance.branchId,
        paid: isPaid,
    };
}

function createParticipantsFromEvent(event: GoogleEvent, settings: Settings, googleUserInfo: GoogleUserInfo, clients: ClientInfo[]): {
    participants: ParticipantInitToServer[];
    newParticipants: NewParticipantInitToServer[];
} {
    const participants: ParticipantInitToServer[] = [];
    const newParticipants: NewParticipantInitToServer[] = [];

    // We are only creating guests. The user can add clients later.
    event.guests.forEach(email => {
        if (email === googleUserInfo.email)
            return;

        const foundClient = clients.find(c => c.email === email);
        if (foundClient) {
            participants.push({ client: foundClient.id.toIRI(), ...guestData });
        }
        else {
            const contact = ClientContact.fromEmail(email);
            newParticipants.push({ contact: clientContactToServer(contact, settings), ...guestData });
        }
        // TODO find some other info
    });

    return { participants, newParticipants };
}

const guestData = {
    type: ParticipantType.Guest,
    price: 0,
    payByCredit: false,
} as const;
