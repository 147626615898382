import localStorage from '@/utils/localStorage';

/**
 * ISO 3166-1 alpha-2
 * /[A-Z]{2}/
 */
export type CountryCode = string;

/** /[a-z]{2}-[A-Z]{2}/ */
export type LocaleCode = string;

export type TimezoneCode = string;

// TODO create appUser/client locale select
export type LocaleType = 'appUser' | 'client' | 'invoice';

export const SUPPORTED_LOCALES = {
    appUser: [
        'cs-CZ',
        'en-US',
    ],
    client: [
        'cs-CZ',
        'en-US',
    ],
    invoice: [
        'cs-CZ',
        'en-US',
        'de-DE',
        'es-ES',
    ],
} as const;

export type ClientLocaleCode = typeof SUPPORTED_LOCALES.client[number];

export const ALL_SUPPORTED_LOCALES: LocaleCode[] = [ ...new Set<LocaleCode>(Object.values(SUPPORTED_LOCALES).flatMap(locales => locales)).values() ];

const LOCALE_KEY = 'locale';

function getDefaultLocale() {
    // If the appUser set the locale manually (i.e., he interacted with the flag button), we should use them.
    const storedLocale = localStorage.get<LocaleCode>(LOCALE_KEY);
    if (storedLocale !== null && (SUPPORTED_LOCALES.appUser as readonly string[]).includes(storedLocale))
        return storedLocale;

    // Let's try Czech.
    if ([ 'cs-CZ', 'cs' ].includes(navigator.language))
        return SUPPORTED_LOCALES.appUser[0];

    return SUPPORTED_LOCALES.appUser[1];
}

export type LocaleOption = {
    value: LocaleCode;
    label: string;
};

export function localeToOption(locale: LocaleCode): LocaleOption {
    return {
        value: locale,
        label: i18next.t('common:locales.' + locale),
    };
}

export function localeToLanguage(locale: LocaleCode): string {
    return locale.substring(0, 2);
}

// Date picker

import date_cs_CZ from 'date-fns/locale/cs';
import date_en_GB from 'date-fns/locale/en-GB';

type DatePickerLocale = Locale;

const DATE_LOCALES: { [key: LocaleCode]: DatePickerLocale } = {
    'cs-CZ': date_cs_CZ,
    'en-US': date_en_GB,  // we always want weeks to start on Monday
};

import { registerLocale, setDefaultLocale } from  'react-datepicker';

Object.entries(DATE_LOCALES).forEach(([ locale, dateLocale ]) => registerLocale(locale, dateLocale));

type TranslationGenerator<T> = (locale: LocaleCode) => T;

function cacheTranslations<T>(generator: TranslationGenerator<T>): (locale: LocaleCode) => T {
    const cache = new Map<LocaleCode, T>();

    return (locale: LocaleCode) => {
        const cached = cache.get(locale);
        if (cached)
            return cached;

        const generated = generator(locale);
        cache.set(locale, generated);

        return generated;
    };
}

// Timezone

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
    type Key = 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit';

    function supportedValuesOf(input: Key): string[];

    interface DisplayNames {
        of(contryCode: CountryCode): string;
    }

    const DisplayNames: {
        prototype: DisplayNames;

        new(locales: LocaleCode[], options: { type: 'region' | 'currency' }): DisplayNames;
    };
}

function getAllTimezones(): TimezoneCode[] {
    try {
        return Intl.supportedValuesOf('timeZone');
    }
    catch (error) {
        return [];
    }
}

export const ALL_TIMEZONES = getAllTimezones();

export type TimezoneOption = {
    value: TimezoneCode;
    label: string;
};

export function timezoneToOption(timezone: TimezoneCode): TimezoneOption {
    return {
        value: timezone,
        label: timezone.replace('_', ' '),
    };
}

export const TIMEZONE_OPTIONS: TimezoneOption[] = ALL_TIMEZONES.map(timezoneToOption);

export function setTimezone(newTimezone: TimezoneCode) {
    Settings.defaultZone = newTimezone;
}

// These codes corresponds to not-really-a-countries (i.e., EU is the European Union, SU is the Soviet Union etc.)
const BANNED_COUNTRY_CODES = [ 'AC', 'AN', 'BU', 'CP', 'CS', 'DD', 'DG', 'DY', 'EA', 'EU', 'EZ', 'FX', 'HV', 'IC', 'NH', 'QO', 'RH', 'SU', 'TA', 'TP', 'UK', 'UN', 'VD', 'XA', 'XB', 'YD', 'YU', 'ZR', 'ZZ' ];

function getCountryCodes(): CountryCode[] {
    const A = 65;
    const Z = 90;

    const translator = new Intl.DisplayNames([ 'en' ], { type: 'region' });
    const codes: CountryCode[] = [];

    for (let i = A; i <= Z; i++) {
        for (let j = A; j <= Z; j++) {
            const code = String.fromCharCode(i) + String.fromCharCode(j);
            const name = translator.of(code);
            if (code !== name && !BANNED_COUNTRY_CODES.includes(code))
                codes.push(code);
        }
    }

    return codes;
}

const COUNTRY_CODES = getCountryCodes();

export const DEFAULT_COUNTRY = 'CZ';

export type CountryOption = {
    value: CountryCode;
    label: string;
};

function countryToOptionGeneral(country: CountryCode, translator: Intl.DisplayNames): CountryOption {
    return {
        value: country,
        label: translator.of(country),
    };
}

type CountryOptionsTranslator = {
    options: CountryOption[];
    countryToOption: (country: CountryCode) => CountryOption;
};

function translateCountryOptions(locale: LocaleCode): CountryOptionsTranslator {
    const translator = new Intl.DisplayNames([ locale ], { type: 'region' });
    const countryToOption = (country: CountryCode) => countryToOptionGeneral(country, translator);

    return {
        options: COUNTRY_CODES.map(countryToOption),
        countryToOption,
    };
}

export const getCountryOptionsTranslator = cacheTranslations(translateCountryOptions);

import i18next, { type TFunction, type i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from '@/translations';
import { capitalize, last } from '@/utils/common';
import { DateTimeFormat } from './IntlExtensions';
import { getAllCurrencies, type Currency, type CurrencyIRI, priceToServer } from '@/modules/money';

i18next
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false }, // React already does escaping
        lng: getDefaultLocale(),
        ns: [ 'pages', 'components', 'common', 'client' ],
        resources,
        returnNull: false,
    });

i18next.on('languageChanged', (locale: LocaleCode) => {
    setDefaultLocale(locale);
});

setDefaultLocale(getDefaultLocale());

export default i18next;

export function setLocale(newLocale: LocaleCode) {
    i18next.changeLanguage(newLocale);
    Settings.defaultLocale = newLocale;
    localStorage.set(LOCALE_KEY, newLocale);
}

// Complex translations - when the translation is too complicated to be created equally for each locale.

type SupportedLocales = typeof SUPPORTED_LOCALES.appUser[number];

export type LocaleTFunction<TData> = (t: TFunction, data: TData) => string;
type LocaleTDefinition = Record<string, LocaleTFunction<any>>;

export type ComplexTDefinition<T> = Record<SupportedLocales, T>;
type ComplexTData<TDef extends LocaleTDefinition, TKey extends keyof TDef> = TDef[TKey] extends LocaleTFunction<infer TData> ? TData : never;
export type ComplexTFunction<TDef extends LocaleTDefinition> = <TKey extends keyof TDef>(key: TKey, data: ComplexTData<TDef, TKey>) => string;

export function complexTranslation<TDef extends LocaleTDefinition>(
    definition: ComplexTDefinition<TDef>,
    i18n: i18n,
    namespace: string,
    kexPrefix: string,
): { t: TFunction, ct: ComplexTFunction<TDef>} {
    const t = i18n.getFixedT(i18n.language, namespace, kexPrefix);
    return {
        t,
        ct: (key, data) => definition[i18n.language as SupportedLocales][key](t, data),
    };
}


// Months

/** The month number is 1-based. */
type MonthTranslator = (month: number) => string;

const MONTHS_IN_YEAR = 12;

function translateMonths(locale: LocaleCode): MonthTranslator {
    const names = [ ...new Array(MONTHS_IN_YEAR) ]
        .map((_, index) => (new Date(1998, index, 1)).toLocaleString(locale, { month: 'long' }));
    
    return (month: number) => names[month - 1];
}

export const getMonthTranslator = cacheTranslations(translateMonths);

// Month options

export type MonthOption = {
    value: number;
    label: string;
};

type MonthOptionsTranslator = {
    options: MonthOption[];
    monthToOption: (month: number) => MonthOption;
};

const defaultMonthIndices = [ ...new Array(MONTHS_IN_YEAR) ].map((_, index) => index);

/**
 * This function isn't cached because it's used with different months.
 * @param locale useTranslation.i18n.language
 * @param months 0 = January, 11 = December, default is all months
 */
export function generateMonthOptionsTranslator(locale: LocaleCode, months: number[] = defaultMonthIndices): MonthOptionsTranslator {
    const translator = getMonthTranslator(locale);
    const monthToOption = (month: number) => ({
        value: month,
        label: capitalize(translator(month + 1)),
    });

    return {
        options: months.map(monthToOption),
        monthToOption,
    };
}

// Days

/** The day number is 1-based. */
export type DayTranslator = (day: number) => string;

const DAYS_IN_WEEK = 7;

function translateDays(locale: LocaleCode): DayTranslator {
    const names = [ ...new Array(DAYS_IN_WEEK) ]
        .map((_, index) => (new Date(1998, 0, 5 + index)).toLocaleString(locale, { weekday: 'long' }));

    return (day: number) => names[day - 1];
}

export const getDayTranslator = cacheTranslations(translateDays);

// Defaults

import cityToCountry from '@/data/cityToCountry.json';
import { Settings } from 'luxon';

function getCountryFromTimezone(timezone: TimezoneCode): CountryCode {
    const split = timezone.split('/');
    const city = last(split);

    return city in cityToCountry ? cityToCountry[city as keyof typeof cityToCountry] : DEFAULT_COUNTRY;
}

const EU_LIKE_COUNTRIES_WITHOUT_CZ = [
    'BE', 'EL', 'LT', 'PT', 'BG', 'ES', 'LU', 'RO', 'FR', 'HU', 'SI', 'DK', 'HR', 'MT', 'SK', 'DE', 'IT', 'NL', 'FI', 'EE', 'CY', 'AT', 'SE', 'IE', 'LV', 'PL', 'EF', 'IS', 'NO', 'LI', 'CH', 'UK', 'BA', 'ME', 'MD', 'MK', 'AL', 'RS', 'TR', 'UA', 'XE', 'GE', 'RU',
];

function getCurrencyForCountry(country: CountryCode): Currency {
    const currencies = getAllCurrencies();

    if (country === 'CZ')
        return currencies.find(currency => currency.code === 'CZK') ?? currencies[0];

    if (country === 'GB')
        return currencies.find(currency => currency.code === 'GBP') ?? currencies[0];

    if (EU_LIKE_COUNTRIES_WITHOUT_CZ.includes(country))
        return currencies.find(currency => currency.code === 'EUR') ?? currencies[0];

    return currencies.find(currency => currency.code === 'USD') ?? currencies[0];
}

function getDefaultPriceForCurrency(currency: Currency): number {
    // E.g., 600 czk, 20 eur ... it ain't much, but it's honest work.
    return currency.minimalAmount * 40;
}

/** To server. */
type AppUserDefaults = {
    timezone: TimezoneCode;
    locale: LocaleCode;
    country: CountryCode;
    currency: CurrencyIRI;
    duration: number;
    price: number;
};

export function getAppUserDefaults(): AppUserDefaults {
    const timezone = DateTimeFormat().resolvedOptions().timeZone;
    const country = getCountryFromTimezone(timezone);
    const currency = getCurrencyForCountry(country);

    return {
        timezone,
        locale: getDefaultLocale(),
        country,
        currency: currency.toIRI(),
        duration: 3600,
        price: priceToServer(getDefaultPriceForCurrency(currency)),
    };
}
