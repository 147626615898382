import React, { useCallback, type ReactNode, useMemo } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { components, type GroupBase, type OptionProps, type OptionsOrGroups } from 'react-select';
import { classNames, handleKeyDown } from '../forms/FormSelect';
import { ClientContact, type ClientInfo } from '@/types/Client';
import { Query, getNameFromEmail } from '@/utils/common';
import { canonizeEmail, createOnChange, createValue, createValueWithFilter, isEmail } from '@/utils/forms';
import { useTranslation } from 'react-i18next';
import { googleApi } from '@/utils/api/google';
import { useUser } from '@/context/UserProvider';
import { FlowlanceLogo } from '@/components/icons';
import { IoPersonCircleOutline } from 'react-icons/io5';
import ClientIconLink, { ClientIconRow } from './ClientIconLink';
import { getClientIdentifier, getClientOrContact, getContactIdentifier, getParticipantName, type Participant } from '@/types/EventParticipant';
import { DeleteButton } from '../forms/buttons';
import { type Control, Controller, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';

type ContinuousParticipantSelectProps = Readonly<{
    clients: ClientInfo[];
    value: Participant[];
    onChange: (participants: Participant[]) => void;
    hideValue?: boolean;
    className?: string;
    placeholder?: string;
}>;

export function ContinuousParticipantSelect({ clients, value, onChange, hideValue, className, placeholder }: ContinuousParticipantSelectProps) {
    const { t } = useTranslation('components', { keyPrefix: 'continuousParticipantSelect' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'select' });
    const newOptionLabel = useCallback((input: string) => (<>
        {tf('create-option-label') + ' '}<span className='fw-semibold'>{canonizeEmail(input)}</span>
    </>), [ tf ]);
    const { appUser } = useUser();

    const loadOptions = useCallback(
        async (rawQuery: string) => loadOptionsFunction(rawQuery, clients, appUser.isContactsEnabled),
        [ clients, appUser.isContactsEnabled ],
    );

    const innerValue = useMemo(() => createValueWithFilter(
        participantToOption,
        true,
        value,
    ), [ value ]);

    const innerOnChange = useMemo(() => createOnChange(
        (option: Option) => {
            const identifier = optionToStringValue(option);
            return 'id' in option.value
                ? { info: option.value, identifier }
                : { contact: option.value, identifier };
        },
        true,
        onChange,
    ), [ onChange ]);

    const remove = useCallback((participant: Participant) => {
        onChange(value.filter(p => p !== participant));
    }, [ value, onChange ]);

    return (<>
        <AsyncCreatableSelect
            isMulti
            isClearable={false}
            className={className}
            classNames={classNames}
            defaultOptions
            loadOptions={loadOptions}
            value={innerValue}
            onChange={innerOnChange}
            isValidNewOption={isValidNewOption}
            formatCreateLabel={newOptionLabel}
            getNewOptionData={createNewOption}
            getOptionValue={optionToStringValue}
            components={{
                Option: OptionComponent as (props: OptionProps<Option, true>) => JSX.Element,
            }}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            noOptionsMessage={() => t('no-options-message')}
            loadingMessage={() => t('loading-message')}
            controlShouldRenderValue={false}
        />
        {!hideValue && (
            <div className='d-flex flex-column gap-2 mt-2'>
                {value.map(participant => (
                    <ParticipantRow key={participant.identifier} participant={participant} remove={remove} />
                ))}
            </div>
        )}
    </>);
}

type ParticipantRowProps = Readonly<{
    participant: Participant;
    remove: (participant: Participant) => void;
}>;

export function ParticipantRow({ participant, remove }: ParticipantRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'continuousParticipantSelect' });

    return (
        <div className='d-flex align-items-center gap-3'>
            <div className='flex-grow-1 overflow-hidden'>
                <ClientIconLink client={getClientOrContact(participant)} newTab />
            </div>
            <DeleteButton
                aria={t('delete-button-aria', { name: getParticipantName(participant) })}
                className='flex-shrink-0'
                onClick={() => remove(participant)}
            />
        </div>
    );
}

export function ParticipantRowLarge({ participant, remove }: ParticipantRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'continuousParticipantSelect' });

    return (
        <div className='d-flex align-items-center gap-3'>
            <ClientIconRow client={getClientOrContact(participant)} className='flex-grow-1 overflow-hidden' />
            <DeleteButton
                aria={t('delete-button-aria', { name: getParticipantName(participant) })}
                className='flex-shrink-0'
                onClick={() => remove(participant)}
            />
        </div>
    );
}

async function loadOptionsFunction(rawQuery: string, fetchedClients: ClientInfo[], isContactsEnabled: boolean) {
    const fetchedContacts = await fetchContacts(rawQuery, isContactsEnabled);
    const query = new Query(getNameFromEmail(rawQuery));

    return computeCombinedClients(fetchedClients, fetchedContacts, query).map(valueToOption);
}

async function fetchContacts(query: string, isEnabled: boolean): Promise<ClientContact[]> {
    if (!isEnabled)
        return [];

    const response = await googleApi.searchContacts(query);
    if (!response.status || !response.data.results)
        return [];

    return response.data.results.map(result => ClientContact.fromServer(result.person)).filter((contact): contact is ClientContact => !!contact);
}

/**
 * The map in the input is there purely for optimization (so we don't have to build it every time).
 */
function computeCombinedClients(fetchedClients: ClientInfo[], fetchedContacts: ClientContact[], query: Query): (ClientInfo | ClientContact)[] {
    // First, we try to match all our clients by the query. The matched ones are automatically included in the output.
    const matchedClients = fetchedClients.filter(client => client.query.match(query));

    // Some sets for faster lookups.
    const usedEmails = new Set(matchedClients.map(client => client.email));
    const clientEmails = new Set(fetchedClients.map(client => client.email));

    // Our clients that are matched by google but not by us.
    const matchedByGoogleClients: ClientInfo[] = [];
    const filteredContacts = fetchedContacts
        // We filter out the google contacts that are already used by our clients.
        .filter(contact => !usedEmails.has(contact.canonicalEmail))
        // However, if google found a contact that corresponds to our existing client but we didn't match it by the query, we want to use our client instead of the contact.
        .filter(contact => {
            if (!clientEmails.has(contact.canonicalEmail))
                return true;

            fetchedClients
                .filter(client => client.email === contact.canonicalEmail)
                .forEach(client => matchedByGoogleClients.push(client));
            return false;
        });

    return [
        ...matchedClients,
        ...matchedByGoogleClients,
        ...filteredContacts,
    ];
}

type Option = {
    label: ReactNode;
    value: ClientInfo | ClientContact;
};

function optionToStringValue(option: Option): string {
    return 'id' in option.value
        ? getClientIdentifier(option.value)
        : getContactIdentifier(option.value);
}

function participantToOption(participant: Participant): Option {
    return 'contact' in participant
        ? valueToOption(participant.contact)
        : valueToOption(participant.info);
}

function valueToOption(client: ClientInfo | ClientContact): Option {
    return {
        label: client.name,
        value: client,
    };
}

function createNewOption(input: string, label: ReactNode): Option {
    return {
        label,
        value: ClientContact.fromEmail(input),
    };
}

function OptionComponent<IsMulti extends boolean>({ children, ...props }: OptionProps<Option, IsMulti>) {
    if (typeof children !== 'string')
        return <components.Option {...props}>{children}</components.Option>;

    const client = props.data.value;
    const isClient = 'id' in client;

    return (
        <components.Option {...props}>
            <div className='d-flex align-items-center'>
                {isClient ? (
                    <FlowlanceLogo size={24} className='flex-shrink-0' />
                ) : (
                    <IoPersonCircleOutline size={24} className='flex-shrink-0' />
                )}
                <div className='ps-2 overflow-hidden'>
                    <div className='text-truncate'>{client.name}</div>
                    <div className='text-truncate fs-small'>{client.email}</div>
                </div>
            </div>
        </components.Option>
    );
}

function isValidNewOption(input: string, selected: readonly Option[], available: OptionsOrGroups<Option, GroupBase<Option>>): boolean {
    if (!isEmail(input))
        return false;

    const canonicalEmail = canonizeEmail(input);

    return ![ ...selected, ...(available as Option[]) ].map(o => o.value).filter((o): o is ClientInfo => 'id' in o).some(client => client.email === canonicalEmail);
}

type SingleContinuousParticipantSelectProps = Readonly<{
    clients: ClientInfo[];
    value: Participant | undefined;
    onChange: (participant: Participant | undefined) => void;
    isCreatable?: boolean;
    autoFocus?: boolean;
    defaultIsOpen?: boolean;
    className?: string;
}>;

export function SingleContinuousParticipantSelect({ clients, value, onChange, isCreatable, autoFocus, defaultIsOpen, className }: SingleContinuousParticipantSelectProps) {
    const { t } = useTranslation('components', { keyPrefix: 'continuousParticipantSelect' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'select' });
    const newOptionLabel = useCallback((input: string) => (<>
        {tf('create-option-label') + ' '}<span className='fw-semibold'>{canonizeEmail(input)}</span>
    </>), [ tf ]);

    const { appUser } = useUser();

    const loadOptions = useCallback(
        async (rawQuery: string) => loadOptionsFunction(rawQuery, clients, appUser.isContactsEnabled),
        [ clients, appUser.isContactsEnabled ],
    );

    const innerValue = useMemo(() => createValue(
        participantToOption,
        false,
        value,
    ), [ value ]);

    const innerOnChange = useMemo(() => createOnChange(
        (option: Option) => {
            const identifier = optionToStringValue(option);

            return 'id' in option.value
                ? { info: option.value, identifier }
                : { contact: option.value, identifier };
        },
        false,
        onChange,
    ), [ onChange ]);

    if (isCreatable) {
        return (
            <AsyncCreatableSelect
                isMulti={false}
                className={className}
                classNames={classNames}
                defaultOptions
                loadOptions={loadOptions}
                value={innerValue ?? null}
                onChange={innerOnChange}
                isValidNewOption={isValidNewOption}
                formatCreateLabel={newOptionLabel}
                getNewOptionData={createNewOption}
                getOptionValue={optionToStringValue}
                components={{
                    Option: OptionComponent as (props: OptionProps<Option, false>) => JSX.Element,
                }}
                onKeyDown={handleKeyDown}
                placeholder={t('placeholder')}
                noOptionsMessage={() => t('no-options-message')}
                loadingMessage={() => t('loading-message')}
            />
        );
    }

    return (
        <AsyncSelect
            isMulti={false}
            autoFocus={autoFocus}
            defaultMenuIsOpen={defaultIsOpen}
            className={className}
            classNames={classNames}
            defaultOptions
            loadOptions={loadOptions}
            value={innerValue ?? null}
            onChange={innerOnChange}
            getOptionValue={optionToStringValue}
            components={{
                Option: OptionComponent as (props: OptionProps<Option, false>) => JSX.Element,
            }}
            onKeyDown={handleKeyDown}
            placeholder={t('placeholder')}
            noOptionsMessage={() => t('no-options-message-single')}
            loadingMessage={() => t('loading-message')}
        />
    );
}

type ControlledParticipantSelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    clients: ClientInfo[];
    rules: UseControllerProps<TFieldValues>['rules'];
};

export function ControlledParticipantSelect<TFieldValues extends FieldValues>({ control, name, clients, rules }: ControlledParticipantSelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value?: Participant, onChange: (value?: Participant) => void } }) => {
        return (
            <SingleContinuousParticipantSelect
                isCreatable
                clients={clients}
                value={field.value}
                onChange={field.onChange}
            />
        );
    }, [ clients ]);

    return (
        <Controller
            control={control as Control<FieldValues>}
            name={name}
            render={InnerSelect}
            rules={rules as UseControllerProps<FieldValues>['rules']}
        />
    );
}
