import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Pagination as ReactPagination } from 'react-bootstrap';
import { DEFAULT_ITEMS_PER_PAGE } from '@/utils/math';
import { usePagination, type PaginationController } from '@/hooks';
import { ChevronLeftIcon, ChevronRightIcon } from '../icons';

type PaginationProps = {
    controller: PaginationController;
    itemsPerPage?: number;
    className?: string;
};

export default function Pagination({ controller, ...rest }: PaginationProps) {
    const itemsPerPage = rest.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE;
    const totalPages = Math.ceil(controller.totalItems / itemsPerPage);
    const pages = useMemo(() => generatePages(controller.page, totalPages), [ controller.page, totalPages ]);

    if (totalPages < 2)
        return null;

    return (
        <div className={clsx('sh-pagination', rest.className)}>
            <ReactPagination>
                <ReactPagination.Prev disabled={controller.page === 1} onClick={() => controller.setPage(controller.page - 1)}>
                    <ChevronLeftIcon size={18} />
                </ReactPagination.Prev>
                {pages.map(page => typeof page === 'number'
                    ? (
                        <ReactPagination.Item
                            key={page}
                            active={page === controller.page}
                            onClick={() => controller.setPage(page)}
                        >
                            {page}
                        </ReactPagination.Item>
                    )
                    : <ReactPagination.Ellipsis key={page} className='pagination-ellipsis' disabled />,
                )}
                <ReactPagination.Next disabled={controller.page === totalPages} onClick={() => controller.setPage(controller.page + 1)}>
                    <ChevronRightIcon size={18} />
                </ReactPagination.Next>
            </ReactPagination>
        </div>
    );
}

type PageDefinition = number | 'ellipsis-start' | 'ellipsis-end';

const DISPLAY_PAGES_AROUND = 2;

function generatePages(page: number, totalPages: number): PageDefinition[] {
    const pages: PageDefinition[] = [];

    const firstPage = page - DISPLAY_PAGES_AROUND;
    const lastPage = page + DISPLAY_PAGES_AROUND;

    if (firstPage > 1) {
        pages.push(1);

        if (firstPage === 3)
            pages.push(2);
        else if (firstPage > 3)
            pages.push('ellipsis-start');
    }

    for (let i = firstPage; i <= lastPage; i++)
        pages.push(i);

    if (lastPage < totalPages) {
        if (lastPage === totalPages - 2)
            pages.push(totalPages - 1);
        else if (lastPage < totalPages - 2)
            pages.push('ellipsis-end');

        pages.push(totalPages);
    }

    return pages.filter(p => typeof p !== 'number' || p >= 1 && p <= totalPages);
}

type FrontendPaginatorProps<T> = {
    items: T[];
    render: (item: T) => JSX.Element;
    itemsPerPage?: number;
};

export function FrontendPaginator<T>({ items, render, ...rest }: FrontendPaginatorProps<T>) {
    const itemsPerPage = rest.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE;
    const pagination = usePagination(items.length);

    const minIndex = (pagination.page - 1) * itemsPerPage;
    const maxIndex = minIndex + itemsPerPage;

    return (<>
        {items
            .filter((item, index) => index >= minIndex && index < maxIndex)
            .map(render)
        }
        <Pagination controller={pagination} itemsPerPage={itemsPerPage} />
    </>);
}
