import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { type PersonSettingsUpdate } from '@/types/Settings';
import { type Person } from '@/types/Person';
import { SpinnerButton } from '../common';
import { useTranslation } from 'react-i18next';

type PersonFormData = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
};

type PersonFormProps = Readonly<{
    input: Person;
    onSubmit: (output: PersonSettingsUpdate) => Promise<void>;
}>;

export default function PersonForm({ input, onSubmit }: PersonFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const [ isFetching, setIsFetching ] = useState(false);

    const { register, handleSubmit } = useForm<PersonFormData>({
        defaultValues: {
            firstName: input.firstName,
            lastName: input.lastName,
            email: input.email,
            phoneNumber: input.phoneNumber,
        },
    });

    async function onValid(data: PersonFormData) {
        setIsFetching(true);
        await onSubmit(data);
        setIsFetching(false);
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onValid)} className='sh-design'>
            <Row className='gap-row-3'>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('first-name-label')}</Form.Label>
                    <Form.Control {...register('firstName')} />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('last-name-label')}</Form.Label>
                    <Form.Control {...register('lastName')} />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('email-label')}</Form.Label>
                    <Form.Control {...register('email')}
                        disabled
                        readOnly
                    />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('phone-label')}</Form.Label>
                    <Form.Control {...register('phoneNumber')} />
                </Form.Group>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <SpinnerButton
                        type='submit'
                        isFetching={isFetching}
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </Col>
            </Row>
        </Form>
    );
}
