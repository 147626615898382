import React from 'react';
import { Nav } from 'react-bootstrap';
import { MdOutlineSpaceDashboard, MdOutlineViewHeadline } from 'react-icons/md';
import { matchPath, Link } from 'react-router-dom';
import { IoMdCalendar } from 'react-icons/io';
import { type IconType } from 'react-icons/lib';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { routes } from '@/router';
import { CircleUserIcon, PaymentsIcon, SettingsIcon, UsersIcon } from '../icons';
import { toMaster, useUser } from '@/context/UserProvider';

export const settingSectionMenuGroups: MenuGroupProps = {
    nameTranslationId: 'settings-section',
    items: [
        {
            icon: CircleUserIcon,
            nameTranslationId: 'my-profile',
            to: routes.settings.resolve({ key: 'general' }),
            showSubMenuOnDefault: true,
            subMenu: [
                {
                    nameTranslationId: 'user-settings',
                    icon: SettingsIcon,
                    to: routes.settings.resolve({ key: 'general' }),
                },
                {
                    nameTranslationId: 'payments',
                    icon: PaymentsIcon,
                    to: routes.payments,
                },
                {
                    nameTranslationId: 'subscription',
                    to: routes.subscription,
                    masterOnly: true,
                },
                {
                    nameTranslationId: 'integrations',
                    to: routes.integrations,
                },
            ],
        },
    ],
};

export const overviewMenuItems = [
    {
        icon: MdOutlineSpaceDashboard,
        nameTranslationId: 'dashboard',
        to: routes.dashboard,
    },
    {
        icon: IoMdCalendar,
        nameTranslationId: 'calendar',
        to: routes.calendar,
    },
    {
        icon: UsersIcon,
        nameTranslationId: 'clients',
        to: routes.clients.list,
        match: [ routes.clients.detail.path, routes.clients.new, routes.clients.import, routes.orders.newBackpay ],
        plusLink: routes.clients.new,
    },
    {
        icon: MdOutlineViewHeadline,
        nameTranslationId: 'orders',
        to: routes.orders.list,
        match: routes.orders.detail.path,
    },
    {
        nameTranslationId: 'team',
        to: routes.team,
        masterOnly: true,
    },
];

type MenuGroupProps = {
    nameTranslationId: string;
    hideTitle?: boolean;
    items: MenuItemDefinition[];
    index?: number;
};

type MenuItemBase = Readonly<{
    nameTranslationId: string;
    to: string;
    match?: string | string[];
    icon?: IconType;
    masterOnly?: boolean;
}>;

type MenuItemDefinition = MenuItemBase & {
    icon: IconType;
    subMenu?: MenuItemBase[];
    showSubMenuOnDefault?: boolean;
};

function getMatchPaths(item: MenuItemBase): string[] {
    if (!item.match)
        return [ item.to ];
    if (typeof item.match === 'string')
        return [ item.to, item.match ];

    return [ item.to, ...item.match ];
}

export function isActive(item: MenuItemBase, pathName: string): boolean {
    for (const path of getMatchPaths(item)) {
        if (matchPath(path, pathName))
            return true;
    }

    return false;
}

export function SubMenuItem(props: MenuItemBase) {
    const { t } = useTranslation('pages');
    const isMasterOrFreelancer = !!toMaster(useUser());

    if (props.masterOnly && !isMasterOrFreelancer)
        return null;

    const Icon = props.icon;

    return (
        <Nav.Item>
            <Link className={clsx('sh-navigation-button d-flex align-items-center gap-1 py-2 px-3', isActive(props, location.pathname) ? 'link-primary' : 'text-reset')} to={props.to}>
                {Icon && (
                    <Icon size={18} />
                )}
                <span className='lha-3 fw-medium'>{t(`mainMenu.${props.nameTranslationId}`)}</span>
            </Link>
        </Nav.Item>
    );
}
