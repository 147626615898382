import { capitalize } from '@/utils/common';

export type Person = {
    readonly email: string;
    readonly firstName: string | undefined;
    readonly lastName: string | undefined;
    readonly phoneNumber?: string;
};

export function getPersonName(person: Person): string {
    if (person.firstName)
        return person.firstName + (person.lastName ? ' ' + person.lastName : '');

    if (person.lastName)
        return person.lastName;

    return person.email;
}

export function getShortcut(person: Person): string {
    if (person.firstName) {
        return person.lastName
            ? person.firstName[0].toUpperCase() + person.lastName[0].toUpperCase()
            : capitalize(person.firstName.slice(0, 2));
    }

    return capitalize((
        person.lastName
            ? person.lastName
            : person.email
    ).slice(0, 2));
}
