import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import type { UseEventDispatch, UseEventState } from './useEvent';
import { useTranslation } from 'react-i18next';

type ConfirmCloseModalProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
}>;

export default function ConfirmCloseModal({ state, dispatch }: ConfirmCloseModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventSidebar.confirmCloseModal' });    

    return (
        <Modal show={state.isConfirmClose} onHide={() => dispatch({ type: 'confirmClose', value: 'back' })} className='sh-event-modal'>
            <div className='header'>
                {t('title')}
            </div>
            <div className='body'>
                <Button variant='primary' onClick={() => dispatch({ type: 'confirmClose', value: 'discard' })}>
                    {t('discard-button')}
                </Button>
                <Button variant='outline-secondary' onClick={() => dispatch({ type: 'confirmClose', value: 'back' })}>
                    {t('back-button')}
                </Button>
            </div>
        </Modal>
    );
}
