import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';

import { SpinnerButton } from '@/components/common';
import FormErrorMessage from '@/components/forms/FormErrorMessage';
import { ControlledStringSelect } from '../forms/FormSelect';

export type PersonFormData = {
    firstName: string;
    lastName: string;
    profession: string;
};

type RegisterPersonFormProps = Readonly<{
    onSubmit: (output: PersonFormData) => void;
    isFetching: boolean;
}>;

export default function RegisterPersonForm({ onSubmit, isFetching }: RegisterPersonFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'register.personForm' });
    const { t: tp } = useTranslation('pages', { keyPrefix: 'register.personForm.profession' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const { register, control, handleSubmit, formState: { errors } } = useForm<PersonFormData>();

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className='w-100'>
            <Form.Group className='sh-form-label-inside'>
                <Form.Label>{t('first-name-label')}</Form.Label>
                <Form.Control {...register('firstName', { required: tf('first-name-required') })} />
                <FormErrorMessage errors={errors} name='firstName' />
            </Form.Group>
            <Form.Group className='sh-form-label-inside mt-3'>
                <Form.Label>{t('last-name-label')}</Form.Label>
                <Form.Control {...register('lastName', { required: tf('last-name-required') })} />
                <FormErrorMessage errors={errors} name='lastName' />
            </Form.Group>
            <Form.Group className='sh-form-label-inside mt-3'>
                <Form.Label>{t('profession-label')}</Form.Label>
                <ControlledStringSelect
                    control={control}
                    name='profession'
                    options={professionOptions}
                    t={tp}
                    rules={{ required: tf('profession-required') }}
                    placeholder=''
                    isSearchable={false}
                />
                <FormErrorMessage errors={errors} name='profession' />
            </Form.Group>
            <SpinnerButton
                type='submit'
                className='w-100 mt-3'
                isFetching={isFetching}
            >
                {t('finish-button')}
            </SpinnerButton>
        </Form>
    );
}

export const professionOptions = [
    'tutor',
    'mentor',
    'coach',
    'therapist',
    'consultant',
    'gym-trainer',
    'photographer',
    'marketing',
    'other',
];
