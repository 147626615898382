import { type Currency, moneyFromServer, type CurrencyIRI, type Money, priceFromServer } from '@/modules/money';
import { Id, type Entity, type IRI } from './Id';
import { type EventState } from './Event';
import { type OrderState } from './orders/Order';
import { DateTime } from 'luxon';

export type CreditAccountFromServer = {
    '@id': IRI;
    currency: CurrencyIRI;
    totalAmount: number;
};

export class CreditAccount implements Entity {
    private constructor(
        readonly id: Id,
        readonly totalAmount: Money,
    ) {}

    static fromServer(input: CreditAccountFromServer) {
        return new CreditAccount(
            Id.fromIRI(input['@id']),
            moneyFromServer(input.totalAmount, input.currency),
        );
    }

    static fromNewTransaction(iri: IRI, currency: Currency) {
        return new CreditAccount(Id.fromIRI(iri), { amount: 0, currency });
    }

    setTotalAmount(amount: number): CreditAccount {
        return new CreditAccount(this.id, { ...this.totalAmount, amount });
    }
}

// TODO there should be only orderItem or eventParticipant, right?
export type CreditTransactionFromServer = {
    '@id': IRI;
    creditAccount: IRI;
    amount: number;
    title: string;
    createdAt: string;
    creditOrderItem?: {
        orderItem: {
            order: {
                '@id': IRI;
                state: OrderState;
            };
        };
    };
    eventParticipant?: {
        event: {
            '@id': IRI;
            state: EventState;
        };
    };
};

export class CreditTransaction implements Entity {
    private constructor(
        readonly id: Id,
        readonly accountId: Id,
        readonly total: Money,
        readonly title: string,
        readonly createdAt: DateTime,
        readonly reason: CreditTransactionReason,
    ) {}

    static fromServer(input: CreditTransactionFromServer, currency: Currency) {
        return new CreditTransaction(
            Id.fromIRI(input['@id']),
            Id.fromIRI(input.creditAccount),
            { amount: priceFromServer(input.amount), currency },
            input.title,
            DateTime.fromISO(input.createdAt),
            reasonFromServer(input),
        );
    }
}

type CreditTransactionReason = {
    readonly type: 'invoice';
    readonly id: Id;
    readonly state: OrderState;
} | {
    readonly type: 'event';
    readonly id: Id;
    readonly state: EventState;
} | {
    readonly type: 'manual';
};

function reasonFromServer(input: CreditTransactionFromServer): CreditTransactionReason {
    if (input.creditOrderItem) {
        return {
            type: 'invoice',
            id: Id.fromIRI(input.creditOrderItem.orderItem.order['@id']),
            state: input.creditOrderItem.orderItem.order.state,
        };
    }

    if (input.eventParticipant) {
        return {
            type: 'event',
            id: Id.fromIRI(input.eventParticipant.event['@id']),
            state: input.eventParticipant.event.state,
        };
    }

    return { type: 'manual' };
}

export type CreditTransactionInit = {
    amount: number;
    currency: CurrencyIRI;
    title: string;
};

export type CreditTransactionEdit = {
    amount: number;
    title: string;
};
