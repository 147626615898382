import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from '@/App';
import { emptyFunction } from './utils/common';

if (import.meta.env.PROD) {
    console.debug = emptyFunction;
    console.log = emptyFunction;
}

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const sentryDsnKey = import.meta.env.VITE_SENTRY_DSN;

if (sentryDsnKey) {
    Sentry.init({
        dsn: sentryDsnKey,
        integrations: [ new BrowserTracing() ],
        tracesSampleRate: 1.0,
        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    });
}

import '@/styles/index.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-datepicker/dist/react-datepicker.css';
import { NotificationProvider } from '@/context/NotificationProvider';
import { AuthProvider } from '@/context/AuthProvider';

import '@/types/i18n';

import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';

const posthogApiKey = import.meta.env.VITE_POSTHOG_KEY;
const posthogApiHost = import.meta.env.VITE_POSTHOG_HOST;
const posthogUiHost = import.meta.env.VITE_POSTHOG_UI_HOST;

const posthogClient = (posthogApiKey && posthogApiHost) ? posthog : undefined;
if (posthogClient && posthogApiKey)
    posthogClient.init(posthogApiKey, { api_host: posthogApiHost, ui_host: posthogUiHost });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <PostHogProvider client={posthogClient} >
            <NotificationProvider>
                <BrowserRouter>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </BrowserRouter>
            </NotificationProvider>
        </PostHogProvider>
    </React.StrictMode>,
);
