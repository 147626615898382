import React, { useState } from 'react';
import { ClientInfo, type ClientInit } from '@/types/Client';
import { createTranslatedSuccessAlert } from '@/components/notifications';
import useNotifications from '@/context/NotificationProvider';
import ClientForm from '@/components/client/ClientForm';
import { routes } from '@/router';
import BlockNavigationModal from '../../components/BlockNavigationModal';
import { useBlockerModal } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { createErrorAlert } from '../../components/notifications/TranslatedAlertMessage';
import { useAnalytics } from '@/types/analytics';
import { api } from '@/utils/api/backend';

export default function NewClient() {
    const { t } = useTranslation('pages', { keyPrefix: 'newClient' });
    const { addAlert } = useNotifications();
    const [ isFetching, setIsFetching ] = useState(false);
    const [ isDirty, setIsDirty ] = useState(false);
    const { navigateUnblocked, control } = useBlockerModal(isDirty);
    const analytics = useAnalytics();

    async function onSubmit(init: ClientInit) {
        setIsFetching(true);
        const response = await api.client.create(init);
        setIsFetching(false);

        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            return;
        }

        const client = ClientInfo.fromServer(response.data);
        analytics.clientCreated(client);

        addAlert(createTranslatedSuccessAlert('common:clientCreatedAlert', { links: {
            a: routes.clients.detail.resolve({ id: client.id.toString(), key: 'general' }),
        } }));

        navigateUnblocked(routes.clients.list);
    }

    return (
        <div className='container-small pb-5'>
            <h1>{t('page-title')}</h1>
            <BlockNavigationModal control={control} />
            <ClientForm
                onSubmit={onSubmit}
                isFetching={isFetching}
                onChange={setIsDirty}
            />
        </div>
    );
}
