import { type Money, type TaxRate, type TaxRateIRI, priceToServer } from '@/modules/money';
import { type IRI, type Id } from '../Id';
import { EventInfo, type EventInfoFromServer } from '../Event';
import { EventParticipant, type EventParticipantFromServer, type PayingParticipant } from '../EventParticipant';
import { type OrderItem } from './OrderItem';
import { type DateTime } from 'luxon';

export type EventOrderItemFromServer = {
    eventParticipant: EventParticipantFromServer & {
        event: EventInfoFromServer;
    };
};

export class EventOrderItem implements OrderItem {
    public constructor(
        readonly id: Id,
        readonly title: string,
        readonly quantity: number,
        readonly unitPrice: Money,
        readonly vat: TaxRate,
        readonly index: number,
        readonly createdAt: DateTime,

        readonly participant: PayingParticipant,
        readonly event: EventInfo,
    ) {
    }

    public static fromServer(base: OrderItem, input: EventOrderItemFromServer): EventOrderItem {
        const participant = EventParticipant.fromServer(input.eventParticipant) as PayingParticipant;
        return new EventOrderItem(
            base.id,
            base.title,
            base.quantity,
            base.unitPrice,
            base.vat,
            base.index,
            base.createdAt,
            participant,
            EventInfo.fromServer(input.eventParticipant.event),
        );
    }
}

export type EventItemToServer = {
    title: string;
    
    /** EventParticipant. All should have the same client. */
    participants: IRI[];
};

export type EventItemUpdateToServer = {
    /** The id.toString() of the item. */
    id: string;
    title: string;
    unitPrice: number;
    vat: TaxRateIRI;
    isDeleted?: boolean;
};

export function eventItemUpdateToServer(input: EditableEventItem): EventItemUpdateToServer {
    return {
        id: input.id,
        title: input.title,
        unitPrice: priceToServer(input.unitPrice as number),
        vat: input.vat.toIRI(),
        isDeleted: input.isDeleted ? true : undefined,
    };
}

export type EditableEventItem = {
    readonly id: string;
    readonly event: EventInfo;
    title: string;
    unitPrice: number | '' | '-';
    quantity: number;
    vat: TaxRate;
    isDeleted: boolean;
};

export function isEventItemEqual(form: EditableEventItem, item: EventOrderItem): boolean {
    return !form.isDeleted
        && form.title === item.title
        && form.unitPrice === item.unitPrice.amount
        && form.vat === item.vat;
}
