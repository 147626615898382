import React, { type ReactNode, useCallback, useMemo } from 'react';
import ReactSelect, { type SingleValue } from 'react-select';
import { classNames } from '@/components/forms/FormSelect';
import { getAllTaxRatesForCountries, getTaxRate, type TaxRate, type TaxRateIRI } from '@/modules/money';
import { type CountryCode } from '@/types/i18n';
import { useMaster } from '@/context/UserProvider';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { type InvoicingProfile } from '@/types/Invoicing';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { type TFunction } from 'i18next';

type Option = {
    value: TaxRateIRI;
    label: ReactNode;
};

type Group = {
    options: Option[];
    label: string;
};

function vatToOption(vat: TaxRate): Option {
    return {
        value: vat.toIRI(),
        label: <span className={clsx(!vat.isEnabled && 'text-secondary')}>{vat.label}</span>,
    };
}

type PlainVatSelectProps = Readonly<{
    iri?: TaxRateIRI;
    onChange: (iri?: TaxRateIRI) => void;
    country?: CountryCode;
    className?: string;
    disabled?: boolean;
}>;

function PlainVatSelect({ iri, onChange, country, className, disabled }: PlainVatSelectProps) {
    const { t } = useTranslation('common', { keyPrefix: 'select' });
    const handleOnChange = useCallback((option: SingleValue<Option>) => onChange(option !== null ? option.value : undefined), [ onChange ]);
    
    const { profiles } = useMaster();
    const innerOptions = useMemo(() => createOptions(profiles, country, t), [ profiles, country, t ]);

    return (
        <ReactSelect
            placeholder={t('vat-placeholder')}
            value={iri !== undefined ? vatToOption(getTaxRate(iri)) : undefined}
            onChange={handleOnChange}
            options={innerOptions}
            classNames={classNames}
            className={clsx('sh-vat-select monospace-numbers', className)}
            isDisabled={disabled}
        />
    );
}

function createOptions(profiles: InvoicingProfile[], country: CountryCode | undefined, t: TFunction): (Option | Group)[] {
    const taxRates = getTaxRates(profiles, country);
    const output: (Option | Group)[] = taxRates.filter(taxRate => taxRate.isEnabled).map(vatToOption);
    
    if (output.length === taxRates.length)
        return output;

    const disabled = taxRates.filter(taxRate => !taxRate.isEnabled).map(vatToOption);
    output.push({
        label: t('disabled-vat-group-label'),
        options: disabled,
    });

    return output;
}

function getTaxRates(profiles: InvoicingProfile[], country: CountryCode | undefined): TaxRate[] {
    if (country) 
        return getAllTaxRatesForCountries([ country ]);

    // TODO use something like tax countries? Or at least compute the countries before?
    const countries: CountryCode[] = [];
    profiles.forEach(profile => {
        if (profile.address?.country && !countries.includes(profile.address.country))
            countries.push(profile.address.country);
    });

    return getAllTaxRatesForCountries(countries);
}

type VatSelectProps = Readonly<{
    value?: TaxRate;
    onChange: (value?: TaxRate) => void;
    country?: CountryCode;
    className?: string;
    disabled?: boolean;
}>;

export function VatSelect({ value, onChange, ...rest }: VatSelectProps) {
    const handleOnChange = useCallback((value?: TaxRateIRI) => onChange(value ? getTaxRate(value) : undefined), [ onChange ]);
    
    return (
        <PlainVatSelect
            iri={value?.toIRI()}
            onChange={handleOnChange}
            {...rest}
        />
    );
}

type ControlledVatSelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    country?: CountryCode;
    className?: string;
};

export function ControlledVatSelect<TFieldValues extends FieldValues>({ control, name, country, className }: ControlledVatSelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: TaxRateIRI, onChange: (value?: TaxRateIRI) => void } }) => {
        return (
            <PlainVatSelect
                iri={field.value}
                onChange={field.onChange}
                country={country}
                className={className}
            />
        );
    }, [ country, className ]);
    
    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}