import { DateTime } from 'luxon';
import { type Entity, Id, type IRI } from './Id';

export type TaskFromServer = {
    '@id': IRI;
    text: string;
    createdAt: string;
    completed: boolean;
};

export class Task implements Entity {
    private constructor(
        readonly id: Id,
        readonly text: string,
        readonly createdAt: DateTime,
        readonly isCompleted: boolean,
    ) {}

    static fromServer(input: TaskFromServer): Task {
        return new Task(
            Id.fromIRI(input['@id']),
            input.text,
            DateTime.fromISO(input.createdAt),
            input.completed,
        );
    }
}

export type TaskEditToServer = {
    text?: string;
    completed?: boolean;
};

export type TaskInitToServer = {
    text: string;
};
