import { type Result } from '@/types/api/result';
import { type AxiosRequestConfig, type AxiosInstance, type AxiosResponse } from 'axios';
import qs from 'qs';
import { type Id } from '@/types/Id';
import { type ApiAuthorizer, CANCELED_ERROR } from './ApiAuthorizer';
import { type RequestBody } from '@/types/api/endpoint';

/**
 * This represents a custom frontend error that was thrown by the api interceptors themselves.
 * For example, if the api token is missing for google, this error will be thrown.
 */
export const FRONTEND_ERROR_NAME = 'FrontendError';

type ActionToPath = (action: Id | string) => string;

export class RawApi {
    constructor(
        private readonly axiosInstance: AxiosInstance,
        readonly authorizer: ApiAuthorizer,
        private readonly actionToPath: ActionToPath,
    ) {}

    GET<TData>(
        action: Id | string,
        signal?: AbortSignal,
        params = {},
    ): Promise<Result<TData>> {
        const path = this.actionToPath(action);
        const info = 'GET:    ' + path;

        return this.requestWrapperFunction<TData>(this.axiosInstance.get<TData>(path, {
            params,
            signal,
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: 'brackets' });
            },
        }), info, params);
    }

    POST<TData, TRequestBody extends RequestBody = RequestBody>(
        action: Id | string,
        body?: TRequestBody,
        config: AxiosRequestConfig = {},
    ): Promise<Result<TData>> {
        const path = this.actionToPath(action);
        const info = 'POST:   ' + path;

        return this.requestWrapperFunction<TData>(this.axiosInstance.post<TData>(path, body, config), info, config.params, body);
    }

    PUT<TData, TRequestBody extends RequestBody = RequestBody>(
        action: Id | string,
        body?: TRequestBody,
        config: AxiosRequestConfig = {},
    ): Promise<Result<TData>> {
        const path = this.actionToPath(action);
        const info = 'PUT:    ' + path;

        return this.requestWrapperFunction<TData>(this.axiosInstance.put<TData>(path, body, config), info, config.params, body);
    }
    
    PATCH<TData, TRequestBody extends RequestBody = RequestBody>(
        action: Id | string,
        body?: TRequestBody,
        config: AxiosRequestConfig = {},
    ): Promise<Result<TData>> {
        const path = this.actionToPath(action);
        const info = 'PATCH:  ' + path;
        const configWithHeaders = {
            ...config,
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
        };
        
        return this.requestWrapperFunction<TData>(this.axiosInstance.patch<TData>(path, body, configWithHeaders), info, config.params, body);
    }
    
    DELETE<TData>(
        action: Id | string,
        body?: unknown,
        config: AxiosRequestConfig = {},
    ): Promise<Result<TData>> {
        const path = this.actionToPath(action);
        const info = 'DELETE: ' + path;
    
        return this.requestWrapperFunction<TData>(this.axiosInstance.delete<TData>(path, { ...config, data: body }), info, config.params);
    }

    prepareAbort(): [ AbortSignal, () => void ] {
        const controller = new AbortController();

        return [
            controller.signal,
            () => {
                controller.abort();
            },
        ];
    }

    private requestWrapperFunction<TData>(promise: Promise<AxiosResponse<TData>>, info: string, params: unknown, body?: unknown): Promise<Result<TData>> {
        const coalescedParams = params !== undefined ? params : {};
        return promise
            .then(response => {
                if (body !== undefined)
                    console.log(info, coalescedParams, body, response.data);
                else
                    console.log(info, coalescedParams, response.data);

                return ({
                    status: true,
                    data: response.data,
                } as Result<TData>);
            }).catch(error => {
                if (error.name === 'CanceledError') {
                    return ({
                        status: false,
                        error: CANCELED_ERROR,
                    } as Result<TData>);
                }

                if (error.name === FRONTEND_ERROR_NAME) {
                    console.warn(FRONTEND_ERROR_NAME, error.data);
                    return ({
                        status: false,
                        error: error.data,
                    } as Result<TData>);
                }

                console.error(info, coalescedParams, error.response?.data, error);

                // Expired access token or invalid credentials
                if (error.response?.status === 401) {
                    console.warn('[Api] Unauthorized: ' + error.response?.data?.message);
                    this.authorizer.JWTExpired();
                    return ({
                        status: false,
                        error: { type: 'login.unauthorized' },
                    } as Result<TData>);
                }

                return ({
                    status: false,
                    error: error.response?.data?.error || error.response?.data?.message || error.response?.data || error.message || error,
                    response: error.response,
                } as Result<TData>);
            });
    }
}
