import { type Currency, type CurrencyIRI, type Money } from '@/modules/money/types';
import { type Entity, Id, type IRI, type OmitId } from '@/types/Id';
import { getCurrency, moneyFromServer } from '@/modules/money';

export type TeamSettingsFromServer = {
    '@id': IRI;
    pricings: PricingFromServer[];
    currency: CurrencyIRI;
};

export class TeamSettings implements Entity {
    private constructor(
        readonly id: Id,
        readonly pricings: Pricing[],
        readonly currency: Currency,
    ) {}

    static fromServer(input: TeamSettingsFromServer) {
        return new TeamSettings(
            Id.fromIRI(input['@id']),
            input.pricings.map(Pricing.fromServer),
            getCurrency(input.currency),
        );
    }
}

export type PricingFromServer = {
    '@id': IRI;
    currency: CurrencyIRI;
    /** In seconds. */
    duration: number;
    price: number;
};

export class Pricing implements Entity {
    private constructor(
        readonly id: Id,
        /** In seconds. */
        readonly duration: number,
        readonly price: Money,
    ) {}

    static fromServer(input: PricingFromServer) {
        return new Pricing(
            Id.fromIRI(input['@id']),
            input.duration,
            moneyFromServer(input.price, input.currency),
        );
    }
}

export type PricingToServer = OmitId<PricingFromServer>;
