import { type PostHog, usePostHog } from 'posthog-js/react';
import { useMemo } from 'react';
import { type ClientInfo } from './Client';
import { type Order } from './orders/Order';
import { type EventGroup } from './EventGroup';
import type { AppUserInit } from './AppUser';
import { getCurrency } from '@/modules/money';
import type { CountryCode, LocaleCode, TimezoneCode } from './i18n';
import { type CheckoutType } from '@/components/orders/checkout/useCheckout';

export function useAnalytics(): Analytics {
    const posthog = usePostHog();
    return useMemo(() => new Analytics(posthog), [ posthog ]);
}

export class Analytics {
    constructor(
        // Although the hook is supposed to always return a valid objects, it can actually return undefined (e.g., if we don't have the posthog api key).
        private readonly posthog: PostHog | undefined,
    ) {}

    start(userIdentifier: string) {
        this.posthog?.identify(userIdentifier);
    }

    stop() {
        this.posthog?.reset();
    }

    userRegistered(init: AppUserInit) {
        const eventData = {
            type: init.type,
            email: init.email,
            timezone: init.timezone,
            locale: init.locale,
            country: init.country,
            currency: getCurrency(init.currency).code,
        };

        this.posthog?.capture('UserRegistered', {
            ...eventData,
            // The `$set` is a magic name in posthog that will cause the properties to be set on the user profile, so we don't have to create a separate set event for that.
            '$set': createUserSettings(eventData),
        });

        if ('dataLayer' in window && window.dataLayer instanceof Array)
            window.dataLayer.push({ 'event': 'sign_up' });
    }

    userLoggedIn() {
        this.posthog?.capture('UserLoggedIn');
    }

    userLoggedOut() {
        this.posthog?.capture('UserLoggedOut');
    }

    settingsUpdated(settings: Partial<UserSettings>) {
        const eventData = createUserSettings(settings);
        this.posthog?.capture('SettingsUpdated', { ...eventData, '$set': eventData });
    }

    clientCreated(client: ClientInfo) {
        this.posthog?.capture('ClientCreated', {
            id: client.id.toIRI(),
            email: client.email,
            name: client.name,
        });
    }

    orderCreated(order: Order, type: CheckoutType | 'event', usecase: CheckoutType | OrderCreatedUsecase) {
        this.posthog?.capture('OrderCreated', {
            id: order.id.toIRI(),
            items: order.items.length,
            type,
            usecase,
        });
    }

    eventGroupCreated(group: EventGroup, usecase: EventCreatedUsecase) {
        this.posthog?.capture('EventGroupCreated', {
            id: group.id.toIRI(),
            events: group.events.length,
            // The total number of unique clients (and/or guests - we don't differentiate between them here).
            clients: group.clients.length,
            usecase,
        });
    }
}

const userSettingsProperties = [ 'timezone', 'locale', 'country', 'currency' ] as const;

type UserSettings = {
    timezone: TimezoneCode;
    locale: LocaleCode;
    country: CountryCode;
    currency: string;
};

// We just want to make sure that nothing unexpected will be passed to the posthog. So we check all properties manually.
function createUserSettings(settings: Partial<UserSettings>): Partial<UserSettings> {
    const output: Partial<UserSettings> = {};
    for (const key of userSettingsProperties) {
        if (settings[key])
            output[key] = settings[key];
    }

    return output;
}

type OrderCreatedType = 'event' | 'custom' | 'both';
type OrderCreatedUsecase = 'event' | 'backpay';

// TODO remove when unified
function getOrderType(order: Order): OrderCreatedType {
    const isEvent = order.getEventItems().length > 0;
    if (!isEvent)
        return 'custom';

    const isCustom = order.getBasicItems().length > 0;
    return isCustom ? 'both' : 'event';
}

type EventCreatedUsecase = 'new' | 'google' | 'schedule';
