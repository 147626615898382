import { api } from '@/utils/api/backend';
import { googleApi } from '@/utils/api/google';
import { useCallback, useEffect, useState } from 'react';
import { GoogleCalendar, sortCalendars } from '@/types/calendar/Google';
import { useUser } from '@/context/UserProvider';
import { compareSets } from '@/utils/common';
import localStorage from '@/utils/localStorage';

type UseGoogleCalendarReturn = {
    calendars?: GoogleCalendar[];
    activeIds: Set<string>;
    toggleCalendar: (calendar: GoogleCalendar, newValue: boolean) => void;
}

export function useGoogleCalendars(): UseGoogleCalendarReturn {
    const { appUser } = useUser();
    const [ calendars, setCalendars ] = useState<GoogleCalendar[]>();
    const [ activeIds, setActiveIds ] = useState(getInitialEnabledCalendarIds());

    const fetchCalendars = useCallback(async (signal?: AbortSignal) => {
        if (!appUser.isCalendarEnabled) {
            setCalendars([]);
            setActiveIds(updateActiveIds([]));
            return;
        }
        setCalendars(undefined);
        const response = await googleApi.getCalendars(signal);
        if (!response.status)
            return;
            
        const fetchedCalendars = response.data.items.map(item => GoogleCalendar.fromServer(item, appUser.googleCalendarId))
            // The default calendar is blocked on FE - we act like if it didn't exist.
            .filter(calendar => !calendar.isDefault);
        sortCalendars(fetchedCalendars);

        setCalendars(fetchedCalendars);
        setActiveIds(updateActiveIds(fetchedCalendars.map(calendar => calendar.id)));
    }, [ appUser ]);

    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchCalendars(signal);

        return abort;
    }, [ fetchCalendars ]);

    const toggleCalendar = useCallback((calendar: GoogleCalendar, newValue: boolean) => {
        setActiveIds(updateActiveId(calendar.id, newValue));
    }, []);

    return {
        calendars,
        activeIds,
        toggleCalendar,
    };
}

function updateActiveIds(allIds: string[]): (oldValue: Set<string>) => Set<string> {
    return (oldValue: Set<string>) => {
        const disabledIds = getDisabledCalendarIds();
        // We take all fetched calendars and then block those that are explicitly disabled.
        const newArray = allIds.filter(id => !disabledIds.has(id));
        const newValue = new Set(newArray);
        cacheActiveIds(newArray, allIds);

        return compareSets(oldValue, newValue) ? oldValue : newValue;
    };
}

const FILTER_KEY = 'calendars_filter';

type CalendarFilter = {
    [key: string]: boolean;
}

function cacheActiveIds(activeIds: string[], allIds: string[]) {
    const filter: CalendarFilter = {};
    allIds.forEach(id => filter[id] = false);
    activeIds.forEach(id => filter[id] = true);

    localStorage.set(FILTER_KEY, filter);
}

function updateActiveId(id: string, idValue: boolean): (oldValue: Set<string>) => Set<string> {
    return (oldValue: Set<string>) => {
        const newValue = computeNewActiveIds(oldValue, id, idValue);
        if (oldValue !== newValue)
            cacheActiveId(id, idValue);

        return newValue;
    };
}

function computeNewActiveIds(oldValue: Set<string>, id: string, idValue: boolean): Set<string> {
    if (idValue) {
        if (oldValue.has(id))
            return oldValue;

        const newValue = new Set(oldValue);
        newValue.add(id);
        
        return newValue;
    }

    if (!oldValue.has(id))
        return oldValue;
        
    const newValue = new Set(oldValue);
    newValue.delete(id);

    return newValue;
}

function cacheActiveId(id: string, idValue: boolean) {
    const filter = localStorage.get<CalendarFilter>(FILTER_KEY) ?? {};
    filter[id] = idValue;
    localStorage.set(FILTER_KEY, filter);
}

function getInitialEnabledCalendarIds(): Set<string> {
    const filter = localStorage.get<CalendarFilter>(FILTER_KEY) ?? {};
    const enabledIds = Object.keys(filter)
        .filter(id => !!filter[id]);
    return new Set(enabledIds);
}

function getDisabledCalendarIds(): Set<string> {
    const filter = localStorage.get<CalendarFilter>(FILTER_KEY) ?? {};
    const disabledIds = Object.keys(filter)
        .filter(id => filter[id] === false);
    return new Set(disabledIds);
}
