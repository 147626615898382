import React, { type Dispatch, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OrderEndDateFilter, OrderStartDateFilter } from '@/components/common/filters/OrderDateFilter';
import OrderStateFilter from '@/components/common/filters/OrderStateFilter';
import createOrderClientFilter, { filterName as orderClientFilterName } from '@/components/common/filters/OrderClientFilter';
import { type UseToggleSet, useClients, useToggle } from '@/hooks';
import { Button, ButtonGroup, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FilterRow, { useFilters } from '@/components/common/filters/FilterRow';
import { type ExportOrders, ExportType } from '@/utils/api/backend/endpoints/order';
import { api } from '@/utils/api/backend';
import { type IRI } from '@/types/Id';
import { type OrderState } from '@/types/orders/Order';
import DownloadFile, { type DownloadFileRef } from '@/components/common/DownloadFile';
import { SpinnerButton } from '@/components/common';
import { CANCELED_ERROR } from '@/utils/api/ApiAuthorizer';
import { type TFunction } from 'i18next';
import { getStringEnumValues } from '@/utils/common';

export function OrdersExport() {
    const { t } = useTranslation('pages', { keyPrefix: 'ordersExport' });
    const downloadRef = useRef<DownloadFileRef>(null);
    const [ isFetching, setIsFetching ] = useToggle(true);
    const [ format, setFormat ] = useState(ExportType.PdfZip);

    const { totalItems, filtersControl, cachedQuery } = useOrderCount(format, setIsFetching);

    async function fetchOrderExport() {
        const ref = downloadRef.current;
        if (!ref)
            return;

        setIsFetching.true();

        const response = await api.order.export(cachedQuery, { responseType: 'blob' });
        if (!response.status)
            return;

        ref.download(response.data);

        setIsFetching.false();
    }

    return (
        <Container className='pb-4 content-large'>
            <DownloadFile fileName={`orders.${FILE_EXTENSIONS[format]}`} ref={downloadRef} />
            <h1>{t('page-title')}</h1>
            <div>
                <label className='fw-medium mb-2'>{t('export-format')}</label>
                <br />
                <ButtonGroup>
                    {exportTypes.map(exportType => formatTypeButton(exportType, format, setFormat, t))}
                </ButtonGroup>
            </div>
            <div className='mt-3'>
                <label className='fw-medium mb-2'>{t('filters')}</label>
                <br />
                <FilterRow control={filtersControl} />
            </div>
            {/* The key here is used to refresh the button each time the count changes, so that its width isn't fixed on the size of the largest widht */}
            <SpinnerButton className='mt-5' isFetching={isFetching} onClick={fetchOrderExport} key={totalItems}>
                {t('export-button', { count: totalItems })}
            </SpinnerButton>
        </Container>
    );
}

function formatTypeButton(format: ExportType, value: ExportType, setValue: Dispatch<ExportType>, t: TFunction) {
    return (
        <OverlayTrigger key={format} placement='top' overlay={<Tooltip>{t(`formats.${format}.description`)}</Tooltip>}>
            <Button className='compact' variant={value === format ? 'primary' : 'outline-primary'} onClick={() => setValue(format)}>
                {t(`formats.${format}.title`)}
            </Button>
        </OverlayTrigger>
    );
}

const exportTypes = getStringEnumValues(ExportType);

const FILE_EXTENSIONS = {
    [ExportType.PdfZip]: 'zip',
    [ExportType.Pdf]: 'pdf',
    [ExportType.Csv]: 'csv',
    [ExportType.Isdoc]: 'zip',
} as const;

function useOrderCount(format: ExportType, setIsFetching: UseToggleSet) {
    const [ totalItems, setTotalItems ] = useState<number>();

    const { clients } = useClients();
    const filters = useMemo(() => [
        OrderStateFilter,
        OrderStartDateFilter,
        OrderEndDateFilter,
        createOrderClientFilter(clients ?? []),
    ], [ clients ]);

    const filtersControl = useFilters(filters);

    const stateFilterToServer = filtersControl.toServer(OrderStateFilter.name);
    const clientsFilterToServer = filtersControl.toServer(orderClientFilterName);
    const startDateFilterToServer = filtersControl.toServer(OrderStartDateFilter.name);
    const endDateFilterToServer = filtersControl.toServer(OrderEndDateFilter.name);

    const cachedQuery: ExportOrders = useMemo(() => ({
        format,
        clients: clientsFilterToServer as IRI[],
        states: stateFilterToServer as OrderState[],
        createdAfter: startDateFilterToServer as string,
        createdStrictlyBefore: endDateFilterToServer as string,
    }), [ format, stateFilterToServer, clientsFilterToServer, startDateFilterToServer, endDateFilterToServer ]);

    const fetchOrderCount = useCallback(async (signal: AbortSignal, data: ExportOrders) => {
        setIsFetching.true();
        const response = await api.order.countExport(data, { signal });
        if (!response.status) {
            if (response.error !== CANCELED_ERROR)
                setIsFetching.false();
            return;
        }

        setTotalItems(response.data.count);
        setIsFetching.false();
    }, [ setTotalItems, setIsFetching ]);

    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchOrderCount(signal, cachedQuery);

        return abort;
    }, [ fetchOrderCount, cachedQuery ]);

    return {
        totalItems,
        filtersControl,
        cachedQuery,
    };
}
