import { createDELETE, createGET, createPOST, createPUT } from '../rawApiObject';
import type { HydraCollection } from '@/types/api/result';
import { type Id } from '@/types/Id';
import type { ProductEditToServer, ProductFromServer, ProductInitToServer } from '@/types/Product';

export const product = {
    getAll: createGET<HydraCollection<ProductFromServer>>(
        '/products',
    ),
    create: createPOST<ProductFromServer, ProductInitToServer>(
        '/products/create',
    ),
    get: createGET<{ id: Id }, ProductFromServer>(
        u => `${u.id.toIRI()}`,
    ),
    update: createPUT<{ id: Id }, ProductFromServer, ProductEditToServer>(
        u => `${u.id.toIRI()}`,
    ),
    delete: createDELETE<{ id: Id }, ProductFromServer>(
        u => `${u.id.toIRI()}`,
    ),
};
