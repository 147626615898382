import React, { useCallback, useMemo } from 'react';
import { api } from '@/utils/api/backend';
import { type ClientInfo } from '@/types/Client';
import { useTranslation } from 'react-i18next';
import { type PaymentState, type UseEventDispatch, type UseEventState } from './useEvent';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChevronDownIcon, ChevronUpIcon, InvoiceIcon, UsersIcon, WalletIcon } from '../icons';
import { transformToPrice } from '@/utils/math';
import { ContinuousParticipantSelect } from '../client/ContinuousParticipantSelect';
import ClientIconLink, { ClientIconBadge } from '../client/ClientIconLink';
import { EventParticipant, getParticipantName, getClientOrContact, type EditablePayingParticipant } from '@/types/EventParticipant';
import CurrencySelect from '../forms/CurrencySelect';
import MoneyDisplay, { CurrencyDisplay } from '../common/MoneyDisplay';
import i18next from '@/types/i18n';
import { CloseButton, DeleteButton, EditButton } from '../forms/buttons';
import { VatSelect } from '../forms/VatSelect';
import { masterComponent, useMaster } from '@/context/UserProvider';
import type { TFunction } from 'i18next';
import { computeRecurrenceCount } from '@/types/recurrence';
import { useUpdating } from '@/hooks';
import { Table } from '../common';
import { type RecurrenceBranch } from '@/types/Event';
import { computePriceSummary, OrderSummary } from './priceSummary';
import { HiLockClosed } from 'react-icons/hi';

type EventPaymentProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
    clients: ClientInfo[];
}>;

export const EventPayment = masterComponent(({ state, dispatch, clients }: EventPaymentProps) => {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });

    // If the event isn't new, we show data about only one item.
    const item = state.event ? undefined : state.form.item;
    const totalEvents = useMemo(() => item ? computeEventsCount(item) : 1, [ item ]);
    const existingPaymentState = useMemo(() => {
        if (state.event?.clients.some(c => c.isInOrder))
            return 'order';
        if (state.event?.clients.some(c => c.payment.isPayByCredit))
            return 'credit';

        return undefined;
    }, [ state.event ]);

    if (!state.payment) {
        return (
            <Button onClick={() => dispatch({ type: 'payment', operation: 'create' })}>
                <WalletIcon size={20} className='me-2' />
                {t('setup-payment-button')}
            </Button>
        );
    }

    if (existingPaymentState) {
        return (
            <div className='d-flex flex-column gap-2 pb-3'>
                <div className='d-flex align-items-center gap-3' style={{ height: 42 }}>
                    <WalletIcon size={20} />
                    <span className='fw-medium'>{t('payment-label')}</span>
                    <div className='flex-grow-1' />
                    {!state.payment?.isEditing ? (
                        <EditButton aria={t('start-edit-button-aria')} onClick={() => dispatch({ type: 'payment', operation: 'startEdit' })} />
                    ) : (
                        <CloseButton aria={t('stop-edit-button-aria')} onClick={() => dispatch({ type: 'payment', operation: 'stopEdit' })} />
                    )}
                </div>
                {existingPaymentState === 'order' ? (
                    <ExistingOrder state={state} dispatch={dispatch} />
                ) : (
                    <ExistingCredit state={state} dispatch={dispatch} />
                )}
            </div>
        );
    }

    return (
        <div className='d-flex flex-column gap-2 pb-3'>
            <div className='d-flex align-items-center gap-3' style={{ height: 42 }}>
                <WalletIcon size={20} />
                <span className='fw-medium'>{t('payment-label')}</span>
                <div className='flex-grow-1' />
                <DeleteButton aria={t('delete-button-aria')} onClick={() => dispatch({ type: 'payment', operation: 'delete' })} />
            </div>
            <ClientsTable
                state={state}
                dispatch={dispatch}
                clients={clients}
                totalEvents={totalEvents}
            />
        </div>
    );
});

type ClientsTableProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
    clients: ClientInfo[];
    totalEvents: number;
}>;

function ClientsTable({ state, dispatch, clients, totalEvents }: ClientsTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const payment = state.payment;

    const summary = useMemo(() => payment?.clients && computePriceSummary(payment.clients, totalEvents), [ payment?.clients, totalEvents ]);

    const { isTaxPayer } = useMaster();

    if (!payment)
        return null;

    return (
        <div className='d-flex flex-column gap-2' style={{ paddingLeft: '36px' }}>
            <div className='py-2 d-flex gap-3'>
                <Form.Check
                    checked={payment.isLinked}
                    onChange={e => dispatch({ type: 'payment', operation: 'link', value: e.target.checked })}
                    type='switch'
                    className='sh-icon-chain'
                />
                <div>
                    <div className='fw-medium mb-1'>{t(payment.isLinked ? 'link-label-linked' : 'link-label-unlinked')}</div>
                    <div className='text-muted' style={{ fontSize: '12px' }}>{t('link-description')}</div>
                </div>
            </div>
            <div className='sh-divider-light' />
            {payment.isLinked
                ? clientsPriceRow(payment, dispatch, t)
                : addClientRow(payment, dispatch, clients, t)
            }
            {payment.clients.length > 0 && (<>
                {(payment.isShowClients || !payment.isLinked) && (<>
                    <div className='sh-divider-light' />
                    {payment.clients.map(client => (
                        <ClientRow key={client.identifier} client={client} dispatch={dispatch} isLinked={payment.isLinked} />
                    ))}
                </>)}
                {summary && (<>
                    <div className='sh-divider-light' />
                    <div>
                        <div className='d-flex align-items-center justify-content-end gap-2' style={{ height: '42px' }}>
                            {isTaxPayer && (
                                <Form.Group className='d-flex align-items-baseline'>
                                    <Form.Label>{t('vat-label')}</Form.Label>
                                    <VatSelect
                                        value={payment.vat}
                                        onChange={value => value && dispatch({ type: 'payment', operation: 'vat', value })}
                                        className='rs-condensed rs-transparent rs-menu-top'
                                    />
                                </Form.Group>
                            )}
                            <div className='fw-semibold fs-6'>
                                {t('total-price-label')}{' '}
                                <MoneyDisplay money={summary.vatBreakdown.withVat} className='fw-semibold' />
                            </div>
                        </div>
                        {!summary.isOnlyZeroVat && (
                            <div style={{ fontSize: '12px' }} className='text-end text-muted pb-2'>
                                {t('without-vat-label')}{' '}
                                <MoneyDisplay money={summary.vatBreakdown.withoutVat} className='fw-semibold' />
                            </div>
                        )}
                    </div>
                </>)}
            </>)}
        </div>
    );
}

function computeEventsCount(item: RecurrenceBranch): number {
    return item.recurrence
        ? computeRecurrenceCount(item.recurrence, item.startDate)
        : 1;
}

function clientsPriceRow(payment: PaymentState, dispatch: UseEventDispatch, t: TFunction) {
    const isSymbolBefore = payment.currency.isSymbolBefore(i18next.language);

    return (
        <div className='d-flex align-items-center gap-2 py-1'>
            <div className='fw-medium flex-shrink-0'>{t('price-per-client-label')}</div>
            <div className='flex-grow-1'/>
            {isSymbolBefore && (
                <CurrencySelect
                    value={payment.currency}
                    onChange={value => value && dispatch({ type: 'payment', operation: 'currency', value })}
                    className='rs-condensed'
                />
            )}
            <Form.Control
                style={{ maxWidth: '90px' }}
                className='text-end'
                type='number'
                value={payment.price}
                onChange={e => dispatch({ type: 'payment', operation: 'price', value: transformToPrice(e.target.value) })}
            />
            {!isSymbolBefore && (
                <CurrencySelect
                    value={payment.currency}
                    onChange={value => value && dispatch({ type: 'payment', operation: 'currency', value })}
                    className='rs-condensed'
                />
            )}
            <Button variant='outline-secondary' onClick={() => dispatch({ type: 'payment', operation: 'showClients', value: !payment.isShowClients })}>
                {payment.isShowClients ? (<>
                    <ChevronUpIcon size={18} className='me-2' />
                    <span className='fw-medium'>{t('hide-clients-button')}</span>
                </>) : (<>
                    <ChevronDownIcon size={18} className='me-2' />
                    <span className='fw-medium'>{t('show-clients-button')}</span>
                </>)}
            </Button>
        </div>
    );
}

function addClientRow(payment: PaymentState, dispatch: UseEventDispatch, clients: ClientInfo[], t: TFunction) {
    return (
        <div className='d-flex align-items-center gap-3 px-1 py-1'>
            <UsersIcon size={20} />
            <div className='flex-grow-1'>
                <ContinuousParticipantSelect
                    hideValue
                    clients={clients}
                    value={payment.clients}
                    onChange={value => dispatch({ type: 'payment', operation: 'clients', value })}
                    className='rs-remove-indicator rs-transparent'
                    placeholder={t('clients-placeholder')}
                />
            </div>
            <CurrencySelect
                value={payment.currency}
                onChange={value => value && dispatch({ type: 'payment', operation: 'currency', value })}
                className='rs-menu-left rs-condensed rs-transparent'
            />
        </div>
    );
}

type ClientRowProps = Readonly<{
    client: EditablePayingParticipant;
    dispatch: UseEventDispatch;
    isLinked: boolean;
}>;

function ClientRow({ client, dispatch, isLinked }: ClientRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'continuousParticipantSelect' });

    return (
        <div className='d-flex align-items-center' style={{ height: '42px' }}>
            <div className='flex-grow-1 overflow-hidden pe-3'>
                <ClientIconBadge client={getClientOrContact(client)} />
            </div>
            {isLinked ? (
                <MoneyDisplay money={{ amount: client.price || 0, currency: client.currency }} />
            ) : (
                <CurrencyDisplay currency={client.currency} gap={1} className='flex-shrink-0'>
                    <Form.Control
                        style={{ maxWidth: '90px' }}
                        className='text-end'
                        type='number'
                        value={client.price}
                        onChange={e => dispatch({ type: 'payment', operation: 'clientPrice', client, value: transformToPrice(e.target.value) })}
                    />
                </CurrencyDisplay>
            )}
            <DeleteButton
                aria={t('delete-button-aria', { name: getParticipantName(client) })}
                onClick={() => dispatch({ type: 'payment', operation: 'clientRemove', client })}
                className='flex-shrink-0'
            />
        </div>
    );
}

type ExistingOrderProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
}>;

function ExistingOrder({ state, dispatch }: ExistingOrderProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const event = state.event;
    if (!event)
        return null;

    const isSomeoneInStripeOrder = event.clients.some(client => client.isInStripeOrder);

    return (<>
        <Table compact noLines>
            <Table.Header>
                <Table.HeaderCol />
                <Table.HeaderCol xs='auto' className='text-end'>
                    {t('price-col')}
                    {state.payment?.isEditing && isSomeoneInStripeOrder && (
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip>{t('stripe-disabled-price-tooltip')}</Tooltip>}
                        >
                            <span>
                                <HiLockClosed size={16} className='text-primary clickable ms-1 position-relative' style={{ top: -2 }} />
                            </span>
                        </OverlayTrigger>
                    )}
                </Table.HeaderCol>
                <Table.HeaderCol xs='auto' className='text-center text-nowrap'>
                    {t('paid-col')}
                    {isSomeoneInStripeOrder && (
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip>{t('stripe-disabled-paid-tooltip')}</Tooltip>}
                        >
                            <span>
                                <HiLockClosed size={16} className='text-primary clickable ms-1 position-relative' style={{ top: -2 }} />
                            </span>
                        </OverlayTrigger>
                    )}
                </Table.HeaderCol>
                <Table.HeaderCol xs='auto' />
            </Table.Header>
            <Table.Body>
                {state.payment?.clients.map(client => (
                    <ExistingOrderRow key={client.identifier} state={state} dispatch={dispatch} client={client} />
                ))}
            </Table.Body>
        </Table>
        <OrderSummary state={state} />
    </>);
}

type ExistingOrderRowProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
    client: EditablePayingParticipant;
}>;

function ExistingOrderRow({ state, dispatch, client }: ExistingOrderRowProps) {
    const event = state.event!;
    const original = client.original!;

    const syncPaid = useCallback(async (newValue: boolean) => {
        const eventId = event.id;
        const response = await api.event.updateParticipant({ id: eventId }, original.toUpdate({ isPaid: newValue }));
        if (!response.status)
            return false;

        const participant = EventParticipant.fromServer(response.data.participant);
        dispatch({ type: 'update', participant, eventId });

        return true;
    }, [ original, dispatch, event ]);

    const [ paid, updatePaid, isUpdatingPaid ] = useUpdating(client.isPaid, syncPaid);

    return (
        <Table.Row>
            <Table.Col truncate><ClientIconLink client={original.client} /></Table.Col>
            {state.payment?.isEditing && !original.isInStripeOrder ? (
                <Table.Col xs='auto' className='py-0'>
                    <CurrencyDisplay currency={client.currency} gap={1}>
                        <Form.Control
                            style={{ maxWidth: '90px', minWidth: '90px' }}
                            className='text-end mh-0 py-0'
                            type='number'
                            value={client.price}
                            onChange={e => dispatch({ type: 'payment', operation: 'clientPrice', client, value: transformToPrice(e.target.value) })}
                        />
                    </CurrencyDisplay>
                </Table.Col>
            ) : (
                <Table.Col xs='auto' className='text-end'>
                    <MoneyDisplay money={original.payment.price} />
                </Table.Col>
            )}
            <Table.Col xs='auto'>
                <div className='d-flex justify-content-center'>
                    <Form.Check
                        checked={paid}
                        disabled={original.isInStripeOrder || isUpdatingPaid}
                        onChange={e => updatePaid(e.target.checked)}
                        type='switch'
                    />
                </div>
            </Table.Col>
            <Table.Col xs='auto'>
                {original.invoiceIRI && (
                    <a href={original.invoiceIRI} target='_blank' rel='noreferrer'>
                        <InvoiceIcon size={18} />
                    </a>
                )}
            </Table.Col>
        </Table.Row>
    );
}

type ExistingCreditProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
}>;

function ExistingCredit({ state, dispatch }: ExistingCreditProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const event = state.event;
    if (!event)
        return null;

    return (
        <Table compact noLines>
            <Table.Header>
                <Table.HeaderCol />
                <Table.HeaderCol xs='auto' className='text-end'>{t('price-col')}</Table.HeaderCol>
            </Table.Header>
            <Table.Body>
                {state.payment?.clients.map(client => (
                    <ExistingCreditRow key={client.identifier} state={state} dispatch={dispatch} client={client} />
                ))}
            </Table.Body>
        </Table>
    );
}

type ExistingCreditRowProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
    client: EditablePayingParticipant;
}>;

function ExistingCreditRow({ state, dispatch, client }: ExistingCreditRowProps) {
    const original = client.original!;
    const currency = client.currency;

    return (
        <Table.Row style={{ height: '38px' }}>
            <Table.Col truncate className='text-truncate'><ClientIconLink client={original.client} /></Table.Col>
            {state.payment?.isEditing ? (
                <Table.Col xs='auto' className='py-0'>
                    <CurrencyDisplay currency={currency} gap={1}>
                        <Form.Control
                            style={{ maxWidth: '90px', minWidth: '90px' }}
                            className='text-end mh-0 py-0'
                            type='number'
                            value={client.price}
                            onChange={e => dispatch({ type: 'payment', operation: 'clientPrice', client, value: transformToPrice(e.target.value) })}
                        />
                    </CurrencyDisplay>
                </Table.Col>
            ) : (
                <Table.Col xs='auto' className='text-end'>
                    <MoneyDisplay money={original.payment.price} />
                </Table.Col>
            )}
        </Table.Row>
    );
}
