import { type Money, type TaxRate, type TaxRateIRI, priceToServer } from '@/modules/money';
import { type Id } from '../Id';
import { toNumber } from '@/utils/math';
import type { OrderItem } from './OrderItem';
import { type DateTime } from 'luxon';

export class CustomOrderItem implements OrderItem {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly quantity: number,
        readonly unitPrice: Money,
        readonly vat: TaxRate,
        readonly index: number,
        readonly createdAt: DateTime,
    ) {}

    public static fromServer(base: OrderItem): CustomOrderItem {
        return new CustomOrderItem(
            base.id,
            base.title,
            base.quantity,
            base.unitPrice,
            base.vat,
            base.index,
            base.createdAt,
        );
    }
}

export type CustomItemToServer = {
    title: string;
    quantity: number;
    unitPrice: number;
    vat: TaxRateIRI;
};

export type BasicItemUpdateToServer = {
    /** The id.toString() of the item. */
    id: string;
    title: string;
    quantity: number;
    unitPrice: number;
    vat: TaxRateIRI;
    isDeleted?: boolean;
};

export function basicItemUpdateToServer(input: EditableBasicItem): BasicItemUpdateToServer {
    return {
        id: input.id,
        title: input.title,
        quantity: toNumber(input.quantity),
        unitPrice: priceToServer(toNumber(input.unitPrice)),
        vat: input.vat.toIRI(),
        isDeleted: input.isDeleted ? true : undefined,
    };
}

export type EditableBasicItem = {
    readonly id: string;
    title: string;
    quantity: number | '';
    unitPrice: number | '' | '-';
    vat: TaxRate;
    isDeleted: boolean;
};

export function isBasicItemEqual(form: EditableBasicItem, item: OrderItem): boolean {
    return !form.isDeleted
        && form.title === item.title
        && form.quantity === item.quantity
        && form.unitPrice === item.unitPrice.amount
        && form.vat === item.vat;
}
