import { type Entity, Id, type IRI } from './Id';
import { Event, type EventFromServer } from './Event';
import { ClientInfo, type ClientInfoFromServer } from './Client';

export type EventGroupFromServer = {
    '@id': IRI;
    clients: ClientInfoFromServer[];
    description: string;
    events: EventFromServer[];
    createdNotificationSent: boolean;
};

export class EventGroup implements Entity {
    private constructor(
        readonly id: Id,
        readonly description: string,
        readonly events: Event[],
        readonly clients: ClientInfo[],
        readonly isCreatedNotificationSent: boolean,
    ) {}

    static fromServer(input: EventGroupFromServer) {
        return new EventGroup(
            Id.fromIRI(input['@id']),
            //input.title,
            input.description,
            input.events.map(Event.fromServer),
            input.clients.map(ClientInfo.fromServer),
            input.createdNotificationSent,
        );
    }
}
