import React, { useCallback, useMemo } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { type ClientInfo } from '@/types/Client';
import type { BaseLocation } from '@/types/location';
import { useTranslation } from 'react-i18next';
import { type UseEventFormDispatch, type UseEventFormState } from './useEvent';
import { Col, Form, Row } from 'react-bootstrap';
import { ArrowIcon, CalendarIcon, ClockIcon, MapPinIcon, UsersIcon } from '../icons';
import { ContinuousParticipantSelect, ParticipantRowLarge } from '../client/ContinuousParticipantSelect';
import { DatePicker, RecurrenceInput } from '../forms';
import { type DateTime } from 'luxon';
import { getTimeDifference, type RecurrenceBranch } from '@/types/Event';
import { minutesToSeconds } from '@/utils/common';
import type { TFunction } from 'i18next';
import ErrorMessage from '../forms/ErrorMessage';
import LocationSelect from '../location/LocationSelect';
import LocationDisplay from '../location/LocationDisplay';
import { DeleteButton } from '../forms/buttons';
import { useToggle } from '@/hooks';
import { computeRecurrenceCount, type Recurrence } from '@/types/recurrence';
import type { Participant } from '@/types/EventParticipant';
import { TeamMemberSelect } from '../team/TeamMemberSelect';
import { useUser } from '@/context/UserProvider';
import { UserRole } from '@/types/Team';

type EventFormProps = Readonly<{
    state: UseEventFormState;
    dispatch: UseEventFormDispatch;
    clients: ClientInfo[];
    locations: BaseLocation[];
    onLocationCreated?: (newLocation: BaseLocation) => void;
    isForProduct?: boolean;
    recurrenceCountLimit?: number;
}>;

export default function EventForm({ state, dispatch, clients, locations, onLocationCreated, isForProduct, recurrenceCountLimit }: EventFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const [ isDescriptionFocus, setIsDescriptionFocus ] = useToggle(false);
    const { role } = useUser();
    const isTeamMaster = role === UserRole.Master;

    const item = state.form.item;
    const location = useMemo(() => locations.find(location => location.id.equals(state.form.locationId)), [ locations, state.form.locationId ]);
    const isNew = !state.event;
    const itemDispatch = useMemo(() => createItemDispatch(item, dispatch), [ item, dispatch ]);
    const error = validateItem(item, tf);

    // Not the ideal solution, but it would be highly impractical to modify the continuous client select just for this.
    const guests = state.form.guests;
    const removeGuest = useCallback((participant: Participant) => {
        dispatch({ type: 'input', field: 'guests', value: guests.filter(p => p !== participant) });
    }, [ guests, dispatch ]);

    const { recurrence, startDate } = state.form.item;
    const eventsCount: number | undefined = useMemo(() => {
        if (recurrenceCountLimit === undefined)
            return undefined;

        const unlimitedCount = recurrence ? computeRecurrenceCount(recurrence, startDate) : 1;

        return Math.min(unlimitedCount, recurrenceCountLimit);
    }, [ recurrence, startDate, recurrenceCountLimit ]);

    return (
        <div className='sh-calendar-form d-flex flex-column gap-3'>
            {isForProduct ? (
                <Form.Group>
                    <Form.Label>{t('title-label')}</Form.Label>
                    <div className='sh-input-wrapper '>
                        <Form.Control
                            placeholder={t('title-placeholder')}
                            value={state.form.title}
                            onChange={e => dispatch({ type: 'input', field: 'title', value: e.target.value })}
                        // {...register('title', { required: tf('title-required'), maxLength: { value: MAX_TITLE_LENGTH, message: tf('text-too-long', { count: MAX_TITLE_LENGTH }) } })}
                        />
                    </div>
                    {state.error?.invalidForm === 'title' && (
                        <ErrorMessage message={t('title-required')} />
                    )}
                </Form.Group>
            ) : (
                <div>
                    <Form.Group>
                        <Form.Control
                            className='fs-5'
                            placeholder={t('title-placeholder')}
                            value={state.form.title}
                            onChange={e => dispatch({ type: 'input', field: 'title', value: e.target.value })}
                        // {...register('title', { required: tf('title-required'), maxLength: { value: MAX_TITLE_LENGTH, message: tf('text-too-long', { count: MAX_TITLE_LENGTH }) } })}
                        />
                        {state.error?.invalidForm === 'title' && (
                            <ErrorMessage message={t('title-required')} />
                        )}
                    </Form.Group>
                    <Form.Group className='py-2'>
                        <Form.Control
                            placeholder={t('description-placeholder')}
                            as={TextareaAutosize}
                            value={state.form.description}
                            onChange={e => dispatch({ type: 'input', field: 'description', value: e.target.value })}
                            // {...register('description', { required: false, maxLength: { value: MAX_DESCRIPTION_LENGTH, message: tf('text-too-long', { count: MAX_DESCRIPTION_LENGTH }) } })}
                            onFocus={setIsDescriptionFocus.true}
                            onBlur={setIsDescriptionFocus.false}
                            minRows={1}
                            maxRows={isDescriptionFocus ? undefined : 3}
                            className={isDescriptionFocus ? undefined : 'text-truncate-lines-3'}
                        />
                        {/* <FormErrorMessage errors={errors} name='description' /> */}
                    </Form.Group>
                </div>
            )}

            <Row>
                <Form.Group as={Col}>
                    <Form.Label>{t('date-label')}</Form.Label>
                    <div className='sh-input-wrapper gap-3'>
                        <CalendarIcon size={16} />
                        <DatePicker
                            selected={item.startDate}
                            onChange={itemDispatch.startDate}
                            type='date'
                        />
                    </div>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{t('time-label')}</Form.Label>
                    <div className='sh-input-wrapper justify-content-start'>
                        <ClockIcon size={16} className='me-3 flex-shrink-0' />
                        <div className='sh-compact-date'>
                            <DatePicker
                                selected={item.startDate}
                                onChange={itemDispatch.startDate}
                                type='time'
                            />
                        </div>
                        <ArrowIcon size={17} className='mx-3 flex-shrink-0' />
                        <div>
                            <DatePicker
                                selected={item.endDate}
                                onChange={itemDispatch.endDate}
                                type='time'
                            />
                        </div>
                    </div>
                </Form.Group>
            </Row>

            <Row>
                {isNew && (
                    <Form.Group as={Col} xs={6}>
                        <Form.Label>{t('recurrence-label')}</Form.Label>
                        <div className='sh-input-wrapper'>
                            <RecurrenceInput
                                startDate={item.startDate}
                                value={item.recurrence}
                                onChange={itemDispatch.recurrence}
                                className='rs-menu-long rs-borderless rs-menu-overflow extra-compact flex-grow-1'
                                countLimit={recurrenceCountLimit}
                            />
                        </div>
                        {eventsCount !== undefined && recurrence && (
                            <div className='mt-2 ms-3 fs-small text-muted'>{t('events-count-label', { count: eventsCount })}</div>
                        )}
                    </Form.Group>
                )}
                <Form.Group as={Col} xs={isNew ? 6 : 12}>
                    <Form.Label>{t('location-label')}</Form.Label>
                    <div className='sh-input-wrapper pe-1'>
                        {!location && (
                            <MapPinIcon size={18} className='me-3' />
                        )}
                        {location ? (
                            <LocationDisplay location={location} compact className='flex-grow-1 text-truncate' />
                        ) : (
                            <LocationSelect
                                locations={locations}
                                onLocationCreated={onLocationCreated}
                                value={state.form.locationId}
                                onChange={value => dispatch({ type: 'input', field: 'locationId', value })}
                                className='flex-grow-1 rs-remove-indicator rs-borderless extra-compact'
                            />
                        )}
                        {location && (
                            <DeleteButton
                                aria={t('delete-location-button-label')}
                                onClick={() => dispatch({ type: 'input', field: 'locationId', value: undefined })}
                                className='ms-3 flex-shrink-0'
                            />
                        )}
                    </div>
                </Form.Group>
            </Row>
            {error && <ErrorMessage message={error} />}

            <Form.Group>
                <Form.Label>{t('guests-label')}</Form.Label>
                <div className='sh-input-wrapper gap-3'>
                    <UsersIcon size={18} className='flex-shrink-0' style={{ marginTop: '2px' }} />
                    <div className='flex-grow-1 flex-shrink-1' style={{ minWidth: 0 }}>
                        <ContinuousParticipantSelect
                            clients={clients}
                            value={guests}
                            onChange={value => dispatch({ type: 'input', field: 'guests', value })}
                            className='rs-remove-indicator rs-borderless extra-compact'
                            placeholder={t('guests-placeholder')}
                            hideValue
                        />
                    </div>
                </div>
                {guests.length > 0 && (
                    <div className='d-flex flex-column gap-3 mt-3'>
                        {guests.map(participant => (
                            <ParticipantRowLarge key={participant.identifier} participant={participant} remove={removeGuest} />
                        ))}
                    </div>
                )}
            </Form.Group>

            {isNew && isTeamMaster && (
                <Form.Group className='mt-3'>
                    <Form.Label>{t('scheduler-label')}</Form.Label>
                    <TeamMemberSelect
                        value={state.form.scheduler}
                        onChange={value => dispatch({ type: 'input', field: 'scheduler', value })}
                    />
                </Form.Group>
            )}
        </div>
    );
}

function createItemDispatch(item: RecurrenceBranch, dispatch: UseEventFormDispatch) {
    const update = (value: RecurrenceBranch) => dispatch({ type: 'input', field: 'item', value });

    return {
        startDate: (startDate?: DateTime) => {
            if (!startDate)
                return;

            // When the start date updates, the duration should stay the same. Therefore, we have to change the end date as well.
            const duration = getTimeDifference(item.startDate, item.endDate);
            const endDate = startDate.plus({ seconds: duration });
            update({ ...item, startDate, endDate });
        },
        endDate: (endDate?: DateTime) => {
            if (!endDate)
                return;

            // When the end date updates, the start date stays the same.
            update({ ...item, endDate });
        },
        recurrence: (recurrence?: Recurrence) => update({ ...item, recurrence }),
    };
}

function validateItem(item: RecurrenceBranch, tf: TFunction) {
    if (getTimeDifference(item.startDate, item.endDate) < minutesToSeconds(5))
        return tf('duration-less-than-5');

    return undefined;
}
