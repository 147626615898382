import React, { createContext, type ReactNode, useState, useContext, useEffect, useMemo } from 'react';
import { AuthManager, type AuthState, type AuthStateLogin } from '@/utils/AuthManager';
import { rawBackendApi } from '@/utils/api/backend';
import { googleApi } from '@/utils/api/google';
import { useAnalytics } from '@/types/analytics';
import type { AppUserInit } from '@/types/AppUser';

export type Context = {
    state: AuthState;
    auth: AuthManager;
};

const AuthContext = createContext<(Context) | undefined>(undefined);

export function AuthProvider({ children }: Readonly<{ children: ReactNode }>) {
    const [ state, setState ] = useState<AuthState>({
        isAuthenticated: false,
        isFetchingNewToken: true, // TODO this should be loaded from the token from storage ...
    });

    const analytics = useAnalytics();

    const auth = useMemo(() => {
        const newAuth = new AuthManager(rawBackendApi, setState, {
            register: (init: AppUserInit) => analytics.userRegistered(init),
            login: ({ identifier }: AuthStateLogin, isThisTab: boolean) => {
                analytics.start(identifier);

                if (isThisTab)
                    analytics.userLoggedIn();
            },
            logout: (_: unknown, isThisTab) => {
                if (isThisTab)
                    analytics.userLoggedOut();

                analytics.stop();
            },
        });
        rawBackendApi.authorizer.setTokenProvider(() => newAuth.getTokens()?.backend);
        googleApi.authorizer.setTokenProvider(() => newAuth.getTokens()?.google);

        return newAuth;
    }, [ analytics ]);

    useEffect(() => {
        auth.onStart();

        return () => auth.cleanup();
    }, [ auth ]);

    const value = useMemo(() => ({ state, auth }), [ state, auth ]);

    return (
        <AuthContext.Provider value={value}>
            { children }
        </AuthContext.Provider>
    );
}

export default function useAuth(): Context {
    const context = useContext(AuthContext);
    if (context === undefined)
        throw new Error('useAuth must be used within an AuthProvider');

    return context;
}
