import React, { useCallback, useMemo, useState } from 'react';
import { type InvoicingProfile } from '@/types/Invoicing';
import { ClientsTable } from '@/pages/client/Clients';
import { useClients, useSortOrder } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import ClientSelect from '../client/ClientSelect';
import { type ClientInfo, Client } from '@/types/Client';
import { api } from '@/utils/api/backend';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import useNotifications from '@/context/NotificationProvider';
import { ClientUpdateType } from '@/utils/api/backend/endpoints/client';

type InvoicingProfileClientsProps = Readonly<{
    profile: InvoicingProfile;
}>;

export default function InvoicingProfileClients({ profile }: InvoicingProfileClientsProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'invoicing-profile' });
    const [ nameOrder, setNameOrder ] = useSortOrder();
    const { clients, addClients, setClients } = useClients({ invoicingProfileId: profile.id, nameOrder });
    const { clients: allClients, setClients: setAllClients } = useClients({ default: [] });
    const [ isFetching, setIsFetching ] = useState(false);
    const { addAlert } = useNotifications();

    const addClient = useCallback(async (client?: ClientInfo) => {
        if (!client)
            return;

        setIsFetching(true);
        const response = await api.client.update({ id: client.id, type: ClientUpdateType.Preferences }, { invoicingProfile: profile.id.toIRI() });
        setIsFetching(false);

        if (!response.status) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        const updatedClient = Client.fromServer(response.data);
        addClients(updatedClient);
        setAllClients(oldAllClients => {
            const index = oldAllClients.findIndex(c => c.id.equals(client.id));
            if (index === -1)
                return oldAllClients;

            oldAllClients[index] = updatedClient;
            return [ ...oldAllClients ];
        });
        addAlert(createTranslatedSuccessAlert('pages:invoicing-profile.add-client-success-alert'));
    }, [ profile.id, addClients, setAllClients, addAlert ]);

    const availableClients = useMemo(() => allClients.filter(c => !c.invoicingProfileId.equals(profile.id)), [ profile.id, allClients ]);

    return (<>
        <Form.Group className='sh-design mb-4 w-50'>
            <Form.Label className='fw-medium'>{t('add-client-label')}</Form.Label>
            <ClientSelect
                clients={availableClients}
                value={undefined}
                onChange={addClient}
                showEmails
                disabled={isFetching}
            />
        </Form.Group>
        <ClientsTable
            clients={clients}
            setClients={setClients}
            noClientsMessage={t('no-clients-for-profile-text')}
            nameOrder={nameOrder}
            switchNameOrder={setNameOrder.toggle}
        />
    </>);
}
