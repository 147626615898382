import { createDELETE, createGET, createPOST, createPUT } from '../rawApiObject';
import { type IRI, type Id } from '@/types/Id';
import type { HydraCollection } from '@/types/api/result';
import { type ClientInfoFromServer, type ClientInit, type ClientFromServer, type ClientUpdate, type ClientStatsFromServer } from '@/types/Client';
import { type AresInfo } from '@/components/settings/useAres';
import { type CreditTransactionEdit, type CreditTransactionFromServer, type CreditTransactionInit } from '@/types/CreditAccount';
import { type CurrencyIRI } from '@/modules/money';
import { type ClientTagUpdate, type ClientTagFromServer, type ClientTagInit } from '@/types/ClientTag';

export const client = {
    get: createGET<{ id: string }, ClientFromServer>(
        u => `/clients/${u.id}`,
    ),
    getAll: createGET<HydraCollection<ClientFromServer>, ClientQueryParams>(
        '/clients',
    ),
    create: createPOST<ClientInfoFromServer, ClientInit>(
        '/clients/create',
    ),
    importAll: createPOST<{ clients: ClientInfoFromServer[] }, { contacts: ClientInit[] }>(
        '/clients/import',
    ),
    update: createPUT<{ id: Id, type: ClientUpdateType }, ClientFromServer, ClientUpdate>(
        u => `${u.id.toIRI()}/${u.type}`,
    ),
    delete: createDELETE<{ id: Id }, void>(
        u => `${u.id.toIRI()}`,
    ),
    aresInfo: createPOST<Omit<AresInfo, 'cin'>, { cin: string }>(
        '/clients/ares-info',
    ),
    stats: createPOST<{ id: Id }, ClientStatsFromServer, ClientStatsQueryParams>(
        u => `${u.id.toIRI()}/stats`,
    ),
    /** A client id is expected. */
    createTransaction: createPOST<{ id: Id }, CreditTransactionFromServer, CreditTransactionInit>(
        u => `${u.id.toIRI()}/credit-accounts/transactions/create`,
    ),
    /** A transaction id is expected. */
    getTransactions: createGET<{ id: Id }, HydraCollection<CreditTransactionFromServer>>(
        u => `${u.id.toIRI()}/transactions`,
    ),
    /** A transaction id is expected. */
    deleteTransaction: createDELETE<{ id: Id }, void>(
        u => `${u.id.toIRI()}`,
    ),
    /** A transaction id is expected. */
    updateTransaction: createPUT<{ id: Id }, CreditTransactionFromServer, CreditTransactionEdit>(
        u => `${u.id.toIRI()}`,
    ),
    createTag: createPOST<{ tag: ClientTagFromServer, client: ClientInfoFromServer }, ClientTagInit>(
        '/client-tags/create',
    ),
    /** A tag id is expected. */
    updateTag: createPUT<{ id: Id }, ClientTagFromServer, ClientTagUpdate>(
        u => `${u.id.toIRI()}/update`,
    ),
    /** A tag id is expected. */
    deleteTag: createDELETE<{ id: Id }, void>(
        u => `${u.id.toIRI()}`,
    ),
    /** A client id is expected. */
    updateTags: createPOST<{ id: Id }, ClientInfoFromServer, { tag: IRI, type: 'add' | 'remove' }>(
        u => `${u.id.toIRI()}/tags`,
    ),
};

type ClientQueryParams = {
    page?: number;
    pagination?: false;
    /** Uuid of the profile. */
    invoicingProfile?: string;
};

export enum ClientUpdateType {
    General = 'general',
    Invoicing = 'invoicing',
    Preferences = 'preferences',
    State = 'state',
}

type ClientStatsQueryParams = {
    currency: CurrencyIRI;
    startDate: string;
    endDate: string;
};
