import pages from './pages.json';
import components from './components.json';
import common from './common.json';
import client from './client.json';

export default {
    pages,
    components,
    common,
    client,
};