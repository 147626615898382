import { BACKEND_API_PREFIX } from '@/utils/api/backend';
import { last } from '@/utils/common';

export class Id<S extends string = 'IRI'> {
    private value: string;
    private iri: UniqueType<string, S>;

    private constructor(iri: UniqueType<string, S>) {
        this.value = Id.IRIToValue(iri);
        this.iri = iri;
    }

    public static fromIRI<Sa extends string>(iri: UniqueType<string, Sa>): Id<Sa> {
        return new Id(iri);
    }

    public static createExample(domain: string): Id {
        return Id.fromIRI('example/' + domain + '/' + Math.random().toString(36).substring(7) as unknown as IRI);
    }

    private static IRIToValue<Sa extends string>(iri: UniqueType<string, Sa>): string {
        // Otherwise this would totally spam the posthog recordings
        if (!import.meta.env.PROD)
            console.debug('Iri to value:', iri);

        return last(iri.split('/'));
    }

    /**
     * @example `/api/v1/events/1edffb91-8a80-6370-ad37-c1040167d210`
     */
    public toIRI(): UniqueType<string, S> {
        return this.iri;
    }

    /*
    public static toIRI(id: Id): string {
        return id.iri;
    }
    */

    public equals(other: Id<S> | string | undefined): boolean {
        return Id.equals(this, other);
    }

    public static equals<Sa extends string>(id1: Id<Sa>, id2: Id<Sa> | string | undefined): boolean {
        if (id2 === undefined)
            return false;

        if (typeof id2 === 'string')
            return id1.value === id2;

        return id1.value === id2.value;
    }

    /**
     * @example `1edffb91-8a80-6370-ad37-c1040167d210`
     */
    public toString(): string {
        return this.value;
    }
}

export interface Entity<S extends string = 'IRI'> {
    id: Id<S>;
}

export type OmitId<T> = Omit<T, '@id'>;

/** eg. "/api/.well-known/genid/9e9de98c191ffb41ad77" */
export type BlankId = string;

export interface BlankNode {
    id: BlankId;
}

declare const secretType: unique symbol;

export type UniqueType<T, S extends string> = Omit<T, typeof secretType> & { [secretType]: S };

/**
 * Compare with Id.toIRI().
 * @example `/api/v1/events/1ee1f181-254a-643c-9779-8f916e630758`
 */
export type IRI = UniqueType<string, 'IRI'>;

/** Workaround for react hook form */
export function plainIdToIRI(id: Id | unknown): IRI {
    return id instanceof Id
        ? id.toIRI()
        : (id as { iri: IRI }).iri;
}

export function isIRI(value: unknown): value is IRI {
    return typeof value === 'string' && value.startsWith(BACKEND_API_PREFIX);
}
