import jwtDecode from 'jwt-decode';
import { random } from '@/utils/math';
import localStorage from './localStorage';
import { type RawApi } from './api/rawApi';
import { MILLISECONDS_IN_SECOND } from './common';
import { CANCELED_ERROR } from './api/ApiAuthorizer';
import type { AppUserInit } from '@/types/AppUser';
import { DataResultSuccess, type Result } from '@/types/api/result';

const TOKENS_KEY = 'access_tokens';

/**
 * This is exported only because the hack in the registerWithoutLogin method. It should be removed when the google registration is fixed on the backend.
 */
export type Tokens = {
    backend: string;
    google?: string;
};

type TokensFromServer = {
    token: string;
    googleToken?: string;
}

function tokensFromServer(input: TokensFromServer): Tokens {
    return {
        backend: input.token,
        google: input.googleToken,
    };
}

let cachedTokens: Tokens | null = null;

enum Role {
    User = 'ROLE_USER',
    AppUser = 'ROLE_APP_USER',
    Admin = 'ROLE_ADMIN'
}

type RawAccessTokenData = {
    exp: number; // UNIX timestamp in seconds
    iat: number;
    roles: Role[];
    email: string;
};

type AccessToken = {
    expires: number; // UNIX timestamp in milliseconds
    issuedAt: number;
    roles: Role[];
    email: string;
};

function parseAccessToken(token: string): AccessToken {
    const rawData = jwtDecode<RawAccessTokenData>(token);
    return {
        expires: rawData.exp * MILLISECONDS_IN_SECOND,
        issuedAt: rawData.iat * MILLISECONDS_IN_SECOND,
        roles: rawData.roles,
        email: rawData.email,
    };
}

/**
 * Possible usecases:
 *  1. The application starts in one or multiple tabs (theoretically indistinguishable cases so we always assume multiple tabs).
 *  2. The application is already running in one or more tabs while another tab is opened (or multiple tabs are opened).
 *  3. The application is already running in one or more tabs while the user logs in.
 *  4. The application is already running in one or more tabs while the user logs out.
 */

function getTokensFromStorage(): Tokens | null {
    return localStorage.get<Tokens>(TOKENS_KEY);
}

const MINIMAL_REFRESH_RATE = 0.5;
const MAXIMAL_REFRESH_RATE = 0.9;
const randomRefreshRate = random(MINIMAL_REFRESH_RATE, MAXIMAL_REFRESH_RATE);
const REFRESH_NOW_TOLERANCE = 100; // in ms

const REFRESHING_KEY = 'refreshing_token_since';
const REFRESHING_TIMEOUT = 1000; // in ms

type RefreshingInfo = {
    since: number;
};

export type AuthStateLogin = {
    isAuthenticated: true;
    identifier: string;
};

export type AuthStateLogout = {
    isAuthenticated: false;
    isFetchingNewToken: boolean;
}

export type AuthState = AuthStateLogin | AuthStateLogout;

function compareStates(state1: AuthState, state2: AuthState): boolean {
    if (state1.isAuthenticated) {
        return state2.isAuthenticated
            && (state1.identifier === state2.identifier);
    }


    return !state2.isAuthenticated
        && (state1.isFetchingNewToken === state2.isFetchingNewToken);
}

type AuthManagerEvents = {
    register?: (init: AppUserInit) => void;
    login?: (state: AuthStateLogin, isThisTab: boolean) => void;
    logout?: (state: AuthStateLogout, isThisTab: boolean) => void;
};

export class AuthManager {
    private readonly update: (authState: AuthState, isThisTab: boolean) => void;

    constructor(
        private readonly api: RawApi,
        update: React.Dispatch<React.SetStateAction<AuthState>>,
        /** Callbacks that will be executed on specific action. */
        private readonly events: AuthManagerEvents = {},
    ) {
        // Do not update if the state isn't new.
        // Probably should be solved by using only boolean variable as a state (instead of an object), but the state can be extended in the future so ...
        this.update = (newState: AuthState, isThisTab: boolean) => update((oldState: AuthState) => {
            if (compareStates(newState, oldState))
                return oldState;

            if (newState.isAuthenticated && !oldState.isAuthenticated)
                events.login?.(newState, isThisTab);
            else if (!newState.isAuthenticated && oldState.isAuthenticated)
                events.logout?.(newState, isThisTab);

            return newState;
        });
    }

    getTokens(): Tokens | null {
        return cachedTokens;
    }

    invalidateAccessToken() {
        cachedTokens = null;
    }

    /* On start */

    public onStart() {
        localStorage.addEventListener(TOKENS_KEY, this.onTokensChange);
        this.api.authorizer.setExpiredJWTCallback(() => this.scheduleRefresh(0));

        const tokens = getTokensFromStorage();

        if (tokens) {
            this.setTokensLocally(tokens, false);
        }
        else {
            this.update({ isAuthenticated: false, isFetchingNewToken: true }, false);
            this.refreshAccessToken();
        }
    }

    private onTokensChange = (newValue: Tokens | null) => {
        if (!newValue)
            this.logoutLocally(false);
        else
            this.setTokensLocally(newValue, false);
    };

    /* Cleanup */

    public cleanup() {
        localStorage.removeEventListener(this.onTokensChange);
        this.cancelScheduledRefresh();
        localStorage.set(REFRESHING_KEY, null);
        this.abort();
    }

    /* Login */

    private setTokensLocally(tokens: Tokens, isThisTab: boolean) {
        cachedTokens = tokens;

        const parsedToken = parseAccessToken(tokens.backend);
        const currentDate = Date.now();
        if (parsedToken.expires < currentDate) {
            this.update({ isAuthenticated: false, isFetchingNewToken: true }, isThisTab);
            this.refreshAccessToken();
            return;
        }

        this.update({ isAuthenticated: true, identifier: parsedToken.email }, isThisTab);

        const timeToRefresh = parsedToken.issuedAt + randomRefreshRate * (parsedToken.expires - parsedToken.issuedAt) - currentDate;

        if (timeToRefresh < REFRESH_NOW_TOLERANCE)
            this.refreshAccessToken();
        else
            this.scheduleRefresh(timeToRefresh);
    }

    private setTokensGlobally(tokens: Tokens) {
        this.setTokensLocally(tokens, true);
        localStorage.set(TOKENS_KEY, tokens);
    }

    public async loginWithEmail(email: string, password: string): Promise<Result<void>> {
        const response = await this.api.POST<TokensFromServer>('/login', { email, password });
        if (!response.status)
            return response;

        this.setTokensGlobally(tokensFromServer(response.data));
        return DataResultSuccess<void>(undefined);
    }

    public async loginWithGoogle(loginToken: string): Promise<boolean> {
        const response = await this.api.POST<TokensFromServer>('/login/google', { loginToken });
        if (!response.status)
            return false;

        this.setTokensGlobally(tokensFromServer(response.data));
        return true;
    }

    public async register(data: AppUserInit, signal?: AbortSignal): Promise<boolean> {
        const response = await this.api.POST<TokensFromServer>('/app-users/register', data, { signal });
        if (!response.status)
            return false;

        this.events.register?.(data);
        this.setTokensGlobally(tokensFromServer(response.data));
        return true;
    }

    /**
     * This is a hack to enable google registration. We have to immediatelly send a request to the backend (which will result in registrating the user), but we can't log him in yet, because we want to ask him something first.
     * It should be removed when the google registration is fixed on the backend.
     */
    public async registerWithoutLogin(data: AppUserInit, signal?: AbortSignal): Promise<Result<Tokens>> {
        const response = await this.api.POST<TokensFromServer>('/app-users/register', data, { signal });
        if (!response.status)
            return response;

        this.events.register?.(data);
        // This is needed for the consequent api requests. But it's horrible.
        cachedTokens = tokensFromServer(response.data);
        return DataResultSuccess(cachedTokens);
    }

    /**
     * Hack for the same reason.
     */
    public loginWithTokens(tokens: Tokens) {
        this.setTokensGlobally(tokens);
    }

    /* Logout */

    private logoutLocally(isThisTab: boolean) {
        this.invalidateAccessToken();
        this.update({ isAuthenticated: false, isFetchingNewToken: false }, isThisTab);
        this.cancelScheduledRefresh();
    }

    private logoutGlobally() {
        this.logoutLocally(true);
        localStorage.remove(TOKENS_KEY);
    }

    public async logout(): Promise<boolean> {
        const response = await this.callInvalidateRequest();
        this.logoutGlobally();

        return response.status;
    }

    /** Public for testing purposes. */
    public async callInvalidateRequest() {
        return this.api.POST('/token/invalidate');
    }

    /* Refreshing */

    private currentRefreshTimeout: number | undefined = undefined;

    private scheduleRefresh(timeToRefresh: number) {
        this.cancelScheduledRefresh();
        this.currentRefreshTimeout = window.setTimeout(this.createRefreshFunction(), timeToRefresh);
    }

    private createRefreshFunction(): () => void {
        const oldToken = cachedTokens;
        return () => {
            if (!cachedTokens || cachedTokens === oldToken)
                this.refreshAccessToken();
        };
    }

    private cancelScheduledRefresh() {
        window.clearTimeout(this.currentRefreshTimeout);
    }

    private abort: () => void = () => {
        console.warn('TODO Abort has not been set properly.');
    };

    public async refreshAccessToken() {
        const currentRefresh = localStorage.get<RefreshingInfo>(REFRESHING_KEY);
        const currentTime = Date.now();

        if (currentRefresh && currentRefresh.since + REFRESHING_TIMEOUT > currentTime)
            return;

        localStorage.set(REFRESHING_KEY, { since: currentTime });

        const [ signal, abort ] = this.api.prepareAbort();
        this.abort = abort;

        const response = await this.api.POST<TokensFromServer>('/token/refresh', undefined, { signal });
        if (response.status) {
            this.setTokensGlobally(tokensFromServer(response.data));
        }
        // TODO This can fail for so many reasons ... for example, when the internet connection is lost. Therefore, we should not log out the user in such cases.
        else if (response.error !== CANCELED_ERROR) {
            this.logoutGlobally();
            // Because this is too hard to do on the backend for some reason.
            this.callInvalidateRequest();
        }

        localStorage.set(REFRESHING_KEY, null);
    }
}
