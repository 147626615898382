import { dehydrate } from '@/types/api/result';
import { useCallback, useEffect, useMemo, useState, type Dispatch, type SetStateAction } from 'react';
import type { DefaultValue } from './utils';
import type { PaginationController } from '../usePagination';
import { ClientInfo } from '@/types/Client';
import type { Id } from '@/types/Id';
import { SortOrder, compareStringsLocalized } from '@/utils/common';
import { useTranslation } from 'react-i18next';
import { api } from '@/utils/api/backend';

type NonDefaultOptions = {
    pagination?: PaginationController;
    invoicingProfileId?: Id;
    nameOrder?: SortOrder;
};

// @ts-expect-error React api overload
export function useClients(options: { default: DefaultValue<ClientInfo[]> } & NonDefaultOptions): {
    clients: ClientInfo[];
    setClients: Dispatch<SetStateAction<ClientInfo[]>>;
    addClients: (clients: ClientInfo | ClientInfo[]) => void;
}

export function useClients(options?: NonDefaultOptions): {
    clients: ClientInfo[] | undefined;
    setClients: Dispatch<SetStateAction<ClientInfo[] | undefined>>;
    addClients: (clients: ClientInfo | ClientInfo[]) => void;
};

/**
 * It's discouraged to use pagination for clients since 2.1.2024 because it don't work correctly with filters.
 * However, it should be fixed in the future when there might be users with too many clients.
 * @see https://flowlancenew.slack.com/archives/C03MNGLLWQ2/p1704226232258679
 */
export function useClients(options?: { default?: DefaultValue<ClientInfo[]> } & NonDefaultOptions) {
    const [ clients, setClients ] = useState(options?.default);
    const { i18n } = useTranslation();

    const fetchClients = useCallback(async (signal?: AbortSignal) => {
        if (options?.pagination)
            setClients(options.default);

        const response = await api.client.getAll(signal, {
            page: options?.pagination?.page,
            pagination: options?.pagination ? undefined : false,
            invoicingProfile: options?.invoicingProfileId?.toString(),
        });
        if (response.status) {
            const { items, totalItems } = dehydrate(response);

            setClients(items.map(ClientInfo.fromServer));
            options?.pagination?.setTotalItems(totalItems);
        }
    }, [ options?.pagination?.page, options?.invoicingProfileId ]);

    const sortedClients = useMemo(() => {
        if (!clients || !options?.nameOrder)
            return clients;

        const result = clients.toSorted((a, b) => compareStringsLocalized(a.name, b.name, i18n.language));
        if (options.nameOrder === SortOrder.Ascending)
            result.reverse();

        return result;
    }, [ clients, options?.nameOrder, i18n.language ]);

    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchClients(signal);

        return abort;
    }, [ fetchClients ]);

    const addClients = useCallback((clients: ClientInfo | ClientInfo[]): void => {
        const clientsArray = Array.isArray(clients) ? clients : [ clients ];
        setClients((oldClients) => [ ...(oldClients ?? []), ...clientsArray ]);
    }, []);

    return {
        clients: sortedClients,
        setClients,
        addClients,
    };
}
