import { type CountryCode, type LocaleCode } from '@/types/i18n';
import { type Entity, Id, type UniqueType } from '@/types/Id';
import { floatToPercent, priceFromServer } from '@/utils/math';

/** eg. "/api/v1/currencies/CZK" */
export type CurrencyIRI = UniqueType<string, 'CurrencyIRI'>;

export type CurrencyFromServer = {
    '@id': CurrencyIRI;
    code: string;
    stripeMinimalCharge: number;
    stripeMaximalCharge: number;
};

/**
 * Should be singleton (for each currency code).
 */
export class Currency implements Entity<'CurrencyIRI'> {
    private constructor(
        readonly id: Id<'CurrencyIRI'>,
        readonly code: string,
        readonly label: string,
        readonly minimalAmount: number,
    ) {}

    displayFull(amount: number, locale: LocaleCode, compact?: boolean): string {
        return Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.code,
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: compact ? 0 : undefined,
        }).format(amount);
    }

    displaySymbol(locale: LocaleCode): string {
        return Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.code,
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(0).replace(/\d/g, '').trim();
    }

    isSymbolBefore(locale: LocaleCode): boolean {
        const cached = this.isSymbolBeforeCache.get(locale);
        if (cached !== undefined)
            return cached;

        const computed = this.computeIsSymbolBefore(locale);
        this.isSymbolBeforeCache.set(locale, computed);

        return computed;
    }

    private isSymbolBeforeCache: Map<LocaleCode, boolean> = new Map();

    private computeIsSymbolBefore(locale: LocaleCode): boolean {
        const parts = Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.code,
            currencyDisplay: 'code',
        }).formatToParts(69);
        
        return parts[0].type === 'currency';
    }

    static displayAmount(amount: number, locale: LocaleCode, compact?: boolean): string {
        return Intl.NumberFormat(locale, {
            minimumFractionDigits: compact ? 0 : undefined,
        }).format(amount);
    }

    static fromServer(input: CurrencyFromServer): Currency {
        return new Currency(
            Id.fromIRI(input['@id']),
            input.code,
            input.code, // TODO - i18n ?
            priceFromServer(input.stripeMinimalCharge),
        );
    }

    toIRI(): CurrencyIRI {
        return this.id.toIRI();
    }
}

export type Money = {
    /** in whole currency (not cents) */
    amount: number;
    currency: Currency;
};

export type FormMoney = {
    amount: number | '';
    currency: Currency;
};

export type TaxRateIRI = UniqueType<string, 'TaxRateIRI'>;

export type TaxRateFromServer = {
    '@id': TaxRateIRI;
    name: string;
    value: number;
    inclusive: boolean;
    country?: CountryCode;
    enabled: boolean;
};

/**
 * Should be singleton (for each tax rate).
 */
export class TaxRate implements Entity<'TaxRateIRI'> {
    private constructor(
        readonly id: Id<'TaxRateIRI'>,
        readonly label: string,
        readonly value: number,
        readonly isInclusive: boolean,
        readonly isEnabled: boolean,
        readonly country?: CountryCode, // If undefined, the tax rate is supposed to be for all countries
    ) {}

    static fromServer(input: TaxRateFromServer): TaxRate {
        return new TaxRate (
            Id.fromIRI(input['@id']),
            `${floatToPercent(input.value)} %`,
            input.value,
            input.inclusive,
            input.enabled,
            input.country,
        );
    }

    toIRI(): TaxRateIRI {
        return this.id.toIRI() as unknown as TaxRateIRI;
    }

    toKey(): string {
        return this.id.toString();
    }

    // The tax rates are singletons so this is valid
    equals(other: TaxRate): boolean {
        return this === other;
    }

    get isZero(): boolean {
        return this.value === 0;
    }
}
