import React from 'react';
import type { TranslationOptions } from '@/components/notifications/TranslatedAlertMessage';
import { MoneyDisplay } from '@/components/common';
import { moneyFromServer, type CurrencyIRI } from '@/modules/money';

const errorTypes = [
    // 'subscription.trialEnded',
    // 'subscription.planEnded',
    // 'subscription.planExceeded',
    'order.minimalCharge',
    'order.maximalCharge',
    'ares.cin',
    // 'ares.fetch', // This should be displayed as a generic error.
    'login.unauthorized',
] as const;

type ErrorType = typeof errorTypes[number];
const allErrors = new Set<ErrorType>(errorTypes);

type TranslationData = { i18nKey: string, options?: TranslationOptions };
type ErrorTranslationFunction<TErrorType extends ErrorType = ErrorType> = (error: TypedError<TErrorType>) => TranslationData;

function defaultTranslation(type: ErrorType): TranslationData {
    return { i18nKey: type };
}

// type ErrorData<TErrorType extends ErrorType> = TErrorType extends 'subscription.planExceeded' ? {
//     a: number;
// } : TErrorType extends 'subscription.trialEnded' | 'subscription.planEnded' ? {
//     available: number;
//     cart: number;
// } : Record<string, unknown>;

type ErrorData<TErrorType extends ErrorType> =
    TErrorType extends 'order.minimalCharge' ? {
        provided: number;
        required: number;
        currency: CurrencyIRI;
    } :
    TErrorType extends 'order.maximalCharge' ? {
        provided: number;
        required: number;
        currency: CurrencyIRI;
    } :
    TErrorType extends 'ares.cin' ? {
        cin: string;
    }
    : Record<string, unknown>;

export type TypedError<TErrorType extends ErrorType = ErrorType> = {
    type: TErrorType;
} & ErrorData<TErrorType>;

const customTranslatedErrors = new Map<ErrorType, ErrorTranslationFunction>([
    [
        'order.minimalCharge',
        e => {
            const error = e as TypedError<'order.minimalCharge'>;
            return {
                i18nKey: 'order.minimalCharge',
                options: {
                    components: {
                        money: <MoneyDisplay money={moneyFromServer(error.required, error.currency)} />,
                    },
                },
            };
        },
    ],
    [
        'order.maximalCharge',
        e => {
            const error = e as TypedError<'order.maximalCharge'>;
            return {
                i18nKey: 'order.maximalCharge',
                options: {
                    components: {
                        money: <MoneyDisplay money={moneyFromServer(error.required, error.currency)} />,
                    },
                },
            };
        },
    ],
    [
        'ares.cin',
        e => {
            const error = e as TypedError<'ares.cin'>;
            return {
                i18nKey: 'ares.cin',
                options: {
                    values: {
                        cin: error.cin,
                    },
                },
            };
        },
    ],
]);

export function isTypedError(value: unknown): value is TypedError {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value) || typeof value.type !== 'string')
        return false;

    return (allErrors as Set<string>).has(value.type);
}

export function isSpecificTypedError<TErrorType extends ErrorType>(value: unknown, type: TErrorType): value is TypedError<TErrorType> {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value) || typeof value.type !== 'string')
        return false;

    return value.type === type;
}

export function getErrorTranslation(error: TypedError): TranslationData {
    const customTranslation = customTranslatedErrors.get(error.type);

    return customTranslation?.(error) ?? defaultTranslation(error.type);
}
