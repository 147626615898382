import React from 'react';
import { Form } from 'react-bootstrap';
import type { GoogleCalendar } from '@/types/calendar/Google';
import clsx from 'clsx';

type GoogleCalendarsDisplayProps = Readonly<{
    calendars: GoogleCalendar[];
    activeIds: Set<string>;
    toggleCalendar: (calendar: GoogleCalendar, newValue: boolean) => void;
}>;

export default function GoogleCalendarsDisplay({ calendars, activeIds, toggleCalendar }: GoogleCalendarsDisplayProps) {
    // The default calendar should be already filtered out.
    return (
        <div className='d-flex flex-column gap-2'>
            {calendars.map(calendar => {
                const isEnabled = activeIds.has(calendar.id);

                return (
                    <div key={calendar.id} className='sh-design'>
                        <Form.Check>
                            <Form.Check.Input
                                checked={isEnabled}
                                onChange={event => toggleCalendar(calendar, event.target.checked)}
                                style={{ '--sh-base-color': calendar.color }}
                                id={`check-${calendar.id}`}
                            />
                            <Form.Check.Label htmlFor={`check-${calendar.id}`}>{calendar.name}</Form.Check.Label>
                        </Form.Check>
                    </div>
                );
            })}
        </div>
    );
}

type CalendarIconProps = {
    calendar: 'default' | GoogleCalendar | undefined;
    className?: string;
}

export function CalendarIcon({ calendar, className }: CalendarIconProps) {
    const googleCalendar = calendar !== 'default' ? calendar : undefined;

    return (
        <div
            className={clsx('sh-calendar-icon', { default: calendar === 'default' }, className)}
            style={{ backgroundColor: googleCalendar?.color }}
        />
    );
}