import React, { useCallback, useMemo, useState } from 'react';
import { api } from '@/utils/api/backend';
import { InvoicingProfile, type InvoicingProfileInit } from '@/types/Invoicing';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import NewInvoicingProfileForm from '@/components/settings/NewInvoicingProfileForm';
import useNotifications from '@/context/NotificationProvider';
import { createTranslatedErrorAlert } from '@/components/notifications';
import { useMaster } from '@/context/UserProvider';
import { useClients, useToggle } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { routes } from '@/router';
import { type ClientInfo } from '@/types/Client';
import { Table } from '@/components/common';
import { ClientIconCustom } from '@/components/client/ClientIconLink';
import { BuildingIcon, DiamondPercentIcon, PlusIcon } from '@/components/icons';
import { LocaleIcon } from '@/components/common/LocaleToggle';

export default function InvoicingProfilesEditor() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profiles' });

    return (
        <div>
            <div className='d-flex align-items-top justify-content-between mb-3'>
                <h1 className='m-0'>{t('page-title')}</h1>
                <AddProfileButton className='compact' />
            </div>
            <InvoicingProfilesTable />
        </div>
    );
}

type InvoicingProfilesTableProps = Readonly<{
    className?: string;
}>;

function InvoicingProfilesTable({ className }: InvoicingProfilesTableProps) {
    const { profiles } = useMaster();
    const { clients } = useClients();

    if (!clients)
        return null;

    return (
        <Table className={className}>
            <Table.Body>
                {profiles.map(profile => (
                    <ProfileRow
                        key={profile.id.toString()}
                        profile={profile}
                        allClients={clients}
                    />
                ))}
            </Table.Body>
        </Table>
    );
}

type ProfileRowProps = Readonly<{
    profile: InvoicingProfile;
    allClients: ClientInfo[];
}>;

function ProfileRow({ profile, allClients }: ProfileRowProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profiles' });
    const clients = useMemo(() => allClients?.filter(client => client.invoicingProfileId.equals(profile.id)), [ profile, allClients ]);

    const link = routes.invoicingProfiles.detail.resolve({ id: profile.id.toString(), key: 'general' });

    return (
        <Table.Row className='clickable hoverable'>
            <Table.Col link={link}>
                <div className='d-inline-flex align-items-center gap-2'>
                    <LocaleIcon locale={profile.locale} size={18} />
                    <span className='fw-semibold'>{profile.title}</span>
                </div>
            </Table.Col>
            <Table.Col link={link}>
                {profile.legalName && (
                    <div className='d-inline-flex align-items-center gap-2'>
                        <BuildingIcon size={16} />
                        {profile.legalName}
                    </div>
                )}
            </Table.Col>
            <Table.Col link={link}>
                {profile.cin && (
                    <div className='d-inline-flex align-items-center gap-2'>
                        <DiamondPercentIcon size={16} />
                        {profile.cin}
                    </div>
                )}
            </Table.Col>
            <Table.Col xs='auto' link={link}>
                <div className='d-inline-flex align-items-center gap-2'>
                    <ClientIconCustom text={'' + clients.length} />
                    {t('clients-label', { count: clients.length })}
                </div>
            </Table.Col>
        </Table.Row>
    );
}

type AddProfileButtonProps = Readonly<{
    className?: string;
}>;

export function AddProfileButton({ className }: AddProfileButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profiles' });
    const { setProfiles } = useMaster();
    const navigate = useNavigate();
    const [ showModal, setShowModal ] = useToggle(false);

    const createProfile = useCallback((newProfile: InvoicingProfile) => {
        setProfiles(oldProfiles => ([ ...oldProfiles, newProfile ]));
        navigate(routes.invoicingProfiles.detail.resolve({ id: newProfile.id.toString(), key: 'general' }));
    }, [ setProfiles, navigate ]);

    return (<>
        <Button className={className} onClick={setShowModal.true}>
            <PlusIcon size={22} className='me-2' />{t('new-profile-button')}
        </Button>
        <NewProfileModal
            show={showModal}
            onProfileCreated={createProfile}
            onClose={setShowModal.false}
        />
    </>);
}

type NewProfileModalProps = Readonly<{
    show: boolean;
    onClose: () => void;
    onProfileCreated: (newProfile: InvoicingProfile) => void;
}>;

function NewProfileModal({ show, onClose, onProfileCreated }: NewProfileModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'newProfileModal' });
    const { addAlert } = useNotifications();
    const [ isFetching, setIsFetching ] = useState(false);

    async function onSubmit(init: InvoicingProfileInit) {
        setIsFetching(true);
        const response = await api.settings.createInvoicingProfile(init);
        setIsFetching(false);

        if (!response.status) {
            addAlert(createTranslatedErrorAlert());

            onClose();
            return;
        }

        const profile = InvoicingProfile.fromServer(response.data);

        onProfileCreated(profile);
        onClose();
    }

    return (
        <Modal show={show} onHide={onClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NewInvoicingProfileForm
                    onSubmit={onSubmit}
                    isFetching={isFetching}
                />
            </Modal.Body>
        </Modal>
    );
}
