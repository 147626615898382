import React, { useCallback, useMemo, useState } from 'react';
import { ClientInfo } from '@/types/Client';
import { ClientTag, sortClientTags } from '@/types/ClientTag';
import { AddButton } from '../forms/buttons';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { type Id } from '@/types/Id';
import { api } from '@/utils/api/backend';
import { useMaster } from '@/context/UserProvider';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@/hooks';
import { CheckIcon, CloseIcon, DotsHorizontalIcon, TrashIcon } from '../icons';
import { SpinnerButton } from '../common';
import clsx from 'clsx';

type ClientTagButtonProps = Readonly<{
    tag: { name: string, color: string };
    onClick?: () => void;
}>;

export function ClientTagButton({ tag, onClick }: ClientTagButtonProps) {
    return (
        <button className='sh-client-tag-button px-2 py-1 rounded-2 fw-medium text-nowrap' style={{ '--sh-base-color': `#${tag.color}`, height: '25px' }} onClick={onClick}>
            {tag.name}
        </button>
    );
}

type ClientTagDisplayProps = Readonly<{
    tag: { name: string, color: string };
}>;

export function ClientTagDisplay({ tag }: ClientTagDisplayProps) {
    return (
        <div className='px-2 py-1 rounded-2 fw-medium text-nowrap w-fit' style={{ backgroundColor: `#${tag.color}`, height: '25px' }}>
            {tag.name}
        </div>
    );
}

type ClientTagsProps = Readonly<{
    client: ClientInfo;
    setClient: (client: ClientInfo) => void;
}>;

export function ClientTags({ client, setClient }: ClientTagsProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientTags' });
    const { clientTags } = useMaster();
    // All tags are ordered the same way, i.e., by their ids.
    const tags = useMemo(() => clientTags.filter(tag => client.tagIds.some(tagId => tag.id.equals(tagId))), [ clientTags, client.tagIds ]);

    const [ show, setShow ] = useToggle(false);
    const [ editedTagId, setEditedTagId ] = useState<Id>();

    const onEditClose = useCallback(() => setEditedTagId(undefined), []);

    return (
        <div className='d-flex align-items-center gap-1 flex-wrap'>
            <div>
                <Dropdown autoClose='outside' show={show} onToggle={setShow.toggle}>
                    <AddButton aria={t('add-button-aria')} onClick={setShow.true} />
                    <Dropdown.Menu className='p-0' style={{ minWidth: '240px' }}>
                        {show && (
                            <AddTagMenu client={client} setClient={setClient} tags={tags} onClose={setShow.false} />
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {tags.map(tag => (
                <Dropdown key={tag.id.toString()} show={editedTagId?.equals(tag.id)} onToggle={onEditClose}>
                    <ClientTagButton key={tag.id.toString()} tag={tag} onClick={() => setEditedTagId(tag.id)} />
                    <Dropdown.Menu className='p-0'>
                        {editedTagId?.equals(tag.id) && (
                            <EditTagMenu client={client} setClient={setClient} tag={tag} onClose={onEditClose} />
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            ))}
        </div>
    );
}

type AddTagMenuProps = Readonly<{
    client: ClientInfo;
    setClient: (client: ClientInfo) => void;
    tags: ClientTag[];
    onClose: () => void;
}>;

function AddTagMenu({ client, setClient, tags, onClose }: AddTagMenuProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientTags' });
    const { clientTags, setClientTags } = useMaster();
    const [ query, setQuery ] = useState('');
    const [ fetching, setFetching ] = useState<string>();

    const availableTags = useMemo(() => clientTags.filter(tag => !tags.some(clientTag => clientTag.id.equals(tag.id))), [ clientTags, tags ]);
    const filteredTags = useMemo(() =>
        query.trim().length === 0
            ? availableTags
            : availableTags.filter(tag => tag.name.toLowerCase().includes(query.toLowerCase())),
    [ availableTags, query ]);
    const trimmedTags = useMemo(() => filteredTags.slice(0, MAX_DISPLAYED_TAGS), [ filteredTags ]);

    async function createTag(input: string) {
        setFetching(FID_CREATE);
        const response = await api.client.createTag({ name: input.trim(), color: newTagColor, client: client.id.toIRI() });
        setFetching(undefined);
        if (!response.status)
            return;

        const newTag = ClientTag.fromServer(response.data.tag);
        setClientTags(oldTags => sortClientTags([ ...oldTags, newTag ]));

        if (response.data.client) {
            const newClient = ClientInfo.fromServer(response.data.client);
            setClient(newClient);
        }

        onClose?.();
    }

    const newTagColor = useMemo(() => findNextColor(clientTags), [ clientTags ]);
    const isNewEnabled = query.trim().length > 0 && !filteredTags.some(tag => tag.name === query);

    async function addTag(tag: ClientTag) {
        setFetching(tag.id.toString());
        const response = await api.client.updateTags({ id: client.id }, {
            tag: tag.id.toIRI(),
            type: 'add',
        });
        setFetching(undefined);
        if (!response.status)
            return;

        const updatedClient = ClientInfo.fromServer(response.data);
        setClient(updatedClient);
        onClose?.();
    }

    return (
        <div>
            <div className='px-3 py-2'>
                <Form.Label>{t('query-label')}</Form.Label>
                <Form.Control
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    placeholder={t('query-placeholder')}
                    autoFocus
                />
            </div>
            <div className={clsx(!isNewEnabled && 'rounded-bottom-2 overflow-hidden')}>
                {trimmedTags.map(tag => (
                    <SpinnerButton
                        key={tag.id.toString()}
                        className='sh-dropdown-button'
                        onClick={() => addTag(tag)}
                        fetching={fetching}
                        fid={tag.id.toString()}
                        icon={<div className='position-absolute ps-3' style={{ left: '0px' }}>
                            <ClientTagDisplay tag={tag} />
                        </div>}
                    >
                        <div style={{ height: '25px' }} />
                    </SpinnerButton>
                ))}
                {filteredTags.length > MAX_DISPLAYED_TAGS && (
                    <div className='py-2 px-3 text-secondary'>
                        <DotsHorizontalIcon size={22} />
                    </div>
                )}
            </div>
            {isNewEnabled && (<>
                <div className='sh-divider-light' />
                <div className='px-3 py-2 d-flex align-items-center gap-3'>
                    <SpinnerButton
                        className='compact'
                        onClick={() => createTag(query)}
                        fetching={fetching}
                        fid={FID_CREATE}
                    >
                        {t('create-button')}
                    </SpinnerButton>
                    <ClientTagDisplay tag={{ name: query, color: newTagColor }} />
                </div>
            </>)}
        </div>
    );
}

const MAX_DISPLAYED_TAGS = 5;
const FID_CREATE = 'create';

type EditTagMenuProps = Readonly<{
    client: ClientInfo;
    setClient: (client: ClientInfo) => void;
    tag: ClientTag;
    onClose: () => void;
}>;

function EditTagMenu({ client, setClient, tag, onClose }: EditTagMenuProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientTags' });
    const { t: tc } = useTranslation('common', { keyPrefix: 'colors' });
    const { setClientTags } = useMaster();

    const [ formName, setFormName ] = useState(tag.name);
    const [ formHex, setFormHex ] = useState(tag.color);

    const [ fetching, setFetching ] = useState<string>();

    async function removeTag() {
        setFetching(FID_REMOVE);
        const response = await api.client.updateTags({ id: client.id }, {
            tag: tag.id.toIRI(),
            type: 'remove',
        });
        setFetching(undefined);
        if (!response.status)
            return;

        const updatedClient = ClientInfo.fromServer(response.data);
        setClient(updatedClient);
        onClose?.();
    }

    async function updateTag() {
        setFetching(FID_UPDATE);
        const response = await api.client.updateTag({ id: tag.id }, {
            name: formName.trim(),
            color: formHex,
        });
        setFetching(undefined);
        if (!response.status)
            return;

        const updatedTag = ClientTag.fromServer(response.data);
        setClientTags(oldTags => sortClientTags(oldTags.map(oldTag => oldTag.id.equals(tag.id) ? updatedTag : oldTag)));
        onClose?.();
    }

    async function deleteTag() {
        setFetching(FID_DELETE);
        const response = await api.client.deleteTag({ id: tag.id });
        setFetching(undefined);
        if (!response.status)
            return;

        setClientTags(oldTags => oldTags.filter(oldTag => !oldTag.id.equals(tag.id)));
        onClose?.();
    }

    const isChange = getChange(tag, formName, formHex);

    return (
        <div>
            <div className='px-3 py-2'>
                <SpinnerButton
                    variant='outline-secondary'
                    className='compact'
                    onClick={removeTag}
                    fetching={fetching}
                    fid={FID_REMOVE}
                >
                    <CloseIcon size={18} className='me-2'/>{t('remove-button')}
                </SpinnerButton>
            </div>
            <div className='sh-divider-light' />
            <div className='px-3 py-2'>
                <Form.Label>{t('name-label')}</Form.Label>
                <Form.Control
                    value={formName}
                    onChange={e => setFormName(e.target.value)}
                    placeholder={t('name-placeholder')}
                    autoFocus
                />
            </div>
            <div className='sh-divider-light' />
            <div className='d-flex flex-column'>
                <Form.Label className='px-3 pt-2 pb-1 m-0'>{t('color-label')}</Form.Label>
                {Object.entries(PREDEFINED_COLORS).map(([ color, hex ]) => (
                    <Button key={color} className='sh-dropdown-button gap-2' onClick={() => setFormHex(hex)}>
                        <div style={{ width: '16px', height: '16px', backgroundColor: `#${hex}` }} className='rounded-1' />
                        <span className='text-nowrap'>{tc(color)}</span>
                        <div className='flex-grow-1' />
                        {formHex === hex && (
                            <CheckIcon />
                        )}
                    </Button>
                ))}
            </div>
            <div className='sh-divider-light' />
            <div className='px-3 py-2 d-flex flex-column gap-2'>
                {isChange && (
                    <SpinnerButton
                        variant='primary'
                        className='compact'
                        onClick={updateTag}
                        fetching={fetching}
                        fid={FID_UPDATE}
                    >
                        {t('update-button')}
                    </SpinnerButton>
                )}
                <SpinnerButton
                    variant='outline-danger'
                    className='compact'
                    onClick={deleteTag}
                    fetching={fetching}
                    fid={FID_DELETE}
                >
                    <TrashIcon size={18} className='me-2'/>{t('delete-button')}
                </SpinnerButton>
            </div>
        </div>
    );
}

const FID_REMOVE = 'remove';
const FID_DELETE = 'delete';
const FID_UPDATE = 'update';

function getChange(tag: ClientTag, name?: string, hex?: string): boolean {
    if (!name || !hex)
        return false;

    return tag.name !== name || tag.color !== hex;
}

const PREDEFINED_COLORS = {
    'purple': 'd5bce8',
    'blue': 'b6f1fe',
    'cyan': '88F8F8',
    'green': 'a9f9cd',
    'yellow': 'f9face',
    'orange': 'fac19d',
    'red': 'f88888',
    'pink': 'f7bfee',
} as const;

function findNextColor(tags: ClientTag[]): string {
    const usedColors = tags.map(tag => tag.color);
    const allColors = Object.values(PREDEFINED_COLORS);
    const availableColors = allColors.filter(color => !usedColors.includes(color));
    return availableColors[0] ?? allColors[0];
}
