import React, { useCallback, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { type Recurrence, PredefinedOption, getWeekDay, Day } from '@/types/recurrence';
import { useTranslation } from 'react-i18next';
import RecurrenceForm from './RecurrenceForm';
import { type DateTime } from 'luxon';
import { createPredefinedOptions, matchPredefinedOption, type PredefinedOptions } from '@/types/recurrence/algorithms';
import { classNames } from './FormSelect';
import { type TFunction, type i18n } from 'i18next';
import ReactSelect from 'react-select';
import { recurrenceToString } from '@/types/recurrence/translation';
import { getStringEnumValues } from '@/utils/common';
import { useToggle } from '@/hooks';

type RecurrenceInputProps = Readonly<{
    startDate: DateTime;
    value?: Recurrence;
    onChange: (newValue?: Recurrence) => void;
    className?: string;
    countLimit?: number;
}>;

export function RecurrenceInput({ startDate, value, onChange, className, countLimit }: RecurrenceInputProps) {
    const { t, i18n } = useTranslation('common', { keyPrefix: 'recurrence.input' });
    const cache = useMemo(() => createCache(startDate, countLimit, t, i18n), [ startDate, countLimit, t, i18n ]);

    const [ isShowModal, setIsShowModal ] = useToggle(false);

    const { selectedOption, groups } = useMemo(() => {
        const selectedOption = getSelectedOption(value, isShowModal, cache);
        const groups = getGroups(selectedOption, cache);

        return { selectedOption, groups };
    }, [ value, isShowModal, cache ]);

    const selectOption = useCallback((option: Option) => {
        if (option.value === PredefinedOption.Custom) {
            setIsShowModal.true();
            return;
        }

        // This value is only available if the custom option is selected, so selecting it again shouldn't do shit.
        if (option.value === 'custom-new')
            return;

        const recurrence = option.value === PredefinedOption.Never ? undefined : cache.predefined[option.value];
        onChange(recurrence);
    }, [ cache, onChange, setIsShowModal ]);

    const submitModal = useCallback((value: Recurrence) => {
        setIsShowModal.false();
        onChange(value);
    }, [ onChange, setIsShowModal ]);

    return (<>
        <div className={className}>
            <ReactSelect
                value={selectedOption}
                onChange={selectOption}
                options={groups}
                classNames={classNames}
                isDisabled={countLimit === 1}
                isSearchable={false}
            />
        </div>
        <Modal show={isShowModal} onHide={setIsShowModal.false} size='lg' className='sh-modal-w-fit'>
            <Modal.Body>
                <h2 className='mt-0 mb-3'>{t('modal-title')}</h2>
                <RecurrenceForm
                    after={startDate}
                    // The custom option is always defined.
                    input={value ?? cache.predefined[PredefinedOption.Custom]!}
                    onSubmit={submitModal}
                    onCancel={setIsShowModal.false}
                    countLimit={countLimit}
                />
            </Modal.Body>
        </Modal>
    </>);
}

/**
 * Compares the given recurrence with the predefined options. Returns either the predefined option or a custom recurrence.
 */
function getSelectedOption(value: Recurrence | undefined, isShowModal: boolean, cache: CachedData): Option {
    if (isShowModal)
        return cache.options[PredefinedOption.Custom];

    if (!value || value.count === 1)
        return cache.options[PredefinedOption.Never];

    const match = matchPredefinedOption(value, cache.predefined);
    if (match)
        return cache.options[match];
    
    return {
        label: recurrenceToString(value, cache.i18n),
        value: 'custom-new',
    };
}

function getGroups(selectedOption: Option, { groups, options }: CachedData): Group[] {
    if (selectedOption.value !== 'custom-new')
        return groups;

    const output = [ ...groups ];
    output[output.length - 1] = {
        label: groups[0].label,
        options: [
            options[PredefinedOption.Custom],
            selectedOption,
            options[PredefinedOption.Never],
        ],
    };

    return output;
}

type Group = {
    label: string;
    options: Option[];
};

type Option = {
    /** The custom option can be selected, but it's replaced by the actual value of the recurrence after the modal is closed. */
    value: PredefinedOption | 'custom-new';
    label: string;
};

type CachedData = {
    predefined: PredefinedOptions;
    options: Record<PredefinedOption, Option>;
    groups: Group[];
    i18n: i18n;
};

function createCache(startDate: DateTime, countLimit: number | undefined, t: TFunction, i18n: i18n): CachedData {
    const predefined = createPredefinedOptions(startDate, countLimit);
    const weekDay = getWeekDay(startDate);
    const showWeekdaily = weekDay !== Day.Saturday && weekDay !== Day.Sunday;
    const showMonthlyFromEnd = predefined[PredefinedOption.MonthlyFromEnd]?.byDay?.occurence === -1;

    const options: Record<PredefinedOption, Option> = getStringEnumValues(PredefinedOption).reduce((ans, value) => {
        ans[value] = { value, label: getOptionLabel(value, predefined, t, i18n) };
        return ans;
    }, {} as Record<PredefinedOption, Option>);

    const defaultCustomRecurrence = predefined[PredefinedOption.Custom];
    if (!defaultCustomRecurrence)
        throw new Error('Custom recurrence not generated.');

    const groups = [
        ...(countLimit === undefined ? [] : [
            {
                label: t('daily-group'),
                options: [
                    options[PredefinedOption.Daily],
                    ...(showWeekdaily ? [ options[PredefinedOption.Weekdaily] ] : []),
                    options[PredefinedOption.Weekly],
                    options[PredefinedOption.TwoWeekly],
                ],
            },
            {
                label: t('monthly-group'),
                options: [
                    options[PredefinedOption.MonthlyByDay],
                    options[PredefinedOption.MonthlyFromStart],
                    ...(showMonthlyFromEnd ? [ options[PredefinedOption.MonthlyFromEnd] ] : []),
                ],
            },
        ]),
        {
            label: t('custom-group'),
            options: [
                options[PredefinedOption.Custom],
                options[PredefinedOption.Never],
            ],
        },
    ];

    return { predefined, options, groups, i18n };
}

function getOptionLabel(option: PredefinedOption, predefined: PredefinedOptions, t: TFunction, i18n: i18n): string {
    if (option === PredefinedOption.Custom)
        return t('custom-option');
    if (option === PredefinedOption.Never || !predefined[option])
        return t('never-option');

    return recurrenceToString(predefined[option]!, i18n);
}
