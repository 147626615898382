import type { Money, TaxRate } from '@/modules/money';
import type { IRI, Id } from '../Id';
import { type OrderItem } from './OrderItem';
import { type DateTime } from 'luxon';

export type CreditOrderItemFromServer = {
    transaction: IRI;
};

export class CreditOrderItem implements OrderItem {
    public constructor(
        readonly id: Id,
        readonly title: string,
        readonly quantity: number,
        readonly unitPrice: Money,
        readonly vat: TaxRate,
        readonly index: number,
        readonly createdAt: DateTime,

        readonly transaction: IRI,
    ) {
    }

    public static fromServer(base: OrderItem, input: CreditOrderItemFromServer): CreditOrderItem {
        return new CreditOrderItem(
            base.id,
            base.title,
            base.quantity,
            base.unitPrice,
            base.vat,
            base.index,
            base.createdAt,
            input.transaction,
        );
    }
}
