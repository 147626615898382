import React, { Component, createRef } from 'react';
import * as animationFrame from 'dom-helpers/animationFrame';
import DayColumn from './DayColumn';
import TimeGutter from './TimeGutter';
import getWidth from 'dom-helpers/width';
import TimeGridHeader from './TimeGridHeader';
import { localizer } from '.';
import { showMultiDayTimes } from './utils/common';

export default class TimeGrid extends Component {
    constructor(props) {
        super(props);

        this.state = { gutterWidth: undefined, isOverflowing: null };

        this.scrollRef = createRef();
        this.contentRef = createRef();
        this._scrollRatio = null;
        this.gutterRef = createRef();
    }

    getSnapshotBeforeUpdate() {
        this.checkOverflow();
        return null;
    }

    componentDidMount() {
        if (this.props.width == null) 
            this.measureGutter();
    

        this.calculateScroll();
        this.applyScroll();

        window.addEventListener('resize', this.handleResize);
    }

    handleScroll = e => {
        if (this.scrollRef.current) 
            this.scrollRef.current.scrollLeft = e.target.scrollLeft;
    
    };

    handleResize = () => {
        animationFrame.cancel(this.rafHandle);
        this.rafHandle = animationFrame.request(this.checkOverflow);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);

        animationFrame.cancel(this.rafHandle);

        if (this.measureGutterAnimationFrameRequest) 
            window.cancelAnimationFrame(this.measureGutterAnimationFrameRequest);
    
    }

    componentDidUpdate() {
        this.applyScroll();
    }

    handleSelectAlldayEvent = (...args) => {
    //cancel any pending selections so only the event click goes through.
        this.clearSelection();
        this.props.onSelectEvent(...args);
    };

    handleSelectAllDaySlot = (slots, slotInfo) => {
        const start = slots[0];
        const end = slots[slots.length - 1].plus({ day: 1 });

        this.props.onSelectSlot({
            slots,
            start,
            end,
            action: slotInfo.action,
        });
    };

    renderEvents(range, events, backgroundEvents, now) {
        return range.map((date, dayIndex) => {
            const daysEvents = events.filter(event => localizer.inRange(date, event.start, event.end, 'day'));
            const daysBackgroundEvents = backgroundEvents.filter(event => localizer.inRange(date, event.start, event.end, 'day'));

            return (
                <DayColumn
                    {...this.props}
                    min={localizer.merge(date, this.props.min)}
                    max={localizer.merge(date, this.props.max)}
                    isNow={localizer.isSameDate(date, now)}
                    key={dayIndex}
                    dayIndex={dayIndex}
                    date={date}
                    events={daysEvents}
                    backgroundEvents={daysBackgroundEvents}
                />
            );
        });
    }

    render() {
        const { range, min, max } = this.props;

        const start = range[0];
        const end = range[range.length - 1];
        const currentRange = { start, end };
        
        this.slots = range.length;

        const allDayEvents = [];
        const rangeEvents = [];
        const rangeBackgroundEvents = [];


        this.props.events.filter(event => localizer.inEventRange(event, currentRange)).forEach(event => {
            const eStart = event.start;
            const eEnd = event.end;

            if (
                event.allDay ||
                localizer.startAndEndAreDateOnly(eStart, eEnd) ||
                (showMultiDayTimes && !localizer.isSameDate(eStart, eEnd))
            ) 
                allDayEvents.push(event);
            else 
                rangeEvents.push(event);
        });

        this.props.backgroundEvents.filter(event => localizer.inEventRange(event, currentRange)).forEach(event => rangeBackgroundEvents.push(event));

        allDayEvents.sort(localizer.sortEvents);

        return (
            <div
                className={'rbc-time-view'}
            >
                <TimeGridHeader
                    range={range}
                    events={allDayEvents}
                    width={this.props.width || this.state.gutterWidth}
                    getNow={this.props.getNow}
                    selected={this.props.selected}
                    selectable={this.props.selectable}
                    scrollRef={this.scrollRef}
                    isOverflowing={this.state.isOverflowing}
                    onSelectSlot={this.handleSelectAllDaySlot}
                    onSelectEvent={this.handleSelectAlldayEvent}
                    onKeyPressEvent={this.props.onKeyPressEvent}
                    onDrillDown={this.props.onDrillDown}
                />
                <div
                    ref={this.contentRef}
                    className='rbc-time-content'
                    onScroll={this.handleScroll}
                >
                    <TimeGutter
                        date={start}
                        ref={this.gutterRef}
                        min={localizer.merge(start, min)}
                        max={localizer.merge(start, max)}
                        getNow={this.props.getNow}
                        className='rbc-time-gutter'
                    />
                    {this.renderEvents(
                        range,
                        rangeEvents,
                        rangeBackgroundEvents,
                        this.props.getNow(),
                    )}
                </div>
            </div>
        );
    }

    clearSelection() {
        clearTimeout(this._selectTimer);
        this._pendingSelection = [];
    }

    measureGutter() {
        if (this.measureGutterAnimationFrameRequest) 
            window.cancelAnimationFrame(this.measureGutterAnimationFrameRequest);
    
        this.measureGutterAnimationFrameRequest = window.requestAnimationFrame(
            () => {
                const width = this.gutterRef?.current
                    ? getWidth(this.gutterRef.current)
                    : undefined;

                if (width && this.state.gutterWidth !== width) 
                    this.setState({ gutterWidth: width });
        
            },
        );
    }

    applyScroll() {
    // If auto-scroll is disabled, we don't actually apply the scroll
        if (this._scrollRatio != null) {
            const content = this.contentRef.current;
            content.scrollTop = content.scrollHeight * this._scrollRatio;
            // Only do this once
            this._scrollRatio = null;
        }
    }

    calculateScroll(props = this.props) {
        const { min, max, scrollToTime } = props;

        const diffMillis = scrollToTime - localizer.startOf(scrollToTime, 'day');
        const totalMillis = localizer.diff(min, max, 'milliseconds');

        this._scrollRatio = diffMillis / totalMillis;
    }

    checkOverflow = () => {
        if (this._updatingOverflow) 
            return;

        const content = this.contentRef.current;
        const isOverflowing = content.scrollHeight > content.clientHeight;

        if (this.state.isOverflowing !== isOverflowing) {
            this._updatingOverflow = true;
            this.setState({ isOverflowing }, () => {
                this._updatingOverflow = false;
            });
        }
    };
}

// TimeGrid.propTypes = {
//   events: PropTypes.array.isRequired,
//   backgroundEvents: PropTypes.array.isRequired,

//   range: PropTypes.arrayOf(PropTypes.instanceOf(DateTime)),
//   min: PropTypes.instanceOf(DateTime).isRequired,
//   max: PropTypes.instanceOf(DateTime).isRequired,
//   getNow: PropTypes.func.isRequired,

//   scrollToTime: PropTypes.instanceOf(DateTime).isRequired,

//   width: PropTypes.number,
//   selected: PropTypes.object,
//   selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),

//   onNavigate: PropTypes.func,
//   onSelectEvent: PropTypes.func,
//   onKeyPressEvent: PropTypes.func,
// }
