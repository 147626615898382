import { useCallback, useState } from 'react';
import useNotifications from '@/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { api } from '@/utils/api/backend';

const CIN_LENGTH = 8;

export function useAres(callback: (ares: AresInfo) => void) {
    const [ isFetchingAres, setIsFetchingAres ] = useState(false);
    const { addAlert } = useNotifications();

    const fetchAres = useCallback(async (inputCin?: string) => {
        const cin = inputCin?.replace(/\s/g, '').padStart(CIN_LENGTH, '0');
        if (!cin)
            return;

        setIsFetchingAres(true);
        const response = await api.client.aresInfo({ cin });
        setIsFetchingAres(false);

        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            return;
        }

        addAlert(createTranslatedSuccessAlert('components:invoicingForm.ares-success-alert'));
        callback({ ...response.data, cin });
    }, [ callback, addAlert ]);

    return { fetchAres, isFetchingAres };
}

export type AresInfo = {
    legalName?: string;
    cin: string;
    tin?: string;
    address?: {
        city?: string;
        country?: string;
        line1?: string;
        postalCode?: string;
    };
};
