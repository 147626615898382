import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

export function useDaysLeft(dateTo: DateTime): number {
    const [ now, setNow ] = useState(DateTime.now());
    const { days, refreshTimeout } = computeDays(dateTo, now);

    useEffect(() => {
        const timeout = setTimeout(() => setNow(DateTime.now()), refreshTimeout);

        return () => clearInterval(timeout);
    }, [ now ]);

    return days;
}

function computeDays(dateTo: DateTime, now: DateTime): { days: number, refreshTimeout: number } {
    const diffUntilDateTo = dateTo.diff(now, 'days').days;
    const diffUntilEndOfDay = now.endOf('day').diff(now).milliseconds;

    return {
        days: Math.floor(Math.max(0, diffUntilDateTo)),
        refreshTimeout: diffUntilEndOfDay,
    };
}
