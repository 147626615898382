import { Id } from '../Id';
import { BaseLocation, type BaseLocationFromServer, LocationType, PlatformType } from './BaseLocation';

export type VideoLocationFromServer = BaseLocationFromServer & {
    '@type': LocationType.video;
    platform: string;
    url: string;
};

export class VideoLocation extends BaseLocation {
    platform: string;
    url: string;

    private constructor(id: Id, title: string, displayName: string, platform: string, platformType: PlatformType, url: string) {
        super(id, LocationType.video, platform, platformType, title, displayName);

        this.platform = platform;
        this.url = url;
    }

    public static fromServer(input: VideoLocationFromServer) {
        return new VideoLocation(
            Id.fromIRI(input['@id']),
            input.title,
            input.displayName,
            input.platform,
            getPlatformType(input.platform),
            input.url,
        );
    }
}

function getPlatformType(platform: string): PlatformType {
    return platform in PlatformType ? PlatformType[platform as keyof typeof PlatformType] : PlatformType.custom;
}

export type VideoLocationToServer = {
    title: string;
    platform: string;
    url: string;
};
