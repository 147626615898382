import React, { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate, useParams, type NavigateFunction } from 'react-router-dom';
import { api } from '@/utils/api/backend';
import { Button, Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { routes } from '@/router';
import { CheckIcon, ChevronLeftIcon, EyeIcon, TrashIcon } from '@/components/icons';
import Portal, { portals } from '@/components/common/Portal';
import { SpinnerButton } from '@/components/common';
import { useMaster } from '@/context/UserProvider';
import InvoicingProfileClients from '@/components/settings/InvoicingProfileClients';
import InvoicingProfileOrders from '@/components/settings/InvoicingProfileOrders';
import { LocaleIcon } from '@/components/common/LocaleToggle';
import { useBlockerModal, useClients, useToggle } from '@/hooks';
import BlockNavigationModal from '@/components/BlockNavigationModal';
import GeneralSupplierForm from '@/components/settings/GeneralSupplierForm';
import PersonalizationForm from '@/components/settings/PersonalizationForm';
import { InvoicingProfile, type InvoicingProfileUpdate } from '@/types/Invoicing';
import useNotifications from '@/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '@/components/notifications';
import DeleteProfileModal from '@/components/settings/DeleteProfileModal';
import { type ClientInfo } from '@/types/Client';
import { InfoTooltip } from '@/components/forms/buttons';

export default function InvoicingProfileDetail() {
    const { t } = useTranslation('pages', { keyPrefix: 'invoicing-profile' });
    const { id, key } = useParams();
    const navigate = useNavigate();
    const handleSelect = useCallback((newKey: string | null) => id && newKey && navigate(routes.invoicingProfiles.detail.resolve({ id, key: newKey })), [ id, navigate ]);
    const { profiles, setProfiles } = useMaster();
    const { clients } = useClients();

    const profile = useMemo(() => profiles.find(profile => profile.id.equals(id)), [ profiles, id ]);

    const setProfile = useCallback((profile: InvoicingProfile) => {
        setProfiles(oldProfiles => {
            const index = oldProfiles.findIndex(p => p.id.equals(profile.id));
            const newProfiles = [ ...oldProfiles ];
            newProfiles[index] = profile;

            return newProfiles;
        });
    }, [ setProfiles ]);

    const [ isDirty, setIsDirty ] = useState(false);
    const { navigateUnblocked, control } = useBlockerModal(isDirty);

    if (!profile)
        return null;

    return (
        <div className='d-flex flex-column h-100'>
            <BlockNavigationModal control={control} />
            <div className='container-large py-3 d-flex align-items-center gap-col-2'>
                <div className='flex-grow-1 d-flex align-items-center gap-3'>
                    <LocaleIcon locale={profile.locale} size={40} />
                    <div className='d-flex gap-2'>
                        <h2 className='text-truncate m-0'>
                            {profile.title}
                        </h2>
                        <InfoTooltip text={t('title-tooltip')} />
                    </div>
                </div>
                <div id={portals.invoicingProfile.save} />
                <Link to={routes.settings.resolve({ key: 'invoicing' })} className='text-decoration-none'>
                    <Button variant='ghost-secondary' className='square'>
                        <ChevronLeftIcon size={22} />
                        {t('back-to-list-button')}
                    </Button>
                </Link>
                <a href={`${profile.id.toIRI()}/example`} target='_blank' rel='noreferrer' className='text-decoration-none'>
                    <Button variant='ghost-secondary' className='square'>
                        <EyeIcon size={22} />
                        {t('example-invoice-button')}
                    </Button>
                </a>
                {profiles.length > 1 && (
                    // The last profile can't be deleted.
                    <DeleteButton profile={profile} clients={clients ?? []} navigateUnblocked={navigateUnblocked} />
                )}
            </div>
            <Tab.Container
                activeKey={key}
                transition={false}
                onSelect={handleSelect}
            >
                <div className='border-1 border-bottom border-top' style={{ backgroundColor: '#F5F5F5' }}>
                    <div className='container-large'>
                        <Nav className='sh-tabs nav-tabs' as='ul'>
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='general'>{t('general-tab-title')}</Nav.Link></Nav.Item>
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='custom'>{t('custom-tab-title')}</Nav.Link></Nav.Item>
                            {/* The `clients` tab is used to add clients from other profiles to this profile. The `orders` tab shows orders with this profile. Both tabs are useless when the user has only one profile. Although, the `orders` tab is not equal to the orders page event if there is only one profile - because there might have been profiles that are now deleted - but that is such an edge case. */}
                            {profiles.length > 1 && (<>
                                <Nav.Item as='li'><Nav.Link as='button' eventKey='clients'>{t('clients-tab-title')}</Nav.Link></Nav.Item>
                                <Nav.Item as='li'><Nav.Link as='button' eventKey='orders'>{t('orders-tab-title')}</Nav.Link></Nav.Item>
                            </>)}
                        </Nav>
                    </div>
                </div>
                <div className='sh-main-scroller'>
                    <Tab.Content className='container-large py-4'>
                        <Tab.Pane eventKey='general' unmountOnExit>
                            <InvoicingProfileFormPart profile={profile} setProfile={setProfile} isDirty={isDirty} setIsDirty={setIsDirty} type='general' />
                        </Tab.Pane>
                        <Tab.Pane eventKey='custom' unmountOnExit>
                            <InvoicingProfileFormPart profile={profile} setProfile={setProfile} isDirty={isDirty} setIsDirty={setIsDirty} type='custom' />
                        </Tab.Pane>
                        <Tab.Pane eventKey='clients' unmountOnExit>
                            <InvoicingProfileClients profile={profile} />
                        </Tab.Pane>
                        <Tab.Pane eventKey='orders' unmountOnExit>
                            <InvoicingProfileOrders profile={profile} />
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    );
}


const formParts = {
    general: GeneralSupplierForm,
    custom: PersonalizationForm,
};

type InvoicingProfileFormPartProps = {
    profile: InvoicingProfile;
    setProfile: (newProfile: InvoicingProfile) => void;
    type: keyof typeof formParts;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
};

function InvoicingProfileFormPart({ profile, setProfile, type, isDirty, setIsDirty }: InvoicingProfileFormPartProps) {
    const [ isFetching, setIsFetching ] = useState(false);
    const { addAlert } = useNotifications();

    async function onSubmit(update: InvoicingProfileUpdate) {
        setIsFetching(true);
        const response = await api.settings.updateInvoicingProfile({ id: profile.id, type }, update);
        setIsFetching(false);
        if (!response.status){
            addAlert(createTranslatedErrorAlert());
            return;
        }

        setProfile(InvoicingProfile.fromServer(response.data));
        addAlert(createTranslatedSuccessAlert('pages:invoicing-profile.save-success-alert'));
    }

    const saveButton = useCallback(({ onClick }: { onClick: () => void }) => <SaveButton onClick={onClick} isFetching={isFetching} />, [ isFetching ]);
    const InnerForm = formParts[type];

    return (
        <InnerForm
            onSubmit={onSubmit}
            input={profile}
            onChange={setIsDirty}
            saveButton={isDirty ? saveButton : undefined}
        />
    );
}

type SaveButtonProps = Readonly<{
    onClick: () => void;
    isFetching: boolean;
}>;

function SaveButton({ onClick, isFetching }: SaveButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'invoicing-profile' });

    return (
        <Portal to={portals.invoicingProfile.save}>
            <SpinnerButton
                type='submit'
                isFetching={isFetching}
                variant='primary'
                className='square'
                onClick={onClick}
            >
                <CheckIcon size={22} />
                {t('save-button')}
            </SpinnerButton>
        </Portal>
    );
}

type DeleteButtonProps = Readonly<{
    profile: InvoicingProfile;
    clients: ClientInfo[];
    navigateUnblocked: NavigateFunction;
}>;

function DeleteButton({ profile, clients, navigateUnblocked }: DeleteButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'invoicing-profile' });
    const [ showModal, setShowModal ] = useToggle(false);
    const { setProfiles } = useMaster();

    const profileDeleted = useCallback(() => {
        setProfiles(oldProfiles => oldProfiles.filter(p => !p.id.equals(profile.id)));
        navigateUnblocked(routes.settings.resolve({ key: 'invoicing' }));
    }, [ profile.id, setProfiles, navigateUnblocked ]);

    return (<>
        <DeleteProfileModal
            profile={showModal ? profile : undefined}
            allClients={clients}
            onClose={setShowModal.false}
            onDelete={profileDeleted}
        />
        <Button
            variant='ghost-danger'
            className='square'
            onClick={setShowModal.true}
        >
            <TrashIcon size={22} />
            {t('delete-button')}
        </Button>
    </>);
}
