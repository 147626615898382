import { emptyFunction } from '@/utils/common';

export const CANCELED_ERROR = 'canceled';

type TokenProvider = () => string | undefined;
type ExpiredJWTCallback = () => void;

export class ApiAuthorizer {
    // Default value, need to be set by the auth provider.
    private tokenProvider: TokenProvider = () => undefined;

    public setTokenProvider(provider: TokenProvider) {
        this.tokenProvider = provider;
    }

    public getToken(): string | undefined {
        return this.tokenProvider();
    }

    // Default value, need to be set by the auth manager.
    private expiredJWTCallback: ExpiredJWTCallback = emptyFunction;

    public setExpiredJWTCallback(callback: ExpiredJWTCallback) {
        this.expiredJWTCallback = callback;
    }

    public JWTExpired() {
        this.expiredJWTCallback();
    }
}

