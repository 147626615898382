import React, { useEffect, useState } from 'react';
import { Event, type EventState } from '@/types/Event';
import { Modal } from 'react-bootstrap';
import { Pagination, Table } from '@/components/common';
import EventStateBadge from '@/components/event/EventStateBadge';
import { api } from '@/utils/api/backend';
import { getSkeletonArray } from '@/utils/math';
import { SortOrder, type EnumFilter } from '@/utils/common';
import { type Entity, type IRI } from '@/types/Id';
import { useTranslation } from 'react-i18next';
import { usePagination, type PaginationController } from '@/hooks';
import { dehydrate } from '@/types/api/result';
// import localStorage from '@/utils/localStorage';
import DateTimeDisplay from '../common/DateTimeDisplay';
import ClientIconLink from '../client/ClientIconLink';
import EventStateFilter from '../common/filters/EventStateFilter';
import FilterRow, { useFilters } from '../common/filters/FilterRow';
import { EventDetailLink } from '@/pages/event/EventDetail';
import { EditNotesIcon, SortOrderIcon } from '../icons';
import { TeamMemberBadge } from '../team/TeamMemberBadge';
import { useUser } from '@/context/UserProvider';
import { UserRole } from '@/types/Team';

// const FILTER_KEY = 'events_table_filter_0';

const filters = [ EventStateFilter ];

type Filter = {
    state: EnumFilter<EventState>;
    clientIRIs: IRI[];
};

type OrderBy = {
    dateFrom?: SortOrder;
}

type EventsTableProps = {
    client: Entity;
    filter?: Filter;
    orderBy?: OrderBy;
};

export default function EventsTable(props: EventsTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventsTable' });
    const [ events, setEvents ] = useState<Event[]>();
    // const [ filter, setFilter ] = useState<Filter>(props.filter ?? localStorage.get<Filter>(FILTER_KEY) ?? {
    //     state: {
    //         [EventState.Ready]: false,
    //         [EventState.Finished]: false,
    //         [EventState.Canceled]: false,
    //     },
    //     clientIRIs: [],
    // });
    const [ orderBy, setOrderBy ] = useState<OrderBy>(props.orderBy ?? {
        dateFrom: SortOrder.Descending,
    });

    const filtersControl = useFilters(filters);
    const stateFilterToServer = filtersControl.toServer(EventStateFilter.name);

    async function fetchEvents(signal: AbortSignal) {
        setEvents(undefined);
        const response = await api.event.getAll(signal, {
            state: stateFilterToServer as EventState[],
            // participants: props.client ? [ props.client.id.toString() ] : filter.clientIRIs.map(iri => Id.fromIRI(iri).toString()),
            participants: [ props.client.id.toString() ],
            page: pagination.page,
            orderBy,
        });
        if (!response.status)
            return;

        const { items, totalItems } = dehydrate(response);

        setEvents(items.map(Event.fromServer));
        pagination.setTotalItems(totalItems);
    }

    const pagination = usePagination();
    const { reset } = pagination;
    useEffect(() => reset(), [ filtersControl.state, reset ]);

    useEffect(() => {
        // localStorage.set(FILTER_KEY, filter);

        const [ signal, abort ] = api.prepareAbort();
        fetchEvents(signal);

        return abort;
    }, [ stateFilterToServer, orderBy, pagination.page ]);

    const isMaster = useUser().role === UserRole.Master;

    return (<>
        <FilterRow control={filtersControl} className='mb-3' />
        <Table>
            {!events ? (
                <Table.HeaderSkeleton height={17} />
            ) : (
                <Table.Header>
                    <Table.HeaderCol>{t('title-label')}</Table.HeaderCol>
                    {/* {props.client
                    ?  */}

                    <Table.HeaderCol>{t('other-clients-label')}</Table.HeaderCol>
                    {/* TODO enable filtering by clients when no client is provided */}
                    {/* : (
                        <Table.HeaderCol xs={3}>
                        <span className='me-3'>{t('clients-label')}</span>
                        <ClientIriFilter
                        value={filter.clientIRIs}
                        onChange={clientIRIs => setFilter({ ...filter, clientIRIs })}
                        />
                        </Table.HeaderCol>
                        )
                        } */}
                    <Table.HeaderCol xs='auto'>
                        <div className='d-flex align-items-center gap-1'>
                            <span className='text-nowrap'>{t('date-from-label')}</span>
                            <SortOrderIcon
                                orderBy={orderBy.dateFrom}
                                className='clickable'
                                size={18}
                                onClick={() => setOrderBy({ dateFrom: orderBy.dateFrom === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending })}
                            />
                        </div>
                    </Table.HeaderCol>
                    <Table.HeaderCol xs='auto'>{t('state-label')}</Table.HeaderCol>
                    <Table.HeaderCol xs='auto'>{t('notes-label')}</Table.HeaderCol>
                    {isMaster && (
                        <Table.HeaderCol xs='auto'>{t('other-owner-label')}</Table.HeaderCol>
                    )}
                </Table.Header>
            )}
            <Table.Body>
                <EventsList events={events} client={props.client} pagination={pagination} />
            </Table.Body>
        </Table>
        <Pagination controller={pagination} />
    </>);
}

type EventsListProps = Readonly<{
    events?: Event[];
    client?: Entity;
    pagination: PaginationController;
}>;

function EventsList({ events, client, pagination }: EventsListProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventsTable' });
    const isMaster = useUser().role === UserRole.Master;

    if (!events) {
        return <>{
            getSkeletonArray(pagination).map(id => <Table.RowSkeleton key={id} height={23} />)
        }</>;
    }

    if (events.length === 0)
        return <Table.Row><Table.Col colSpan={5} className='text-center fs-4 py-5'>{t('no-events-text')}</Table.Col></Table.Row>;

    return <>
        {events.map(event =>
            <Table.Row className='clickable hoverable' key={event.id.toString()}>
                <Table.Col xs={3} truncate link>
                    <EventDetailLink event={event}>
                        {event.displayTitle}
                    </EventDetailLink>
                </Table.Col>
                <Table.Col truncate className='non-clickable hoverable-exception'>
                    <div className='d-flex gap-2'>
                        {event.guests
                            .filter(participants => !client || !participants.client.id.equals(client.id))
                            .map(participants => <ClientIconLink
                                key={participants.client.id.toString()}
                                client={participants.client}
                            />)
                        }
                    </div>
                </Table.Col>
                <Table.Col xs='auto' link>
                    <EventDetailLink event={event}>
                        <DateTimeDisplay dateTime={event.dateFrom} />
                    </EventDetailLink>
                </Table.Col>
                <Table.Col xs='auto' link>
                    <EventDetailLink event={event}>
                        <EventStateBadge event={event} />
                    </EventDetailLink>
                </Table.Col>
                <Table.Col xs='auto' className='text-center non-clickable hoverable-exception'>
                    <EventNotesModal event={event} />
                </Table.Col>
                {isMaster && (
                    <Table.Col xs='auto' className='non-clickable hoverable-exception'>
                        <div className='d-flex justify-content-center'>
                            <TeamMemberBadge appUserId={event.ownerId} />
                        </div>
                    </Table.Col>
                )}
            </Table.Row>,
        )}
    </>;
}

type EventNotesModalProps = Readonly<{
    event: Event;
    size?: number;
}>;

export function EventNotesModal({ event, size }: EventNotesModalProps) {
    const [ showModal, setShowModal ] = useState(false);

    if (!event.notes)
        return null;

    return (<>
        <EditNotesIcon size={size === undefined ? 20 : size} className='sh-button-icon' onClick={() => setShowModal(true)} />
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Body className='pre-wrap'>
                {event.notes}
            </Modal.Body>
        </Modal>
    </>);
}
