import { DateTime } from 'luxon';
import { Id, type Entity, type IRI } from './Id';

export type LogFromServer = {
    '@id': IRI;
    dateCreated: string;
    type: LogType;
    data: Record<string, unknown>;
};

export class Log implements Entity {
    private constructor(
        readonly id: Id,
        readonly date: DateTime,
        readonly type: LogType,
        readonly data: Record<string, unknown>,
        readonly translationId: string,
    ) {}

    static fromServer(input: LogFromServer): Log {
        return new Log(
            Id.fromIRI(input['@id']),
            DateTime.fromISO(input.dateCreated),
            input.type,
            input.data,
            getLogTranslationId(input),
        );
    }

    /**
     * Sorts logs from the newest to the oldest.
     */
    static compareDesc(a: Log, b: Log): number {
        const dateCompare = +b.date - +a.date;
        if (dateCompare !== 0)
            return dateCompare;

        return (TYPE_PRIORITY[a.type] ?? 0) - (TYPE_PRIORITY[b.type] ?? 0);
    }
}

function getLogTranslationId(input: LogFromServer): string {
    if (input.type === OrderLogType.Transition && !!input.data?.automatic)
        return 'order.transitionAutomatic';

    return input.type;
}

enum OrderLogType {
    Created = 'order.created',
    CreatedNotification = 'order.createdNotification',
    Updated = 'order.updated',
    Transition = 'order.transition',
}

// add more by A | B
type LogType = OrderLogType;

/**
 * A way how to add priority to log types that will be used if the date is the same.
 * A higher number means lower sorting number (i.e., the log will be "older" because of "lower timestamp").
 */
const TYPE_PRIORITY: { [key in LogType]?: number } = {
    [OrderLogType.Created]: 1,
};
