import React, { useCallback, useEffect, useMemo } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { type FileToServer, type InvoicingProfile, type PersonalizationSettingsToServer } from '@/types/Invoicing';
import { Trans, useTranslation } from 'react-i18next';
import { stringToServer } from '@/utils/common';
import clsx from 'clsx';
import { fileToBase64 } from '@/utils/forms';
import { useUser } from '@/context/UserProvider';
import { Link } from 'react-router-dom';
import { routes } from '@/router';
import FormErrorMessage from '../forms/FormErrorMessage';
import { ControlledCondensedInvoiceToggle } from '../forms/CondensedInvoiceToggle';

type PersonalizationFormData = {
    /**
     * FileList = FileList[0] is the newly uploaded logo
     * null = the logo is removed
     * undefined = nothing has changed
     */
    logoFile: FileList | null | undefined;
    isCondensedInvoice: boolean;
    header: string;
    footer: string;
    customKey1: string;
    customValue1: string;
    customKey2: string;
    customValue2: string;
};

const FREE_PLAN_INVOICE_LOGO_URL = '/logo192.png';

function inputToForm(input: InvoicingProfile): PersonalizationFormData {
    return {
        logoFile: undefined,
        isCondensedInvoice: input.isCondensedInvoice,
        header: input.header ?? '',
        footer: input.footer ?? '',
        customKey1: input.customKey1 ?? '',
        customValue1: input.customValue1 ?? '',
        customKey2: input.customKey2 ?? '',
        customValue2: input.customValue2 ?? '',
    };
}

export async function logoToServer(logo: FileList | null | undefined): Promise<FileToServer | null | undefined> {
    if (logo && logo.length !== 0) {
        const file = logo.item(0)!;
        const base64 = await fileToBase64(file);
        return {
            data: base64,
            name: file.name,
            type: file.type,
        };
    }

    if (logo === null)
        return null;

    return undefined;
}

async function formToOutput(data: PersonalizationFormData): Promise<PersonalizationSettingsToServer> {
    const logo = await logoToServer(data.logoFile);

    return {
        logo,
        condensedInvoice: data.isCondensedInvoice,
        header: stringToServer(data.header),
        footer: stringToServer(data.footer),
        customKey1: stringToServer(data.customKey1),
        customValue1: stringToServer(data.customValue1),
        customKey2: stringToServer(data.customKey2),
        customValue2: stringToServer(data.customValue2),
    };
}

export const HEADER_FOOTER_MAX_LENGTH = 255;
// Default DPI for mPDF is 96, which grants us 794px of width for A4. The logo takes up half of that.
// const LOGO_MAX_WIDTH = 794 / 2;
// However, this would not be pretty. Let's round it to 400px.
const LOGO_MAX_WIDTH = 400;
const LOGO_MAX_HEIGHT = 50;

type PersonalizationFormProps = Readonly<{
    input: InvoicingProfile;
    onSubmit: (output: PersonalizationSettingsToServer) => void;
    saveButton?: React.FC<{ onClick: () => void }>;
    onChange?: (isDirty: boolean) => void;
}>;

export default function PersonalizationForm({ input, onSubmit, saveButton, onChange }: PersonalizationFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'invoicingForm' });
    const form = useForm<PersonalizationFormData>({
        defaultValues: inputToForm(input),
    });
    const { register, handleSubmit, watch, formState: { errors, isDirty }, reset, setValue, control } = form;
    const { subscription } = useUser();

    useEffect(() => onChange?.(isDirty), [ isDirty ]);

    async function onValid(data: PersonalizationFormData) {
        const output = await formToOutput(data);
        onSubmit(output);
    }

    useEffect(() => {
        reset(inputToForm(input));
    }, [ input ]);

    const headerLength = watch('header').length;
    const footerLength = watch('footer').length;

    const logoUnchangeable = !subscription.isLogoChangeable;

    const logoFile = watch('logoFile');

    const imageSrc = useMemo(() => {
        if (logoUnchangeable)
            return FREE_PLAN_INVOICE_LOGO_URL;

        if (logoFile === null)
            return undefined;

        const uploadedImage = logoFile?.item(0);
        if (uploadedImage) {
            const objectUrl = URL.createObjectURL(uploadedImage);
            return objectUrl;
        }
        else {
            return input.logoFile?.url;
        }
    }, [ logoFile, input.logoFile?.url, logoUnchangeable ]);

    const { validateCustomKey1, validateCustomKey2 } = useCustomFieldsValidation(form);

    return (
        <Form noValidate onSubmit={handleSubmit(onValid)} className='sh-design'>
            <Form.Group className='d-flex align-items-center'>
                <Col xs={6}>
                    <h5 className='mb-0'>{input.logoFile ? t('change-custom-logo-label') : t('custom-logo-label')}</h5>
                    {logoUnchangeable ? (<>
                        <div className='mb-3'>{t('free-plan-warning')}</div>
                        <Link to={routes.subscription}>
                            <Button variant='primary'>
                                {t('upgrade-plan-button')}
                            </Button>
                        </Link>
                    </>) : (<>
                        <div>
                            <Trans
                                t={t}
                                i18nKey='custom-logo-description'
                                components={{ b: <span className='fw-medium' /> }}
                                tOptions={{ maxWidth: LOGO_MAX_WIDTH, maxHeight: LOGO_MAX_HEIGHT }}
                            />
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <Form.Control
                                type='file'
                                disabled={false}
                                className='sh-reset-form-control mt-3'
                                {...register('logoFile')}
                            />
                            {imageSrc && (
                                <Button
                                    variant='outline-danger'
                                    className='mt-3'
                                    onClick={() => setValue('logoFile', null, { shouldDirty: true })}
                                >
                                    {t('remove-logo-button')}
                                </Button>
                            )}
                        </div>
                    </>)}
                </Col>
                <Col xs={6} className='text-center'>
                    {imageSrc ? (
                        <img src={imageSrc} style={{ maxWidth: LOGO_MAX_WIDTH, maxHeight: LOGO_MAX_HEIGHT, width: '100%', height: '100%', objectFit: 'contain' }} className='non-draggable' />
                    ) : (
                        <div className='d-flex flex-column justify-content-center h-100'>
                            <div>{t('no-logo-label')}</div>
                        </div>
                    )}
                </Col>
            </Form.Group>
            <Row className='mt-4'>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('condensed-invoice-label')}</Form.Label>
                    <ControlledCondensedInvoiceToggle
                        control={control}
                        name='isCondensedInvoice'
                    />
                </Form.Group>
            </Row>
            <Form.Group className='mt-3'>
                <Form.Label>{t('header-label')}</Form.Label>
                <Form.Control
                    placeholder={t('header-placeholder')}
                    as={TextareaAutosize}
                    minRows={2}
                    {...register('header', { maxLength: HEADER_FOOTER_MAX_LENGTH })}
                    aria-describedby='header-textarea'
                />
                <MaxLengthText length={headerLength} maxLength={HEADER_FOOTER_MAX_LENGTH} id='header-textarea' />
            </Form.Group>
            <Form.Group className='mt-3'>
                <Form.Label>{t('footer-label')}</Form.Label>
                <Form.Control
                    placeholder={t('footer-placeholder')}
                    as={TextareaAutosize}
                    minRows={2}
                    {...register('footer', { maxLength: HEADER_FOOTER_MAX_LENGTH })}
                    aria-describedby='footer-textarea'
                />
                <MaxLengthText length={footerLength} maxLength={HEADER_FOOTER_MAX_LENGTH} id='footer-textarea' />
            </Form.Group>
            <h5 className='mb-0 mt-4'>{t('custom-fields-title')}</h5>
            <div className='sh-description-no-border'>{t('custom-fields-description')}</div>
            <Row>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('custom-key-1-label')}</Form.Label>
                    <Form.Control
                        {...register('customKey1', { validate: validateCustomKey1 })}
                        placeholder={t('custom-key-1-placeholder')}
                    />
                    <FormErrorMessage name='customKey1' errors={errors} />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('custom-value-1-label')}</Form.Label>
                    <Form.Control
                        {...register('customValue1')}
                        placeholder={t('custom-value-1-placeholder')}
                    />
                </Form.Group>
            </Row>
            <Row className='mt-3'>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('custom-key-2-label')}</Form.Label>
                    <Form.Control
                        {...register('customKey2', { validate: validateCustomKey2 })}
                    />
                    <FormErrorMessage name='customKey2' errors={errors} />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('custom-value-2-label')}</Form.Label>
                    <Form.Control
                        {...register('customValue2')}
                    />
                </Form.Group>
            </Row>
            <>{saveButton?.({ onClick: handleSubmit(onValid) })}</>
        </Form>
    );
}

type ValidationFormDataType = {
    customKey1: string;
    customValue1: string;
    customKey2: string;
    customValue2: string;
};

export function useCustomFieldsValidation<T extends ValidationFormDataType>(form: UseFormReturn<T>) {
    const { t } = useTranslation('common', { keyPrefix: 'form' });
    const { getValues, trigger, watch, formState: { isSubmitted } } = form as unknown as UseFormReturn<ValidationFormDataType>;

    const validateCustomKey1 = useCallback((keyRaw: string) => {
        const key = keyRaw.trim();
        const value = getValues('customValue1').trim();
        if ((key === '' && value !== '') || (key !== '' && value === ''))
            return t('custom-key-value-required');

        return true;
    }, [ getValues, t ]);

    useEffect(() => {
        if (isSubmitted)
            trigger('customKey1');
    }, [ watch('customValue1').trim(), t ]);

    const validateCustomKey2 = useCallback((keyRaw: string) => {
        const key = keyRaw.trim();
        const value = getValues('customValue2').trim();
        if ((key === '' && value !== '') || (key !== '' && value === ''))
            return t('custom-key-value-required');

        return true;
    }, [ t, getValues ]);

    useEffect(() => {
        if (isSubmitted)
            trigger('customKey2');
    }, [ watch('customValue2').trim(), t ]);

    return { validateCustomKey1, validateCustomKey2 };
}

type MaxLengthTextProps = Readonly<{
    length: number;
    maxLength: number;
    id: string;
}>;

export function MaxLengthText({ length, maxLength, id }: MaxLengthTextProps) {
    return (
        <div className='position-relative'>
            <Form.Text id={id} className={clsx('position-absolute text-nowrap end-0', length > maxLength ? 'text-danger' : 'text-muted')}>
                {length} / {maxLength}
            </Form.Text>
        </div>
    );
}
