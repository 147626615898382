import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RiAlertFill } from 'react-icons/ri';
import { ErrorType, type PlanExceededError, type SubscriptionEndedError, type SubscriptionError } from '@/types/Subscription';
import { Trans, useTranslation } from 'react-i18next';
import { emptyFunction } from '@/utils/common';
import { routes } from '@/router';
import { useCached } from '@/hooks';
import { api } from '@/utils/api/backend';
import SpinnerButton from '../common/SpinnerButton';
import { toMaster, useUser } from '@/context/UserProvider';

type PlanExceededErrorModalProps = Readonly<{
    error: PlanExceededError | undefined;
    onHide: () => void;
}>;

export function PlanExceededErrorModal({ error, onHide }: PlanExceededErrorModalProps) {
    return (
        <CommonModal error={error} onHide={onHide} />
    );
}

type SubscriptionEndedErrorModalProps = Readonly<{
    error: SubscriptionEndedError | undefined;
}>;

export function SubscriptionEndedErrorModal({ error }: SubscriptionEndedErrorModalProps) {
    return (
        <CommonModal error={error} onHide={emptyFunction} />
    );
}

type CommonModalProps = Readonly<{
    error: SubscriptionError | undefined;
    onHide: () => void;
}>;

function CommonModal({ error, onHide }: CommonModalProps) {
    const cachedError = useCached(error);
    if (!cachedError)
        return null;

    return (
        <Modal
            show={!!error}
            backdrop='static'
            keyboard={false}
            centered
        >
            <ModalBody error={cachedError} onHide={onHide} />
        </Modal>
    );
}

type ModalBodyProps = Readonly<{
    error: SubscriptionError;
    onHide: () => void;
}>;

function ModalBody({ error, onHide }: ModalBodyProps) {
    const isMasterOrFreelancer = !!toMaster(useUser());
    const { t } = useTranslation('common', { keyPrefix: isMasterOrFreelancer ? error.type : 'subscription.schedulerError' });
    const [ isFetchingCustomerPortal, setIsFetchingCustomerPortal ] = useState(false);
    const customerPortalPromiseRef = useRef<Promise<string>>();

    useEffect(() => {
        if (error.type !== ErrorType.PlanPending)
            return;

        const [ signal, abort ] = api.prepareAbort();

        customerPortalPromiseRef.current = (async () => {
            const response = await api.team.createSubscriptionCustomerPortal({}, { signal });
            if (!response.status)
                return '';

            return response.data.customerPortalUrl;
        })();

        return abort;
    }, [ error ]);

    async function openCustomerPortal() {
        if (!customerPortalPromiseRef.current)
            return;

        setIsFetchingCustomerPortal(true);
        const customerPortalUrl = await customerPortalPromiseRef.current;
        window.location.href = customerPortalUrl;
    }

    return (
        <Modal.Body className='text-center'>
            <RiAlertFill size={60} className='text-warning' />
            <h2>{t('title')}</h2>
            {!isMasterOrFreelancer ? <>
                <div className='pre-wrap mb-3'>
                    {t('text')}
                </div>
            </> : (error.type === ErrorType.PlanExceeded ? (<>
                <div>
                    <Trans>
                        {t('cart-payments-text', { count: error.cart })}
                        {t('available-payments-text', { count: error.available })}
                    </Trans>
                </div>
                <Row className='mt-4'>
                    <Col>
                        <Button onClick={onHide} className='sh-button-inverse float-end'>
                            {t('hide-button')}
                        </Button>
                    </Col>
                    <Col>
                        <Link to={routes.subscription} className='float-start'>
                            <Button>
                                {t('new-plan-button')}
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </>) : (error.type === ErrorType.PlanPending ? (<>
                <div className='pre-wrap mb-3'>
                    {t('text')}
                </div>
                <SpinnerButton isFetching={isFetchingCustomerPortal} onClick={openCustomerPortal}>
                    {t('checkButton')}
                </SpinnerButton>
            </>) : (<>
                <div className='pre-wrap mb-3'>
                    {t('text')}
                </div>
                <Link to={routes.subscription}>
                    <Button>
                        {t('new-plan-button')}
                    </Button>
                </Link>
            </>)))}
        </Modal.Body>
    );
}
