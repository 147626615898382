import React from 'react';
import { type BaseLocation, PlatformType, getLocationLink } from '@/types/location';
import { abbreviateStringToLength } from '@/utils/common';
import { MdModeEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { MapPinIcon, SettingsIcon, TrashIcon } from '../icons';
import { Skeleton } from '../common';
import clsx from 'clsx';

type LocationDisplayProps = Readonly<{
    location: BaseLocation;
    onEdit?: () => void;
    onDelete?: () => void;
    compact?: boolean;
    className?: string;
}>;

export default function LocationDisplay({ location, onEdit, onDelete, compact, className }: LocationDisplayProps) {
    return (
        <div className={clsx('unselectable d-flex flex-row align-items-center', className)}>
            <a href={getLocationLink(location)} target='_blank' rel='noreferrer' className='d-flex flex-row align-items-center text-decoration-none text-base fw-medium hoverable-underline text-truncate'>
                <LocationIcon location={location} size={compact ? 18 : 32} className='me-3' />
                {compact ? (
                    <span className='lha-3 text-truncate'>{location.title}</span>
                ) : (
                    <div className='text-truncate'>
                        <h2 className='m-0 text-truncate'>{location.title}</h2>
                        <span className='text-secondary text-truncate'>{abbreviateStringToLength(location.displayName, 60)}</span>
                    </div>
                )}
            </a>
            {onEdit && <MdModeEdit size={22} className='clickable ms-auto me-2' onClick={onEdit} />}
            {onDelete && <TrashIcon size={22} className='clickable text-danger' onClick={onDelete} />}
        </div>
    );
}

type TryLocationDisplayProps = Readonly<{
    location?: BaseLocation;
    isDefined: boolean;
    compact?: boolean;
}>;

export function TryLocationDisplay({ location, isDefined, compact }: TryLocationDisplayProps) {
    return location
        ? <LocationDisplay location={location} compact={compact} />
        : <LocationPlaceholder isDefined={isDefined} compact={compact} />;
}

type LocationPlaceholderProps = Readonly<{
    isDefined: boolean;
    compact?: boolean;
}>;

export function LocationPlaceholder({ isDefined, compact }: LocationPlaceholderProps) {
    const { t } = useTranslation('common');

    if (isDefined)
        return <Skeleton height={compact ? 21 : 39} />;

    return (
        <div className='d-flex flex-row align-items-center'>
            <MapPinIcon size={18} className='me-2' />
            <span className='text-secondary'>{t('location-placeholder')}</span>
        </div>
    );
}

const icons = {
    [PlatformType.googleMeet]: 'google-meet',
    [PlatformType.microsoftTeams]: 'ms-teams',
    [PlatformType.zoom]: 'zoom',
    [PlatformType.physicalLocation]: 'google-maps',
    [PlatformType.custom]: 'custom',
} as const;

type LocationIconProps = Readonly<{
    location: BaseLocation;
    size?: string | number;
    className?: string;
}>;

export function LocationIcon(props: LocationIconProps) {
    return PlatformTypeIcon({
        ...props,
        type: props.location.platformType,
    });
}

type PlatformTypeIconProps = Readonly<{
    type: PlatformType;
    size?: string | number;
    className?: string;
}>;

export function PlatformTypeIcon({ type, size, className }: PlatformTypeIconProps) {
    return type === PlatformType.custom
        ? <SettingsIcon style={{ width: size, height: size }} className={className} />
        : <img src={`/location-icons/${icons[type]}.svg`} style={{ width: size, height: size }} className={className} />;
}
