import React, { useEffect, useState } from 'react';
import Topbar from '@/components/menu/Topbar';
import UnauthorizedRoutes from '@/router/UnauthorizedRoutes';
import Loading from '@/pages/Loading';
import { routes } from '@/router';
import AuthorizedRoutes from '@/router/AuthorizedRoutes';
import useAuth from '@/context/AuthProvider';
import { api } from '@/utils/api/backend';
import { AppUser } from '@/types/AppUser';
import { InvoicingProfile } from '@/types/Invoicing';
import AlertDisplay from '@/components/notifications/AlertDisplay';
import { addCurrency, addTaxRate, Currency, TaxRate } from '@/modules/money';
import { Visibility } from '@/types/notifications';
import LocaleToggle from '@/components/common/LocaleToggle';
import { Onboarding } from '@/types/Onboarding';
import { SubscriptionEndedErrorModal } from './components/orders/SubscriptionErrorModal';
import { ErrorType, Subscription, type SubscriptionEndedError } from './types/Subscription';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import DevBar from '@/components/dev/DevBar';
import { localeToLanguage, setLocale, setTimezone } from './types/i18n';
import { BankAccount } from './types/BankAccount';
import { GoToDesktopModal } from './components/GoToDesktopModal';
import { googleApi } from '@/utils/api/google';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import NewVersionNotification, { useNewVersionNotification } from './components/notifications/NewVersionNotification';
import { Settings } from './types/Settings';
import { Team, TeamMember, TeamMembers, UserRole } from './types/Team';
import { TeamSettings } from './types/TeamSettings';
import { UserProvider, type UserDefaults, type SchedulerDefaults, type MasterDefaults, useUser } from './context/UserProvider';
import { type StartFromServer } from './utils/api/backend/endpoints/appUser';
import { GoogleUserInfo, type GoogleUserInfoFromServer } from './types/GoogleUser';
import { ClientTag, sortClientTags } from './types/ClientTag';

const displayDevBar = import.meta.env.DEV;

export default function App() {
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const { state, auth } = useAuth();
    // TODO this is unnecessary because the state is also stored in the context ...
    const [ userDefaults, setUserDefaults ] = useState<UserDefaults>();

    async function fetchStart(signal: AbortSignal) {
        setUserDefaults(undefined);

        // This is for the google integration (specifically for connecting of users that are already registered with the traditional email/password).
        // We have to do it here because the google user info is fetched on start.
        const refreshGoogle = searchParams.get('refresh-google');
        if (refreshGoogle) {
            await auth.refreshAccessToken();
            navigate(routes.integrations, { replace: true });
        }

        const googleUserRequest = googleApi.authorizer.getToken() ? googleApi.getUserInfo(signal) : undefined;

        const response = await api.appUser.start();
        if (!response.status) {
            // TODO handle error
            return;
        }

        const googleUserResponse = googleUserRequest && await googleUserRequest;
        const googleData = googleUserResponse?.status ? googleUserResponse.data : undefined;

        response.data.currencies
            .map(Currency.fromServer)
            .forEach(addCurrency);

        response.data.taxRates
            .map(TaxRate.fromServer)
            .forEach(addTaxRate);

        const defaults = computeDefaults(response.data, googleData);

        // This is automatically set in the user provider, however that is quite slow in the first few moments after the appUser is loaded.
        // For example, the calendar component would otherwise a show wrong locale for a second, then switching to the correct one.
        setTimezone(defaults.settings.timezone);
        setLocale(defaults.settings.locale);

        setUserDefaults(defaults);

        Sentry.setUser({ id: defaults.appUser.id.toString(), email: defaults.appUser.email });
    }

    // When the appUser logs out, we have to delete his state so it wouldn't be there when he log in next time.
    // Otherwise an authorized page would show up for a moment (because defaults are not undefined ...), then it would be immediatelly replaced by the loading screen.
    useEffect(() => {
        if (!state.isAuthenticated) {
            setUserDefaults(undefined);
            Sentry.setUser(null);
        }

    }, [ state.isAuthenticated ]);

    useEffect(() => {
        if (!state.isAuthenticated)
            return;

        const [ signal, abort ] = api.prepareAbort();
        fetchStart(signal);

        return abort;
    }, [ state ]);

    const { i18n } = useTranslation();
    const lang = localeToLanguage(i18n.language);

    const isShowNewVersion = useNewVersionNotification();

    if (!state.isAuthenticated) {
        if (state.isFetchingNewToken) {
            return (
                <Loading />
            );
        }

        return (
            <div id='inner-root' className='unauthorized' lang={lang}>
                <AlertDisplay visibility={Visibility.Unauthorized} />
                <div className='sh-locales-toggle-wrapper'>
                    <LocaleToggle />
                </div>
                <UnauthorizedRoutes />
                <GoToDesktopModal />
            </div>
        );
    }

    if (!userDefaults) {
        return (
            <Loading />
        );
    }

    return (
        <UserProvider defaults={userDefaults}>
            <div id='inner-root' className='authorized' lang={lang}>
                <AlertDisplay visibility={Visibility.Authorized} />
                <NewVersionNotification isShow={isShowNewVersion} />
                <SubscriptionEndedControl />
                <Topbar />
                {/* <Leftbar /> */}
                <div className='sh-content sh-main-scroller'>
                    <AuthorizedRoutes />
                </div>
                {displayDevBar && <DevBar onRestart={() => {
                    const [ signal ] = api.prepareAbort();
                    fetchStart(signal);
                }} />}
                <GoToDesktopModal />
            </div>
        </UserProvider>
    );
}

function computeDefaults(data: StartFromServer, googleData: GoogleUserInfoFromServer | undefined): UserDefaults {
    const settings = Settings.fromServer(data.appUserSettings);
    const team = Team.fromServer(data.team);
    const teamMembersArray = data.teamMembers.map(TeamMember.fromServer);
    const appUser = AppUser.fromServer(data.appUser);
    const teamMembers = new TeamMembers(appUser, teamMembersArray);
    const subscription = Subscription.fromServer(data.subscription);
    const onboarding = Onboarding.fromServer(data.onboarding);
    const googleUserInfo = googleData && GoogleUserInfo.fromServer(googleData);

    if (data.role === UserRole.Scheduler) {
        const defaults: SchedulerDefaults = {
            role: data.role,
            team,
            teamMembers,
            appUser,
            settings,
            onboarding,
            subscription,
            googleUserInfo,
        };
        return defaults;
    }

    const teamSettings = TeamSettings.fromServer(data.teamSettings);
    const profiles = data.profiles.map(InvoicingProfile.fromServer);
    const bankAccounts = data.bankAccounts.map(BankAccount.fromServer);
    const clientTags = sortClientTags(data.clientTags.map(ClientTag.fromServer));

    const defaults: MasterDefaults = {
        role: data.role,
        team,
        teamMembers,
        appUser,
        settings,
        onboarding,
        subscription,
        googleUserInfo,
        teamSettings,
        profiles,
        bankAccounts,
        clientTags,
    };

    return defaults;
}

function SubscriptionEndedControl() {
    const { subscription } = useUser();
    const location = useLocation();

    const [ subscriptionEndedError, setSubscriptionEndedError ] = useState<SubscriptionEndedError>();
    useEffect(() => {
        if (subscription.isPending)
            setSubscriptionEndedError({ type: ErrorType.PlanPending });
        else if (!subscription.isActive)
            setSubscriptionEndedError({ type: ErrorType.PlanEnded });
        else
            setSubscriptionEndedError(undefined);
    }, [ subscription ]);

    return (
        <SubscriptionEndedErrorModal error={location.pathname !== routes.subscription ? subscriptionEndedError : undefined} />
    );
}
