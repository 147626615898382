import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MoneyDisplay, Pagination, Table } from '@/components/common';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OrderInfo, type OrderInfoFromServer, SchedulerOrderInfo, type SchedulerOrderInfoFromServer } from '@/types/orders/Order';
import { api } from '@/utils/api/backend';
import OrderStateBadge from '@/components/orders/OrderStateBadge';
import { getSkeletonArray } from '@/utils/math';
import PaymentMethodIcon from '@/components/orders/PaymentMethodIcon';
import { type Entity } from '@/types/Id';
import { useClients, usePagination, type PaginationController } from '@/hooks';
import { dehydrate } from '@/types/api/result';
import { routes } from '@/router';
import DateTimeDisplay from '../common/DateTimeDisplay';
import ClientIconLink from '@/components/client/ClientIconLink';
import { InvoiceDownloadIcon, SortOrderIcon } from '../icons';
import { getInvoiceNumber } from '@/components/orders/invoiceNumber';
import FilterRow, { useFilters } from '@/components/common/filters/FilterRow';
import createOrderClientFilter, { filterName as orderClientFilterName } from '@/components/common/filters/OrderClientFilter';
import OrderStateFilter from '../common/filters/OrderStateFilter';
import { SortOrder } from '@/utils/common';
import { type OrderQueryParams } from '@/utils/api/backend/endpoints/order';
import { toMaster, useUser } from '@/context/UserProvider';
import { type TFunction } from 'i18next';
import { UserRole } from '@/types/Team';
import { TeamMemberBadge } from '../team/TeamMemberBadge';
import { Link } from 'react-router-dom';
import { OrderEndDateFilter, OrderStartDateFilter } from '../common/filters/OrderDateFilter';

type OrderByState = {
    createdAt: SortOrder;
    index: SortOrder;
};

type OrderType = OrderInfo | SchedulerOrderInfo;

type OrdersTableProps = Readonly<{
    filterClient?: Entity;
    filterProfile?: Entity;
}>;

export default function OrdersTable({ filterClient, filterProfile }: OrdersTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersTable' });
    const [ orders, setOrders ] = useState<OrderType[]>();
    const [ orderBy, setOrderBy ] = useState<OrderByState>({
        createdAt: SortOrder.Descending,
        index: SortOrder.Descending,
    });

    const { clients } = useClients();
    const filters = useMemo(() => [
        OrderStateFilter,
        OrderStartDateFilter,
        OrderEndDateFilter,
        ...(filterClient ? [] : [ createOrderClientFilter(clients ?? []) ]),
    ], [ filterClient, clients ]);

    const filtersControl = useFilters(filters);
    const stateFilterToServer = filtersControl.toServer(OrderStateFilter.name);
    const clientsFilterToServer = filterClient ? filterClient.id.toIRI() : filtersControl.toServer(orderClientFilterName);
    const profileFilterToServer = filterProfile?.id.toString();
    const startDateFilterToServer = filtersControl.toServer(OrderStartDateFilter.name);
    const endDateFilterToServer = filtersControl.toServer(OrderEndDateFilter.name);

    const pagination = usePagination();
    const { page, setTotalItems, reset } = pagination;
    useEffect(() => reset(), [ filtersControl.state, reset ]);

    const cachedQuery: OrderQueryParams = useMemo(() => ({
        state: stateFilterToServer,
        client: clientsFilterToServer,
        'supplier.invoicingProfile': profileFilterToServer,
        'createdAt[after]': startDateFilterToServer,
        'createdAt[strictly_before]': endDateFilterToServer,
        orderBy,
        page,
    }), [ stateFilterToServer, clientsFilterToServer, profileFilterToServer, startDateFilterToServer, endDateFilterToServer, orderBy, page ]);

    const isMasterOrFreelancer = !!toMaster(useUser());

    const fetchOrders = useCallback(async (signal: AbortSignal, query: OrderQueryParams) => {
        setOrders(undefined);
        const response = await api.order.getAll(signal, query);
        if (!response.status)
            return;

        const { items, totalItems } = dehydrate(response);
        const fetchedOrders = isMasterOrFreelancer
            ? (items as OrderInfoFromServer[]).map(OrderInfo.fromServer)
            : (items as SchedulerOrderInfoFromServer[]).map(SchedulerOrderInfo.fromServer);

        setOrders(fetchedOrders);
        setTotalItems(totalItems);
    }, [ setTotalItems, isMasterOrFreelancer ]);

    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchOrders(signal, cachedQuery);

        return abort;
    }, [ fetchOrders, cachedQuery ]);

    function switchOrder() {
        setOrderBy({
            createdAt: orderBy.createdAt === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending,
            index: SortOrder.Descending,
        });
    }

    const showClients = !filterClient;

    return (<>
        <div className='d-flex justify-content-between align-items-start gap-3 mb-3'>
            <FilterRow control={filtersControl} />
            {!filterClient && !filterProfile && (
                <div>
                    <Link to={routes.orders.export}>
                        <Button variant='outline-primary' className='compact'>{t('export-invoices-button')}</Button>
                    </Link>
                </div>
            )}
        </div>
        <Table>
            <OrdersHeader orders={orders} showClients={showClients} orderBy={orderBy} switchOrder={switchOrder} />
            <Table.Body>
                <OrdersList orders={orders} showClients={showClients} pagination={pagination} />
            </Table.Body>
        </Table>
        <Pagination controller={pagination} />
    </>);
}

type OrdersHeaderProps = Readonly<{
    orders?: OrderType[];
    showClients: boolean;
    orderBy: OrderByState;
    switchOrder: () => void;
}>;

function OrdersHeader({ orders, showClients, orderBy, switchOrder }: OrdersHeaderProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersTable' });
    const userContext = useUser();
    const isMasterOrFreelancer = !!toMaster(userContext);

    if (!orders)
        return <Table.HeaderSkeleton height={17} />;

    return isMasterOrFreelancer
        ? masterOrdersHeader(showClients, userContext.role === UserRole.Master, orderBy, switchOrder, t)
        : schedulerOrdersHeader(showClients, orderBy, switchOrder, t);
}

function masterOrdersHeader(showClients: boolean, showScheduler: boolean, orderBy: OrderByState, switchOrder: () => void, t: TFunction) {
    return (
        <Table.Header>
            <Table.HeaderCol xs={2}>
                <span style={{ minWidth: 20, display: 'inline-block' }} className='me-3' />
                <span>{t('number-label')}</span>
            </Table.HeaderCol>
            <Table.HeaderCol xs={3}>{t('title-label')}</Table.HeaderCol>
            {showClients && (
                <Table.HeaderCol xs={2}>{t('client-label')}</Table.HeaderCol>
            )}
            <Table.HeaderCol xs='auto' className='text-nowrap'>
                <div className='d-flex align-items-center gap-1'>
                    <span>{t('created-label')}</span>
                    <SortOrderIcon
                        orderBy={orderBy.createdAt}
                        className='clickable'
                        size={18}
                        onClick={switchOrder}
                    />
                </div>
            </Table.HeaderCol>
            <Table.HeaderCol xs='auto' className='text-end'>{t('price-label')}</Table.HeaderCol>
            <Table.HeaderCol xs='auto' className='text-center'>{t('state-label')}</Table.HeaderCol>
            <Table.HeaderCol xs='auto' className='text-center'>{t('invoice-label')}</Table.HeaderCol>
            {showScheduler && (
                <Table.HeaderCol xs='auto'>{t('scheduler-label')}</Table.HeaderCol>
            )}
        </Table.Header>
    );
}

function schedulerOrdersHeader(showClients: boolean, orderBy: OrderByState, switchOrder: () => void, t: TFunction) {
    return (
        <Table.Header>
            <Table.HeaderCol xs={6}>{t('title-label')}</Table.HeaderCol>
            {showClients && (
                <Table.HeaderCol xs={2}>{t('client-label')}</Table.HeaderCol>
            )}
            <Table.HeaderCol xs='auto' className='text-nowrap'>
                <span className='me-1'>{t('created-label')}</span>
                <SortOrderIcon
                    orderBy={orderBy.createdAt}
                    className='clickable'
                    size={18}
                    onClick={switchOrder}
                />
            </Table.HeaderCol>
            <Table.HeaderCol xs='auto' className='text-center'>{t('state-label')}</Table.HeaderCol>
        </Table.Header>
    );
}

type OrdersListProps = Readonly<{
    orders?: OrderType[];
    showClients: boolean;
    pagination: PaginationController;
}>;

function OrdersList({ orders, showClients, pagination }: OrdersListProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersTable' });
    const userContext = useUser();
    const isMasterOrFreelancer = !!toMaster(userContext);

    if (!orders)
        return <>{getSkeletonArray(pagination).map(id => <Table.RowSkeleton key={id} height={20} />)}</>;

    if (orders.length === 0) {
        return (
            <Table.Row>
                <Table.Col colSpan={7} className='text-center fs-4 py-5'>
                    {t('no-orders-text')}
                </Table.Col>
            </Table.Row>
        );
    }

    return (<>
        {orders.map(order => isMasterOrFreelancer
            ? masterOrderRow(order as OrderInfo, showClients, userContext.role === UserRole.Master, t)
            : schedulerOrderRow(order as SchedulerOrderInfo, showClients),
        )}
    </>);
}

function masterOrderRow(order: OrderInfo, showClients: boolean, showScheduler: boolean, t: TFunction) {
    const link = routes.orders.detail.resolve({ id: order.id.toString() });

    return (
        <Table.Row className='hoverable' key={order.id.toString()}>
            <Table.Col xs={2} truncate link={link} className='text-nowrap'>
                <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip>{t(order.isStripeOrder ? 'type-icon-stripe-tooltip' : 'type-icon-without-stripe-tooltip')}</Tooltip>}
                >
                    <span><PaymentMethodIcon order={order} /></span>
                </OverlayTrigger>
                {order.invoice && <span className='text-muted text-nowrap ms-3'>{getInvoiceNumber(order.invoice)}</span>}
            </Table.Col>
            <Table.Col xs={3} truncate link={link}>{order.title}</Table.Col>
            {showClients && (
                <Table.Col xs={2} truncate className='non-clickable hoverable-exception'>
                    <ClientIconLink client={order.client} />
                </Table.Col>
            )}
            <Table.Col xs='auto' link={link}><DateTimeDisplay date dateTime={order.createdAt} /></Table.Col>
            <Table.Col xs='auto' link={link} className='text-end'><MoneyDisplay money={order.price} /></Table.Col>
            <Table.Col xs='auto' link={link}><OrderStateBadge order={order} /></Table.Col>
            <Table.Col xs='auto' className='non-clickable hoverable-exception text-center'>
                {order.invoice && (
                    <a href={`${order.id.toIRI()}/invoice`} target='_blank' rel='noreferrer'>
                        <InvoiceDownloadIcon size={18} className='sh-button-icon text-dark' />
                    </a>
                )}
            </Table.Col>
            {showScheduler && (
                <Table.Col xs='auto' truncate className='non-clickable hoverable-exception'>
                    <div className='d-flex justify-content-center'>
                        <TeamMemberBadge appUserId={order.schedulerId} />
                    </div>
                </Table.Col>
            )}
        </Table.Row>
    );
}

function schedulerOrderRow(order: SchedulerOrderInfo, showClients: boolean) {
    return (
        <Table.Row className='hoverable' key={order.id.toString()}>
            <Table.Col xs={6} truncate>{order.title}</Table.Col>
            {showClients && (
                <Table.Col xs={2} truncate className='hoverable-exception'>
                    <ClientIconLink client={order.client} />
                </Table.Col>
            )}
            <Table.Col xs='auto'><DateTimeDisplay date dateTime={order.createdAt} /></Table.Col>
            <Table.Col xs='auto'><OrderStateBadge order={order} /></Table.Col>
        </Table.Row>
    );
}
