import React from 'react';
import { useUser } from '@/context/UserProvider';
import { api } from '@/utils/api/backend';
import { AppUser } from '@/types/AppUser';
import { type PreferencesSettingsUpdate, type PersonSettingsUpdate, Settings } from '@/types/Settings';
import PersonForm from '@/components/settings/PersonForm';
import PreferencesForm from '@/components/settings/PreferencesForm';
import { useTranslation } from 'react-i18next';
import useNotifications from '@/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '@/components/notifications';
import { useAnalytics } from '@/types/analytics';

export default function GeneralSettingsEditor() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const { addAlert } = useNotifications();
    const { settings, setSettings, appUser, setAppUser } = useUser();
    const analytics = useAnalytics();

    async function onPersonSubmit(data: PersonSettingsUpdate) {
        const response = await api.appUser.update(appUser, data);

        if (!response.status) {
            setAppUser({ ...appUser });
            addAlert(createTranslatedErrorAlert());
            return;
        }

        const newAppUser = AppUser.fromServer(response.data);
        setAppUser(newAppUser);
        addAlert(createTranslatedSuccessAlert('pages:settings.general.generic-settings-success'));
    }

    async function onPreferencesSubmit(data: PreferencesSettingsUpdate) {
        const response = await api.settings.updatePreferences({ id: settings.id }, data);
        if (!response.status) {
            setSettings({ ...settings });
            addAlert(createTranslatedErrorAlert());
            return;
        }

        analytics.settingsUpdated({ ...data });
        const newSettings = Settings.fromServer(response.data);
        setSettings(newSettings);
        addAlert(createTranslatedSuccessAlert('pages:settings.general.preferences-settings-success'));
    }

    return (
        <div>
            <h1 className='mt-0'>{t('page-title')}</h1>
            <PersonForm
                input={appUser}
                onSubmit={onPersonSubmit}
            />
            <h2 className='mt-5'>{t('preferences-section-title')}</h2>
            <div className='mb-5'>
                <PreferencesForm
                    input={settings}
                    onSubmit={onPreferencesSubmit}
                />
            </div>
        </div>
    );
}
