const FRAGMENT_KEY = '#fragment';
type Fragment = typeof FRAGMENT_KEY;

class ParametrizedRoute<TParam extends string = never, TQuery extends string = never> {
    constructor(
        readonly path: string,
    ) {}

    resolve(params: Record<TParam, string>, query?: Record<TQuery, string>): string {
        let value = this.path;
        for (const key of Object.keys(params))
            value = value.replace(':' + key, params[key as keyof typeof params]);

        if (query) {
            if (FRAGMENT_KEY in query)
                value += '#' + query[FRAGMENT_KEY];

            const queryString = Object.entries(query)
                .filter(([ key ]) => key !== FRAGMENT_KEY)
                .map(([ key, value ]) => `${key}=${value}`)
                .join('&');
            if (queryString.length > 0)
                value += '?' + queryString;
        }

        return value;
    }
}

export const routes = {
    root: '/',
    dashboard: '/',
    calendar: '/calendar',
    clients: {
        list: '/clients',
        detail: new ParametrizedRoute<'id' | 'key', Fragment>('/clients/:id/:key'),
        new: '/clients/new',
        import: '/clients/import',
    },
    orders: {
        list: '/orders',
        detail: new ParametrizedRoute<'id'>('/orders/:id'),
        export: '/orders/export',
        newProduct: '/orders/new',
        newEventGroup: '/orders/new-event',
        newCustom: '/orders/new-custom',
        newBackpay: '/clients/invoice',
    },
    team: '/team',
    events: {
        list: '/lists/events',
        detail: new ParametrizedRoute<'id'>('/events/:id'),
    },
    settings: new ParametrizedRoute<'key'>('/settings/:key'),
    invoicingProfiles: {
        detail: new ParametrizedRoute<'id' | 'key'>('/invoicing-profiles/:id/:key'),
    },
    payments: '/payments',
    subscription: '/subscription',
    integrations: '/integrations',
    login: {
        index: '/login',
        google: '/login/google',
    },
    // The url pages are displayed to the users so we use the 'sign-' version.
    register: {
        index: '/signup',
        google: '/signup/google',
    },
    resetPassword: '/reset-password',
    /** Only for development purposes. Not active in production. */
    dev: '/dev',
} as const;
