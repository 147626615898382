import React from 'react';
import { useTranslation } from 'react-i18next';
import { setLocale, SUPPORTED_LOCALES, type LocaleCode, ALL_SUPPORTED_LOCALES } from '@/types/i18n';
import { findFlagUrlByIso2Code } from 'country-flags-svg';

type LocaleToggleProps = {
    className?: string;
};

export default function LocaleToggle({ className }: LocaleToggleProps) {
    const { i18n } = useTranslation('pages');

    function nextLocale() {
        return i18n.language === SUPPORTED_LOCALES.appUser[0] ? SUPPORTED_LOCALES.appUser[1] : SUPPORTED_LOCALES.appUser[0];
    }

    function toggleLocale() {
        setLocale(nextLocale());
    }

    return (
        <button type='button' onClick={() => toggleLocale()} className={`${className} p-2 border-0`} style={{ background: 'none' }}>
            <LocaleIcon locale={i18n.language} size={26} />
        </button>
    );
}

type LocaleIconProps = {
    locale: LocaleCode;
    size: number;
};

export function LocaleIcon({ locale, size }: LocaleIconProps) {
    return (
        <img src={localeToFlagUrl[locale]} className='rounded-5' style={{ width: size, height: size, objectFit: 'cover' }} />
    );
}

/**
 * The find function is really slow (O(n)) so we have to cache it in the map.
 */
const localeToFlagUrl = ALL_SUPPORTED_LOCALES.reduce((ans, locale) => {
    ans[locale] = findFlagUrlByIso2Code(locale.split('-')[1]);
    return ans;
}, {} as Record<LocaleCode, string>);