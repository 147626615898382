import { createDELETE, createGET, createPATCH, createPOST, createPUT } from '../rawApiObject';
import { type IRI, type Id } from '@/types/Id';
import { type OnboardingInfo, type OnboardingFlag, type OnboardingFromServer } from '@/types/Onboarding';
import type { PreferencesSettingsUpdate, SettingsFromServer } from '@/types/Settings';
import { type BankAccountFromServer, type BankAccountUpdate } from '@/types/BankAccount';
import { type EmptyObject } from '@/utils/common';
import { type InvoicingProfileFromServer, type InvoicingProfileInit, type InvoicingProfileUpdate } from '@/types/Invoicing';
import { type TeamSettingsFromServer, type PricingToServer } from '@/types/TeamSettings';

export const settings = {
    updatePreferences: createPATCH<{ id: Id }, SettingsFromServer, PreferencesSettingsUpdate>(
        u => `${u.id.toIRI()}`,
    ),
    getOnboarding: createGET<OnboardingFromServer>(
        '/app-users/onboarding/get',
    ),
    updateOnboarding: createPUT<OnboardingFromServer, { [OnboardingFlag.SettingsOpened]: boolean }>(
        '/app-users/onboarding/update',
    ),
    updateInfo: createPUT<OnboardingInfo, Partial<OnboardingInfo>>(
        '/app-users/info/update',
    ),
    updatePricings: createPOST<TeamSettingsFromServer, { pricings: PricingToServer[] }>(
        '/teams/settings/pricings/replace-all',
    ),
    createBankAccount: createPOST<BankAccountFromServer, BankAccountUpdate>(
        '/teams/settings/bank-accounts',
    ),
    updateBankAccount: createPUT<{ id: Id }, BankAccountFromServer, BankAccountUpdate>(
        u => `${u.id.toIRI()}`,
    ),
    deleteBankAccount: createDELETE<{ id: Id }, EmptyObject>(
        u => `${u.id.toIRI()}`,
    ),
    createInvoicingProfile: createPOST<InvoicingProfileFromServer, InvoicingProfileInit>(
        '/teams/settings/profiles',
    ),
    replaceInvoicingProfile: createPOST<{ id: Id }, void, { replaceInvoicingProfile?: IRI }>(
        u => `${u.id.toIRI()}/delete-and-replace`,
    ),
    updateInvoicingProfile: createPUT<{ id: Id, type: 'general' | 'custom' }, InvoicingProfileFromServer, InvoicingProfileUpdate>(
        u => `${u.id.toIRI()}/${u.type}`,
    ),
};
