import React, { useEffect, useState } from 'react';
import { PricingsEditor, computeDefaultPricings, pricingsFormDataToNewPricings } from '@/components/calendar/PricingsEditor';
import { Form } from 'react-bootstrap';
import { useMaster } from '@/context/UserProvider';
import { api } from '@/utils/api/backend';
import useNotifications from '@/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '@/components/notifications';
import type { CurrencyIRI } from '@/modules/money';
import { useForm } from 'react-hook-form';
import { SpinnerButton } from '@/components/common';
import { useTranslation } from 'react-i18next';
import { InfoCard } from '@/components/settings/InfoCard';
import { TeamSettings } from '@/types/TeamSettings';

export type PricingFormItem = {
    currency: CurrencyIRI;
    duration: number | '';
    price: number | '';
};

type FormData = {
    pricings: PricingFormItem[];
};

export default function UserPricingsEditor() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.pricings' });
    const { teamSettings, setTeamSettings } = useMaster();
    const { addAlert } = useNotifications();
    const [ fetching, setFetching ] = useState(false);

    const form = useForm<FormData>({
        defaultValues: {
            pricings: computeDefaultPricings(teamSettings.pricings, teamSettings),
        },
    });
    const { handleSubmit } = form;

    async function onSubmit(data: FormData) {
        const pricings = pricingsFormDataToNewPricings(data);

        setFetching(true);
        const response = await api.settings.updatePricings({ pricings });
        setFetching(false);
        if (!response.status) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        setTeamSettings(TeamSettings.fromServer(response.data));
        addAlert(createTranslatedSuccessAlert('components:pricingsEditor.save-success-alert'));
    }

    useEffect(() => {
        form.reset({
            pricings: computeDefaultPricings(teamSettings.pricings, teamSettings),
        });
    }, [ teamSettings, form ]);

    return (
        <div>
            <InfoCard infoKey='pricingsGlobal' className='mb-4' />
            <div className='d-flex justify-content-center'>
                <Form className='w-fit sh-design' noValidate onSubmit={handleSubmit(onSubmit)}>
                    <PricingsEditor form={form} isForUser />
                    <div className='d-flex justify-content-end'>
                        <SpinnerButton type='submit' variant='primary' isFetching={fetching}>
                            {t('save-button')}
                        </SpinnerButton>
                    </div>
                </Form>
            </div>
        </div>
    );
}
